import { Box } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { store } from "src/store";
import ChatDateSeperator from "./ChatDateSeperator";

type Props = {
  renderMessages: any;
};

export default function ChatMessages({ renderMessages }: Props) {
  const { inboxReducer } = store.getState();
  const messageDates: Array<string> = [];
  useEffect(() => {}, [inboxReducer.chat_messages]);
  const messages = useMemo(
    () => (
      <Box>
        {inboxReducer.chat_messages?.map((message, index) => {
          const isNewDate = !messageDates.includes(
            moment(message?.creation_time).format("YYYY-MM-DD"),
          );
          if (isNewDate) {
            messageDates.push(
              moment(message?.creation_time).format("YYYY-MM-DD"),
            );
          }
          return (
            <React.Fragment key={index}>
              {isNewDate && <ChatDateSeperator date={message?.creation_time} />}
              {renderMessages(message, index)}
            </React.Fragment>
          );
        })}
      </Box>
    ),
    [inboxReducer.chat_messages, messageDates],
  );
  return messages;
}
