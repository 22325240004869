import { Button } from "@chakra-ui/react";
import React from "react";
import { connect } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
// eslint-disable-next-line import/no-unresolved, camelcase
import { getMoiUpdate_centerCheck_updates } from "src/api/services";
import { getUserBoards } from "../../actions/auctionsActions";
import {
  getAvailableChats,
  getNewChatsCount,
} from "../../actions/inboxActions";
import { getNewNotificationsCount } from "../../actions/notificationsActions";
import { getListsNew } from "../../actions/premiumActions";
import { getStandaloneReports } from "../../actions/reportsActions";
import {
  approveTerms,
  clearUser,
  getUserSideDetails,
  getUserTags,
} from "../../actions/userActions";
import { FloatingNotifications } from "./FloatingNotifications";
import { TopUserDetails } from "./TopUserDetails";
import { Academy } from "./academy/academy";
import { BrandReport } from "./brand_report/BrandReport";
import { EditBrand as BrandReportEdit } from "./brand_report/EditBrand";
import CampaignNavigation from "./campaign_components/CampaignNavigation";
import CampaignsSection from "./campaign_components/CampaignsSection";
import CreateCampaignSection from "./campaign_components/CreateCampaignSection";
import DashboardPage from "./campaign_components/Dashboard/DashboardPage";
import TodoList from "./campaign_components/TodoWindow/TodoList";
import ShareCampaignDialog from "./campaign_components/dialogs/ShareCampaignDialog";
import RoiSection from "./campaign_components/roi/RoiSection";
import ChatsSection from "./chats_components/ChatsSection";
import { FreeTrialDialog } from "./dialogs/FreeTrialDialog";
import InfluencerRatingDialog from "./dialogs/InfluencerRatingDialog";
import MimicUserDialog from "./dialogs/MimicUserDialog";
import TermsAndConditionsDialog from "./dialogs/TermsAndConditionsDialog";
import UpdatesDialog from "./dialogs/UpdatesDialog";
import EditDetailsSection from "./edit_details/EditDetailsSection";
import { EnterpriseInvite } from "./general_components/EnterpriseInvite";
import { CreateListSection } from "./lists_components/CreateListSection";
import InfluencersPage from "./lists_components/InfluencersPage";
import { ListSection } from "./lists_components/ListSection";
import ReportsSection from "./report_componenets/ReportsSection";
import InfluencersSearch from "./search_components/InfluencersSearch";
import AddToListMenu from "./side_menus/AddToListMenu";
import { InfluencerSegmentations } from "./side_menus/InfluencerSegmentations";
import SupportDialog from "./support_components/SupportDialog";

class MainPageInner extends React.Component {
  constructor(props) {
    super(props);

    this.tabs = [
      "influencers",
      "create-campaign",
      "campaigns",
      "campaign",
      "lists",
      "list",
      "create-list",
      "roi",
      "edit-details",
      "edit-list",
      "edit-campaign",
      "insights",
      "insight",
      "home",
      "inbox",
      "brand-report",
      "edit-brand-report",
      "ambassador",
      "create_ambassador",
      "academy",
      "multi_report",
      "request_data",
      "invitations",
      // to add my todo here
      "todo-list",
    ];
    const startIndex = -1;
    this.state = {
      mode: startIndex,
      tab: this.tabs[startIndex],
      campaingsLoaded: false,
      influencersLoaded: false,
      listsLoaded: false,
      influencerSegmentationsData: null,
      showChatsDialog: false,
      openSections: [],
      influnecersToList: [],
      showTrialModal: true,
      notificationsOpen: false,
      campaignSearch: "",
      listsSearch: "",
      reportsSearch: "",
      reportsLoaded: false,
      shareCampaignOpen: null,
      mimicUserOpen: false,
      showRatingDialog: false,
      showTodoList: false,
      hasRoi: false,
      showUpdatesDialog: false,
      hasNewUpdates: false,
    };
  }

  RefreshInterval;

  togglePreventExit = (status) => {
    this.setState({ preventExit: status });
  };

  toggleTodoList = () => {
    this.setState((prevState) => ({
      showTodoList: !prevState.showTodoList,
    }));
  };

  updateRoute = () => {
    // this.props.router.setRouteLeaveHook(this.props.route, (nextLocation) => {
    //     if (this.props.params.tab === 'ambassador' && !nextLocation.pathname.includes('ambassador')) {
    //         return 'You have unsaved information, are you sure you want to leave this page?';
    //     }
    // });

    let { tab } = this.props.params;
    if (this.props.location.pathname.indexOf("/academy") !== -1) {
      tab = "academy";
    }
    const index = this.tabs.indexOf(tab);
    if (index > -1) {
      this.setState({
        searchParams: this.props.searchParams,
        mode: index,
        tab,
        influencerSegmentationsData: null,
        influnecersToList: [],
      });
    } else {
      // window.location.replace('/home');
      this.setState({
        mode: 13,
        tab: "home",
        influencerSegmentationsData: null,
        influnecersToList: [],
      });
    }
  };

  checkNewUpdates = () => {
    getMoiUpdate_centerCheck_updates()
      .then((res) => {
        this.setState({ hasNewUpdates: res?.data?.updates_available }, () => {
          if (this.state.hasNewUpdates) {
            this.setState({ showUpdatesDialog: true });
          }
        });
      })
      .catch(() => {});
  };

  componentDidMount() {
    this.checkNewUpdates();
    if (localStorage.getItem("h-blur-user")) {
      document.getElementById("body").classList.add("blur-user");
    }

    document.getElementById("body").classList.add("main-body");
    this.updateRoute();
    this.props.getUserBoards(false, null, null, null, () =>
      this.setState({ campaignsLoaded: true }),
    );
    this.props.getListsNew(false, () => this.setState({ listsLoaded: true }));
    if (this.props.user && !this.props.user.parent) {
      this.props.getAvailableChats(true, null, null, null, () =>
        this.setState({ chatsLoaded: true }),
      );
      this.props.getNewChatsCount();
      this.props.getNewNotificationsCount();
      this.props.getUserTags();
      this.handleRefreshInterval();
      window.addEventListener("focus", this.handleRefreshInterval);
      window.addEventListener("blur", this.clearRefreshInterval);
    }

    const isMiniUser =
      this.props.user.userDetails &&
      this.props.user.userDetails.sidebar_data.is_sub_user;
    if (!isMiniUser) {
      this.props.getStandaloneReports(() =>
        this.setState({ reportsLoaded: true }),
      );
    }
    window.openInfluencerRating = this.handleOpenInfluencerRating;

    window.addEventListener("storage", this.handleLocalStorageChanged, false);
    // hotkeys('c,i,l,n,alt+n,s,d', function (event, handler) {
    //     switch (handler.key) {
    //         case "c": this.changeTab(2); break;
    //         case "i": this.setState({ showChatsDialog: !this.state.showChatsDialog }); break;
    //         case "l": this.changeTab(4); break;
    //         case "s": this.changeTab(0); break;
    //         case "alt+n": this.changeTab(1); break;
    //         case "d": this.changeTab(8); break;
    //         case "n": this.openNotifications(); break;
    //     }
    // }.bind(this));
  }

  handleRefreshInterval = () => {
    if (!this.RefreshInterval) {
      this.props.getNewChatsCount();
      this.props.getNewNotificationsCount();
      this.RefreshInterval = window.setInterval(() => {
        this.props.getNewChatsCount();
        this.props.getNewNotificationsCount();
      }, 10000);
    }
  };

  clearRefreshInterval = () => {
    if (this.RefreshInterval) {
      try {
        clearInterval(this.RefreshInterval);
        this.RefreshInterval = null;
      } catch (e) {
        /* Empty */
      }
    }
  };

  handleLocalStorageChanged = () => {
    let data = localStorage.getItem("MOI_USER_KEY_0_8_0");
    if (data) {
      data = JSON.parse(data);
      if (
        this.props.user?.userDetails?.user_id !== data?.userDetails?.user_id
      ) {
        window.location.href = "/home";
      }
    } else {
      window.location.href = "/";
    }
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.params.tab !== this.props.params.tab &&
      this.props.params.tab !== this.state.tab
    ) {
      this.updateRoute();
      window.scrollTo(0, 0);
    }
  }

  handleOpenInfluencerRating = (influencer) => {
    this.setState({ showRatingDialog: influencer });
  };

  componentWillUnmount() {
    document.getElementById("body").classList.remove("main-body");
    window.removeEventListener(
      "storage",
      this.handleLocalStorageChanged,
      false,
    );

    if (this.props.user && !this.props.user.parent) {
      window.removeEventListener("focus", this.handleRefreshInterval);
      window.removeEventListener("blur", this.clearRefreshInterval);
      this.clearRefreshInterval();
    }
  }

  openInfluencerSegmentations = (id, hideFollowers) => {
    this.setState({ influencerSegmentationsData: { id, hideFollowers } });
  };

  closeInfluencerSegmentations = () => {
    if (this.state.influencerSegmentationsData) {
      document.body.classList.remove("unscrollable");
      if (document.getElementById("segm_menu")) {
        document.getElementById("segm_menu").classList.remove("fadeInRight");
        document.getElementById("segm_menu").classList.add("fadeOutRight");
        document.getElementById("seg-shadow")?.classList.add("hidden");

        window.setTimeout(() => {
          this.setState({ influencerSegmentationsData: null });
        }, 500);
      } else {
        this.setState({ influencerSegmentationsData: null });
      }
    }
  };

  changeTab = (index) => {
    this.props.navigate(`/${this.tabs[index]}`);
  };

  openSection = (type) => {
    const { openSections } = this.state;
    openSections.addOrRemove(type);
    this.setState({ openSections });
  };

  closeAddToList = () => {
    if (
      this.state.influnecersToList &&
      this.state.influnecersToList.length === 0
    ) {
      return;
    }
    const element = document.getElementById("add_to_list_menu");
    if (element) {
      element.classList.remove("fadeInRight");
      element.classList.add("fadeOutRight");
      window.setTimeout(() => {
        this.setState({ influnecersToList: [] });
      }, 500);
    } else {
      this.setState({ influnecersToList: [] });
    }
  };

  closeNotifications = () => {
    if (!this.state.notificationsOpen) return;

    this.setState({ notificationsOpen: false });

    // let element = document.getElementById("notifications_side")
    // if (element)
    //     element.classList.add("fadeOutRight");
    // window.setTimeout(function () {
    //     this.setState({ notificationsOpen: false });
    // }.bind(this), 500);
  };

  openNotifications = () => {
    if (this.state.notificationsOpen) this.closeNotifications();
    else this.setState({ notificationsOpen: true });
  };

  openAddToList = (influnecersToList) => {
    this.setState({ influnecersToList });
  };

  changeCampaignTab = (type) => {
    const { mode } = this.state;
    if (mode === 10 || mode === 7 || mode === 3) {
      const { id } = this.props.params;
      if (type === "brief") this.props.navigate(`/edit-campaign/${id}`);
      else if (type === "discovery" || type === "manage") {
        this.props.navigate(`/campaign/${id}/${type}`);
      } else if (type === "roi") {
        const disabled = document.querySelector(
          ".campaign-top-bar .rc-tabs-tab-disabled",
        );
        if (disabled) return;
        this.props.navigate(`/roi/${id}`);
      }
    } else {
      this.changeTab(1);
    }
  };

  changeAcademyPage = (type, id, lessonId, quizId) => {
    const { mode } = this.state;
    if (mode === 19) {
      switch (type) {
        case "course":
          this.props.navigate(`/academy/${id}`);
          break;
        case "lesson":
          this.props.navigate(`/academy/${id}/${lessonId}`);
          break;
        case "quiz":
          this.props.navigate(`/academy/${id}/${lessonId}/quiz/${quizId}`);
          break;
        case "home":
        default:
          this.props.navigate("/academy");
          break;
      }
    }
  };

  navigateToLesson = (chapterId, lessonId) => {
    this.props.navigate(`/academy/${chapterId}/${lessonId}`);
  };

  closeChatDialog = (expand, campaignId, userId) => {
    if (expand) {
      this.setState({
        showChatsDialog: { campaign_id: campaignId, user_id: userId },
      });
      this.props.navigate("/inbox");
    } else {
      this.setState({ showChatsDialog: false });
    }
  };

  toggleShareCampain = (
    id = null,
    name = null,
    tab = null,
    influencers = [],
    shareGuid = null,
  ) => {
    this.setState({
      shareCampaignOpen:
        !id || !name
          ? null
          : { id, name, tab, influencers, share_guid: shareGuid },
    });
  };

  mimicUserOpenDialog = () => {
    this.setState({ mimicUserOpen: true });
  };

  toggleSupportDialog = (state) => {
    this.setState({ supportDialogOpen: state });
  };

  openInfluencerChatDialog = (data) => {
    this.setState({ showChatsDialog: data });
  };

  render() {
    const { mode, influencerSegmentationsData, showChatsDialog, openSections } =
      this.state;

    const userSideData =
      this.props.user && this.props.user.userDetails.sidebar_data
        ? this.props.user.userDetails.sidebar_data
        : { is_premium: true, can_open_campaigns: true };
    const motherAccount = this.props.user && this.props.user.parent;

    return (
      <div className="main">
        <TermsAndConditionsDialog
          should_update_toc={
            this.props.user?.userDetails?.sidebar_data?.should_update_toc
          }
        />
        {this.state.shareCampaignOpen && (
          <ShareCampaignDialog
            {...this.state.shareCampaignOpen}
            handleClose={() => this.toggleShareCampain(null, null, null, null)}
          />
        )}
        {this.state.mimicUserOpen && (
          <MimicUserDialog
            handleClose={() => {
              this.setState({ mimicUserOpen: false });
            }}
            confirm={() => {
              this.setState({ mimicUserOpen: false });
            }}
          />
        )}
        {this.state.notificationsOpen && (
          <FloatingNotifications closeNotifications={this.closeNotifications} />
        )}
        {showChatsDialog && mode !== 14 && (
          <ChatsSection
            floating={true}
            chatsLoaded={this.state.chatsLoaded}
            openInfluencerSegmentations={this.openInfluencerSegmentations}
            handleClose={this.closeChatDialog}
            data={showChatsDialog}
          />
        )}
        {!userSideData.is_premium &&
          !userSideData.pay_per_bid &&
          this.state.showTrialModal && (
            <FreeTrialDialog
              user={userSideData}
              handleClose={() => this.setState({ showTrialModal: false })}
            />
          )}
        {this.props.loaders.general && <div className="top-loader"></div>}
        <TopUserDetails
          openMimicDialog={this.mimicUserOpenDialog.bind(this)}
          logout={() => clearUser()}
          userDetails={this.props.user.userDetails}
          openNotifications={this.openNotifications}
          toggleSupportDialog={this.toggleSupportDialog}
          notifCount={this.props.notifications.unseen_notif_count}
        />
        <div className="main-container">
          <div className="logo">
            <Link to={"/home"}>
              <img src="/images/humanz-logo.png" />
            </Link>
          </div>
          <div className="main-side-container">
            {/* eslint-disable no-nested-ternary */}
            {!motherAccount && userSideData.can_open_campaigns && (
              <div
                className={`${mode === 1} main-side-row`}
                onClick={() => this.changeTab(1)}
              >
                <div
                  className={`create-campaign-btn ${
                    mode === 1 ? "active" : ""
                  }`}
                >
                  <Link
                    to={`/${this.tabs[1]}`}
                    style={{ marginLeft: 0, marginTop: 0 }}
                  >
                    <i
                      className="fad fa-plus-circle fa-lg"
                      style={{ marginRight: "9px" }}
                    ></i>
                    <span>New Campaign</span>
                  </Link>
                </div>
              </div>
            )}
            {userSideData.can_open_campaigns && (
              <>
                <div
                  className={`${
                    mode === 2 || mode === 3 || mode === 7 || mode === 10
                  } main-side-row`}
                >
                  {["campaign", "edit-campaign", "roi"].includes(
                    this.state.tab,
                  ) ? (
                    <>
                      <div className={`campaign-btn`}>
                        <div className={`campaign-btn-header`}>
                          <Link
                            to={`/${this.tabs[2]}`}
                            style={{ marginLeft: 0, marginTop: 0 }}
                          >
                            <i
                              className="fad fa-megaphone"
                              style={{ marginRight: "9px" }}
                            ></i>
                            <span>Campaigns</span>
                          </Link>
                        </div>
                        <div
                          className={`sub-campaign-btn ${
                            this.props.location.pathname ===
                            `/edit-campaign/${this.props.params.id}`
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link to={`/edit-campaign/${this.props.params.id}`}>
                            <i
                              className="fad fa-file-alt"
                              style={{ marginRight: "11px", marginLeft: "5px" }}
                            ></i>
                            <span>Brief</span>
                          </Link>
                        </div>
                        <div
                          className={`sub-campaign-btn ${
                            this.props.location.pathname ===
                            `/campaign/${this.props.params.id}/discovery`
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link
                            to={`/campaign/${this.props.params.id}/discovery`}
                          >
                            <i
                              className="fad fa-magnifying-glass-plus"
                              style={{ marginRight: "9px", marginLeft: "5px" }}
                            ></i>
                            <span>Discovery</span>
                          </Link>
                        </div>
                        <div
                          className={`sub-campaign-btn ${
                            this.props.location.pathname ===
                            `/campaign/${this.props.params.id}/list`
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link to={`/campaign/${this.props.params.id}/list`}>
                            <i
                              className="fad fa-file-user"
                              style={{ marginRight: "12px", marginLeft: "5px" }}
                            ></i>
                            <span>List</span>
                          </Link>
                        </div>
                        <div
                          className={`sub-campaign-btn ${
                            this.props.location.pathname ===
                            `/campaign/${this.props.params.id}/manage`
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link to={`/campaign/${this.props.params.id}/manage`}>
                            <i
                              className="fad fa-list-check"
                              style={{ marginRight: "9px", marginLeft: "5px" }}
                            ></i>
                            <span>Manage</span>
                          </Link>
                        </div>
                        <div
                          className={`sub-campaign-btn ${
                            this.props.location.pathname ===
                            `/roi/${this.props.params.id}`
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link to={`/roi/${this.props.params.id}`}>
                            <i
                              className="fad fa-chart-area"
                              style={{ marginRight: "9px", marginLeft: "5px" }}
                            ></i>
                            <span>Report</span>
                          </Link>
                        </div>
                        <div
                          className={`sub-campaign-btn  ${
                            this.props.location.pathname ===
                            `/campaign/${this.props.params.id}/payments`
                              ? "active"
                              : ""
                          }`}
                        >
                          <Link
                            to={`/campaign/${this.props.params.id}/payments`}
                          >
                            <i
                              className="fad fa-sack-dollar"
                              style={{ marginRight: "9px", marginLeft: "5px" }}
                            ></i>
                            <span>Payments</span>
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Link
                        to={`/${this.tabs[2]}`}
                        style={{ marginLeft: 0, marginTop: 0 }}
                      >
                        <i
                          className="fad fa-megaphone"
                          style={{ marginRight: "13px" }}
                        ></i>
                        <span>Campaigns</span>
                      </Link>
                    </>
                  )}
                </div>
              </>
            )}
            {!motherAccount && (
              <div
                className={`${mode === 0} main-side-row `}
                onClick={() => this.changeTab(0)}
              >
                <Link
                  to={`/${this.tabs[0]}`}
                  style={{ marginLeft: 0, marginTop: 0 }}
                >
                  <i
                    className="fad fa-user-magnifying-glass"
                    style={{ marginRight: "9px" }}
                  ></i>
                  <span>Discovery</span>
                </Link>
              </div>
            )}

            {/* eslint-disable no-nested-ternary */}

            <div
              className={`${
                (mode >= 4 && mode <= 6) || mode === 9
              } main-side-row `}
              onClick={() => this.changeTab(4)}
            >
              <Link
                to={`/${this.tabs[4]}`}
                style={{ marginLeft: 0, marginTop: 0 }}
              >
                <i className="fad fa-users" style={{ marginRight: "10px" }}></i>
                <span>Lists</span>
              </Link>
            </div>

            <div className={`${mode === 11 || mode === 12} main-side-row`}>
              {/* <i
                  className={`fa moi-arrow-left ${openSections.includes(
                    "insights",
                  )}`}
                  onClick={() => this.openSection("insights")}
                /> */}
              {/* <Link
                  to={`/${this.tabs[11]}`}
                  style={{ marginLeft: 0, marginTop: -2, fontSize: 15 }}
                >
                  <div className="far fa-file-chart-line"></div>
                </Link>
                <Link to={`/${this.tabs[11]}`}>Insights</Link> */}
              <Link
                to={`/${this.tabs[11]}`}
                style={{ marginLeft: 0, marginTop: -2 }}
              >
                <i
                  className="fad fa-square-poll-vertical"
                  style={{ marginRight: "15px" }}
                ></i>
                <span>Insights</span>
              </Link>
            </div>

            {openSections.includes("insights") && (
              <div className="main-side-row-section">
                <div className="inner-toolbar-search-input">
                  <div
                    className="moi-search-bold"
                    style={{ position: "relative", marginRight: -18 }}
                  />
                  <input
                    style={{ width: 145, marginRight: -85 }}
                    className={"form-control "}
                    onChange={(e) =>
                      this.setState({
                        reportsSearch: e.target.value.toLowerCase(),
                      })
                    }
                    value={this.state.reportsSearch}
                  />
                </div>
                {this.props.reportsReducer.reports
                  .filter(
                    (x) =>
                      x.name &&
                      x.name.toLowerCase().includes(this.state.reportsSearch),
                  )
                  .map((x) => {
                    const url =
                      x.insight_type === "ambassador_report"
                        ? "ambassador"
                        : "create_ambassador";
                    return (
                      <div key={x.id} className="blurable">
                        <Link title={x.name} to={`/${url}/${x.id}`}>
                          {x.name}
                        </Link>
                      </div>
                    );
                  })}
              </div>
            )}
            {!motherAccount && (
              <div
                className={`main-side-row ${showChatsDialog || mode === 14}`}
                onClick={() => {
                  if (mode === 14) return;
                  this.setState({
                    showChatsDialog: !this.state.showChatsDialog,
                  });
                }}
              >
                <div
                  className="fad fa-comments"
                  style={{ marginTop: 2.3, marginRight: "14px" }}
                />
                <div>
                  <span>Chats</span>

                  {Object.keys(this.props.inbox.new_chats).length > 0 && (
                    <div className="indication-dot" />
                  )}
                </div>
              </div>
            )}

            <div
              className={`${mode === 19} main-side-row `}
              onClick={() => this.changeTab(19)}
            >
              <Link
                to={`/${this.tabs[19]}`}
                style={{ marginLeft: 0, marginTop: -2, fontSize: 15 }}
              >
                <i
                  className="fad fa-graduation-cap"
                  style={{ marginRight: "9px" }}
                ></i>
                <span>Academy</span>
              </Link>
            </div>
            {!userSideData.is_premium && (
              <div>
                <div className="main-side-free-trial">
                  <div style={{ textAlign: "center" }}>
                    <span>Free trial mode</span>
                  </div>
                  <div>
                    {userSideData.number_of_searches} of{" "}
                    {userSideData.max_number_of_searches}
                  </div>
                  <div>
                    <span>
                      You are on <br />{" "}
                      <span style={{ color: "rgb(37, 189, 126)" }}>
                        FREE TRIAL MODE
                      </span>
                      <br /> To upgrade your subscription and start using Humanz
                      deep analysis and unique reach features, please refer to{" "}
                      <Link
                        to={"/home"}
                        style={{ color: "var(--main-baby-blue-color)" }}
                      >
                        {" "}
                        features list
                      </Link>
                    </span>
                  </div>
                </div>
                <button
                  onClick={() =>
                    this.props.navigate("/edit-details/payment-method")
                  }
                  className="btn btn-primary"
                  style={{
                    background: "rgb(37, 189, 126)",
                    marginLeft: 10,
                    marginTop: 15,
                    border: "none",
                    padding: "5px 50px",
                    fontSize: 20,
                  }}
                >
                  Upgrade
                </button>
              </div>
            )}

            <div
              className={`main-side-row `}
              style={{ position: "absolute", bottom: 0 }}
            >
              <Link
                as={Button}
                onClick={() => this.setState({ showUpdatesDialog: true })}
                style={{ marginLeft: 0, marginTop: -2, fontSize: 15 }}
              >
                <i
                  className="fad fa-newspaper"
                  style={{ marginRight: "9px" }}
                ></i>
                {this.state.hasNewUpdates ? (
                  <div className="indication-dot" />
                ) : null}
                <span>Updates</span>
              </Link>
            </div>
          </div>

          <div className="main-middle">
            <div
              className={`campaign-indication ${mode === 10 ? "draft" : ""}`}
            >
              {mode === 3 || mode === 7 ? (
                <div
                  id="share-campaign-btn"
                  onClick={() => {
                    const button = document.getElementById("share-campaign");
                    if (button) {
                      button.click();
                    }
                  }}
                >
                  <div
                    className="moi-forward-arrow2"
                    style={{ paddingTop: "4px" }}
                  ></div>
                  <div style={{ marginLeft: "5px" }}>Share Campaign</div>
                </div>
              ) : null}
              {["campaign", "edit-campaign", "roi"].includes(
                this.state.tab,
              ) && (
                <TodoList
                  isOpen={this.state.showTodoList}
                  setShowTodoList={this.toggleTodoList}
                />
              )}
            </div>
            {influencerSegmentationsData && (
              <InfluencerSegmentations
                openInfluencerChatDialog={this.openInfluencerChatDialog}
                id={influencerSegmentationsData.id}
                hideFollowers={influencerSegmentationsData.hideFollowers}
                closeInfluencerSegmentations={this.closeInfluencerSegmentations}
                openAddToList={this.openAddToList}
                openInfluencerSegmentations={this.openInfluencerSegmentations}
              />
            )}
            {this.state.influnecersToList.length > 0 && (
              <AddToListMenu
                auctionId={this.props.params.id}
                close={this.closeAddToList}
                influencers={this.state.influnecersToList}
              />
            )}
            {userSideData.can_open_campaigns && mode === 1 && (
              <CreateCampaignSection id={this.props.params.id} />
            )}
            {userSideData.can_open_campaigns && mode === 2 && (
              <CampaignsSection
                campaingsLoaded={this.state.campaingsLoaded}
                toggleShareCampain={this.toggleShareCampain}
              />
            )}
            {mode === 0 && (
              <InfluencersSearch
                closeAddToList={this.closeAddToList}
                openAddToList={this.openAddToList}
                loaded={this.state.influencersLoaded}
                openInfluencerSegmentations={this.openInfluencerSegmentations}
                closeInfluencerSegmentations={this.closeInfluencerSegmentations}
              />
            )}
            {mode === 3 && (
              <CampaignNavigation
                closeChatDialog={this.closeChatDialog}
                toggleShareCampain={this.toggleShareCampain}
                listsLoaded={this.state.listsLoaded}
                tab={this.props.params.tab}
                id={this.props.params.id}
                share_guid={this.props.params.share_guid}
                section={this.props.params.section}
                influencersLoaded={this.state.influencersLoaded}
                closeAddToList={this.closeAddToList}
                openAddToList={this.openAddToList}
                openInfluencerSegmentations={this.openInfluencerSegmentations}
                closeInfluencerSegmentations={this.closeInfluencerSegmentations}
                openChat={this.openInfluencerChatDialog}
              />
            )}
            {mode === 4 && (
              <InfluencersPage
                listsLoaded={this.state.listsLoaded}
                tab="influencers_lists"
                user={this.props.user}
                openAddToList={this.openAddToList}
              />
            )}
            {mode === 5 && (
              <>
                <ListSection
                  listsLoaded={this.state.listsLoaded}
                  closeInfluencerSegmentations={
                    this.closeInfluencerSegmentations
                  }
                  id={this.props.params.id}
                  tab={this.props.params.tab}
                  openAddToList={this.openAddToList}
                  openInfluencerSegmentations={this.openInfluencerSegmentations}
                />
              </>
            )}
            {mode === 6 && <CreateListSection />}
            {(mode === 7 ||
              mode === 12 ||
              mode === 17 ||
              mode === 18 ||
              mode === 20) && (
              <RoiSection
                openShareCampaign={this.toggleShareCampain}
                isAmbassador={mode === 17 || mode === 18}
                isAmbassadorUnfinished={mode === 18}
                togglePreventExit={this.togglePreventExit}
                fromCampaign={mode === 7}
                id={this.props.params.id}
                openAddToList={this.openAddToList}
                userCurrency={this.props.user.userDetails.currency}
                isMultiReport={mode === 20}
                ambassadorPrice={userSideData.ambassador_report_price}
                route={this.props.route}
                openInfluencerSegmentations={this.openInfluencerSegmentations}
              />
            )}
            {mode === 8 && (
              <EditDetailsSection
                searchParams={this.state.searchParams}
                tab={this.props.params.id}
                user={this.props.user}
              />
            )}
            {mode === 9 && (
              <CreateListSection editMode={true} id={this.props.params.id} />
            )}
            {mode === 10 && (
              <CreateCampaignSection
                editMode={true}
                id={this.props.params.id}
              />
            )}
            {mode === 11 && <ReportsSection />}
            {mode === 13 && <DashboardPage />}
            {mode === 14 && (
              <ChatsSection
                floating={false}
                chatsLoaded={this.state.chatsLoaded}
                handleClose={() => this.setState({ showChatsDialog: false })}
                data={showChatsDialog}
              />
            )}
            {mode === 15 && <BrandReport />}
            {mode === 16 && <BrandReportEdit id={this.props.params.id} />}
            {mode === 19 && (
              <Academy
                params={this.props.params}
                changeAcademyPage={this.changeAcademyPage}
              />
            )}
            {mode === 22 && (
              <InfluencersPage
                listsLoaded={this.state.listsLoaded}
                tab="external_influencers"
                user={this.props.user}
                openAddToList={this.openAddToList}
              />
            )}
          </div>
        </div>
        <ToastContainer pauseOnFocusLoss={false} />
        {/* <DesktopNotifications /> */}
        {this.state.supportDialogOpen && (
          <SupportDialog
            user={this.props.user.userDetails}
            handleClose={() => {
              this.toggleSupportDialog(false);
            }}
          />
        )}
        {this.state.showRatingDialog && (
          <InfluencerRatingDialog
            influencer={this.state.showRatingDialog}
            onClose={() => this.setState({ showRatingDialog: false })}
          />
        )}
        <EnterpriseInvite user={this.props.user} />
        {/* <MarkDownDialog /> */}
        {this.state.showUpdatesDialog ? (
          <UpdatesDialog
            handleClose={() => {
              this.setState({ showUpdatesDialog: false });
            }}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
    loaders: state.loadersReducer,
    popups: state.notifications,
    boardReducer: state.boardReducer,
    lists: state.listsReducer.lists,
    inbox: state.inboxReducer,
    notifications: state.notificationsReducer,
    reportsReducer: state.reportsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListsNew: (archive, callback) => {
      dispatch(getListsNew(archive, callback));
    },
    getUserBoards: (archive, filter, lastTime, tags, callback) => {
      dispatch(getUserBoards(archive, filter, lastTime, tags, callback));
    },
    getAvailableChats: (
      cleanMessages,
      type,
      maxSeenTime,
      nameFilter,
      callBack,
    ) => {
      dispatch(
        getAvailableChats(
          cleanMessages,
          type,
          maxSeenTime,
          nameFilter,
          callBack,
        ),
      );
    },
    getNewChatsCount: () => {
      dispatch(getNewChatsCount());
    },
    getNewNotificationsCount: () => {
      dispatch(getNewNotificationsCount());
    },
    getStandaloneReports: (callback) => {
      dispatch(getStandaloneReports(callback));
    },
    getUserSideDetails: () => {
      dispatch(getUserSideDetails());
    },
    getUserTags: () => {
      dispatch(getUserTags());
    },
    approveTerms: () => {
      dispatch(approveTerms());
    },
  };
};

const MainPageConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainPageInner);

export const MainPage = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  return (
    <MainPageConnected
      {...props}
      navigate={navigate}
      params={params}
      location={location}
      searchParams={searchParams}
    />
  );
};
