import NoDataMessage from "../general_components/NoDataMessage";

type Props = {
  text?: string;
};

export default function StartNewChat({ text }: Props) {
  return (
    <NoDataMessage
      style={{
        fontSize: 18,
        textAlign: "center",
        marginTop: 10,
        alignContent: "center",
        background: "transparent",
        filter: "grayscale(1)",
      }}
    >
      <img src={"/images/no_tasks.png"} />
      <br />
      {text ? text : "Start a new chat"}
    </NoDataMessage>
  );
}
