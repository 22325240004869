import { Box, Flex } from "@chakra-ui/react";
import moment from "moment-timezone";

type Props = {
  date: string | undefined;
};

export default function ChatDateSeperator({ date }: Props) {
  const dayName = moment(date).format("dddd");
  return (
    <Flex style={{ placeContent: "center" }} className="date-seperator">
      <Box
        sx={{
          color: "#434D56",
          fontSize: "12px",
          fontWeight: "500",
          textTransform: "uppercase",
          margin: "10px 0",
          background: "#F6F6F6",
          boxShadow: " 0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
          display: "inline-flex",
          borderRadius: 5,
          padding: "10px",
        }}
      >
        {dayName} {moment(date).format("MMMM D, YYYY")}
      </Box>
    </Flex>
  );
}
