import { useMemo } from "react";
import Textarea from "react-textarea-autosize";

type Props = {
  setState: any;
  handleKeyPress: any;
  message: string;
};

const ChatInput = ({ setState, message, handleKeyPress }: Props) => {
  return useMemo(
    () => (
      <Textarea
        onFocus={() => setState({ showEmojiPicker: false })}
        value={message}
        onChange={(e) => {
          setState({ message: e.target.value });
        }}
        className="clickable"
        placeholder="Type your message..."
        minRows={1}
        maxRows={7}
        dir="auto"
        id="chat-textarea"
        onKeyPress={handleKeyPress}
      />
    ),
    [message],
  );
};

export default ChatInput;
