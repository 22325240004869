// prettier-ignore
import "./instrument";
// TODO - lint
/* eslint-disable no-underscore-dangle, no-underscore-dangle, no-param-reassign, no-param-reassign, no-plusplus, no-underscore-dangle, no-param-reassign, no-param-reassign */
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { setAutoFreeze } from "immer";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./actions/security";
import { setUserFromLocalStorage } from "./actions/userActions";
import { MainPage } from "./components/feed_components/MainPage";
import PeachRedirectPage from "./components/feed_components/payments_components/PeachRedirectPage";
import ConnectSocialApi from "./components/feed_components/shared_components/ConnectSocialAPI";
import { SharedContainer } from "./components/feed_components/shared_components/SharedContainer";
import AgencyInvoices from "./components/feed_components/shared_components/agency_invoices/AgencyInvoices";
import { CookiesPolicy } from "./components/global_components/CookiesPolicy";
import { LicensesWebPage } from "./components/global_components/LicensesWebPage";
import PageCrash from "./components/global_components/PageCrash";
import { Wizard } from "./components/global_components/pixelWizard/Wizard";
import CompleteLoginPage from "./components/login_components/CompleteLoginPage";
import DeclineInvite from "./components/login_components/DeclineInvite";
import GoogleLoginPage from "./components/login_components/GoogleLoginPage";
import LoginPage from "./components/login_components/LoginPage";
import MoveToMainPage from "./components/login_components/MoveToMainPage";
import MoveToShop from "./components/login_components/MoveToShop";
import OpenAppLink from "./components/login_components/OpenAppLink";
import TipaltiAgentSuccess from "./components/login_components/TipaltiAgentSuccess";
import TipaltiIframe from "./components/login_components/TipaltiIframe";
import { App } from "./containers/App";
import "./css";
import "./global";
import { PromptProvider } from "./hooks/promt";
import "./i18n";
import { lang as enLang } from "./languages/en";
import { store } from "./store";
import { AppChakraProvider } from "./ui/ChakraProvider";

const queryClient = new QueryClient();

if (Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Jerusalem") {
  const script = document.createElement("script");
  window.nl_compact = "1";
  window.nl_pos = "br";
  window.nl_lang = "en";
  window.nl_contact = "p:+16469804766|u:info+d:humanz.ai";
  window.nl_dir = "/nagish/nl-files/";
  // Set the script source or content
  script.src = "/nagish/nagishli.js"; // External script
  script.charset = "utf-8";
  script.defer = true;
  // script.textContent = 'console.log("Hello from dynamically injected script!");'; // Inline script

  // Append the script element to the document body
  document.body.appendChild(script);
}

const init = () => {
  ReactGA.initialize("UA-98083652-1");

  let Index = LoginPage;

  if (
    localStorage.getItem("MOI_USER_KEY_0_8_0") !== null &&
    localStorage.getItem("moi-jwt-token") !== undefined
  ) {
    const user = JSON.parse(localStorage.getItem("MOI_USER_KEY_0_8_0"));
    store.dispatch(setUserFromLocalStorage(user));
    if (user.userDetails) {
      try {
        const details = user.userDetails;
        ReactGA.set({ userId: details.user_id });
        Sentry.setUser({ id: details.user_id });
        const isPresenter = details.is_presenter;
        if (isPresenter === false) {
          if (
            user.finished_basic_details ||
            (details.sidebar_data &&
              details.sidebar_data.finished_basic_details)
          ) {
            Index = MainPage;
          } else {
            Index = CompleteLoginPage;
          }
        } else if (isPresenter === true) {
          Index = LoginPage;
        } else {
          Index = LoginPage;
        }
      } catch (err) {
        Index = LoginPage;
      }
    }
  }

  window.lang = enLang;

  localStorage.setItem("MOI_LANG", "en");
  return Index;
};

const Index = init();

setAutoFreeze(false);
const domNode = document.getElementById("root");
const root = createRoot(domNode);
const AppWrapper = Sentry.withProfiler(() => {
  return (
    <Sentry.ErrorBoundary fallback={<PageCrash />}>
      <AppChakraProvider>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PromptProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<App />}>
                    <Route index element={<Index />} />
                    <Route
                      path="/cookie-policy"
                      element={<CookiesPolicy />}
                    ></Route>
                    <Route
                      path="/connect-influencer/:auth"
                      element={<ConnectSocialApi />}
                    />
                    <Route
                      path="/talent-manager/:auth"
                      element={<ConnectSocialApi />}
                    />
                    <Route
                      path="/ag-invoice/:auth"
                      element={<AgencyInvoices />}
                    />
                    <Route
                      path="/web-opensources"
                      element={<LicensesWebPage />}
                    />
                    <Route path="/pixel_wizard/:guid" element={<Wizard />} />
                    <Route
                      path="/pixel_wizard/:guid/:step"
                      element={<Wizard />}
                    />
                    <Route
                      path="/forgot-password/challenge"
                      element={<LoginPage />}
                    />
                    <Route
                      path="/tipalti/fill_details/:guid"
                      element={<TipaltiIframe />}
                    />
                    <Route
                      path="/tipalti/success"
                      element={<TipaltiAgentSuccess />}
                    />
                    <Route
                      path="/email-validation/validation"
                      element={<LoginPage />}
                    />
                    <Route
                      path="/enterprise_invite/:invite_id/decline"
                      element={<DeclineInvite />}
                    />
                    <Route path="/post/:id" element={<OpenAppLink />} />
                    <Route
                      path="/app-email-validation/validation"
                      element={<OpenAppLink />}
                    />
                    <Route
                      path="/reference/downloadapp"
                      element={<MoveToShop />}
                    />
                    <Route
                      path="/reference/gotomain"
                      element={<MoveToMainPage />}
                    />
                    <Route
                      path="/app-redirect/google-signin"
                      element={<GoogleLoginPage />}
                    />
                    <Route
                      path="/app-redirect/external-link/:external"
                      element={<OpenAppLink />}
                    />
                    <Route
                      path="/complete-signup"
                      element={<CompleteLoginPage />}
                    ></Route>
                    <Route
                      path="/shares/campaign/:share_type/:auth"
                      element={<SharedContainer />}
                    ></Route>
                    <Route
                      path="/shares/campaign/:share_type/:auth/:id"
                      element={<SharedContainer />}
                    ></Route>
                    <Route
                      path="/shares/:share_type/:auth/:id"
                      element={<SharedContainer />}
                    ></Route>
                    <Route
                      path="/shares/:share_type/:auth"
                      element={<SharedContainer />}
                    ></Route>
                    <Route
                      path="/payments/peach"
                      element={<PeachRedirectPage />}
                    ></Route>
                    <Route path="/academy" element={<Index />} />
                    <Route path="/academy/:course" element={<Index />} />
                    <Route
                      path="/academy/:chapter/:lesson"
                      element={<Index />}
                    />
                    <Route
                      path="/academy/:chapter/:lesson/quiz/:quiz_id"
                      element={<Index />}
                    />
                    <Route path="/:tab" element={<Index />} />
                    <Route path="/:tab/:id" element={<Index />} />
                    <Route path="/:tab/:id/:section" element={<Index />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </PromptProvider>
          </Provider>
        </QueryClientProvider>
      </AppChakraProvider>
      ,
    </Sentry.ErrorBoundary>
  );
});
root.render(<AppWrapper />);
