// TODO - lint
/* eslint-disable no-unused-vars, react/no-deprecated, camelcase, class-methods-use-this, consistent-return, class-methods-use-this, no-param-reassign, consistent-return, consistent-return, import/no-default-export */
// General Imports
import React from "react";
import { connect } from "react-redux";
import Textarea from "react-textarea-autosize";
// Actions imports
import {
  getAvailableChats,
  sendFileMessage,
  sendFileMessageMultipleMessage,
  sendMultipleMessage,
} from "../../../actions/inboxActions";
import { setToasterNotificaion } from "../../../actions/notificationsActions";
// Components Import
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import Tooltip from "rc-tooltip";
import NoImage from "../../../images/svgs/placeholder.svg";
import { FileTypes, FileUpload } from "../../../ui/FileUpload";
import { FormFooterButtonGroup } from "../../../ui/FormFooterButtonGroup";

class SendMultiMessageSide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      message: "",
      selectedLists: [],
      sending: false,
      columns: [
        { label: "bids", name: "bids", collapse: true },
        { label: "invites", name: "invite", collapse: true },
        { label: "approval / publish", name: "approval", collapse: true },
      ],
      file: null,
      fileUrl: null,
      openUploadModal: false,
    };
  }

  componentWillMount() {
    const selectedLists = [];
    if (this.props.influencersSelected.length > 0) {
      this.props.influencers.forEach((x) => {
        if (
          (x.humanz_fk || x.influencer_agent?.agent_user_id) &&
          this.props.influencersSelected.includes(x.influencer_id)
        ) {
          selectedLists.push(x.influencer_id);
        }
      });
    }
    this.setState({ selectedLists });
  }

  onListSelect(id) {
    const { selectedLists } = this.state;
    selectedLists.addOrRemove(id);
    this.setState({ selectedLists });
  }

  handleFileUpload(image_url, fileType) {
    const fileTypes = ["image", "video", "pdf"];
    this.setState({
      openUploadModal: false,
      fileUrl: image_url,
      fileType: fileTypes[fileType],
    });
  }

  sendMessage() {
    const { message, fileUrl } = this.state;
    const counters = this.state.selectedLists.length;

    if ((!message && !fileUrl) || this.state.sending || counters.length === 0) {
      return;
    }

    const campaign_id = this.props.board.board.board_id;
    //concat all influencers
    let allInfluencers = (
      this.props.board.board.states.bids?.influencers ?? []
    ).concat(this.props.board.board.states.invite?.influencers ?? []);
    allInfluencers = allInfluencers.concat(
      this.props.board.board.states.approval?.influencers ?? [],
    );

    const influencersObjToSend = allInfluencers
      .filter((x) => this.state.selectedLists.includes(x.influencer_id))
      .map((x) => {
        return {
          influencer_id: x.influencer_id,
          influencer_user_id: x.humanz_fk,
          agent_user_id: x.influencer_agent?.agent_user_id,
        };
      });

    this.setState({ sending: true });
    let attachment = null;
    if (this.state.fileUrl) {
      attachment = {
        url: this.state.fileUrl,
        attachment_type: this.state.fileType,
      };
    }
    this.props.sendMultipleMessage(
      influencersObjToSend,
      campaign_id,
      message,
      attachment,
      () => {
        this.setState({ sending: false });
        this.props.setToasterNotificaion("Messages sent successfully");
        this.props.getAvaliableChats();
        this.props.handleClose();
      },
    );
  }

  renderRow(data, idx) {
    let influencerName = data.name;
    let agentName = data.influencer_agent?.agent_user_id
      ? data.influencer_agent?.name
      : null;

    const selectedCheckBox = this.state.selectedLists.includes(
      data.influencer_id,
    );
    return (
      <div
        className="import-list-row"
        key={idx}
        style={{
          paddingLeft: 6,
          paddingRight: 10,
          justifyContent: "space-around",
        }}
      >
        <div style={{ width: 45, height: 45 }}>
          <img
            className="blurable"
            style={{ width: 45, height: 45 }}
            src={data.picture_url ? data.picture_url : NoImage}
          />
          {agentName && (
            <div
              style={{
                position: "relative",
                left: 30,
                bottom: 18,
                background: "#EEF0F1",
                height: 24,
                width: 24,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #D8DADB",
              }}
            >
              <i
                className="fa-duotone fa-user-tie"
                style={{
                  fontSize: 16,
                }}
              />
            </div>
          )}
        </div>
        <div
          className={"import-list-row-details "}
          style={{
            display: "flex",
            boxShadow: selectedCheckBox
              ? "none"
              : "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: 6,
            background: selectedCheckBox ? "#249FF0" : "white",
            marginLeft: 15,
          }}
        >
          <strong
            style={{
              width: 350,
              color: this.state.selectedLists.includes(data.influencer_id)
                ? "white"
                : "black",
            }}
            onClick={() => this.onListSelect(data.influencer_id)}
            // className="import-list-name blurable-large"
            title={data.name}
          >
            {!data.user_registered && `${agentName} (${influencerName})`}
            {data.user_registered && agentName && (
              <div>
                <span>{influencerName}</span>
                <span style={{ color: "gray" }}> X </span>
                <span>{agentName}</span>
              </div>
            )}
            {data.user_registered && !agentName && influencerName}
          </strong>
        </div>
        <div
          className="checkbox"
          style={{ padding: 0, paddingTop: 0, marginLeft: -10 }}
        >
          <label>
            <input
              type="checkbox"
              checked={this.state.selectedLists.includes(data.influencer_id)}
              onChange={() => this.onListSelect(data.influencer_id)}
            />
            <span className="checkbox-material">
              <span className="check"></span>
            </span>
          </label>
        </div>
      </div>
    );
  }

  getConcatColumn(data, selectedColumn) {
    const column = { notifications: 0, influencers: [] };
    const columndata = data
      ? data.states[this.state.columns[selectedColumn].name]
      : null;
    if (columndata) {
      column.influencers = columndata.influencers;
    }

    if (selectedColumn === 3) {
      column.influencers = column.influencers.concat(
        data && data.states[this.state.columns[2].name]
          ? data.states[this.state.columns[2].name].influencers
          : [],
      );
    }
    // apply sorting..
    if (
      this.state.sortKey &&
      column.influencers &&
      column.influencers.length > 0
    ) {
      this.sortInfluencers(column.influencers, this.state.sortKey);
    }
    column.influencers = column.influencers.filter(
      (x) => x.user_registered || x.influencer_agent?.agent_user_id,
    );
    return column;
  }

  arrayInArray(arr1, arr2) {
    if (!arr1 || arr1.length === 0 || !arr2 || arr2.length === 0) return;
    let flag = true;
    arr1.forEach((x) => {
      if (flag) {
        flag = arr2.includes(x);
      }
    });
    return flag;
  }

  chcekAll(checked, ids) {
    if (ids.length === 0) return;
    const selected = this.state.selectedLists;
    if (checked) {
      ids.forEach((x) => {
        if (selected.includes(x)) {
          selected.addOrRemove(x);
        }
      });
    } else {
      ids.forEach((x) => {
        if (!selected.includes(x)) {
          selected.addOrRemove(x);
        }
      });
    }

    this.setState({ selectedLists: selected });
  }

  openFilePicker = () => {
    document.getElementById("upload-file").click();
  };

  addFile = (files) => {
    this.setState({ file: files[0] });
  };

  renderSection(index) {
    const column = this.state.columns[index];
    const data = this.props.board.board;
    let { influencers } = this.getConcatColumn(data, index);
    influencers = influencers.filter((x) => {
      x.name = x.name ? x.name : "";
      return x.name.toLowerCase().includes(this.state.search.toLowerCase());
    });
    if (influencers.length === 0) return;

    const ids = influencers.map((x) => x.influencer_id);
    const checked = this.arrayInArray(ids, this.state.selectedLists);
    return (
      <div style={{ background: "#f5f5f5", borderRadius: 8, paddingBottom: 8 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: 20,
            // marginBottom: 10,
            marginTop: 15,
            background: "#f5f5f5",
            padding: "8px 10px",
            paddingBottom: 0,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 5,
              alignItems: "center",
            }}
          >
            <i
              className={`fa-duotone fa-solid fa-circle-${
                column.collapse ? "plus" : "minus"
              }`}
              onClick={() => {
                column.collapse = !column.collapse;
                this.setState({});
              }}
              style={{
                cursor: "pointer",
                fontSize: 20,
              }}
            />
            <strong
              className="label-title"
              style={{
                fontSize: 15,
                display: "block",
                color: "#333",
                marginBottom: 0,
              }}
            >
              {/* <i class="fa-duotone fa-solid fa-circle-plus"></i> */}

              {/* <span
              className={`collapse-input ${column.collapse}`}
              onClick={() => {
                column.collapse = !column.collapse;
                this.setState({});
              }}
            /> */}
              {column.label}
            </strong>
          </div>
          <div
            className="checkbox"
            style={{
              padding: 0,
              paddingTop: 0,
              margin: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: 12, fontWeight: 500, color: "#808080" }}>
              Select all
            </span>

            <label style={{ paddingLeft: 5 }}>
              <input
                type="checkbox"
                checked={checked}
                onChange={() => this.chcekAll(checked, ids)}
              />
              <span className="checkbox-material">
                <span className="check"></span>
              </span>
            </label>
          </div>
        </div>
        {!column.collapse &&
          influencers.map((x, idx) => this.renderRow(x, idx))}
      </div>
    );
  }

  render() {
    if (!this.props.board.board) return;

    return (
      <div
        className="import-from-list-container animated fadeInRight faster"
        id="multi_messages_side"
        style={{ padding: 0 }}
      >
        <div style={{ background: "#FAFAFA" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 5%",
              borderTop: "1px solid #EAE8E8",
            }}
          >
            <div
              style={{
                fontSize: 22,
                color: "#333",
              }}
            >
              Broadcast new message
            </div>
            <div
              // className="import-list-close-button"
              onClick={() => this.props.handleClose()}
            >
              <span className="moi-ximage" style={{ color: "#BBBBBB" }} />
            </div>
          </div>
          {/* <div
            className="import-from-list-spacer"
            style={{ marginTop: 20, marginBottom: 20, width: "100%" }}
          /> */}
          <div>
            <div className="blurable-large" style={{ marginBottom: 20 }}>
              {this.state.fileUrl ? (
                <div className="file-pre-send">
                  {this.state.fileType.includes("image") ? (
                    // <i className="far fa-image"></i>
                    <img
                      src={this.state.fileUrl}
                      style={{ width: "auto", height: 100 }}
                    />
                  ) : (
                    <i className="far fa-file"></i>
                  )}

                  <div>{this.state.fileUrl}</div>
                  <i
                    style={{ cursor: "pointer" }}
                    className="far fa-times"
                    onClick={() =>
                      this.setState({ fileUrl: null, fileType: null })
                    }
                  ></i>
                </div>
              ) : (
                <div>
                  <Textarea
                    className={"input-line"}
                    style={{
                      border: "1px solid #EAE8E8",
                      width: "90%",
                      marginLeft: "5%",
                      background: "white",
                    }}
                    defaultValue={this.state.message}
                    onChange={(e) => {
                      this.setState({ message: e.target.value });
                    }}
                    minRows={5}
                    maxRows={5}
                    placeholder={"Write your message here"}
                  />
                  {this.state.message.length !== 0 ? (
                    <Tooltip
                      label="Attach a file"
                      placement="top"
                      overlay={
                        <span>{"Can't upload file and write a message"}</span>
                      }
                    >
                      <div
                        onClick={() => {
                          if (this.state.message.length === 0)
                            this.setState({ openUploadModal: true });
                        }}
                        className="far fa-paperclip"
                        style={{
                          position: "absolute",
                          fontSize: 18,
                          marginTop: -25,
                          marginLeft: 492,
                          cursor:
                            this.state.message.length === 0
                              ? "pointer"
                              : "default",
                          opacity: this.state.message.length === 0 ? 1 : 0.2,
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <div
                      onClick={() => {
                        if (this.state.message.length === 0)
                          this.setState({ openUploadModal: true });
                      }}
                      className="far fa-paperclip"
                      style={{
                        position: "absolute",
                        fontSize: 18,
                        marginTop: -25,
                        marginLeft: 492,
                        cursor:
                          this.state.message.length === 0
                            ? "pointer"
                            : "default",
                        opacity: this.state.message.length === 0 ? 1 : 0.2,
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <div
              style={{
                background: "white",
                borderTop: "2px solid #F2F2F2",
                padding: 10,
              }}
            >
              <div
                className="import-list-influencers-row"
                style={{ paddingLeft: 10, background: "white" }}
              >
                <div
                  className="inner-toolbar-search-input"
                  style={{ marginTop: 10 }}
                >
                  <div
                    className="moi-search-bold"
                    style={{ fontSize: 14, marginTop: 10 }}
                  />
                  <input
                    onChange={(e) =>
                      this.setState({ search: e.target.value.toLowerCase() })
                    }
                    style={{
                      height: 36,
                      width: 200,
                      boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.25)",
                      paddingLeft: 25,
                    }}
                    placeholder="Search influencer"
                    className="form-control"
                  />
                </div>
              </div>
              <div
                className="import-list-section no-infinite"
                style={{
                  marginTop: 10,
                  height: "calc(100vh - 480px)",
                  paddingLeft: 10,
                }}
              >
                {this.renderSection(0)}
                {this.renderSection(1)}
                {this.renderSection(2)}
              </div>
            </div>
          </div>

          <div className="import-from-list-buttons-row">
            <button
              className="btn btn-primry"
              disabled={
                (!this.state.message && !this.state.fileUrl) ||
                this.state.sending ||
                this.state.selectedLists.length === 0
              }
              onClick={() => this.sendMessage()}
            >
              Send
            </button>
            <button
              className="btn btn-primry"
              onClick={() => this.props.handleClose()}
            >
              Cancel
            </button>
          </div>
        </div>
        {this.state.openUploadModal && (
          <Modal
            isOpen={true}
            onClose={() => {
              this.setState({ openUploadModal: false });
            }}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Uploade file</ModalHeader>
              <ModalCloseButton />
              <ModalBody pt={6}>
                <div className="input-row">
                  <FileUpload
                    fileType={[
                      FileTypes.image,
                      FileTypes.video,
                      FileTypes.document,
                    ]}
                    previewFileUrl={this.state.fileUrl || undefined}
                    onFileUploaded={(image_url, fileType) =>
                      this.handleFileUpload(image_url, fileType)
                    }
                    iconSize={"24"}
                    showText={false}
                    disabled={false}
                    deleteFile={() => {
                      console.log("deleting file");
                      this.setState({ fileUrl: null });
                    }}
                    circle
                    references
                  />
                </div>

                <div className="clearfix" />

                <FormFooterButtonGroup py={3}>
                  <Button
                    type="button"
                    onClick={() => {
                      this.setState({ openUploadModal: false });
                    }}
                  >
                    Cancel
                  </Button>
                </FormFooterButtonGroup>
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    board: state.boardReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMultipleMessage: (ids, campaignId, message, attachment, callback) => {
      dispatch(
        sendMultipleMessage(ids, campaignId, message, attachment, callback),
      );
    },
    setToasterNotificaion: (message) => {
      dispatch(setToasterNotificaion(message));
    },
    sendFileMessage: (
      chatId,
      campaignId,
      uri,
      name,
      mime,
      attachmentType,
      messageId,
      callback,
    ) => {
      dispatch(
        sendFileMessage(
          chatId,
          campaignId,
          uri,
          name,
          mime,
          attachmentType,
          messageId,
          callback,
        ),
      );
    },
    getAvaliableChats: () => {
      dispatch(getAvailableChats());
    },
    sendFileMessageMultipleMessage: (
      ids,
      campaignId,
      file,
      attachmentType,
      callback,
    ) => {
      dispatch(
        sendFileMessageMultipleMessage(
          ids,
          campaignId,
          file,
          attachmentType,
          callback,
        ),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SendMultiMessageSide);
