/* eslint-disable */
//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import { AxiosRequestConfig } from "axios";
import {
  UseQueryOptions,
  useQuery,
  useMutation,
  UseMutationOptions,
  QueryClient,
  QueryKey,
} from "@tanstack/react-query";
import { RequestError, SwaggerResponse } from "./config";

import type {
  AcademyQuizQuestion,
  AdditionalPrice,
  AdvertiserDisputeDetailsExtended,
  AffiliateTimeline,
  AgeSegmentationData,
  AgentExternalInfluencerInviteDetails,
  AgentInChatInfo,
  AgentsDashboard,
  AgentsDashboardTopCampaignsInfluencers,
  AllAdvertiserDetails,
  AllAgentDetails,
  AllUserTags,
  AllowedForUSBankAccount,
  AllowedImageUploadTypes,
  AppUserReport,
  AuctionDataForAgentExtended,
  AuctionDataForInfluencer,
  AuctionDataForInfluencerExtended,
  AuctionDataForInfluencerLink,
  AuctionLink,
  AuctionPaymentsData,
  AuctionPhase,
  AuctionQuestions,
  AuctionSales,
  AuctionTargetAudience,
  AuctionToDoItem,
  AuctionTypes,
  BillingAddress,
  Campaign,
  CampaignFileIdentifier,
  CampaignIdNamePicture,
  CampaignReportFilterParameters,
  CampaignShareParameters,
  CampaignShareParametersWithOwnerDetails,
  CampaignSummary,
  Chats,
  CollaborateInfluencerProfile,
  CollaborateMessagesReceived,
  CommunityFeedComment,
  CommunityFeedItem,
  CommunitySuggestion,
  CouponInSales,
  Course,
  CourseSummary,
  CreditCardIssuer,
  Currency,
  DashboardDailySales,
  DateAndCount,
  DeleteAgentInvoicesQueryParams,
  DeleteMoiAgentAgent_idBankIdQueryParams,
  DeleteMoiAuctionAuction_idTargetsDeleteQueryParams,
  DeleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfrontQueryParams,
  DeleteMoiAuctionsAuction_idUpload_invoiceQueryParams,
  DeleteMoiAuctionsRoiSlidesQueryParams,
  DeleteMoiInboxIdStarQueryParams,
  DeleteMoiInboxMessageMessage_guidQueryParams,
  DeleteShopifyRoiFilterQueryParams,
  DeleteWixRoiFilterQueryParams,
  DeviceInfo,
  DosAndDonts,
  EditAgentDetails,
  EditAuctionOverview,
  EditAuctionPayments,
  EditAuctionProduct,
  EditAuctionProducts,
  EditBrandInfo,
  EditedKPIS,
  EmbeddingSearchType,
  ExistingUSAgentBankAccount,
  ExtendedAuctionData,
  ExternalInfluencerInviteDetails,
  ExternalPageSettings,
  ExtraPayoutDetails,
  FollowersData,
  Frequencies,
  GetAcademyCoursesQueryParams,
  GetAgentAuctionsIdQueryParams,
  GetAgentAuctionsQueryParams,
  GetAgentInfluencerInfluencer_idQueryParams,
  GetAgentInfluencerStatusQueryParams,
  GetAgentInfluencersQueryParams,
  GetAgentInvoicesQueryParams,
  GetCollaborateGet_matchesQueryParams,
  GetCollaborateInboxMatch_idQueryParams,
  GetForgot_passwordQueryParams,
  GetForgot_passwordValidateQueryParams,
  GetForgot_passwordValidate_influencerQueryParams,
  GetLogoutQueryParams,
  GetMoiAgentAgent_idBankIdQueryParams,
  GetMoiAgentAgent_idBankQueryParams,
  GetMoiAgentAgent_idBankUsQueryParams,
  GetMoiAgentQueryParams,
  GetMoiAgents_dashboardQueryParams,
  GetMoiAgents_dashboardSales_graphQueryParams,
  GetMoiAgents_dashboardTop_influencers_campaginsQueryParams,
  GetMoiAuctionAuction_idTargetsMonthsQueryParams,
  GetMoiAuctionAuction_idTargetsQueryParams,
  GetMoiAuctionManageAuction_idFilesInfluencer_idQueryParams,
  GetMoiAuctionManageAuction_idInfluencer_paymentsQueryParams,
  GetMoiAuctionManageAuction_idQueryParams,
  GetMoiAuctionsAuction_idInfluencer_pixel_analyticsQueryParams,
  GetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdownQueryParams,
  GetMoiAuctionsIdBidsQueryParams,
  GetMoiAuctionsIdRoiQueryParams,
  GetMoiAuctionsInfluencerQueryParams,
  GetMoiAuctionsLiteInfluencerQueryParams,
  GetMoiAuctionsRoiSlidesQueryParams,
  GetMoiCampaignsQueryParams,
  GetMoiCommunity_feedApprovalQueryParams,
  GetMoiCommunity_feedPost_idCommentsQueryParams,
  GetMoiCommunity_feedPost_idLikeQueryParams,
  GetMoiCommunity_feedQueryParams,
  GetMoiCommunity_feedSuggestionAllQueryParams,
  GetMoiEdit_detailsInfluencerBankUsQueryParams,
  GetMoiEnterpriseUsers_logsQueryParams,
  GetMoiFollowers_segmentationHumanz_fkQueryParams,
  GetMoiHomepage_dashboardQueryParams,
  GetMoiHomepage_dashboardTop_influencersQueryParams,
  GetMoiInboxCampaignsQueryParams,
  GetMoiInboxInfluencersInfluencer_idQueryParams,
  GetMoiInboxInfluencersQueryParams,
  GetMoiInfluencerCampaigns_autocompleteQueryParams,
  GetMoiInfluencerMonths_autocompleteQueryParams,
  GetMoiInfluencer_dashboardQueryParams,
  GetMoiInfluencer_dashboardTodolistQueryParams,
  GetMoiInfluencer_dashboardTop_campaignsQueryParams,
  GetMoiInfluencer_profileViewed_my_profileQueryParams,
  GetMoiInsightsQueryParams,
  GetMoiInvitesExternalQueryParams,
  GetMoiPaymentPayment_from_advertiserDisputeQueryParams,
  GetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_idQueryParams,
  GetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_idQueryParams,
  GetMoiPremiumInfluencer_storiesQueryParams,
  GetMoiPremiumListsQueryParams,
  GetMoiPremiumListsUsers_mappingQueryParams,
  GetMoiPremiumLists_with_scrollingQueryParams,
  GetMoiReviewsInfluencerIdQueryParams,
  GetMoiSupport_centerQueryParams,
  GetMoiSupport_centerTicket_idQueryParams,
  GetMoiUpdate_centerQueryParams,
  GetMoiUser_feature_feedbackQueryParams,
  GetSharesBrand_reportTop_wordsQueryParams,
  GetSharesCampaignManageQueryParams,
  GetShopifyRoiFilterQueryParams,
  GetUtilsSend_support_notificationUser_idQueryParams,
  GetUtilsTipaltiRequest_idQueryParams,
  GetVerify_emailQueryParams,
  GetWixRoiFilterQueryParams,
  HomepageDashboard,
  ILBankAccount,
  IndianBankAccount,
  InfluencerActivityData,
  InfluencerAgentEntity,
  InfluencerAgentInfo,
  InfluencerAgentInviteResponse,
  InfluencerAgentInviteStatus,
  InfluencerCampaignPictureMetric,
  InfluencerCardData,
  InfluencerChangePassword,
  InfluencerCollaborateFilterParameters,
  InfluencerDashboardSocialData,
  InfluencerDashboardTopCampaign,
  InfluencerDetails,
  InfluencerDetailsEdit,
  InfluencerDetailsRegsitration,
  InfluencerExtendedData,
  InfluencerFollowersSegmentation,
  InfluencerInManageAuctionData,
  InfluencerInstagramPost,
  InfluencerInstagramStories,
  InfluencerInvite,
  InfluencerProfileDashboard,
  InfluencerProfilePage,
  InfluencerProfileTodo,
  InfluencerRateCard,
  InfluencerSearchParametersWithQuestions,
  InfluencerShallowData,
  InfluencerStatusInAgentList,
  InfluencerUserDetails,
  InfluencerWithMedias,
  InitUSBankAccount,
  InitUSBankAccountSuccess,
  InsightsReportShallowInfo,
  InstructionIndicesToDelete,
  KPI,
  Lesson,
  LinkAvailableCheck,
  LinkedMediaForInfluencer,
  LinkedTaskPost,
  ListData,
  ListExtendedData,
  ListFormAnswer,
  ListSummary,
  ListTasksAmountAndPercentages,
  ManageAuctionData,
  ManageAuctionState,
  ManageCampaignRoiWithParameters,
  ManagedCampaignROI,
  Message,
  MetaTag,
  MinimalAuctionDataForAgent,
  MinimalAuctionDataForInfluencer,
  MinimalListSummary,
  MinimalSocialData,
  MultiReport,
  MultiReportFilterParameters,
  MultiReportShareParameters,
  NewAuctionData,
  NewReviewData,
  NewSupportTicketArticle,
  NoteMessage,
  NotificationData,
  Owner,
  PayoutInvoiceData,
  PixelEventData,
  PixelEventsCheckResponse,
  PixelWizardDetails,
  PostAgentInvoicesQueryParams,
  PostForgot_passwordCreate_email_verificationQueryParams,
  PostMoiAuctionManageAuction_idAttach_storyTask_idQueryParams,
  PostMoiAuctionManageAuction_idCustom_linkQueryParams,
  PostMoiAuctionManageAuction_idPost_linkTask_idQueryParams,
  PostMoiAuctionManageAuction_idProposal_uploadTask_idQueryParams,
  PostMoiAuctionManageAuction_idProposals_historyInfluencer_idQueryParams,
  PostMoiAuctionManageAuction_idProposals_historyQueryParams,
  PostMoiAuctionsAuction_idPixel_phasesQueryParams,
  PostMoiAuctionsIdProducts_sales_roiQueryParams,
  PostMoiAuctionsIdRoi_filteredQueryParams,
  PostMoiAuctionsIdSales_roi_breakdownBreakdownQueryParams,
  PostMoiAuctionsQueryParams,
  PostMoiCommunity_feedPost_idCommentsQueryParams,
  PostMoiInboxIdAttachmentQueryParams,
  PostMoiInboxIdQueryParams,
  PostMoiInboxIdStarQueryParams,
  PostMoiInbox_with_first_timeIdQueryParams,
  PostMoiPremiumAuctionAuction_idTodoTo_do_idCompleteQueryParams,
  PostMoiPremiumAuctionAuction_idTodo_from_templateQueryParams,
  PostMoiPremiumBoardsBoard_idModify_taskInfluencer_idQueryParams,
  PostMoiPremiumListsList_idInfluencer_idPriceQueryParams,
  PostMoiPremiumSearch_influencersQueryParams,
  PostMoiSupport_centerTicket_idQueryParams,
  PostMoiUpload_imageUpload_typeQueryParams,
  PostSharesCampaignProposals_historyInfluencer_idQueryParams,
  PostSharesRoiProducts_sales_roiQueryParams,
  PostShopifySearch_influencersQueryParams,
  PostSocialLoginAgentInvite_influencerAgent_uuidQueryParams,
  PostSocialLoginExternal_registerBusiness_discoveryQueryParams,
  PostSocialLoginExternal_registerLink_apiQueryParams,
  PostTypes,
  PostWixSearch_influencersQueryParams,
  PremiumListSharedLink,
  ProductDetails,
  ProductIdsToDelete,
  ProposalData,
  PurchaseClicksType,
  PutMoiAgentAgent_idBankQueryParams,
  PutMoiAgentAgent_idBankTurkeyQueryParams,
  PutMoiAgentAgent_idBankUsQueryParams,
  PutMoiAuctionManageAuction_idCustom_linkQueryParams,
  PutMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_responseQueryParams,
  PutMoiAuctionsAuction_idBidsEdit_bidQueryParams,
  PutMoiAuctionsIdBid_invite_as_agentQueryParams,
  PutMoiAuctionsIdBidsQueryParams,
  PutMoiEdit_detailsAdvertiserQueryParams,
  PutMoiEdit_detailsAgentQueryParams,
  PutMoiEdit_detailsInfluencerBankUsQueryParams,
  PutMoiEdit_detailsInfluencerPasswordQueryParams,
  PutMoiInboxIdQueryParams,
  PutMoiPaymentPayment_from_advertiserUpgrade_to_premiumQueryParams,
  PutMoiPremiumListsList_idQueryParams,
  PutShopifyRoiFilterQueryParams,
  PutUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_responseQueryParams,
  PutWixRoiFilterQueryParams,
  QuestionAnswer,
  QuestionIdsToDelete,
  QuizResult,
  ROISlidesInfo,
  ROISlidesReportStatus,
  ReachAndFrequency,
  ReadReviewData,
  RegisteredBankAccount,
  RegisteredUKAgentBankAccount,
  RegisteredUSAgentBankAccount,
  RequestBodyAuctionPhase,
  RequestBodyAuctionToDoItem,
  RequestBodyBankAccountDetails,
  RequestBodyBillingAddress,
  RequestBodyCampaignReportFilterParameters,
  RequestBodyCampaignTag,
  RequestBodyCampaignTag2,
  RequestBodyChallengeDetails,
  RequestBodyContentSearchParameters,
  RequestBodyCreateCommunityFeedItem,
  RequestBodyEndorseBody,
  RequestBodyExternalInvoiceData,
  RequestBodyExtraDataFilter,
  RequestBodyFileToUploadDetails,
  RequestBodyFiltersAndScrollProducts,
  RequestBodyInfluencerCustomLink,
  RequestBodyInfluencerIds,
  RequestBodyInfluencerSearchParameters,
  RequestBodyInfluencerShortLinkUpdateData,
  RequestBodyInfluencerShortLinkUpdateData2,
  RequestBodyInfluencerTaskProposalData,
  RequestBodyInviteResponse,
  RequestBodyInviteResponse2,
  RequestBodyLinkAvailableCheck,
  RequestBodyLoginData,
  RequestBodyMiniUsersIds,
  RequestBodyMultiReport,
  RequestBodyMultiReportShareParameters,
  RequestBodyNewAgentInfo,
  RequestBodyNewBidForAuctionData,
  RequestBodyNoteToAdd,
  RequestBodyOrderedTimeFilter,
  RequestBodyPorfileMediaAttachedInsertArray,
  RequestBodyRemoveSocialConnection,
  RequestBodySocialRegisteredLinkData,
  RequestBodySupportRequestDetails,
  RequestBodyTaskModification,
  RequestBodyTaskProposalFeedbackData,
  RequestBodyTurkishBankAccount,
  RequestBodyUKBankAccount,
  RequestBodyUsersToOperate,
  RequestBodyWixUserSettings,
  RequestDataInfluencersList,
  RequestDataSummary,
  ReviewsStatistics,
  ShadowInfluencer,
  ShadowInfluencerWithId,
  ShareListParameters,
  ShareNotesSummary,
  ShareOwnerDetails,
  ShareReportParameters,
  SideBarData,
  SimilarListInfluencers,
  SocialLoginDataExtracted,
  SocialLoginProvider,
  SocialNetworkOptions,
  StoryViewsUpload,
  SuccessfulCharge,
  SuccessfulCreditCardTokenization,
  SupportRequestDetailsExtended,
  SupportTicket,
  SupportTicketArticle,
  SwipeLimits,
  Target,
  TargetEdit,
  TargetNotification,
  Task,
  TaskManageData,
  TaskModification,
  TaskReminderData,
  TaskWithAdditonalPrice,
  TaskWithId,
  TaskWithPost,
  TasksAmounts,
  TasksPaymentsInstructions,
  TimeFilter,
  TodoTemplate,
  TrackerEvent,
  UnfinishedCampaign,
  UpdatesAvailable,
  UploadedImage,
  UserFeatureFeedback,
  UserOnFeed,
  UserOnFeedAndActionTime,
  UserSocialLoginSuccess,
  UserType,
  ValidPasswordResetToken,
  VersionUpdate,
  WixInfluencerData,
  WixInfluencerManage,
  WixInfluencerMedia,
  WixSavedRoiFilterData,
  WixStoryView,
  WixUserSettings,
} from "./types";
import {
  deleteAgentInfluencerArchiveInfluencer_id,
  deleteAgentInfluencerDetach,
  deleteAgentInfluencerInfluencer_id,
  deleteAgentInfluencerStatusDecline_all,
  deleteAgentInvoices,
  deleteCollaborateMatchInboxMatch_idMessage_id,
  deleteCollaborateOptout,
  deleteMoiAgentAgent_id,
  deleteMoiAgentAgent_idBankId,
  deleteMoiAuctionAuction_idTargetsDelete,
  deleteMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id,
  deleteMoiAuctionManageAuction_idFilesInfluencer_id,
  deleteMoiAuctionManageAuction_idInfluencer_idExtra_payoutExtra_payout_id,
  deleteMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes,
  deleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront,
  deleteMoiAuctionManageAuction_idProposal_feedbackProposal_id,
  deleteMoiAuctionManageAuction_idShort_linkInfluencer_id,
  deleteMoiAuctionManageAuction_idTagTag_id,
  deleteMoiAuctionManageBoard_idInfluencer_idPost_linkTask_id,
  deleteMoiAuctionManageBoard_idStory_view_countInfluencer_idTask_id,
  deleteMoiAuctionsAuction_idCoupons,
  deleteMoiAuctionsAuction_idInstructions,
  deleteMoiAuctionsAuction_idPixel_phasesPhase_id,
  deleteMoiAuctionsAuction_idProduct,
  deleteMoiAuctionsAuction_idQuestions,
  deleteMoiAuctionsAuction_idUpload_invoice,
  deleteMoiAuctionsId,
  deleteMoiAuctionsRoiSlides,
  deleteMoiBidsBid_idHide,
  deleteMoiBidsDeleteBid_id,
  deleteMoiCommunity_feedApprovalPost_id,
  deleteMoiCommunity_feedPost_id,
  deleteMoiCommunity_feedPost_idCommentsComment_id,
  deleteMoiCommunity_feedPost_idCommentsComment_idLike,
  deleteMoiCommunity_feedPost_idLike,
  deleteMoiCommunity_feedSuggestionSuggestion_id,
  deleteMoiDelete_user,
  deleteMoiDetailsBrandBrand_id,
  deleteMoiEdit_detailsInfluencerBankId,
  deleteMoiEnterpriseUsersRemove_user,
  deleteMoiInboxIdStar,
  deleteMoiInboxMessageMessage_guid,
  deleteMoiMeta_tagsTag_typeTag_id,
  deleteMoiNotifications,
  deleteMoiNotificationsNotificationId,
  deleteMoiPaymentPayment_from_advertiserPayment_method_id,
  deleteMoiPremiumAuctionAuction_idShare,
  deleteMoiPremiumAuctionAuction_idTodoTo_do_id,
  deleteMoiPremiumListsList_id,
  deleteMoiPremiumListsList_idShare,
  deleteMoiPremiumListsList_idTagTag_id,
  deleteMoiPremiumRoiAuction_idShare,
  deleteMoiPremiumTodo_templatesTemplate_id,
  deleteMoiPremiumTodo_templatesTemplate_idTodoTo_do_id,
  deleteMoiRemove_last_user_instagram_challengeChallenge_id,
  deleteMoiShadow_influencersShadow_influencer_id,
  deleteMoiSupport_centerTicket_id,
  deleteMoiTask_offersTask_offer_id,
  deleteMoiUnfinished_campaignsUnfinished_campaign_id,
  deleteMoiUpload_imageUpload_type,
  deleteShopifyManageInfluencers,
  deleteShopifyRoiFilter,
  deleteShopifySettingsLinkInfluencer_id,
  deleteWixManageInfluencers,
  deleteWixRoiFilter,
  deleteWixSettingsLinkInfluencer_id,
  getAcademyChaptersChapter_idLessonLesson_ordinal_num,
  getAcademyCourses,
  getAcademyCoursesCourse_translation_id,
  getAcademyQuizQuiz_id,
  getAgentAuctions,
  getAgentAuctionsId,
  getAgentAuctionsIdInfluencerInfluencer_id,
  getAgentCurrent_user,
  getAgentInfluencerInfluencer_id,
  getAgentInfluencerStatus,
  getAgentInfluencers,
  getAgentInvoices,
  getAgentPaymentsExternal_page,
  getCollaborateFilter_parameters,
  getCollaborateGet_followers_intersectionUser_id,
  getCollaborateGet_likers,
  getCollaborateGet_matches,
  getCollaborateGet_new_users,
  getCollaborateInboxMatch_id,
  getCollaborateInboxNew_chats,
  getCollaborateMatchMatch_id,
  getCollaborateMatchUnseen_count,
  getEnterprise_inviteInvite_id,
  getEnterprise_inviteInvite_idAccept,
  getEnterprise_inviteInvite_idDecline,
  getExternal_apiProfileInfluencer_username,
  getForgot_password,
  getForgot_passwordValidate,
  getForgot_passwordValidate_influencer,
  getHealthy,
  getInvoices,
  getLogout,
  getMoiAdvertiser_saved_queries,
  getMoiAffiliate_details,
  getMoiAgent,
  getMoiAgentAgent_id,
  getMoiAgentAgent_idBank,
  getMoiAgentAgent_idBankId,
  getMoiAgentAgent_idBankUk,
  getMoiAgentAgent_idBankUkId,
  getMoiAgentAgent_idBankUs,
  getMoiAgentAgent_idBankUsId,
  getMoiAgents_dashboard,
  getMoiAgents_dashboardSales_graph,
  getMoiAgents_dashboardTop_influencers_campagins,
  getMoiAuctionAuction_idTargets,
  getMoiAuctionAuction_idTargetsMonths,
  getMoiAuctionManage,
  getMoiAuctionManageAuction_id,
  getMoiAuctionManageAuction_idFilesInfluencer_id,
  getMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid,
  getMoiAuctionManageAuction_idInfluencer_payments,
  getMoiAuctionManageAuction_idInfluencers,
  getMoiAuctionManageAuction_idNext_month,
  getMoiAuctionManageAuction_idPayments,
  getMoiAuctionManageAuction_idPaymentsPayment_idTasks_details,
  getMoiAuctionManageAuction_idShort_link,
  getMoiAuctionTop_productsAuction_id,
  getMoiAuctionsAuction_idCoupons,
  getMoiAuctionsAuction_idInfluencer,
  getMoiAuctionsAuction_idInfluencerLinks,
  getMoiAuctionsAuction_idInfluencerProducts,
  getMoiAuctionsAuction_idInfluencer_pixel_analytics,
  getMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown,
  getMoiAuctionsAuction_idLast_month_for_roi,
  getMoiAuctionsAuction_idPixel_phases,
  getMoiAuctionsId,
  getMoiAuctionsIdBids,
  getMoiAuctionsIdRoi,
  getMoiAuctionsInfluencer,
  getMoiAuctionsLiteInfluencer,
  getMoiAuctionsRoiSlides,
  getMoiAuctions_price_suggest,
  getMoiCampaigns,
  getMoiCommunity_feed,
  getMoiCommunity_feedApproval,
  getMoiCommunity_feedPost_id,
  getMoiCommunity_feedPost_idComments,
  getMoiCommunity_feedPost_idLike,
  getMoiCommunity_feedSuggestionAll,
  getMoiCoupons,
  getMoiDetailsAdvertiser,
  getMoiDetailsBrand,
  getMoiDetailsCurrent_user,
  getMoiDetailsInfluencer,
  getMoiDetailsInfluencerDashboard,
  getMoiDetailsInfluencerRate_card,
  getMoiEdit_detailsAdvertiserBilling,
  getMoiEdit_detailsCurrent_userBilling,
  getMoiEdit_detailsInfluencerBank,
  getMoiEdit_detailsInfluencerBankId,
  getMoiEdit_detailsInfluencerBankUs,
  getMoiEnterpriseUsers,
  getMoiEnterpriseUsers_logs,
  getMoiExternal_page_settings,
  getMoiFollowers_segmentationHumanz_fk,
  getMoiGet_last_user_instagram_challenge,
  getMoiHomepage_dashboard,
  getMoiHomepage_dashboardTop_influencers,
  getMoiInbox,
  getMoiInboxCampaigns,
  getMoiInboxInfluencers,
  getMoiInboxInfluencersInfluencer_id,
  getMoiInboxNew_chats,
  getMoiInboxStarred_chats,
  getMoiInfluencerCampaigns_autocomplete,
  getMoiInfluencerMonths_autocomplete,
  getMoiInfluencer_dashboard,
  getMoiInfluencer_dashboardTodolist,
  getMoiInfluencer_dashboardTop_campaigns,
  getMoiInfluencer_profile,
  getMoiInfluencer_profileInfluencer_username,
  getMoiInfluencer_profileProfile_statistics,
  getMoiInfluencer_profileViewed_my_profile,
  getMoiInsights,
  getMoiInvitesExternal,
  getMoiMeta_tags,
  getMoiMulti_reportReport_id,
  getMoiMulti_reportsReport_idShare,
  getMoiNotificationsNew_notifications,
  getMoiPaymentInfluencerPayments,
  getMoiPaymentPayment_from_advertiserDispute,
  getMoiPaymentPayment_from_advertiserDisputes,
  getMoiPaymentPayment_from_advertiserInvoices,
  getMoiPaymentPayment_from_advertiserMethods,
  getMoiPaymentPayment_from_advertiserNext_billing_period,
  getMoiPremiumAuctionAuction_idShare,
  getMoiPremiumAuctionAuction_idTodo,
  getMoiPremiumAuctionAuction_idTodo_status,
  getMoiPremiumCampaign_listsAuction_id,
  getMoiPremiumGet_influencer_dataInfluencer_id,
  getMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id,
  getMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id,
  getMoiPremiumInfluencer_stories,
  getMoiPremiumLists,
  getMoiPremiumListsInfluencers_request_data_list,
  getMoiPremiumListsList_id,
  getMoiPremiumListsList_idForm_data,
  getMoiPremiumListsList_idFrequenices,
  getMoiPremiumListsList_idGet_list_segmentation_data,
  getMoiPremiumListsList_idShare,
  getMoiPremiumListsUsers_mapping,
  getMoiPremiumLists_with_scrolling,
  getMoiPremiumOpen_chatInfluencer_id,
  getMoiPremiumRequest_data_summary,
  getMoiPremiumRoiAuction_idShare,
  getMoiPremiumTodo_templates,
  getMoiReviewsInfluencerId,
  getMoiShadow_influencers,
  getMoiSharesShare_guidNotes,
  getMoiSharesShare_guidNotesItem_typeNotesItem_id,
  getMoiSidebar,
  getMoiSupport_center,
  getMoiSupport_centerLast_seen_timeTicket_id,
  getMoiSupport_centerTicket_id,
  getMoiTask_offersTask_offer_id,
  getMoiUnfinished_campaignsUnfinished_campaign_id,
  getMoiUpdate_center,
  getMoiUpdate_centerCheck_updates,
  getMoiUser_feature_feedback,
  getPixel_wizard,
  getSharesBrand_reportTop_words,
  getSharesCampaign,
  getSharesCampaignBrief,
  getSharesCampaignList,
  getSharesCampaignManage,
  getSharesCampaignNotes,
  getSharesCampaignNotesItem_typeNotesItem_id,
  getSharesCampaignShare_details,
  getSharesCampaignTracker,
  getSharesInfluencer_card,
  getSharesInvite,
  getSharesLists,
  getSharesListsFrequenices,
  getSharesListsGet_list_segmentation_data,
  getSharesListsNotes,
  getSharesListsNotesItem_typeNotesItem_id,
  getSharesMulti_reports,
  getSharesMulti_reportsShare_details,
  getSharesRoiLast_month_for_roi,
  getShopify,
  getShopifyInfluencerInfluencer_id,
  getShopifyManageInfluencers,
  getShopifyRoiFilter,
  getShopifyRoiFilterSaved,
  getShopifySettings,
  getSocialLoginAgentInvite_influencerAgent_uuid,
  getSocialLoginAttach_socialTiktokInit,
  getSocialLoginExternal_registerLink_apiInvite,
  getSocialLoginFacebook_instagramPermissions,
  getTipaltiGenerate_external_linkRequest_id,
  getUtilsApp_min_versions,
  getUtilsMin_app_version,
  getUtilsSend_support_notificationUser_id,
  getUtilsTipaltiRequest_id,
  getVerify_email,
  getWix,
  getWixInfluencerInfluencer_id,
  getWixManageInfluencers,
  getWixRoiFilter,
  getWixRoiFilterSaved,
  getWixSettings,
  postAcademyChaptersChapter_idLessonLesson_ordinal_num,
  postAcademyCoursesCourse_translation_id,
  postAcademyQuizQuiz_id,
  postAgentAuctionsAuction_idArchive,
  postAgentGenerate_upload_path,
  postAgentInfluencerArchiveInfluencer_id,
  postAgentInfluencerExternal_pageInfluencer_id,
  postAgentInfluencerInfluencer_id,
  postAgentInfluencerStatus,
  postAgentInvoices,
  postAgentSearch_influencer,
  postChallengeCreate_phone_challenge,
  postChallengeCreate_social_login_challenge,
  postChallengeRequest_call,
  postChallengeTest_email_challenge,
  postChallengeTest_phone_challenge,
  postCollaborateMatchMatch_idSeen,
  postCollaborateMatchMatch_idUnmatch,
  postContact_email,
  postEnterprise_inviteInvite_id,
  postForgot_passwordCreate_email_verification,
  postForgot_passwordReset,
  postInvoices,
  postInvoicesGenerate_upload_path,
  postLogin,
  postLoginAgent_mimic,
  postLoginApp,
  postLoginFirst_time_login,
  postLoginMimic,
  postLoginMimic_app,
  postLoginMimic_role,
  postLoginRefreshToken,
  postMoiAdd_feature_request,
  postMoiAdd_password,
  postMoiAdvertiserMini_users,
  postMoiAdvertiser_support,
  postMoiAdvertiser_supportExtended,
  postMoiAgent,
  postMoiApp_block_userUser_id,
  postMoiApp_user_report,
  postMoiAuctionAuction_idTargetsCreate,
  postMoiAuctionAuction_idTargetsUpdate,
  postMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id,
  postMoiAuctionManageAuction_idAttach_storyTask_id,
  postMoiAuctionManageAuction_idCustom_link,
  postMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid,
  postMoiAuctionManageAuction_idInfluencer_idExtra_payout,
  postMoiAuctionManageAuction_idPayInfluencer_id,
  postMoiAuctionManageAuction_idPaymentsInfluencer_idChange_recurring_payment,
  postMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes,
  postMoiAuctionManageAuction_idPaymentsInfluencer_idStop_affiliation,
  postMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront,
  postMoiAuctionManageAuction_idPost_linkTask_id,
  postMoiAuctionManageAuction_idProposal_feedbackInfluencer_idShare_all_for_user,
  postMoiAuctionManageAuction_idProposal_feedbackProposal_id,
  postMoiAuctionManageAuction_idProposal_feedbackProposal_idShare,
  postMoiAuctionManageAuction_idProposal_feedbackProposal_idToggle_share_uploads,
  postMoiAuctionManageAuction_idProposal_uploadTask_id,
  postMoiAuctionManageAuction_idProposal_upload_as_advertiserInfluencer_idTaskTask_id,
  postMoiAuctionManageAuction_idProposals_history,
  postMoiAuctionManageAuction_idProposals_historyInfluencer_id,
  postMoiAuctionManageAuction_idSearch_bids,
  postMoiAuctionManageAuction_idSeen_column_notification,
  postMoiAuctionManageAuction_idTag,
  postMoiAuctionManageAuction_idToggle_influencers_next_month,
  postMoiAuctionManageBoard_idApprove_bidInfluencer_id,
  postMoiAuctionManageBoard_idInfluencer_idPost_link,
  postMoiAuctionManageBoard_idInfluencer_idTask_create_link,
  postMoiAuctionManageBoard_idNegotiate_bidInfluencer_id,
  postMoiAuctionManageBoard_idStory_view_countInfluencer_id,
  postMoiAuctions,
  postMoiAuctionsAttach_invoice,
  postMoiAuctionsAuction_idCoupons,
  postMoiAuctionsAuction_idCouponsInfluencer_idPixel_inject,
  postMoiAuctionsAuction_idEdit_tasks,
  postMoiAuctionsAuction_idInstructions,
  postMoiAuctionsAuction_idKpis,
  postMoiAuctionsAuction_idMultiple_tasks,
  postMoiAuctionsAuction_idOverview,
  postMoiAuctionsAuction_idPayment,
  postMoiAuctionsAuction_idPixel_phases,
  postMoiAuctionsAuction_idProduct,
  postMoiAuctionsAuction_idQuestions,
  postMoiAuctionsAuction_idSales,
  postMoiAuctionsAuction_idUpload_invoice,
  postMoiAuctionsCheck_link_availability,
  postMoiAuctionsIdProducts_sales_roi,
  postMoiAuctionsIdRevert,
  postMoiAuctionsIdRoi_filtered,
  postMoiAuctionsIdSales_roi_breakdownBreakdown,
  postMoiAuctionsRoiMedia,
  postMoiAuctionsRoiSlides,
  postMoiAuctionsRoi_reach_frequencies,
  postMoiAutocomplete_sales,
  postMoiChange_age_groupInfluencer_id,
  postMoiChange_categoriesInfluencer_id,
  postMoiChange_connectionInfluencer_id,
  postMoiChange_countryInfluencer_id,
  postMoiChange_genderInfluencer_id,
  postMoiCheck_influencers_agents,
  postMoiCity_search,
  postMoiCommunity_feed,
  postMoiCommunity_feedApprovalPost_id,
  postMoiCommunity_feedPost_idComments,
  postMoiCommunity_feedPost_idCommentsComment_idLike,
  postMoiCommunity_feedPost_idLike,
  postMoiCommunity_feedPost_idLink_clicked,
  postMoiCommunity_feedPost_idVote_on_poll,
  postMoiCommunity_feedSuggestionSuggestion_id,
  postMoiCoupons,
  postMoiDelete_userCancel,
  postMoiDetailsBrand,
  postMoiDetailsInfluencerRate_card,
  postMoiEdit_detailsAdvertiserBilling,
  postMoiEdit_detailsCurrent_userBilling,
  postMoiEdit_detailsInfluencerBankEdit_tax_numberBank_details_id,
  postMoiEdit_detailsInfluencerUser_name,
  postMoiEnterpriseUsersAdd_user,
  postMoiEnterpriseUsersChange_enterprise,
  postMoiExact_username_search,
  postMoiExternal_page_settings,
  postMoiFrequenices,
  postMoiGenerate_upload_path,
  postMoiGet_media_id,
  postMoiHumanz_username_search,
  postMoiInboxBoard_idMulti_message,
  postMoiInboxId,
  postMoiInboxIdAttachment,
  postMoiInboxIdStar,
  postMoiInbox_with_first_timeId,
  postMoiInfluencerUpdate_details_on_login,
  postMoiInfluencer_profileEndorseInfluencer_id,
  postMoiInfluencer_profilePosts_link,
  postMoiInfluencer_profileUnendorseInfluencer_id,
  postMoiInfluencer_rated_app,
  postMoiInfluencer_request_update,
  postMoiInvitesExternal,
  postMoiMeta_tags,
  postMoiMulti_report,
  postMoiMulti_reportReport_idReport,
  postMoiMulti_reportSuggest_kpis_target,
  postMoiNotifications,
  postMoiPaymentEarly_payoutRequest,
  postMoiPaymentPay_influencer,
  postMoiPaymentPayment_from_advertiser,
  postMoiPaymentPayment_from_advertiserPaypal_response,
  postMoiPremiumAuctionAuction_idArchive,
  postMoiPremiumAuctionAuction_idTodo,
  postMoiPremiumAuctionAuction_idTodoTo_do_idComplete,
  postMoiPremiumAuctionAuction_idTodo_from_template,
  postMoiPremiumAuctionAuction_idTodo_reorder,
  postMoiPremiumAuctionsAuction_idSearch_content,
  postMoiPremiumBoard_idInfluencer_idPost_link,
  postMoiPremiumBoardsBoard_idModify_taskInfluencer_id,
  postMoiPremiumBoardsBoard_idSeen_approval_tasksInfluencer_id,
  postMoiPremiumForm_dataConnect_user,
  postMoiPremiumGet_influencer_dataInfluencer_id,
  postMoiPremiumInfluencer_noteInfluencer_id,
  postMoiPremiumListsList_idArchive,
  postMoiPremiumListsList_idEdit_lists_tasks,
  postMoiPremiumListsList_idFavorite,
  postMoiPremiumListsList_idForm_dataMore_answers,
  postMoiPremiumListsList_idInfluencer_idNote,
  postMoiPremiumListsList_idInfluencer_idPrice,
  postMoiPremiumListsList_idInfluencers_order,
  postMoiPremiumListsList_idModify_taskInfluencer_id,
  postMoiPremiumListsList_idPosts_linkInfluencer_id,
  postMoiPremiumListsList_idSearch,
  postMoiPremiumListsList_idSearch_content,
  postMoiPremiumListsList_idTag,
  postMoiPremiumRequest_data,
  postMoiPremiumSearch_influencers,
  postMoiPremiumSearch_similar_influencersInfluencer_id,
  postMoiPremiumSearch_similar_list_influencersList_id,
  postMoiPremiumTodo_templates,
  postMoiPremiumTodo_templatesTemplate_id,
  postMoiPremiumTodo_templatesTemplate_idTodo_reorder,
  postMoiRemove_social,
  postMoiRemove_social_connectionInfluencer_id,
  postMoiReviewsInfluencerId,
  postMoiSet_as_pageInfluencer_id,
  postMoiShadow_influencers,
  postMoiShadow_influencersShadow_influencer_id,
  postMoiSharesShare_guidNotesItem_typeNotesItem_id,
  postMoiSubscribe_affiliateAffiliateCode,
  postMoiSupport_centerTicket_id,
  postMoiTask_offersTask_offer_id,
  postMoiUnfinished_campaigns,
  postMoiUnfinished_campaignsUnfinished_campaign_id,
  postMoiUnfinished_campaignsUnfinished_campaign_idImage_uploaded,
  postMoiUpload_imageUpload_type,
  postMoiUpload_video,
  postMoiUser_feature_feedback,
  postMoiWrite_log,
  postPixel_wizard,
  postPixel_wizardUpdate_platform,
  postSharesAmbassador_reportFrequencies,
  postSharesCampaignManageProposal_feedbackProposal_id,
  postSharesCampaignNotesItem_typeNotesItem_id,
  postSharesCampaignProposals_historyInfluencer_id,
  postSharesCampaignRoi,
  postSharesLists,
  postSharesListsGet_influencer_dataInfluencer_id,
  postSharesListsNotesItem_typeNotesItem_id,
  postSharesListsSearch,
  postSharesRoi,
  postSharesRoiProducts_sales_roi,
  postShopifyGenerate_upload_path,
  postShopifyRoi,
  postShopifySearch_influencers,
  postShopifySettingsLink,
  postShopifySettingsLinkInfluencer_id,
  postShopifyUser_support,
  postSignup,
  postSignupFrom_influencer_challenge,
  postSocialLoginAdvertiser_login_with_social,
  postSocialLoginAgentInvite_influencerAgent_uuid,
  postSocialLoginAgent_login_with_social,
  postSocialLoginAttach_social,
  postSocialLoginAttach_social_unregistered,
  postSocialLoginExternal_attach_social,
  postSocialLoginExternal_registerBusiness_discovery,
  postSocialLoginExternal_registerLink_api,
  postSocialLoginFacebook_instagramBusiness_discovery,
  postSocialLoginUnregistered_login_with_social,
  postUtilsDevice_info,
  postUtilsSend_targets_notification,
  postUtilsTask_offersTask_offer_id,
  postUtilsWrite_log,
  postWixGenerate_upload_path,
  postWixRoi,
  postWixSearch_influencers,
  postWixSettingsLink,
  postWixSettingsLinkInfluencer_id,
  postWixUser_support,
  putAcademyChaptersChapter_idLessonLesson_ordinal_num,
  putAgentPaymentsExternal_page,
  putCollaborateFilter_parameters,
  putCollaborateInboxMatch_idResend_messages,
  putCollaborateOptout,
  putCollaborateSwipe_influencerInfluencer_id,
  putMoiAdvertiser_saved_queries,
  putMoiAgentAgent_id,
  putMoiAgentAgent_idBank,
  putMoiAgentAgent_idBankTurkey,
  putMoiAgentAgent_idBankUk,
  putMoiAgentAgent_idBankUs,
  putMoiAuctionManageAuction_idCustom_link,
  putMoiAuctionManageAuction_idInfluencer_idTags,
  putMoiAuctionManageAuction_idInfluencer_idTrash,
  putMoiAuctionManageAuction_idInfluencer_idUntrash,
  putMoiAuctionManageAuction_idInvite,
  putMoiAuctionManageAuction_idManage_influencerInfluencer_idAdd_agentAgent_id,
  putMoiAuctionManageAuction_idShort_linkInfluencer_id,
  putMoiAuctionManageAuction_idTagTag_id,
  putMoiAuctionManageBoard_idInfluencer_idChange_note,
  putMoiAuctionManageBoard_idInvite_influencersInfluencer_idAdvertiser_response,
  putMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response,
  putMoiAuctionManageInfluencer_idInvite_taskTask_idAdvertiser_response,
  putMoiAuctionManageInvite_taskTask_idInfluencer_response,
  putMoiAuctionsAuction_idBidsEdit_bid,
  putMoiAuctionsAuction_idInstructions,
  putMoiAuctionsAuction_idMini_users,
  putMoiAuctionsAuction_idPixel_phasesPhase_id,
  putMoiAuctionsAuction_idQuestions_answers,
  putMoiAuctionsId,
  putMoiAuctionsIdAdd_new_task_as_an_influencer,
  putMoiAuctionsIdBid_invite_as_agent,
  putMoiAuctionsIdBids,
  putMoiAuctionsIdWant_to_participate,
  putMoiBidsBid_idHide,
  putMoiCommunity_feedPin_postPost_id,
  putMoiCommunity_feedPost_id,
  putMoiCommunity_feedPost_idVideo,
  putMoiCommunity_feedSuggestionSuggestion_id,
  putMoiDetailsBrandBrand_id,
  putMoiEdit_detailsAdvertiser,
  putMoiEdit_detailsAgent,
  putMoiEdit_detailsCountry,
  putMoiEdit_detailsCurrent_userTocUpdate,
  putMoiEdit_detailsInfluencer,
  putMoiEdit_detailsInfluencerBank,
  putMoiEdit_detailsInfluencerBankIl,
  putMoiEdit_detailsInfluencerBankIndia,
  putMoiEdit_detailsInfluencerBankTurkey,
  putMoiEdit_detailsInfluencerBankUk,
  putMoiEdit_detailsInfluencerBankUs,
  putMoiEdit_detailsInfluencerPassword,
  putMoiInboxId,
  putMoiInboxIdResend_messages,
  putMoiInfluencer_intro_video,
  putMoiInsightsReport_idMini_users,
  putMoiMulti_reportReport_id,
  putMoiMulti_reportsReport_idShare,
  putMoiMulti_reportsReport_idShareShared_guid,
  putMoiNotificationsNotificationId,
  putMoiNotificationsRegister_push_token,
  putMoiPaymentPayment_from_advertiserDispute,
  putMoiPaymentPayment_from_advertiserPrimaryPayment_method_id,
  putMoiPaymentPayment_from_advertiserTokenize_credit_card,
  putMoiPaymentPayment_from_advertiserUpgrade_to_premium,
  putMoiPremiumAuctionAuction_idShare,
  putMoiPremiumAuctionAuction_idShareInvite_influencers,
  putMoiPremiumAuctionAuction_idTodoTo_do_id,
  putMoiPremiumListList_idMini_users,
  putMoiPremiumLists,
  putMoiPremiumListsAdd_to_lists,
  putMoiPremiumListsList_id,
  putMoiPremiumListsList_idEdit,
  putMoiPremiumListsList_idRemove_influencers,
  putMoiPremiumListsList_idShare,
  putMoiPremiumListsList_idSwitch_hide_influencers,
  putMoiPremiumListsList_idTagTag_id,
  putMoiPremiumRoiAuction_idShare,
  putMoiPremiumTodo_templatesTemplate_idTodoTo_do_id,
  putMoiSupport_centerTicket_id,
  putMoiTask_offersTask_offer_id,
  putPixel_wizard,
  putSharesListsInfluencer_idNote,
  putSharesListsInfluencer_idResponse,
  putShopifyManageInfluencers,
  putShopifyManageInfluencersMediaInfluencer_id,
  putShopifyRoiFilter,
  putShopifySettings,
  putUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response,
  putUtilsTask_invite_responseInfluencer_idTask_id,
  putUtilsTrash_influencerAuction_idInfluencer_id,
  putWixManageInfluencers,
  putWixManageInfluencersMediaInfluencer_id,
  putWixRoiFilter,
  putWixSettings,
} from "./services";

/**
* @package
*/
export type SwaggerTypescriptMutationDefaultParams<TExtra> = {
  _extraVariables?: TExtra;
  configOverride?: AxiosRequestConfig;
};
type SwaggerTypescriptUseQueryOptions<TData> = Omit<
  UseQueryOptions<SwaggerResponse<TData>, RequestError | Error>,
  "queryKey"
>;

type SwaggerTypescriptUseMutationOptions<TData, TRequest, TExtra> =
  UseMutationOptions<
    SwaggerResponse<TData>,
    RequestError | Error,
    TRequest & SwaggerTypescriptMutationDefaultParams<TExtra>
  >;

type SwaggerTypescriptUseMutationOptionsVoid<TData, TExtra> =
  UseMutationOptions<
    SwaggerResponse<TData>,
    RequestError | Error,
    SwaggerTypescriptMutationDefaultParams<TExtra> | void
  >;

/**
 *
 * Remove influencer from active list
 */
/**
* @package
*/
export const useDeleteAgentInfluencerArchiveInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * Influencer ID to remove
       */
      influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,

        configOverride,
      } = _o || {};

      return deleteAgentInfluencerArchiveInfluencer_id(
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * remove influencer from agent management list - influencer request
 */
/**
* @package
*/
export const useDeleteAgentInfluencerDetach = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { configOverride } = _o || {};

      return deleteAgentInfluencerDetach(configOverride);
    },
    ...options,
  });
};

/**
 *
 * remove influencer from agent exclusive list
 */
/**
* @package
*/
export const useDeleteAgentInfluencerInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the influencer id to remove
       */
      influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,

        configOverride,
      } = _o || {};

      return deleteAgentInfluencerInfluencer_id(
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * decline all agent invites for influencer
 */
/**
* @package
*/
export const useDeleteAgentInfluencerStatusDecline_all = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { configOverride } = _o || {};

      return deleteAgentInfluencerStatusDecline_all(configOverride);
    },
    ...options,
  });
};

/**
 *
 * remove invoice
 */
/**
* @package
*/
export const useDeleteAgentInvoices = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: RequestBodyExternalInvoiceData;
      queryParams?: DeleteAgentInvoicesQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return deleteAgentInvoices(requestBody, queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * mark message as deleted for both sides of the match
 */
/**
* @package
*/
export const useDeleteCollaborateMatchInboxMatch_idMessage_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The match id you want to delete the message from
       */
      match_id: number;
      /**
       *
       * The mesasge id you want to delete
       */
      message_id: string;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        match_id,
        message_id,

        configOverride,
      } = _o || {};

      return deleteCollaborateMatchInboxMatch_idMessage_id(
        match_id,
        message_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * return to collaboration - will not return your matches
 */
/**
* @package
*/
export const useDeleteCollaborateOptout = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { configOverride } = _o || {};

      return deleteCollaborateOptout(configOverride);
    },
    ...options,
  });
};

/**
 *
 * Delete agent by ID
 */
/**
* @package
*/
export const useDeleteMoiAgentAgent_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The agent id to delete
       */
      agent_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        agent_id,

        configOverride,
      } = _o || {};

      return deleteMoiAgentAgent_id(
        agent_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete specific agent registered bank account details
 */
/**
* @package
*/
export const useDeleteMoiAgentAgent_idBankId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      id: number;
      /**
       *
       * The agent id to query
       */
      agent_id: number;
      queryParams?: DeleteMoiAgentAgent_idBankIdQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        id,
        agent_id,

        queryParams,
        configOverride,
      } = _o || {};

      return deleteMoiAgentAgent_idBankId(
        id,
        agent_id,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete a target
 */
/**
* @package
*/
export const useDeleteMoiAuctionAuction_idTargetsDelete = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      queryParams: DeleteMoiAuctionAuction_idTargetsDeleteQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,

        queryParams,
        configOverride,
      } = _o || {};

      return deleteMoiAuctionAuction_idTargetsDelete(
        auction_id,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Remove reminder
 */
/**
* @package
*/
export const useDeleteMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The auction id for proposal
         */
        auction_id: number;
        /**
         *
         * The influencer id for proposal
         */
        influencer_id: number;
        /**
         *
         * The auction id for proposal
         */
        task_id: number;
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          influencer_id,
          task_id,

          configOverride,
        } = _o || {};

        return deleteMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id(
          auction_id,
          influencer_id,
          task_id,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * delete files processed
 */
/**
* @package
*/
export const useDeleteMoiAuctionManageAuction_idFilesInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * ID of the influencer to whom the file is to be attached
       */
      influencer_id: number;
      /**
       *
       * Campaign ID to which the file is associated with the user
       */
      auction_id: number;
      requestBody: { uuids?: string[] };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionManageAuction_idFilesInfluencer_id(
        influencer_id,
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * mark extra payout as deleted.
 */
/**
* @package
*/
export const useDeleteMoiAuctionManageAuction_idInfluencer_idExtra_payoutExtra_payout_id =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The id of the auction
         */
        auction_id: number;
        /**
         *
         * The id of the influencers
         */
        influencer_id: number;
        /**
         *
         * Id of the extra payout
         */
        extra_payout_id: number;
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          influencer_id,
          extra_payout_id,

          configOverride,
        } = _o || {};

        return deleteMoiAuctionManageAuction_idInfluencer_idExtra_payoutExtra_payout_id(
          auction_id,
          influencer_id,
          extra_payout_id,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * delete dispute for payment
 */
/**
* @package
*/
export const useDeleteMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The id of the auction
         */
        auction_id: number;
        /**
         *
         * The id of the influencer
         */
        influencer_id: number;
        requestBody: { ids?: string[] };
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          influencer_id,
          requestBody,

          configOverride,
        } = _o || {};

        return deleteMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes(
          auction_id,
          influencer_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * delete upfront payment
 */
/**
* @package
*/
export const useDeleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the influencer
       */
      influencer_id: number;
      queryParams: DeleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfrontQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,

        queryParams,
        configOverride,
      } = _o || {};

      return deleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront(
        auction_id,
        influencer_id,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Advertiser's delete response for influencer proposal
 */
/**
* @package
*/
export const useDeleteMoiAuctionManageAuction_idProposal_feedbackProposal_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The campaign id for proposal
       */
      auction_id: number;
      /**
       *
       * The influencer's proposal id
       */
      proposal_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        proposal_id,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionManageAuction_idProposal_feedbackProposal_id(
        auction_id,
        proposal_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete influencer short link
 */
/**
* @package
*/
export const useDeleteMoiAuctionManageAuction_idShort_linkInfluencer_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the influencer
       */
      influencer_id: number;
      requestBody: {
        /**
         *
         * - Format: int64
         */
        extra_id?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionManageAuction_idShort_linkInfluencer_id(
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Delete board tag
 */
/**
* @package
*/
export const useDeleteMoiAuctionManageAuction_idTagTag_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the tag
       */
      tag_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        tag_id,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionManageAuction_idTagTag_id(
        auction_id,
        tag_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Advertiser delete influencer linked media
 */
/**
* @package
*/
export const useDeleteMoiAuctionManageBoard_idInfluencer_idPost_linkTask_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The board id for proposal
       */
      board_id: number;
      /**
       *
       * The influencer's task id
       */
      task_id: number;
      /**
       *
       * The influencer's id
       */
      influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        board_id,
        task_id,
        influencer_id,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionManageBoard_idInfluencer_idPost_linkTask_id(
        board_id,
        task_id,
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Advertiser delete influencer linked story
 */
/**
* @package
*/
export const useDeleteMoiAuctionManageBoard_idStory_view_countInfluencer_idTask_id =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The board id for proposal
         */
        board_id: number;
        /**
         *
         * The influencer's task id
         */
        task_id: number;
        /**
         *
         * The influencer's id
         */
        influencer_id: number;
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          board_id,
          task_id,
          influencer_id,

          configOverride,
        } = _o || {};

        return deleteMoiAuctionManageBoard_idStory_view_countInfluencer_idTask_id(
          board_id,
          task_id,
          influencer_id,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * mark coupon as deleted
 */
/**
* @package
*/
export const useDeleteMoiAuctionsAuction_idCoupons = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to get sales coupons
       */
      auction_id: number;
      requestBody: {
        /**
         *
         * - Format: int64
         */
        coupon_auction_id?: number;
        /**
         *
         * - Format: uuid
         */
        coupon_uuid?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionsAuction_idCoupons(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Delete auction instructions for existing auction
 */
/**
* @package
*/
export const useDeleteMoiAuctionsAuction_idInstructions = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to remove instruction from
       */
      auction_id: number;
      requestBody: InstructionIndicesToDelete;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionsAuction_idInstructions(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit auction phase
 */
/**
* @package
*/
export const useDeleteMoiAuctionsAuction_idPixel_phasesPhase_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to get the phases for
       */
      auction_id: number;
      /**
       *
       * the phase id get edit
       */
      phase_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        phase_id,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionsAuction_idPixel_phasesPhase_id(
        auction_id,
        phase_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Delete auction products for existing auction
 */
/**
* @package
*/
export const useDeleteMoiAuctionsAuction_idProduct = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to add products to
       */
      auction_id: number;
      requestBody: ProductIdsToDelete;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionsAuction_idProduct(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Delete auction questions for existing auction
 */
/**
* @package
*/
export const useDeleteMoiAuctionsAuction_idQuestions = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to remomve questions from
       */
      auction_id: number;
      requestBody: QuestionIdsToDelete;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionsAuction_idQuestions(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete invoice file to server multipart
 */
/**
* @package
*/
export const useDeleteMoiAuctionsAuction_idUpload_invoice = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id
       */
      auction_id: number;
      queryParams?: DeleteMoiAuctionsAuction_idUpload_invoiceQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,

        queryParams,
        configOverride,
      } = _o || {};

      return deleteMoiAuctionsAuction_idUpload_invoice(
        auction_id,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Delete an existing auction
 */
/**
* @package
*/
export const useDeleteMoiAuctionsId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id to delete
       */
      id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        id,

        configOverride,
      } = _o || {};

      return deleteMoiAuctionsId(
        id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Soft delete a specific report
 */
/**
* @package
*/
export const useDeleteMoiAuctionsRoiSlides = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { queryParams?: DeleteMoiAuctionsRoiSlidesQueryParams },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { queryParams, configOverride } = _o || {};

      return deleteMoiAuctionsRoiSlides(queryParams, configOverride);
    },
    ...options,
  });
};

/**
 * 
 * hide bid

 */
/**
* @package
*/
export const useDeleteMoiBidsBid_idHide = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The bid id you want to unhide
       */
      bid_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        bid_id,

        configOverride,
      } = _o || {};

      return deleteMoiBidsBid_idHide(
        bid_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Mark bid as_deleted to true

 */
/**
* @package
*/
export const useDeleteMoiBidsDeleteBid_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The bid_id to mark as undeleted
       */
      bid_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        bid_id,

        configOverride,
      } = _o || {};

      return deleteMoiBidsDeleteBid_id(
        bid_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * only for approvers , revert response
 */
/**
* @package
*/
export const useDeleteMoiCommunity_feedApprovalPost_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,

        configOverride,
      } = _o || {};

      return deleteMoiCommunity_feedApprovalPost_id(
        post_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete post in feed
 */
/**
* @package
*/
export const useDeleteMoiCommunity_feedPost_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,

        configOverride,
      } = _o || {};

      return deleteMoiCommunity_feedPost_id(
        post_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add comment to feed item
 */
/**
* @package
*/
export const useDeleteMoiCommunity_feedPost_idCommentsComment_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
      /**
       *
       * comment id
       */
      comment_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,
        comment_id,

        configOverride,
      } = _o || {};

      return deleteMoiCommunity_feedPost_idCommentsComment_id(
        post_id,
        comment_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * unlike post in feed
 */
/**
* @package
*/
export const useDeleteMoiCommunity_feedPost_idCommentsComment_idLike = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
      /**
       *
       * comment id
       */
      comment_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,
        comment_id,

        configOverride,
      } = _o || {};

      return deleteMoiCommunity_feedPost_idCommentsComment_idLike(
        post_id,
        comment_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * unlike post in feed
 */
/**
* @package
*/
export const useDeleteMoiCommunity_feedPost_idLike = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,

        configOverride,
      } = _o || {};

      return deleteMoiCommunity_feedPost_idLike(
        post_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * mark suggestion as deleted
 */
/**
* @package
*/
export const useDeleteMoiCommunity_feedSuggestionSuggestion_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * marks suggestion as deleted
       */
      suggestion_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        suggestion_id,

        configOverride,
      } = _o || {};

      return deleteMoiCommunity_feedSuggestionSuggestion_id(
        suggestion_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * delete user

 */
/**
* @package
*/
export const useDeleteMoiDelete_user = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { configOverride } = _o || {};

      return deleteMoiDelete_user(configOverride);
    },
    ...options,
  });
};

/**
 *
 * delete brand
 */
/**
* @package
*/
export const useDeleteMoiDetailsBrandBrand_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The brand to delete
       */
      brand_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        brand_id,

        configOverride,
      } = _o || {};

      return deleteMoiDetailsBrandBrand_id(
        brand_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete specific registered bank account details
 */
/**
* @package
*/
export const useDeleteMoiEdit_detailsInfluencerBankId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<any, { id: number }, TExtra>,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        id,

        configOverride,
      } = _o || {};

      return deleteMoiEdit_detailsInfluencerBankId(
        id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * remove user from your sub users
 */
/**
* @package
*/
export const useDeleteMoiEnterpriseUsersRemove_user = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: { email?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return deleteMoiEnterpriseUsersRemove_user(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * unstar chat
 */
/**
* @package
*/
export const useDeleteMoiInboxIdStar = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The user id you want to chat with
       */
      id: number;
      queryParams?: DeleteMoiInboxIdStarQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        id,

        queryParams,
        configOverride,
      } = _o || {};

      return deleteMoiInboxIdStar(
        id,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Delete messages from this chat

 */
/**
* @package
*/
export const useDeleteMoiInboxMessageMessage_guid = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The message guid to delete
       */
      message_guid: string;
      queryParams?: DeleteMoiInboxMessageMessage_guidQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        message_guid,

        queryParams,
        configOverride,
      } = _o || {};

      return deleteMoiInboxMessageMessage_guid(
        message_guid,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Delete meta tag

 */
/**
* @package
*/
export const useDeleteMoiMeta_tagsTag_typeTag_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The tag type
       */
      tag_type: string;
      /**
       *
       * The tag id
       */
      tag_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        tag_type,
        tag_id,

        configOverride,
      } = _o || {};

      return deleteMoiMeta_tagsTag_typeTag_id(
        tag_type,
        tag_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Soft delete all user notifications
 */
/**
* @package
*/
export const useDeleteMoiNotifications = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { configOverride } = _o || {};

      return deleteMoiNotifications(configOverride);
    },
    ...options,
  });
};

/**
 *
 * Soft delete notification
 */
/**
* @package
*/
export const useDeleteMoiNotificationsNotificationId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * Notification id to delete
       */
      notificationId: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        notificationId,

        configOverride,
      } = _o || {};

      return deleteMoiNotificationsNotificationId(
        notificationId,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete payment method
 */
/**
* @package
*/
export const useDeleteMoiPaymentPayment_from_advertiserPayment_method_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The payment method to delete
       */
      payment_method_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        payment_method_id,

        configOverride,
      } = _o || {};

      return deleteMoiPaymentPayment_from_advertiserPayment_method_id(
        payment_method_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * disable roi share
 */
/**
* @package
*/
export const useDeleteMoiPremiumAuctionAuction_idShare = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to share
       */
      auction_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,

        configOverride,
      } = _o || {};

      return deleteMoiPremiumAuctionAuction_idShare(
        auction_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit auction todo item
 */
/**
* @package
*/
export const useDeleteMoiPremiumAuctionAuction_idTodoTo_do_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction to share
       */
      auction_id: number;
      /**
       *
       * The to do item you want to delete
       */
      to_do_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        to_do_id,

        configOverride,
      } = _o || {};

      return deleteMoiPremiumAuctionAuction_idTodoTo_do_id(
        auction_id,
        to_do_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete list
 */
/**
* @package
*/
export const useDeleteMoiPremiumListsList_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to add to
       */
      list_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,

        configOverride,
      } = _o || {};

      return deleteMoiPremiumListsList_id(
        list_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * disable link share
 */
/**
* @package
*/
export const useDeleteMoiPremiumListsList_idShare = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to share
       */
      list_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,

        configOverride,
      } = _o || {};

      return deleteMoiPremiumListsList_idShare(
        list_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Delete board tag
 */
/**
* @package
*/
export const useDeleteMoiPremiumListsList_idTagTag_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list
       */
      list_id: number;
      /**
       *
       * The id of the tag
       */
      tag_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        tag_id,

        configOverride,
      } = _o || {};

      return deleteMoiPremiumListsList_idTagTag_id(
        list_id,
        tag_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * disable roi share
 */
/**
* @package
*/
export const useDeleteMoiPremiumRoiAuction_idShare = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to share
       */
      auction_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,

        configOverride,
      } = _o || {};

      return deleteMoiPremiumRoiAuction_idShare(
        auction_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete template
 */
/**
* @package
*/
export const useDeleteMoiPremiumTodo_templatesTemplate_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the template to create item in
       */
      template_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        template_id,

        configOverride,
      } = _o || {};

      return deleteMoiPremiumTodo_templatesTemplate_id(
        template_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete auction todo item
 */
/**
* @package
*/
export const useDeleteMoiPremiumTodo_templatesTemplate_idTodoTo_do_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the template to edit
       */
      template_id: number;
      /**
       *
       * The to do item you want to delete
       */
      to_do_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        template_id,
        to_do_id,

        configOverride,
      } = _o || {};

      return deleteMoiPremiumTodo_templatesTemplate_idTodoTo_do_id(
        template_id,
        to_do_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Delete the last instagram challenge of a user

 */
/**
* @package
*/
export const useDeleteMoiRemove_last_user_instagram_challengeChallenge_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the challenge id
       */
      challenge_id: string;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        challenge_id,

        configOverride,
      } = _o || {};

      return deleteMoiRemove_last_user_instagram_challengeChallenge_id(
        challenge_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Delete shadow influencer by id
 */
/**
* @package
*/
export const useDeleteMoiShadow_influencersShadow_influencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * Shadow influencer id to edit
       */
      shadow_influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        shadow_influencer_id,

        configOverride,
      } = _o || {};

      return deleteMoiShadow_influencersShadow_influencer_id(
        shadow_influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete specific ticket
 */
/**
* @package
*/
export const useDeleteMoiSupport_centerTicket_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * ticket id to query
       */
      ticket_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        ticket_id,

        configOverride,
      } = _o || {};

      return deleteMoiSupport_centerTicket_id(
        ticket_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete task offer
 */
/**
* @package
*/
export const useDeleteMoiTask_offersTask_offer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the offer
       */
      task_offer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        task_offer_id,

        configOverride,
      } = _o || {};

      return deleteMoiTask_offersTask_offer_id(
        task_offer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Delete unfinished campaign
 */
/**
* @package
*/
export const useDeleteMoiUnfinished_campaignsUnfinished_campaign_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The unfinished campaign id
       */
      unfinished_campaign_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        unfinished_campaign_id,

        configOverride,
      } = _o || {};

      return deleteMoiUnfinished_campaignsUnfinished_campaign_id(
        unfinished_campaign_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * cancel ongoing uploads
 */
/**
* @package
*/
export const useDeleteMoiUpload_imageUpload_type = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: uuid
       */
      id?: string;
      status?: "cancel" | "not_found" | "used";
    }[],
    { upload_type: AllowedImageUploadTypes; requestBody: string[] },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        upload_type,
        requestBody,

        configOverride,
      } = _o || {};

      return deleteMoiUpload_imageUpload_type(
        upload_type,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add influencers to user
 */
/**
* @package
*/
export const useDeleteShopifyManageInfluencers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        influencer_id?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return deleteShopifyManageInfluencers(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Delete saved filter by id
 */
/**
* @package
*/
export const useDeleteShopifyRoiFilter = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { queryParams: DeleteShopifyRoiFilterQueryParams },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { queryParams, configOverride } = _o || {};

      return deleteShopifyRoiFilter(queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * Delete short link extra link for influencer
 */
/**
* @package
*/
export const useDeleteShopifySettingsLinkInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the influencer id
       */
      influencer_id: number;
      requestBody: RequestBodyInfluencerShortLinkUpdateData;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return deleteShopifySettingsLinkInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add influencers to user
 */
/**
* @package
*/
export const useDeleteWixManageInfluencers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        influencer_id?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return deleteWixManageInfluencers(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Delete saved filter by id
 */
/**
* @package
*/
export const useDeleteWixRoiFilter = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { queryParams: DeleteWixRoiFilterQueryParams },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { queryParams, configOverride } = _o || {};

      return deleteWixRoiFilter(queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * Delete short link extra link for influencer
 */
/**
* @package
*/
export const useDeleteWixSettingsLinkInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the influencer id
       */
      influencer_id: number;
      requestBody: RequestBodyInfluencerShortLinkUpdateData;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return deleteWixSettingsLinkInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Get specific course translation id => only if susbcribed to it

 */
/**
* @package
*/
export const useGetAcademyChaptersChapter_idLessonLesson_ordinal_num = (
  /**
   *
   * the current chapter id
   */
  chapter_id: number,
  /**
   *
   * the lesson ordinal number
   */
  lesson_ordinal_num: number,
  options?: SwaggerTypescriptUseQueryOptions<Lesson>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetAcademyChaptersChapter_idLessonLesson_ordinal_num.info(
      chapter_id,
      lesson_ordinal_num,

      configOverride,
    );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAcademyChaptersChapter_idLessonLesson_ordinal_num.info = (
  /**
   *
   * the current chapter id
   */
  chapter_id: number,
  /**
   *
   * the lesson ordinal number
   */
  lesson_ordinal_num: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getAcademyChaptersChapter_idLessonLesson_ordinal_num.key,
      chapter_id,
      lesson_ordinal_num,
    ] as QueryKey,
    fun: () =>
      getAcademyChaptersChapter_idLessonLesson_ordinal_num(
        chapter_id,
        lesson_ordinal_num,

        configOverride,
      ),
  };
};
useGetAcademyChaptersChapter_idLessonLesson_ordinal_num.prefetch = (
  client: QueryClient,

  /**
   *
   * the current chapter id
   */
  chapter_id: number,
  /**
   *
   * the lesson ordinal number
   */
  lesson_ordinal_num: number,
  options?: SwaggerTypescriptUseQueryOptions<Lesson>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetAcademyChaptersChapter_idLessonLesson_ordinal_num.info(
      chapter_id,
      lesson_ordinal_num,

      configOverride,
    );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get all available courses for me

 */
/**
* @package
*/
export const useGetAcademyCourses = (
  queryParams?: GetAcademyCoursesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CourseSummary[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAcademyCourses.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAcademyCourses.info = (
  queryParams?: GetAcademyCoursesQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getAcademyCourses.key, queryParams] as QueryKey,
    fun: () =>
      getAcademyCourses(
        queryParams,

        configOverride,
      ),
  };
};
useGetAcademyCourses.prefetch = (
  client: QueryClient,
  queryParams?: GetAcademyCoursesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CourseSummary[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAcademyCourses.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get specific course translation id => only if susbcribed to it

 */
/**
* @package
*/
export const useGetAcademyCoursesCourse_translation_id = (
  /**
   *
   * the course translation id
   */
  course_translation_id: number,
  options?: SwaggerTypescriptUseQueryOptions<Course>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAcademyCoursesCourse_translation_id.info(
    course_translation_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAcademyCoursesCourse_translation_id.info = (
  /**
   *
   * the course translation id
   */
  course_translation_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getAcademyCoursesCourse_translation_id.key,
      course_translation_id,
    ] as QueryKey,
    fun: () =>
      getAcademyCoursesCourse_translation_id(
        course_translation_id,

        configOverride,
      ),
  };
};
useGetAcademyCoursesCourse_translation_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the course translation id
   */
  course_translation_id: number,
  options?: SwaggerTypescriptUseQueryOptions<Course>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAcademyCoursesCourse_translation_id.info(
    course_translation_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get quiz

 */
/**
* @package
*/
export const useGetAcademyQuizQuiz_id = (
  /**
   *
   * the chapter quiz id
   */
  quiz_id: string,
  options?: SwaggerTypescriptUseQueryOptions<AcademyQuizQuestion[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAcademyQuizQuiz_id.info(
    quiz_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAcademyQuizQuiz_id.info = (
  /**
   *
   * the chapter quiz id
   */
  quiz_id: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getAcademyQuizQuiz_id.key, quiz_id] as QueryKey,
    fun: () =>
      getAcademyQuizQuiz_id(
        quiz_id,

        configOverride,
      ),
  };
};
useGetAcademyQuizQuiz_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the chapter quiz id
   */
  quiz_id: string,
  options?: SwaggerTypescriptUseQueryOptions<AcademyQuizQuestion[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAcademyQuizQuiz_id.info(
    quiz_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get all auctions for agent
 */
/**
* @package
*/
export const useGetAgentAuctions = (
  queryParams: GetAgentAuctionsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<MinimalAuctionDataForAgent[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentAuctions.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAgentAuctions.info = (
  queryParams: GetAgentAuctionsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getAgentAuctions.key, queryParams] as QueryKey,
    fun: () =>
      getAgentAuctions(
        queryParams,

        configOverride,
      ),
  };
};
useGetAgentAuctions.prefetch = (
  client: QueryClient,
  queryParams: GetAgentAuctionsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<MinimalAuctionDataForAgent[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentAuctions.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get auction by id for agent
 */
/**
* @package
*/
export const useGetAgentAuctionsId = (
  /**
   *
   * the auction id to query
   */
  id: number,
  queryParams?: GetAgentAuctionsIdQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AuctionDataForAgentExtended>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentAuctionsId.info(
    id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAgentAuctionsId.info = (
  /**
   *
   * the auction id to query
   */
  id: number,
  queryParams?: GetAgentAuctionsIdQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getAgentAuctionsId.key, id, queryParams] as QueryKey,
    fun: () =>
      getAgentAuctionsId(
        id,

        queryParams,

        configOverride,
      ),
  };
};
useGetAgentAuctionsId.prefetch = (
  client: QueryClient,

  /**
   *
   * the auction id to query
   */
  id: number,
  queryParams?: GetAgentAuctionsIdQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AuctionDataForAgentExtended>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentAuctionsId.info(
    id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get auction by id for agent from influencer view
 */
/**
* @package
*/
export const useGetAgentAuctionsIdInfluencerInfluencer_id = (
  /**
   *
   * the auction id to query
   */
  id: number,
  /**
   *
   * specific influencer id to filter
   */
  influencer_id: number,
  options?: SwaggerTypescriptUseQueryOptions<AuctionDataForInfluencerExtended>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentAuctionsIdInfluencerInfluencer_id.info(
    id,
    influencer_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAgentAuctionsIdInfluencerInfluencer_id.info = (
  /**
   *
   * the auction id to query
   */
  id: number,
  /**
   *
   * specific influencer id to filter
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getAgentAuctionsIdInfluencerInfluencer_id.key,
      id,
      influencer_id,
    ] as QueryKey,
    fun: () =>
      getAgentAuctionsIdInfluencerInfluencer_id(
        id,
        influencer_id,

        configOverride,
      ),
  };
};
useGetAgentAuctionsIdInfluencerInfluencer_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the auction id to query
   */
  id: number,
  /**
   *
   * specific influencer id to filter
   */
  influencer_id: number,
  options?: SwaggerTypescriptUseQueryOptions<AuctionDataForInfluencerExtended>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentAuctionsIdInfluencerInfluencer_id.info(
    id,
    influencer_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get current agent details
 */
/**
* @package
*/
export const useGetAgentCurrent_user = (
  options?: SwaggerTypescriptUseQueryOptions<AllAgentDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentCurrent_user.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAgentCurrent_user.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getAgentCurrent_user.key] as QueryKey,
    fun: () => getAgentCurrent_user(configOverride),
  };
};
useGetAgentCurrent_user.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<AllAgentDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentCurrent_user.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * query influencer status and info by id
 */
/**
* @package
*/
export const useGetAgentInfluencerInfluencer_id = (
  /**
   *
   * the influencer id to query
   */
  influencer_id: number,
  queryParams?: GetAgentInfluencerInfluencer_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerStatusInAgentList>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentInfluencerInfluencer_id.info(
    influencer_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAgentInfluencerInfluencer_id.info = (
  /**
   *
   * the influencer id to query
   */
  influencer_id: number,
  queryParams?: GetAgentInfluencerInfluencer_idQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getAgentInfluencerInfluencer_id.key,
      influencer_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getAgentInfluencerInfluencer_id(
        influencer_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetAgentInfluencerInfluencer_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the influencer id to query
   */
  influencer_id: number,
  queryParams?: GetAgentInfluencerInfluencer_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerStatusInAgentList>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentInfluencerInfluencer_id.info(
    influencer_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list all of agent users linked to influencer, defaults to only currently pending
 */
/**
* @package
*/
export const useGetAgentInfluencerStatus = (
  queryParams?: GetAgentInfluencerStatusQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      agent_display_name?: string;
      /**
       *
       * - Format: int64
       */
      agent_user_id?: number;
      history?: {
        /**
         *
         * - Format: date-time
         */
        end_time?: string;
        /**
         *
         * - Format: date-time
         */
        start_time?: string;
        status?: InfluencerAgentInviteStatus;
      }[];
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentInfluencerStatus.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAgentInfluencerStatus.info = (
  queryParams?: GetAgentInfluencerStatusQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getAgentInfluencerStatus.key, queryParams] as QueryKey,
    fun: () =>
      getAgentInfluencerStatus(
        queryParams,

        configOverride,
      ),
  };
};
useGetAgentInfluencerStatus.prefetch = (
  client: QueryClient,
  queryParams?: GetAgentInfluencerStatusQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      agent_display_name?: string;
      /**
       *
       * - Format: int64
       */
      agent_user_id?: number;
      history?: {
        /**
         *
         * - Format: date-time
         */
        end_time?: string;
        /**
         *
         * - Format: date-time
         */
        start_time?: string;
        status?: InfluencerAgentInviteStatus;
      }[];
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentInfluencerStatus.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list all agent's influencers
 */
/**
* @package
*/
export const useGetAgentInfluencers = (
  queryParams?: GetAgentInfluencersQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerStatusInAgentList[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentInfluencers.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAgentInfluencers.info = (
  queryParams?: GetAgentInfluencersQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getAgentInfluencers.key, queryParams] as QueryKey,
    fun: () =>
      getAgentInfluencers(
        queryParams,

        configOverride,
      ),
  };
};
useGetAgentInfluencers.prefetch = (
  client: QueryClient,
  queryParams?: GetAgentInfluencersQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerStatusInAgentList[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentInfluencers.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get external invoices by shared guid
 */
/**
* @package
*/
export const useGetAgentInvoices = (
  queryParams?: GetAgentInvoicesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<PayoutInvoiceData[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentInvoices.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAgentInvoices.info = (
  queryParams?: GetAgentInvoicesQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getAgentInvoices.key, queryParams] as QueryKey,
    fun: () =>
      getAgentInvoices(
        queryParams,

        configOverride,
      ),
  };
};
useGetAgentInvoices.prefetch = (
  client: QueryClient,
  queryParams?: GetAgentInvoicesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<PayoutInvoiceData[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentInvoices.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get/create payments page id by agent user id
 */
/**
* @package
*/
export const useGetAgentPaymentsExternal_page = (
  options?: SwaggerTypescriptUseQueryOptions<{
    company_id?: string;
    is_active?: boolean;
    /**
     *
     * - Format: uuid
     */
    uuid?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentPaymentsExternal_page.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetAgentPaymentsExternal_page.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getAgentPaymentsExternal_page.key] as QueryKey,
    fun: () => getAgentPaymentsExternal_page(configOverride),
  };
};
useGetAgentPaymentsExternal_page.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    company_id?: string;
    is_active?: boolean;
    /**
     *
     * - Format: uuid
     */
    uuid?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetAgentPaymentsExternal_page.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get influencer collaborate filter paramteres
 */
/**
* @package
*/
export const useGetCollaborateFilter_parameters = (
  options?: SwaggerTypescriptUseQueryOptions<InfluencerCollaborateFilterParameters>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateFilter_parameters.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetCollaborateFilter_parameters.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getCollaborateFilter_parameters.key] as QueryKey,
    fun: () => getCollaborateFilter_parameters(configOverride),
  };
};
useGetCollaborateFilter_parameters.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerCollaborateFilterParameters>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateFilter_parameters.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get intersection of followers between me and another influencer
 */
/**
* @package
*/
export const useGetCollaborateGet_followers_intersectionUser_id = (
  /**
   *
   * The influencer id you want to chat with
   */
  user_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: double
     */
    percentage?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateGet_followers_intersectionUser_id.info(
    user_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetCollaborateGet_followers_intersectionUser_id.info = (
  /**
   *
   * The influencer id you want to chat with
   */
  user_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getCollaborateGet_followers_intersectionUser_id.key,
      user_id,
    ] as QueryKey,
    fun: () =>
      getCollaborateGet_followers_intersectionUser_id(
        user_id,

        configOverride,
      ),
  };
};
useGetCollaborateGet_followers_intersectionUser_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The influencer id you want to chat with
   */
  user_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: double
     */
    percentage?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateGet_followers_intersectionUser_id.info(
    user_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get collaborate likes the user
 */
/**
* @package
*/
export const useGetCollaborateGet_likers = (
  options?: SwaggerTypescriptUseQueryOptions<CollaborateInfluencerProfile[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateGet_likers.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetCollaborateGet_likers.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getCollaborateGet_likers.key] as QueryKey,
    fun: () => getCollaborateGet_likers(configOverride),
  };
};
useGetCollaborateGet_likers.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<CollaborateInfluencerProfile[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateGet_likers.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get collaborate matches
 */
/**
* @package
*/
export const useGetCollaborateGet_matches = (
  queryParams?: GetCollaborateGet_matchesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      influencer?: CollaborateInfluencerProfile;
      /**
       *
       * - Format: date-time
       */
      match_activity_time?: string;
      /**
       *
       * - Format: int64
       */
      match_id?: number;
      /**
       *
       * MatchLastMessage
       *
       */
      messages_details?: {
        last_message?: string;
        last_message_content_type?: string;
        last_message_mine?: boolean;
        /**
         *
         * - Format: date-time
         */
        last_message_time?: string;
        mark_deleted?: boolean;
        message_guid?: string;
        seen_match?: boolean;
        /**
         *
         * - Format: int64
         */
        unseen_messages_count?: number;
      };
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateGet_matches.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetCollaborateGet_matches.info = (
  queryParams?: GetCollaborateGet_matchesQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getCollaborateGet_matches.key, queryParams] as QueryKey,
    fun: () =>
      getCollaborateGet_matches(
        queryParams,

        configOverride,
      ),
  };
};
useGetCollaborateGet_matches.prefetch = (
  client: QueryClient,
  queryParams?: GetCollaborateGet_matchesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      influencer?: CollaborateInfluencerProfile;
      /**
       *
       * - Format: date-time
       */
      match_activity_time?: string;
      /**
       *
       * - Format: int64
       */
      match_id?: number;
      /**
       *
       * MatchLastMessage
       *
       */
      messages_details?: {
        last_message?: string;
        last_message_content_type?: string;
        last_message_mine?: boolean;
        /**
         *
         * - Format: date-time
         */
        last_message_time?: string;
        mark_deleted?: boolean;
        message_guid?: string;
        seen_match?: boolean;
        /**
         *
         * - Format: int64
         */
        unseen_messages_count?: number;
      };
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateGet_matches.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get collaborate useres to swipe
 */
/**
* @package
*/
export const useGetCollaborateGet_new_users = (
  options?: SwaggerTypescriptUseQueryOptions<{
    influencers?: CollaborateInfluencerProfile[];
    swipe_limits?: SwipeLimits;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateGet_new_users.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetCollaborateGet_new_users.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getCollaborateGet_new_users.key] as QueryKey,
    fun: () => getCollaborateGet_new_users(configOverride),
  };
};
useGetCollaborateGet_new_users.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    influencers?: CollaborateInfluencerProfile[];
    swipe_limits?: SwipeLimits;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateGet_new_users.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get more messages from this chat

 */
/**
* @package
*/
export const useGetCollaborateInboxMatch_id = (
  /**
   *
   * The match id you want to chat with
   */
  match_id: number,
  queryParams?: GetCollaborateInboxMatch_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CollaborateMessagesReceived>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateInboxMatch_id.info(
    match_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetCollaborateInboxMatch_id.info = (
  /**
   *
   * The match id you want to chat with
   */
  match_id: number,
  queryParams?: GetCollaborateInboxMatch_idQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getCollaborateInboxMatch_id.key, match_id, queryParams] as QueryKey,
    fun: () =>
      getCollaborateInboxMatch_id(
        match_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetCollaborateInboxMatch_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The match id you want to chat with
   */
  match_id: number,
  queryParams?: GetCollaborateInboxMatch_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CollaborateMessagesReceived>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateInboxMatch_id.info(
    match_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get user ids of chats which have new messages

 */
/**
* @package
*/
export const useGetCollaborateInboxNew_chats = (
  options?: SwaggerTypescriptUseQueryOptions<{ match_ids?: number[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateInboxNew_chats.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetCollaborateInboxNew_chats.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getCollaborateInboxNew_chats.key] as QueryKey,
    fun: () => getCollaborateInboxNew_chats(configOverride),
  };
};
useGetCollaborateInboxNew_chats.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{ match_ids?: number[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateInboxNew_chats.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get match id with messages

 */
/**
* @package
*/
export const useGetCollaborateMatchMatch_id = (
  /**
   *
   * The match id you want to chat with
   */
  match_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    influencer_details?: CollaborateInfluencerProfile;
    last_messages?: CollaborateMessagesReceived;
    /**
     *
     * - Format: date-time
     */
    matched_at?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateMatchMatch_id.info(
    match_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetCollaborateMatchMatch_id.info = (
  /**
   *
   * The match id you want to chat with
   */
  match_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getCollaborateMatchMatch_id.key, match_id] as QueryKey,
    fun: () =>
      getCollaborateMatchMatch_id(
        match_id,

        configOverride,
      ),
  };
};
useGetCollaborateMatchMatch_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The match id you want to chat with
   */
  match_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    influencer_details?: CollaborateInfluencerProfile;
    last_messages?: CollaborateMessagesReceived;
    /**
     *
     * - Format: date-time
     */
    matched_at?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateMatchMatch_id.info(
    match_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get number of matches unseen

 */
/**
* @package
*/
export const useGetCollaborateMatchUnseen_count = (
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: int64
     */
    unseen?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateMatchUnseen_count.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetCollaborateMatchUnseen_count.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getCollaborateMatchUnseen_count.key] as QueryKey,
    fun: () => getCollaborateMatchUnseen_count(configOverride),
  };
};
useGetCollaborateMatchUnseen_count.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: int64
     */
    unseen?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetCollaborateMatchUnseen_count.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * get invite details

 */
/**
* @package
*/
export const useGetEnterprise_inviteInvite_id = (
  /**
   *
   * the invite id
   */
  invite_id: string,
  options?: SwaggerTypescriptUseQueryOptions<{
    email_invited?: string;
    /**
     *
     * - Format: int64
     */
    enterprise_name?: string;
    image?: string;
    /**
     *
     * - Format: date-time
     */
    invite_created_at?: string;
    invite_status?: "accepted" | "rejected" | "removed" | "waiting_response";
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetEnterprise_inviteInvite_id.info(
    invite_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetEnterprise_inviteInvite_id.info = (
  /**
   *
   * the invite id
   */
  invite_id: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getEnterprise_inviteInvite_id.key, invite_id] as QueryKey,
    fun: () =>
      getEnterprise_inviteInvite_id(
        invite_id,

        configOverride,
      ),
  };
};
useGetEnterprise_inviteInvite_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the invite id
   */
  invite_id: string,
  options?: SwaggerTypescriptUseQueryOptions<{
    email_invited?: string;
    /**
     *
     * - Format: int64
     */
    enterprise_name?: string;
    image?: string;
    /**
     *
     * - Format: date-time
     */
    invite_created_at?: string;
    invite_status?: "accepted" | "rejected" | "removed" | "waiting_response";
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetEnterprise_inviteInvite_id.info(
    invite_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * accept invite

 */
/**
* @package
*/
export const useGetEnterprise_inviteInvite_idAccept = (
  /**
   *
   * the invite id
   */
  invite_id: string,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetEnterprise_inviteInvite_idAccept.info(
    invite_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetEnterprise_inviteInvite_idAccept.info = (
  /**
   *
   * the invite id
   */
  invite_id: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getEnterprise_inviteInvite_idAccept.key, invite_id] as QueryKey,
    fun: () =>
      getEnterprise_inviteInvite_idAccept(
        invite_id,

        configOverride,
      ),
  };
};
useGetEnterprise_inviteInvite_idAccept.prefetch = (
  client: QueryClient,

  /**
   *
   * the invite id
   */
  invite_id: string,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetEnterprise_inviteInvite_idAccept.info(
    invite_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * decline invite

 */
/**
* @package
*/
export const useGetEnterprise_inviteInvite_idDecline = (
  /**
   *
   * the invite id
   */
  invite_id: string,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetEnterprise_inviteInvite_idDecline.info(
    invite_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetEnterprise_inviteInvite_idDecline.info = (
  /**
   *
   * the invite id
   */
  invite_id: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getEnterprise_inviteInvite_idDecline.key, invite_id] as QueryKey,
    fun: () =>
      getEnterprise_inviteInvite_idDecline(
        invite_id,

        configOverride,
      ),
  };
};
useGetEnterprise_inviteInvite_idDecline.prefetch = (
  client: QueryClient,

  /**
   *
   * the invite id
   */
  invite_id: string,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetEnterprise_inviteInvite_idDecline.info(
    invite_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const useGetExternal_apiProfileInfluencer_username = (
  /**
   *
   * the influencer username
   */
  influencer_username: string,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerProfilePage>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetExternal_apiProfileInfluencer_username.info(
    influencer_username,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetExternal_apiProfileInfluencer_username.info = (
  /**
   *
   * the influencer username
   */
  influencer_username: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getExternal_apiProfileInfluencer_username.key,
      influencer_username,
    ] as QueryKey,
    fun: () =>
      getExternal_apiProfileInfluencer_username(
        influencer_username,

        configOverride,
      ),
  };
};
useGetExternal_apiProfileInfluencer_username.prefetch = (
  client: QueryClient,

  /**
   *
   * the influencer username
   */
  influencer_username: string,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerProfilePage>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetExternal_apiProfileInfluencer_username.info(
    influencer_username,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Create a password recovery process
 */
/**
* @package
*/
export const useGetForgot_password = (
  queryParams: GetForgot_passwordQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetForgot_password.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetForgot_password.info = (
  queryParams: GetForgot_passwordQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getForgot_password.key, queryParams] as QueryKey,
    fun: () =>
      getForgot_password(
        queryParams,

        configOverride,
      ),
  };
};
useGetForgot_password.prefetch = (
  client: QueryClient,
  queryParams: GetForgot_passwordQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetForgot_password.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Validate reset password request with token and email
 */
/**
* @package
*/
export const useGetForgot_passwordValidate = (
  queryParams: GetForgot_passwordValidateQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetForgot_passwordValidate.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetForgot_passwordValidate.info = (
  queryParams: GetForgot_passwordValidateQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getForgot_passwordValidate.key, queryParams] as QueryKey,
    fun: () =>
      getForgot_passwordValidate(
        queryParams,

        configOverride,
      ),
  };
};
useGetForgot_passwordValidate.prefetch = (
  client: QueryClient,
  queryParams: GetForgot_passwordValidateQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetForgot_passwordValidate.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Validate reset password request with token and email of influencer
 */
/**
* @package
*/
export const useGetForgot_passwordValidate_influencer = (
  queryParams: GetForgot_passwordValidate_influencerQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ValidPasswordResetToken>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetForgot_passwordValidate_influencer.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetForgot_passwordValidate_influencer.info = (
  queryParams: GetForgot_passwordValidate_influencerQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getForgot_passwordValidate_influencer.key, queryParams] as QueryKey,
    fun: () =>
      getForgot_passwordValidate_influencer(
        queryParams,

        configOverride,
      ),
  };
};
useGetForgot_passwordValidate_influencer.prefetch = (
  client: QueryClient,
  queryParams: GetForgot_passwordValidate_influencerQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ValidPasswordResetToken>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetForgot_passwordValidate_influencer.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * check if healthy for load balancer

 */
/**
* @package
*/
export const useGetHealthy = (
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetHealthy.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetHealthy.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getHealthy.key] as QueryKey,
    fun: () => getHealthy(configOverride),
  };
};
useGetHealthy.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetHealthy.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get external invoices by shared guid
 */
/**
* @package
*/
export const useGetInvoices = (
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
    /**
     *
     * - minLength: 1
     */
    "auth-key-password": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<PayoutInvoiceData[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetInvoices.info(headerParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetInvoices.info = (
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
    /**
     *
     * - minLength: 1
     */
    "auth-key-password": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getInvoices.key, headerParams] as QueryKey,
    fun: () => getInvoices(headerParams, configOverride),
  };
};
useGetInvoices.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
    /**
     *
     * - minLength: 1
     */
    "auth-key-password": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<PayoutInvoiceData[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetInvoices.info(headerParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Logout from current session
 */
/**
* @package
*/
export const useGetLogout = (
  queryParams?: GetLogoutQueryParams,
  headerParams?: { "X-MoiBackendRefreshToken": string },
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetLogout.info(
    queryParams,
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetLogout.info = (
  queryParams?: GetLogoutQueryParams,
  headerParams?: { "X-MoiBackendRefreshToken": string },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getLogout.key, queryParams, headerParams] as QueryKey,
    fun: () => getLogout(queryParams, headerParams, configOverride),
  };
};
useGetLogout.prefetch = (
  client: QueryClient,
  queryParams?: GetLogoutQueryParams,
  headerParams?: { "X-MoiBackendRefreshToken": string },
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetLogout.info(
    queryParams,
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get advertiser queries
 */
/**
* @package
*/
export const useGetMoiAdvertiser_saved_queries = (
  options?: SwaggerTypescriptUseQueryOptions<{ data?: string; id?: number }[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAdvertiser_saved_queries.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAdvertiser_saved_queries.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAdvertiser_saved_queries.key] as QueryKey,
    fun: () => getMoiAdvertiser_saved_queries(configOverride),
  };
};
useGetMoiAdvertiser_saved_queries.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{ data?: string; id?: number }[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAdvertiser_saved_queries.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get affiliate details
 */
/**
* @package
*/
export const useGetMoiAffiliate_details = (
  options?: SwaggerTypescriptUseQueryOptions<{
    affiliate_with?: string;
    my_code?: string;
    /**
     *
     * - Format: int64
     */
    number_affiliate_with_me?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAffiliate_details.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAffiliate_details.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiAffiliate_details.key] as QueryKey,
    fun: () => getMoiAffiliate_details(configOverride),
  };
};
useGetMoiAffiliate_details.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    affiliate_with?: string;
    my_code?: string;
    /**
     *
     * - Format: int64
     */
    number_affiliate_with_me?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAffiliate_details.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get Agents Info
 */
/**
* @package
*/
export const useGetMoiAgent = (
  queryParams?: GetMoiAgentQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerAgentInfo[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgent.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgent.info = (
  queryParams?: GetMoiAgentQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAgent.key, queryParams] as QueryKey,
    fun: () =>
      getMoiAgent(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAgent.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiAgentQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerAgentInfo[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgent.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get Agent Info by ID
 */
/**
* @package
*/
export const useGetMoiAgentAgent_id = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerAgentInfo>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_id.info(
    agent_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgentAgent_id.info = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAgentAgent_id.key, agent_id] as QueryKey,
    fun: () =>
      getMoiAgentAgent_id(
        agent_id,

        configOverride,
      ),
  };
};
useGetMoiAgentAgent_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The agent id to query
   */
  agent_id: number,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerAgentInfo>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_id.info(
    agent_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list all registered bank accounts
 */
/**
* @package
*/
export const useGetMoiAgentAgent_idBank = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredBankAccount[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBank.info(
    agent_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgentAgent_idBank.info = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAgentAgent_idBank.key, agent_id, queryParams] as QueryKey,
    fun: () =>
      getMoiAgentAgent_idBank(
        agent_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAgentAgent_idBank.prefetch = (
  client: QueryClient,

  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredBankAccount[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBank.info(
    agent_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get agent registered bank account details
 */
/**
* @package
*/
export const useGetMoiAgentAgent_idBankId = (
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankIdQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredBankAccount>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBankId.info(
    id,
    agent_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgentAgent_idBankId.info = (
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankIdQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAgentAgent_idBankId.key, id, agent_id, queryParams] as QueryKey,
    fun: () =>
      getMoiAgentAgent_idBankId(
        id,
        agent_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAgentAgent_idBankId.prefetch = (
  client: QueryClient,
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankIdQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredBankAccount>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBankId.info(
    id,
    agent_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list all UK registered bank accounts for agent
 */
/**
* @package
*/
export const useGetMoiAgentAgent_idBankUk = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredUKAgentBankAccount[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBankUk.info(
    agent_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgentAgent_idBankUk.info = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAgentAgent_idBankUk.key, agent_id] as QueryKey,
    fun: () =>
      getMoiAgentAgent_idBankUk(
        agent_id,

        configOverride,
      ),
  };
};
useGetMoiAgentAgent_idBankUk.prefetch = (
  client: QueryClient,

  /**
   *
   * The agent id to query
   */
  agent_id: number,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredUKAgentBankAccount[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBankUk.info(
    agent_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get uk agent registered bank account details
 */
/**
* @package
*/
export const useGetMoiAgentAgent_idBankUkId = (
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredUKAgentBankAccount>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBankUkId.info(
    id,
    agent_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgentAgent_idBankUkId.info = (
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAgentAgent_idBankUkId.key, id, agent_id] as QueryKey,
    fun: () =>
      getMoiAgentAgent_idBankUkId(
        id,
        agent_id,

        configOverride,
      ),
  };
};
useGetMoiAgentAgent_idBankUkId.prefetch = (
  client: QueryClient,
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredUKAgentBankAccount>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBankUkId.info(
    id,
    agent_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list all US registered bank accounts for agent
 */
/**
* @package
*/
export const useGetMoiAgentAgent_idBankUs = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankUsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ExistingUSAgentBankAccount[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBankUs.info(
    agent_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgentAgent_idBankUs.info = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankUsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAgentAgent_idBankUs.key, agent_id, queryParams] as QueryKey,
    fun: () =>
      getMoiAgentAgent_idBankUs(
        agent_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAgentAgent_idBankUs.prefetch = (
  client: QueryClient,

  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankUsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ExistingUSAgentBankAccount[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBankUs.info(
    agent_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get us agent registered bank account details
 */
/**
* @package
*/
export const useGetMoiAgentAgent_idBankUsId = (
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredUSAgentBankAccount>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBankUsId.info(
    id,
    agent_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgentAgent_idBankUsId.info = (
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAgentAgent_idBankUsId.key, id, agent_id] as QueryKey,
    fun: () =>
      getMoiAgentAgent_idBankUsId(
        id,
        agent_id,

        configOverride,
      ),
  };
};
useGetMoiAgentAgent_idBankUsId.prefetch = (
  client: QueryClient,
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredUSAgentBankAccount>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgentAgent_idBankUsId.info(
    id,
    agent_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get data for agents dashboard
 */
/**
* @package
*/
export const useGetMoiAgents_dashboard = (
  queryParams: GetMoiAgents_dashboardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AgentsDashboard>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgents_dashboard.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgents_dashboard.info = (
  queryParams: GetMoiAgents_dashboardQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAgents_dashboard.key, queryParams] as QueryKey,
    fun: () =>
      getMoiAgents_dashboard(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAgents_dashboard.prefetch = (
  client: QueryClient,
  queryParams: GetMoiAgents_dashboardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AgentsDashboard>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgents_dashboard.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get monthly sales graph
 */
/**
* @package
*/
export const useGetMoiAgents_dashboardSales_graph = (
  queryParams: GetMoiAgents_dashboardSales_graphQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<DashboardDailySales[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgents_dashboardSales_graph.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgents_dashboardSales_graph.info = (
  queryParams: GetMoiAgents_dashboardSales_graphQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAgents_dashboardSales_graph.key, queryParams] as QueryKey,
    fun: () =>
      getMoiAgents_dashboardSales_graph(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAgents_dashboardSales_graph.prefetch = (
  client: QueryClient,
  queryParams: GetMoiAgents_dashboardSales_graphQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<DashboardDailySales[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgents_dashboardSales_graph.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get top_influencers and top_campaigns engagement and sales for agents dashboard
 */
/**
* @package
*/
export const useGetMoiAgents_dashboardTop_influencers_campagins = (
  queryParams: GetMoiAgents_dashboardTop_influencers_campaginsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AgentsDashboardTopCampaignsInfluencers>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgents_dashboardTop_influencers_campagins.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAgents_dashboardTop_influencers_campagins.info = (
  queryParams: GetMoiAgents_dashboardTop_influencers_campaginsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAgents_dashboardTop_influencers_campagins.key,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiAgents_dashboardTop_influencers_campagins(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAgents_dashboardTop_influencers_campagins.prefetch = (
  client: QueryClient,
  queryParams: GetMoiAgents_dashboardTop_influencers_campaginsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AgentsDashboardTopCampaignsInfluencers>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAgents_dashboardTop_influencers_campagins.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all targets
 */
/**
* @package
*/
export const useGetMoiAuctionAuction_idTargets = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionAuction_idTargetsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<Target[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionAuction_idTargets.info(
    auction_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionAuction_idTargets.info = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionAuction_idTargetsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionAuction_idTargets.key,
      auction_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionAuction_idTargets(
        auction_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionAuction_idTargets.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionAuction_idTargetsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<Target[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionAuction_idTargets.info(
    auction_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all months with targets
 */
/**
* @package
*/
export const useGetMoiAuctionAuction_idTargetsMonths = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionAuction_idTargetsMonthsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<string[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionAuction_idTargetsMonths.info(
    auction_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionAuction_idTargetsMonths.info = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionAuction_idTargetsMonthsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionAuction_idTargetsMonths.key,
      auction_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionAuction_idTargetsMonths(
        auction_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionAuction_idTargetsMonths.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionAuction_idTargetsMonthsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<string[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionAuction_idTargetsMonths.info(
    auction_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all user manage boards summaries
 */
/**
* @package
*/
export const useGetMoiAuctionManage = (
  options?: SwaggerTypescriptUseQueryOptions<CampaignSummary[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManage.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionManage.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiAuctionManage.key] as QueryKey,
    fun: () => getMoiAuctionManage(configOverride),
  };
};
useGetMoiAuctionManage.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<CampaignSummary[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManage.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all board details
 */
/**
* @package
*/
export const useGetMoiAuctionManageAuction_id = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionManageAuction_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ManageAuctionData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_id.info(
    auction_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionManageAuction_id.info = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionManageAuction_idQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionManageAuction_id.key,
      auction_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionManageAuction_id(
        auction_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionManageAuction_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionManageAuction_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ManageAuctionData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_id.info(
    auction_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Initiate a new file upload process
 */
/**
* @package
*/
export const useGetMoiAuctionManageAuction_idFilesInfluencer_id = (
  /**
   *
   * ID of the influencer to whom the file is to be attached
   */
  influencer_id: number,
  /**
   *
   * Campaign ID to which the file is associated with the user
   */
  auction_id: number,
  queryParams: GetMoiAuctionManageAuction_idFilesInfluencer_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CampaignFileIdentifier>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idFilesInfluencer_id.info(
    influencer_id,
    auction_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionManageAuction_idFilesInfluencer_id.info = (
  /**
   *
   * ID of the influencer to whom the file is to be attached
   */
  influencer_id: number,
  /**
   *
   * Campaign ID to which the file is associated with the user
   */
  auction_id: number,
  queryParams: GetMoiAuctionManageAuction_idFilesInfluencer_idQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionManageAuction_idFilesInfluencer_id.key,
      influencer_id,
      auction_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionManageAuction_idFilesInfluencer_id(
        influencer_id,
        auction_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionManageAuction_idFilesInfluencer_id.prefetch = (
  client: QueryClient,

  /**
   *
   * ID of the influencer to whom the file is to be attached
   */
  influencer_id: number,
  /**
   *
   * Campaign ID to which the file is associated with the user
   */
  auction_id: number,
  queryParams: GetMoiAuctionManageAuction_idFilesInfluencer_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CampaignFileIdentifier>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idFilesInfluencer_id.info(
    influencer_id,
    auction_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * show the file by ID
 */
/**
* @package
*/
export const useGetMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid = (
  /**
   *
   * ID of the influencer to whom the file is to be attached
   */
  influencer_id: number,
  /**
   *
   * Campaign ID to which the file is associated with the user
   */
  auction_id: number,
  /**
   *
   * UUID of the upload request (received in the GET request response)
   */
  file_uuid: string,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid.info(
      influencer_id,
      auction_id,
      file_uuid,

      configOverride,
    );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid.info = (
  /**
   *
   * ID of the influencer to whom the file is to be attached
   */
  influencer_id: number,
  /**
   *
   * Campaign ID to which the file is associated with the user
   */
  auction_id: number,
  /**
   *
   * UUID of the upload request (received in the GET request response)
   */
  file_uuid: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid.key,
      influencer_id,
      auction_id,
      file_uuid,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid(
        influencer_id,
        auction_id,
        file_uuid,

        configOverride,
      ),
  };
};
useGetMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid.prefetch = (
  client: QueryClient,

  /**
   *
   * ID of the influencer to whom the file is to be attached
   */
  influencer_id: number,
  /**
   *
   * Campaign ID to which the file is associated with the user
   */
  auction_id: number,
  /**
   *
   * UUID of the upload request (received in the GET request response)
   */
  file_uuid: string,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid.info(
      influencer_id,
      auction_id,
      file_uuid,

      configOverride,
    );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all payments related to the board for influencer
 */
/**
* @package
*/
export const useGetMoiAuctionManageAuction_idInfluencer_payments = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionManageAuction_idInfluencer_paymentsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AuctionPaymentsData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idInfluencer_payments.info(
    auction_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionManageAuction_idInfluencer_payments.info = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionManageAuction_idInfluencer_paymentsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionManageAuction_idInfluencer_payments.key,
      auction_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionManageAuction_idInfluencer_payments(
        auction_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionManageAuction_idInfluencer_payments.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionManageAuction_idInfluencer_paymentsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AuctionPaymentsData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idInfluencer_payments.info(
    auction_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get campaign influencers
 */
/**
* @package
*/
export const useGetMoiAuctionManageAuction_idInfluencers = (
  /**
   *
   * The id of the campaign
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<number[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idInfluencers.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionManageAuction_idInfluencers.info = (
  /**
   *
   * The id of the campaign
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionManageAuction_idInfluencers.key, auction_id] as QueryKey,
    fun: () =>
      getMoiAuctionManageAuction_idInfluencers(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionManageAuction_idInfluencers.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the campaign
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<number[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idInfluencers.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all board details
 */
/**
* @package
*/
export const useGetMoiAuctionManageAuction_idNext_month = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ManageAuctionState>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idNext_month.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionManageAuction_idNext_month.info = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionManageAuction_idNext_month.key, auction_id] as QueryKey,
    fun: () =>
      getMoiAuctionManageAuction_idNext_month(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionManageAuction_idNext_month.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ManageAuctionState>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idNext_month.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all payments related to the board
 */
/**
* @package
*/
export const useGetMoiAuctionManageAuction_idPayments = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<AuctionPaymentsData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idPayments.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionManageAuction_idPayments.info = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionManageAuction_idPayments.key, auction_id] as QueryKey,
    fun: () =>
      getMoiAuctionManageAuction_idPayments(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionManageAuction_idPayments.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<AuctionPaymentsData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idPayments.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get payments tasks data
 */
/**
* @package
*/
export const useGetMoiAuctionManageAuction_idPaymentsPayment_idTasks_details = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the cycle payment
   */
  payment_id: string,
  options?: SwaggerTypescriptUseQueryOptions<TaskManageData[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiAuctionManageAuction_idPaymentsPayment_idTasks_details.info(
      auction_id,
      payment_id,

      configOverride,
    );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionManageAuction_idPaymentsPayment_idTasks_details.info = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the cycle payment
   */
  payment_id: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionManageAuction_idPaymentsPayment_idTasks_details.key,
      auction_id,
      payment_id,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionManageAuction_idPaymentsPayment_idTasks_details(
        auction_id,
        payment_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionManageAuction_idPaymentsPayment_idTasks_details.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the cycle payment
   */
  payment_id: string,
  options?: SwaggerTypescriptUseQueryOptions<TaskManageData[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiAuctionManageAuction_idPaymentsPayment_idTasks_details.info(
      auction_id,
      payment_id,

      configOverride,
    );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get board short links
 */
/**
* @package
*/
export const useGetMoiAuctionManageAuction_idShort_link = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    links?: {
      created_by_influencer?: boolean;
      description?: string;
      extra_links?: {
        created_by_influencer?: boolean;
        description?: string;
        /**
         *
         * - Format: int64
         */
        extra_id?: number;
        long_link?: string;
      }[];
      /**
       *
       * - Format: int64
       */
      influencer_id?: number;
      long_link?: string;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idShort_link.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionManageAuction_idShort_link.info = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionManageAuction_idShort_link.key, auction_id] as QueryKey,
    fun: () =>
      getMoiAuctionManageAuction_idShort_link(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionManageAuction_idShort_link.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    links?: {
      created_by_influencer?: boolean;
      description?: string;
      extra_links?: {
        created_by_influencer?: boolean;
        description?: string;
        /**
         *
         * - Format: int64
         */
        extra_id?: number;
        long_link?: string;
      }[];
      /**
       *
       * - Format: int64
       */
      influencer_id?: number;
      long_link?: string;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionManageAuction_idShort_link.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get the most viewed products of an auction by auction id
 */
/**
* @package
*/
export const useGetMoiAuctionTop_productsAuction_id = (
  /**
   *
   * The auction id to retrieve top products for
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ProductDetails[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionTop_productsAuction_id.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionTop_productsAuction_id.info = (
  /**
   *
   * The auction id to retrieve top products for
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionTop_productsAuction_id.key, auction_id] as QueryKey,
    fun: () =>
      getMoiAuctionTop_productsAuction_id(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionTop_productsAuction_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The auction id to retrieve top products for
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ProductDetails[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionTop_productsAuction_id.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get Auction copuns only
 */
/**
* @package
*/
export const useGetMoiAuctionsAuction_idCoupons = (
  /**
   *
   * the auction id to get sales coupons
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<CouponInSales[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idCoupons.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsAuction_idCoupons.info = (
  /**
   *
   * the auction id to get sales coupons
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionsAuction_idCoupons.key, auction_id] as QueryKey,
    fun: () =>
      getMoiAuctionsAuction_idCoupons(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionsAuction_idCoupons.prefetch = (
  client: QueryClient,

  /**
   *
   * the auction id to get sales coupons
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<CouponInSales[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idCoupons.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get auction by id for influencer
 */
/**
* @package
*/
export const useGetMoiAuctionsAuction_idInfluencer = (
  /**
   *
   * the auction id to get
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<AuctionDataForInfluencerExtended>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idInfluencer.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsAuction_idInfluencer.info = (
  /**
   *
   * the auction id to get
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionsAuction_idInfluencer.key, auction_id] as QueryKey,
    fun: () =>
      getMoiAuctionsAuction_idInfluencer(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionsAuction_idInfluencer.prefetch = (
  client: QueryClient,

  /**
   *
   * the auction id to get
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<AuctionDataForInfluencerExtended>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idInfluencer.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get all links for influencer

 */
/**
* @package
*/
export const useGetMoiAuctionsAuction_idInfluencerLinks = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<AuctionDataForInfluencerLink[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idInfluencerLinks.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsAuction_idInfluencerLinks.info = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionsAuction_idInfluencerLinks.key, auction_id] as QueryKey,
    fun: () =>
      getMoiAuctionsAuction_idInfluencerLinks(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionsAuction_idInfluencerLinks.prefetch = (
  client: QueryClient,

  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<AuctionDataForInfluencerLink[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idInfluencerLinks.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get all products of auctions for influencer

 */
/**
* @package
*/
export const useGetMoiAuctionsAuction_idInfluencerProducts = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    products?: EditAuctionProduct[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idInfluencerProducts.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsAuction_idInfluencerProducts.info = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionsAuction_idInfluencerProducts.key,
      auction_id,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionsAuction_idInfluencerProducts(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionsAuction_idInfluencerProducts.prefetch = (
  client: QueryClient,

  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    products?: EditAuctionProduct[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idInfluencerProducts.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get all auctions for influencer

 */
/**
* @package
*/
export const useGetMoiAuctionsAuction_idInfluencer_pixel_analytics = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  queryParams?: GetMoiAuctionsAuction_idInfluencer_pixel_analyticsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    clicks?: AffiliateTimeline;
    comission?: number;
    /**
     *
     * - Format: double
     */
    commission_value?: number;
    /**
     *
     * - Format: int64
     */
    coupons?: number;
    currency?: Currency;
    earnings?: AffiliateTimeline;
    /**
     *
     * - Format: date-time
     */
    last_updated?: string;
    purchases?: AffiliateTimeline;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiAuctionsAuction_idInfluencer_pixel_analytics.info(
      auction_id,

      queryParams,
      configOverride,
    );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsAuction_idInfluencer_pixel_analytics.info = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  queryParams?: GetMoiAuctionsAuction_idInfluencer_pixel_analyticsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionsAuction_idInfluencer_pixel_analytics.key,
      auction_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionsAuction_idInfluencer_pixel_analytics(
        auction_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionsAuction_idInfluencer_pixel_analytics.prefetch = (
  client: QueryClient,

  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  queryParams?: GetMoiAuctionsAuction_idInfluencer_pixel_analyticsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    clicks?: AffiliateTimeline;
    comission?: number;
    /**
     *
     * - Format: double
     */
    commission_value?: number;
    /**
     *
     * - Format: int64
     */
    coupons?: number;
    currency?: Currency;
    earnings?: AffiliateTimeline;
    /**
     *
     * - Format: date-time
     */
    last_updated?: string;
    purchases?: AffiliateTimeline;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiAuctionsAuction_idInfluencer_pixel_analytics.info(
      auction_id,

      queryParams,
      configOverride,
    );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get affiliation breakdown by phases

 */
/**
* @package
*/
export const useGetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  queryParams?: GetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdownQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      brands_included?: string[];
      color?: string;
      /**
       *
       * - Format: double
       */
      commission?: number;
      /**
       *
       * - Format: double
       */
      commission_value?: number;
      /**
       *
       * - Format: date-time
       */
      end_time?: string;
      is_base?: boolean;
      /**
       *
       * - Format: date-time
       */
      last_updated?: string;
      /**
       *
       * - Format: double
       */
      net_sales?: number;
      /**
       *
       * - Format: date-time
       */
      start_time?: string;
      title?: string;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown.info(
      auction_id,

      queryParams,
      configOverride,
    );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown.info = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  queryParams?: GetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdownQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown.key,
      auction_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown(
        auction_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown.prefetch = (
  client: QueryClient,

  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  queryParams?: GetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdownQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      brands_included?: string[];
      color?: string;
      /**
       *
       * - Format: double
       */
      commission?: number;
      /**
       *
       * - Format: double
       */
      commission_value?: number;
      /**
       *
       * - Format: date-time
       */
      end_time?: string;
      is_base?: boolean;
      /**
       *
       * - Format: date-time
       */
      last_updated?: string;
      /**
       *
       * - Format: double
       */
      net_sales?: number;
      /**
       *
       * - Format: date-time
       */
      start_time?: string;
      title?: string;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown.info(
      auction_id,

      queryParams,
      configOverride,
    );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get roi for last month
 */
/**
* @package
*/
export const useGetMoiAuctionsAuction_idLast_month_for_roi = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: date
     */
    last_month?: string;
    /**
     *
     * - Format: date
     */
    last_month_with_purchases?: string;
    one_month_campaign?: boolean;
    pixel_enabled?: boolean;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idLast_month_for_roi.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsAuction_idLast_month_for_roi.info = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiAuctionsAuction_idLast_month_for_roi.key,
      auction_id,
    ] as QueryKey,
    fun: () =>
      getMoiAuctionsAuction_idLast_month_for_roi(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionsAuction_idLast_month_for_roi.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: date
     */
    last_month?: string;
    /**
     *
     * - Format: date
     */
    last_month_with_purchases?: string;
    one_month_campaign?: boolean;
    pixel_enabled?: boolean;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idLast_month_for_roi.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get Auction pixel phases
 */
/**
* @package
*/
export const useGetMoiAuctionsAuction_idPixel_phases = (
  /**
   *
   * the auction id to get the phases for
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    influencers: {
      full_name?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image?: string;
    }[];
    phases: AuctionPhase[];
    app_id?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idPixel_phases.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsAuction_idPixel_phases.info = (
  /**
   *
   * the auction id to get the phases for
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionsAuction_idPixel_phases.key, auction_id] as QueryKey,
    fun: () =>
      getMoiAuctionsAuction_idPixel_phases(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiAuctionsAuction_idPixel_phases.prefetch = (
  client: QueryClient,

  /**
   *
   * the auction id to get the phases for
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    influencers: {
      full_name?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image?: string;
    }[];
    phases: AuctionPhase[];
    app_id?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsAuction_idPixel_phases.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get specific auction by id
 */
/**
* @package
*/
export const useGetMoiAuctionsId = (
  /**
   *
   * The id of the auction to get
   */
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<ExtendedAuctionData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsId.info(
    id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsId.info = (
  /**
   *
   * The id of the auction to get
   */
  id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionsId.key, id] as QueryKey,
    fun: () =>
      getMoiAuctionsId(
        id,

        configOverride,
      ),
  };
};
useGetMoiAuctionsId.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction to get
   */
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<ExtendedAuctionData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsId.info(
    id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Returns bids and market segmentation
 */
/**
* @package
*/
export const useGetMoiAuctionsIdBids = (
  /**
   *
   * The auction id to return bids to
   */
  id: number,
  queryParams?: GetMoiAuctionsIdBidsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      /**
       *
       * - Format: int64
       */
      auction_id?: number;
      /**
       *
       * BidderData
       *
       */
      bid_details?: {
        /**
         *
         * - Format: double
         */
        bid?: number;
        /**
         *
         * - Format: double
         */
        bid_commission?: number;
        /**
         *
         * - Format: int64
         */
        bid_id?: number;
        /**
         *
         * - Format: double
         */
        bid_taxes?: number;
        bidder_about_me?: string;
        bidder_address?: string;
        /**
         *
         * - Format: int64
         */
        bidder_age?: number;
        /**
         *
         * - Format: int64
         */
        bidder_id?: number;
        bidder_name?: string;
        bidder_username?: string;
        /**
         *
         * - Format: double
         */
        discounted_bid?: number;
        /**
         *
         * - Format: int64
         */
        ongoing_active_campaign_id?: number;
        picture_url?: string;
        reviews_statistics?: ReviewsStatistics;
        vip_user?: boolean;
      };
      influencer_activity_data?: InfluencerActivityData;
      /**
       *
       * InfluencerSegmentation
       *
       */
      influencer_segmentation?: {
        /**
         *
         * AgeByGenderSegmentationData
         *
         */
        age_by_gender_segmentation?: {
          females?: AgeSegmentationData;
          males?: AgeSegmentationData;
        };
        age_segmentation?: AgeSegmentationData;
        /**
         *
         * - Format: double
         */
        average_likes?: number;
        /**
         *
         * - Format: int64
         */
        followers?: number;
        /**
         *
         * GenderSegmentationData
         *
         */
        gender_segmentation?: {
          /**
           *
           * - Format: double
           */
          female?: number;
          /**
           *
           * - Format: double
           */
          male?: number;
        };
        /**
         *
         * - Format: int32
         */
        moi_grade?: number;
        states_data?: string;
      };
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsIdBids.info(
    id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsIdBids.info = (
  /**
   *
   * The auction id to return bids to
   */
  id: number,
  queryParams?: GetMoiAuctionsIdBidsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionsIdBids.key, id, queryParams] as QueryKey,
    fun: () =>
      getMoiAuctionsIdBids(
        id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionsIdBids.prefetch = (
  client: QueryClient,

  /**
   *
   * The auction id to return bids to
   */
  id: number,
  queryParams?: GetMoiAuctionsIdBidsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      /**
       *
       * - Format: int64
       */
      auction_id?: number;
      /**
       *
       * BidderData
       *
       */
      bid_details?: {
        /**
         *
         * - Format: double
         */
        bid?: number;
        /**
         *
         * - Format: double
         */
        bid_commission?: number;
        /**
         *
         * - Format: int64
         */
        bid_id?: number;
        /**
         *
         * - Format: double
         */
        bid_taxes?: number;
        bidder_about_me?: string;
        bidder_address?: string;
        /**
         *
         * - Format: int64
         */
        bidder_age?: number;
        /**
         *
         * - Format: int64
         */
        bidder_id?: number;
        bidder_name?: string;
        bidder_username?: string;
        /**
         *
         * - Format: double
         */
        discounted_bid?: number;
        /**
         *
         * - Format: int64
         */
        ongoing_active_campaign_id?: number;
        picture_url?: string;
        reviews_statistics?: ReviewsStatistics;
        vip_user?: boolean;
      };
      influencer_activity_data?: InfluencerActivityData;
      /**
       *
       * InfluencerSegmentation
       *
       */
      influencer_segmentation?: {
        /**
         *
         * AgeByGenderSegmentationData
         *
         */
        age_by_gender_segmentation?: {
          females?: AgeSegmentationData;
          males?: AgeSegmentationData;
        };
        age_segmentation?: AgeSegmentationData;
        /**
         *
         * - Format: double
         */
        average_likes?: number;
        /**
         *
         * - Format: int64
         */
        followers?: number;
        /**
         *
         * GenderSegmentationData
         *
         */
        gender_segmentation?: {
          /**
           *
           * - Format: double
           */
          female?: number;
          /**
           *
           * - Format: double
           */
          male?: number;
        };
        /**
         *
         * - Format: int32
         */
        moi_grade?: number;
        states_data?: string;
      };
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsIdBids.info(
    id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Returns ROI data for specific auction
 */
/**
* @package
*/
export const useGetMoiAuctionsIdRoi = (
  /**
   *
   * The auction id to return ROI to
   */
  id: number,
  queryParams?: GetMoiAuctionsIdRoiQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ManagedCampaignROI>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsIdRoi.info(
    id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsIdRoi.info = (
  /**
   *
   * The auction id to return ROI to
   */
  id: number,
  queryParams?: GetMoiAuctionsIdRoiQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionsIdRoi.key, id, queryParams] as QueryKey,
    fun: () =>
      getMoiAuctionsIdRoi(
        id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionsIdRoi.prefetch = (
  client: QueryClient,

  /**
   *
   * The auction id to return ROI to
   */
  id: number,
  queryParams?: GetMoiAuctionsIdRoiQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ManagedCampaignROI>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsIdRoi.info(
    id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get all auctions for influencer

 */
/**
* @package
*/
export const useGetMoiAuctionsInfluencer = (
  queryParams: GetMoiAuctionsInfluencerQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * AuctionsArray
     *
     */
    auctions?: AuctionDataForInfluencer[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsInfluencer.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsInfluencer.info = (
  queryParams: GetMoiAuctionsInfluencerQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionsInfluencer.key, queryParams] as QueryKey,
    fun: () =>
      getMoiAuctionsInfluencer(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionsInfluencer.prefetch = (
  client: QueryClient,
  queryParams: GetMoiAuctionsInfluencerQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * AuctionsArray
     *
     */
    auctions?: AuctionDataForInfluencer[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsInfluencer.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get all auctions for influencer

 */
/**
* @package
*/
export const useGetMoiAuctionsLiteInfluencer = (
  queryParams: GetMoiAuctionsLiteInfluencerQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * AuctionsArray
     *
     */
    auctions?: MinimalAuctionDataForInfluencer[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsLiteInfluencer.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsLiteInfluencer.info = (
  queryParams: GetMoiAuctionsLiteInfluencerQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionsLiteInfluencer.key, queryParams] as QueryKey,
    fun: () =>
      getMoiAuctionsLiteInfluencer(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionsLiteInfluencer.prefetch = (
  client: QueryClient,
  queryParams: GetMoiAuctionsLiteInfluencerQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * AuctionsArray
     *
     */
    auctions?: MinimalAuctionDataForInfluencer[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsLiteInfluencer.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * List ROI Slides
 */
/**
* @package
*/
export const useGetMoiAuctionsRoiSlides = (
  queryParams?: GetMoiAuctionsRoiSlidesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ROISlidesReportStatus>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsRoiSlides.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctionsRoiSlides.info = (
  queryParams?: GetMoiAuctionsRoiSlidesQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctionsRoiSlides.key, queryParams] as QueryKey,
    fun: () =>
      getMoiAuctionsRoiSlides(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiAuctionsRoiSlides.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiAuctionsRoiSlidesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ROISlidesReportStatus>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctionsRoiSlides.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Returns suggested price buckets for asked filter
 */
/**
* @package
*/
export const useGetMoiAuctions_price_suggest = (
  options?: SwaggerTypescriptUseQueryOptions<{
    buckets?: {
      /**
       *
       * - Format: int64
       */
      max?: number;
      /**
       *
       * - Format: int64
       */
      min?: number;
      /**
       *
       * - Format: double
       */
      price?: number;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctions_price_suggest.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiAuctions_price_suggest.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiAuctions_price_suggest.key] as QueryKey,
    fun: () => getMoiAuctions_price_suggest(configOverride),
  };
};
useGetMoiAuctions_price_suggest.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    buckets?: {
      /**
       *
       * - Format: int64
       */
      max?: number;
      /**
       *
       * - Format: int64
       */
      min?: number;
      /**
       *
       * - Format: double
       */
      price?: number;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiAuctions_price_suggest.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get all my campaigns

 */
/**
* @package
*/
export const useGetMoiCampaigns = (
  queryParams?: GetMoiCampaignsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      all_sub_users_allowed?: boolean;
      /**
       *
       * - Format: int64
       */
      campaign_list_id?: number;
      campaign_state?: "brief" | "manage" | "report";
      campaign_type?: AuctionTypes;
      categories?: string[];
      /**
       *
       * - Format: int64
       */
      content_amount?: number;
      /**
       *
       * - Format: date-time
       */
      creation_time?: string;
      /**
       *
       * - Format: date-time
       */
      end_time?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image?: string;
      /**
       *
       * - Format: int64
       */
      influencers_amount?: number;
      is_pixel_enabled?: boolean;
      name?: string;
      one_month_campaign?: boolean;
      /**
       *
       * - Format: int64
       */
      original_creator_id?: number;
      owner?: Owner;
      shared_campaign?: boolean;
      sub_users_allowed?: number[];
      waiting_approval?: boolean;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCampaigns.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiCampaigns.info = (
  queryParams?: GetMoiCampaignsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiCampaigns.key, queryParams] as QueryKey,
    fun: () =>
      getMoiCampaigns(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiCampaigns.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiCampaignsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      all_sub_users_allowed?: boolean;
      /**
       *
       * - Format: int64
       */
      campaign_list_id?: number;
      campaign_state?: "brief" | "manage" | "report";
      campaign_type?: AuctionTypes;
      categories?: string[];
      /**
       *
       * - Format: int64
       */
      content_amount?: number;
      /**
       *
       * - Format: date-time
       */
      creation_time?: string;
      /**
       *
       * - Format: date-time
       */
      end_time?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image?: string;
      /**
       *
       * - Format: int64
       */
      influencers_amount?: number;
      is_pixel_enabled?: boolean;
      name?: string;
      one_month_campaign?: boolean;
      /**
       *
       * - Format: int64
       */
      original_creator_id?: number;
      owner?: Owner;
      shared_campaign?: boolean;
      sub_users_allowed?: number[];
      waiting_approval?: boolean;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCampaigns.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get community feed for influencer
 */
/**
* @package
*/
export const useGetMoiCommunity_feed = (
  queryParams: GetMoiCommunity_feedQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CommunityFeedItem[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feed.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiCommunity_feed.info = (
  queryParams: GetMoiCommunity_feedQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiCommunity_feed.key, queryParams] as QueryKey,
    fun: () =>
      getMoiCommunity_feed(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiCommunity_feed.prefetch = (
  client: QueryClient,
  queryParams: GetMoiCommunity_feedQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CommunityFeedItem[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feed.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get community feed for influencer
 */
/**
* @package
*/
export const useGetMoiCommunity_feedApproval = (
  queryParams?: GetMoiCommunity_feedApprovalQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CommunityFeedItem[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feedApproval.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiCommunity_feedApproval.info = (
  queryParams?: GetMoiCommunity_feedApprovalQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiCommunity_feedApproval.key, queryParams] as QueryKey,
    fun: () =>
      getMoiCommunity_feedApproval(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiCommunity_feedApproval.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiCommunity_feedApprovalQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CommunityFeedItem[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feedApproval.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get community feed item for influencer
 */
/**
* @package
*/
export const useGetMoiCommunity_feedPost_id = (
  /**
   *
   * post id
   */
  post_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    comments?: CommunityFeedComment[];
    post?: CommunityFeedItem;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feedPost_id.info(
    post_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiCommunity_feedPost_id.info = (
  /**
   *
   * post id
   */
  post_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiCommunity_feedPost_id.key, post_id] as QueryKey,
    fun: () =>
      getMoiCommunity_feedPost_id(
        post_id,

        configOverride,
      ),
  };
};
useGetMoiCommunity_feedPost_id.prefetch = (
  client: QueryClient,

  /**
   *
   * post id
   */
  post_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    comments?: CommunityFeedComment[];
    post?: CommunityFeedItem;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feedPost_id.info(
    post_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get community feed comments
 */
/**
* @package
*/
export const useGetMoiCommunity_feedPost_idComments = (
  /**
   *
   * post id
   */
  post_id: number,
  queryParams?: GetMoiCommunity_feedPost_idCommentsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CommunityFeedComment[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feedPost_idComments.info(
    post_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiCommunity_feedPost_idComments.info = (
  /**
   *
   * post id
   */
  post_id: number,
  queryParams?: GetMoiCommunity_feedPost_idCommentsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiCommunity_feedPost_idComments.key,
      post_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiCommunity_feedPost_idComments(
        post_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiCommunity_feedPost_idComments.prefetch = (
  client: QueryClient,

  /**
   *
   * post id
   */
  post_id: number,
  queryParams?: GetMoiCommunity_feedPost_idCommentsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CommunityFeedComment[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feedPost_idComments.info(
    post_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get community feed likes of post
 */
/**
* @package
*/
export const useGetMoiCommunity_feedPost_idLike = (
  /**
   *
   * post id
   */
  post_id: number,
  queryParams?: GetMoiCommunity_feedPost_idLikeQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<UserOnFeedAndActionTime[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feedPost_idLike.info(
    post_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiCommunity_feedPost_idLike.info = (
  /**
   *
   * post id
   */
  post_id: number,
  queryParams?: GetMoiCommunity_feedPost_idLikeQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiCommunity_feedPost_idLike.key,
      post_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiCommunity_feedPost_idLike(
        post_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiCommunity_feedPost_idLike.prefetch = (
  client: QueryClient,

  /**
   *
   * post id
   */
  post_id: number,
  queryParams?: GetMoiCommunity_feedPost_idLikeQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<UserOnFeedAndActionTime[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feedPost_idLike.info(
    post_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Query for community suggestions
 */
/**
* @package
*/
export const useGetMoiCommunity_feedSuggestionAll = (
  queryParams?: GetMoiCommunity_feedSuggestionAllQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CommunitySuggestion[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feedSuggestionAll.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiCommunity_feedSuggestionAll.info = (
  queryParams?: GetMoiCommunity_feedSuggestionAllQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiCommunity_feedSuggestionAll.key, queryParams] as QueryKey,
    fun: () =>
      getMoiCommunity_feedSuggestionAll(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiCommunity_feedSuggestionAll.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiCommunity_feedSuggestionAllQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CommunitySuggestion[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCommunity_feedSuggestionAll.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list applied coupons for user
 */
/**
* @package
*/
export const useGetMoiCoupons = (
  options?: SwaggerTypescriptUseQueryOptions<
    {
      /**
       *
       * - Format: int32
       */
      amount_of_usages_left?: number;
      /**
       *
       * - Format: double
       */
      campaign_discount?: number;
      /**
       *
       * - pattern: ^[a-zA-Z0-9]{3,12}$
       */
      coupon?: string;
      /**
       *
       * - Format: date-time
       */
      expiration_time?: string;
      /**
       *
       * - Format: int32
       */
      max_amount_of_usages?: number;
      /**
       *
       * - Format: date-time
       */
      usage_time?: string;
      /**
       *
       * - Format: int64
       */
      used_in_auction_id?: number;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCoupons.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiCoupons.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiCoupons.key] as QueryKey,
    fun: () => getMoiCoupons(configOverride),
  };
};
useGetMoiCoupons.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      /**
       *
       * - Format: int32
       */
      amount_of_usages_left?: number;
      /**
       *
       * - Format: double
       */
      campaign_discount?: number;
      /**
       *
       * - pattern: ^[a-zA-Z0-9]{3,12}$
       */
      coupon?: string;
      /**
       *
       * - Format: date-time
       */
      expiration_time?: string;
      /**
       *
       * - Format: int32
       */
      max_amount_of_usages?: number;
      /**
       *
       * - Format: date-time
       */
      usage_time?: string;
      /**
       *
       * - Format: int64
       */
      used_in_auction_id?: number;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiCoupons.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get advertiser details
 */
/**
* @package
*/
export const useGetMoiDetailsAdvertiser = (
  options?: SwaggerTypescriptUseQueryOptions<AllAdvertiserDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsAdvertiser.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiDetailsAdvertiser.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiDetailsAdvertiser.key] as QueryKey,
    fun: () => getMoiDetailsAdvertiser(configOverride),
  };
};
useGetMoiDetailsAdvertiser.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<AllAdvertiserDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsAdvertiser.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get brand list by user ID
 */
/**
* @package
*/
export const useGetMoiDetailsBrand = (
  options?: SwaggerTypescriptUseQueryOptions<EditBrandInfo[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsBrand.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiDetailsBrand.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiDetailsBrand.key] as QueryKey,
    fun: () => getMoiDetailsBrand(configOverride),
  };
};
useGetMoiDetailsBrand.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<EditBrandInfo[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsBrand.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get advertiser details
 */
/**
* @package
*/
export const useGetMoiDetailsCurrent_user = (
  options?: SwaggerTypescriptUseQueryOptions<AllAdvertiserDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsCurrent_user.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiDetailsCurrent_user.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiDetailsCurrent_user.key] as QueryKey,
    fun: () => getMoiDetailsCurrent_user(configOverride),
  };
};
useGetMoiDetailsCurrent_user.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<AllAdvertiserDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsCurrent_user.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get influencer details
 */
/**
* @package
*/
export const useGetMoiDetailsInfluencer = (
  options?: SwaggerTypescriptUseQueryOptions<InfluencerDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsInfluencer.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiDetailsInfluencer.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiDetailsInfluencer.key] as QueryKey,
    fun: () => getMoiDetailsInfluencer(configOverride),
  };
};
useGetMoiDetailsInfluencer.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsInfluencer.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get influencer statistics
 */
/**
* @package
*/
export const useGetMoiDetailsInfluencerDashboard = (
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
    /**
     *
     * InfluencerDashboardSocial
     *
     */
    social_data?: {
      instagram?: InfluencerDashboardSocialData;
      tiktok?: InfluencerDashboardSocialData;
      twitter?: InfluencerDashboardSocialData;
      youtube?: InfluencerDashboardSocialData;
    };
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsInfluencerDashboard.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiDetailsInfluencerDashboard.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiDetailsInfluencerDashboard.key] as QueryKey,
    fun: () => getMoiDetailsInfluencerDashboard(configOverride),
  };
};
useGetMoiDetailsInfluencerDashboard.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
    /**
     *
     * InfluencerDashboardSocial
     *
     */
    social_data?: {
      instagram?: InfluencerDashboardSocialData;
      tiktok?: InfluencerDashboardSocialData;
      twitter?: InfluencerDashboardSocialData;
      youtube?: InfluencerDashboardSocialData;
    };
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsInfluencerDashboard.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get influencer rate card
 */
/**
* @package
*/
export const useGetMoiDetailsInfluencerRate_card = (
  options?: SwaggerTypescriptUseQueryOptions<InfluencerRateCard>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsInfluencerRate_card.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiDetailsInfluencerRate_card.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiDetailsInfluencerRate_card.key] as QueryKey,
    fun: () => getMoiDetailsInfluencerRate_card(configOverride),
  };
};
useGetMoiDetailsInfluencerRate_card.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerRateCard>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiDetailsInfluencerRate_card.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get current billing address
 */
/**
* @package
*/
export const useGetMoiEdit_detailsAdvertiserBilling = (
  options?: SwaggerTypescriptUseQueryOptions<BillingAddress>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiEdit_detailsAdvertiserBilling.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiEdit_detailsAdvertiserBilling.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiEdit_detailsAdvertiserBilling.key] as QueryKey,
    fun: () => getMoiEdit_detailsAdvertiserBilling(configOverride),
  };
};
useGetMoiEdit_detailsAdvertiserBilling.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<BillingAddress>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiEdit_detailsAdvertiserBilling.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get current billing address
 */
/**
* @package
*/
export const useGetMoiEdit_detailsCurrent_userBilling = (
  options?: SwaggerTypescriptUseQueryOptions<BillingAddress>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiEdit_detailsCurrent_userBilling.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiEdit_detailsCurrent_userBilling.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiEdit_detailsCurrent_userBilling.key] as QueryKey,
    fun: () => getMoiEdit_detailsCurrent_userBilling(configOverride),
  };
};
useGetMoiEdit_detailsCurrent_userBilling.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<BillingAddress>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiEdit_detailsCurrent_userBilling.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list all registered bank accounts
 */
/**
* @package
*/
export const useGetMoiEdit_detailsInfluencerBank = (
  options?: SwaggerTypescriptUseQueryOptions<RegisteredBankAccount[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiEdit_detailsInfluencerBank.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiEdit_detailsInfluencerBank.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiEdit_detailsInfluencerBank.key] as QueryKey,
    fun: () => getMoiEdit_detailsInfluencerBank(configOverride),
  };
};
useGetMoiEdit_detailsInfluencerBank.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredBankAccount[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiEdit_detailsInfluencerBank.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get registered bank account details
 */
/**
* @package
*/
export const useGetMoiEdit_detailsInfluencerBankId = (
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredBankAccount>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiEdit_detailsInfluencerBankId.info(
    id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiEdit_detailsInfluencerBankId.info = (
  id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiEdit_detailsInfluencerBankId.key, id] as QueryKey,
    fun: () =>
      getMoiEdit_detailsInfluencerBankId(
        id,

        configOverride,
      ),
  };
};
useGetMoiEdit_detailsInfluencerBankId.prefetch = (
  client: QueryClient,
  id: number,
  options?: SwaggerTypescriptUseQueryOptions<RegisteredBankAccount>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiEdit_detailsInfluencerBankId.info(
    id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Check if allowed to add a US bank
 */
/**
* @package
*/
export const useGetMoiEdit_detailsInfluencerBankUs = (
  queryParams?: GetMoiEdit_detailsInfluencerBankUsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AllowedForUSBankAccount>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiEdit_detailsInfluencerBankUs.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiEdit_detailsInfluencerBankUs.info = (
  queryParams?: GetMoiEdit_detailsInfluencerBankUsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiEdit_detailsInfluencerBankUs.key, queryParams] as QueryKey,
    fun: () =>
      getMoiEdit_detailsInfluencerBankUs(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiEdit_detailsInfluencerBankUs.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiEdit_detailsInfluencerBankUsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AllowedForUSBankAccount>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiEdit_detailsInfluencerBankUs.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * get users

 */
/**
* @package
*/
export const useGetMoiEnterpriseUsers = (
  options?: SwaggerTypescriptUseQueryOptions<
    {
      email?: string;
      image?: string;
      /**
       *
       * - Format: date-time
       */
      invite_created_at?: string;
      /**
       *
       * - Format: date-time
       */
      invite_response_at?: string;
      invite_status?: "accepted" | "rejected" | "removed" | "waiting_response";
      /**
       *
       * - Format: int64
       */
      name?: string;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiEnterpriseUsers.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiEnterpriseUsers.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiEnterpriseUsers.key] as QueryKey,
    fun: () => getMoiEnterpriseUsers(configOverride),
  };
};
useGetMoiEnterpriseUsers.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      email?: string;
      image?: string;
      /**
       *
       * - Format: date-time
       */
      invite_created_at?: string;
      /**
       *
       * - Format: date-time
       */
      invite_response_at?: string;
      invite_status?: "accepted" | "rejected" | "removed" | "waiting_response";
      /**
       *
       * - Format: int64
       */
      name?: string;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiEnterpriseUsers.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * get users logs

 */
/**
* @package
*/
export const useGetMoiEnterpriseUsers_logs = (
  queryParams?: GetMoiEnterpriseUsers_logsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      /**
       *
       * - Format: date-time
       */
      action_created_at?: string;
      action_description?: string;
      /**
       *
       * - Format: int64
       */
      action_name?: string;
      /**
       *
       * - Format: int64
       */
      item_id?: number;
      item_image?: string;
      item_title?: string;
      item_type?: "auction" | "list";
      sub_user_email?: string;
      /**
       *
       * - Format: int64
       */
      sub_user_id?: number;
      sub_user_name?: string;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiEnterpriseUsers_logs.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiEnterpriseUsers_logs.info = (
  queryParams?: GetMoiEnterpriseUsers_logsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiEnterpriseUsers_logs.key, queryParams] as QueryKey,
    fun: () =>
      getMoiEnterpriseUsers_logs(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiEnterpriseUsers_logs.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiEnterpriseUsers_logsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      /**
       *
       * - Format: date-time
       */
      action_created_at?: string;
      action_description?: string;
      /**
       *
       * - Format: int64
       */
      action_name?: string;
      /**
       *
       * - Format: int64
       */
      item_id?: number;
      item_image?: string;
      item_title?: string;
      item_type?: "auction" | "list";
      sub_user_email?: string;
      /**
       *
       * - Format: int64
       */
      sub_user_id?: number;
      sub_user_name?: string;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiEnterpriseUsers_logs.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get influencer external page settings
 */
/**
* @package
*/
export const useGetMoiExternal_page_settings = (
  options?: SwaggerTypescriptUseQueryOptions<ExternalPageSettings>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiExternal_page_settings.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiExternal_page_settings.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiExternal_page_settings.key] as QueryKey,
    fun: () => getMoiExternal_page_settings(configOverride),
  };
};
useGetMoiExternal_page_settings.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ExternalPageSettings>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiExternal_page_settings.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Returns bids and followers segmentation for influencer
 */
/**
* @package
*/
export const useGetMoiFollowers_segmentationHumanz_fk = (
  /**
   *
   * The humanz fk to return followers segmentation to
   */
  humanz_fk: number,
  queryParams?: GetMoiFollowers_segmentationHumanz_fkQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerFollowersSegmentation>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiFollowers_segmentationHumanz_fk.info(
    humanz_fk,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiFollowers_segmentationHumanz_fk.info = (
  /**
   *
   * The humanz fk to return followers segmentation to
   */
  humanz_fk: number,
  queryParams?: GetMoiFollowers_segmentationHumanz_fkQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiFollowers_segmentationHumanz_fk.key,
      humanz_fk,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiFollowers_segmentationHumanz_fk(
        humanz_fk,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiFollowers_segmentationHumanz_fk.prefetch = (
  client: QueryClient,

  /**
   *
   * The humanz fk to return followers segmentation to
   */
  humanz_fk: number,
  queryParams?: GetMoiFollowers_segmentationHumanz_fkQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerFollowersSegmentation>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiFollowers_segmentationHumanz_fk.info(
    humanz_fk,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get the last instagram challenge of a user

 */
/**
* @package
*/
export const useGetMoiGet_last_user_instagram_challenge = (
  options?: SwaggerTypescriptUseQueryOptions<{
    challenge?: string;
    challenge_id?: string;
    checked_async?: boolean;
    /**
     *
     * - Format: int64
     */
    followers?: number;
    instagram_id?: string;
    instagram_username?: string;
    post_id?: string;
    profile_pic_url?: string;
    /**
     *
     * - Format: date-time
     */
    validated_at?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiGet_last_user_instagram_challenge.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiGet_last_user_instagram_challenge.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiGet_last_user_instagram_challenge.key] as QueryKey,
    fun: () => getMoiGet_last_user_instagram_challenge(configOverride),
  };
};
useGetMoiGet_last_user_instagram_challenge.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    challenge?: string;
    challenge_id?: string;
    checked_async?: boolean;
    /**
     *
     * - Format: int64
     */
    followers?: number;
    instagram_id?: string;
    instagram_username?: string;
    post_id?: string;
    profile_pic_url?: string;
    /**
     *
     * - Format: date-time
     */
    validated_at?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiGet_last_user_instagram_challenge.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get data for homepage dashboard
 */
/**
* @package
*/
export const useGetMoiHomepage_dashboard = (
  queryParams: GetMoiHomepage_dashboardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<HomepageDashboard>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiHomepage_dashboard.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiHomepage_dashboard.info = (
  queryParams: GetMoiHomepage_dashboardQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiHomepage_dashboard.key, queryParams] as QueryKey,
    fun: () =>
      getMoiHomepage_dashboard(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiHomepage_dashboard.prefetch = (
  client: QueryClient,
  queryParams: GetMoiHomepage_dashboardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<HomepageDashboard>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiHomepage_dashboard.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get top_influencers by sales or engagement for homepage dashboard
 */
/**
* @package
*/
export const useGetMoiHomepage_dashboardTop_influencers = (
  queryParams: GetMoiHomepage_dashboardTop_influencersQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerCampaignPictureMetric[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiHomepage_dashboardTop_influencers.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiHomepage_dashboardTop_influencers.info = (
  queryParams: GetMoiHomepage_dashboardTop_influencersQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiHomepage_dashboardTop_influencers.key, queryParams] as QueryKey,
    fun: () =>
      getMoiHomepage_dashboardTop_influencers(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiHomepage_dashboardTop_influencers.prefetch = (
  client: QueryClient,
  queryParams: GetMoiHomepage_dashboardTop_influencersQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerCampaignPictureMetric[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiHomepage_dashboardTop_influencers.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get all available users you can chat with - for influencer or agent
 */
/**
* @package
*/
export const useGetMoiInbox = (
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - pattern: ^[1-9][0-9]+$
     */
    agents?: { [x: string]: AgentInChatInfo };
    campaigns_chats?: {
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: date-time
       */
      created_at?: string;
      image?: string;
      title?: string;
      users_chats?: Chats;
    }[];
    users_chats?: Chats;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInbox.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInbox.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiInbox.key] as QueryKey,
    fun: () => getMoiInbox(configOverride),
  };
};
useGetMoiInbox.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - pattern: ^[1-9][0-9]+$
     */
    agents?: { [x: string]: AgentInChatInfo };
    campaigns_chats?: {
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: date-time
       */
      created_at?: string;
      image?: string;
      title?: string;
      users_chats?: Chats;
    }[];
    users_chats?: Chats;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInbox.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get all available chat influencers you can chat with inside campaigns
 */
/**
* @package
*/
export const useGetMoiInboxCampaigns = (
  queryParams?: GetMoiInboxCampaignsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      agent?: AgentInChatInfo;
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: date-time
       */
      created_at?: string;
      image?: string;
      title?: string;
      /**
       *
       * - Format: int32
       */
      unread_count?: number;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInboxCampaigns.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInboxCampaigns.info = (
  queryParams?: GetMoiInboxCampaignsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiInboxCampaigns.key, queryParams] as QueryKey,
    fun: () =>
      getMoiInboxCampaigns(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInboxCampaigns.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiInboxCampaignsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      agent?: AgentInChatInfo;
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: date-time
       */
      created_at?: string;
      image?: string;
      title?: string;
      /**
       *
       * - Format: int32
       */
      unread_count?: number;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInboxCampaigns.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get all available chats with influencers you can chat with
 */
/**
* @package
*/
export const useGetMoiInboxInfluencers = (
  queryParams?: GetMoiInboxInfluencersQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    influencers?: Chats;
    /**
     *
     * - Format: int64
     */
    starred_count?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInboxInfluencers.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInboxInfluencers.info = (
  queryParams?: GetMoiInboxInfluencersQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiInboxInfluencers.key, queryParams] as QueryKey,
    fun: () =>
      getMoiInboxInfluencers(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInboxInfluencers.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiInboxInfluencersQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    influencers?: Chats;
    /**
     *
     * - Format: int64
     */
    starred_count?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInboxInfluencers.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get available chat with influencer
 */
/**
* @package
*/
export const useGetMoiInboxInfluencersInfluencer_id = (
  /**
   *
   * Influencer Id
   */
  influencer_id: number,
  queryParams?: GetMoiInboxInfluencersInfluencer_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    influencers?: Chats;
    /**
     *
     * - Format: int64
     */
    starred_count?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInboxInfluencersInfluencer_id.info(
    influencer_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInboxInfluencersInfluencer_id.info = (
  /**
   *
   * Influencer Id
   */
  influencer_id: number,
  queryParams?: GetMoiInboxInfluencersInfluencer_idQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiInboxInfluencersInfluencer_id.key,
      influencer_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiInboxInfluencersInfluencer_id(
        influencer_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInboxInfluencersInfluencer_id.prefetch = (
  client: QueryClient,

  /**
   *
   * Influencer Id
   */
  influencer_id: number,
  queryParams?: GetMoiInboxInfluencersInfluencer_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    influencers?: Chats;
    /**
     *
     * - Format: int64
     */
    starred_count?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInboxInfluencersInfluencer_id.info(
    influencer_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get user ids of chats which have new messages

 */
/**
* @package
*/
export const useGetMoiInboxNew_chats = (
  options?: SwaggerTypescriptUseQueryOptions<{
    new_chats?: {
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInboxNew_chats.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInboxNew_chats.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiInboxNew_chats.key] as QueryKey,
    fun: () => getMoiInboxNew_chats(configOverride),
  };
};
useGetMoiInboxNew_chats.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    new_chats?: {
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInboxNew_chats.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get user ids of chats which have new messages

 */
/**
* @package
*/
export const useGetMoiInboxStarred_chats = (
  options?: SwaggerTypescriptUseQueryOptions<{
    new_chats?: {
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInboxStarred_chats.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInboxStarred_chats.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiInboxStarred_chats.key] as QueryKey,
    fun: () => getMoiInboxStarred_chats(configOverride),
  };
};
useGetMoiInboxStarred_chats.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    new_chats?: {
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInboxStarred_chats.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get campaigns for autocomplete
 */
/**
* @package
*/
export const useGetMoiInfluencerCampaigns_autocomplete = (
  queryParams?: GetMoiInfluencerCampaigns_autocompleteQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CampaignIdNamePicture[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencerCampaigns_autocomplete.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInfluencerCampaigns_autocomplete.info = (
  queryParams?: GetMoiInfluencerCampaigns_autocompleteQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiInfluencerCampaigns_autocomplete.key, queryParams] as QueryKey,
    fun: () =>
      getMoiInfluencerCampaigns_autocomplete(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInfluencerCampaigns_autocomplete.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiInfluencerCampaigns_autocompleteQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<CampaignIdNamePicture[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencerCampaigns_autocomplete.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get months for autocomplete
 */
/**
* @package
*/
export const useGetMoiInfluencerMonths_autocomplete = (
  queryParams?: GetMoiInfluencerMonths_autocompleteQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<string[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencerMonths_autocomplete.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInfluencerMonths_autocomplete.info = (
  queryParams?: GetMoiInfluencerMonths_autocompleteQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiInfluencerMonths_autocomplete.key, queryParams] as QueryKey,
    fun: () =>
      getMoiInfluencerMonths_autocomplete(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInfluencerMonths_autocomplete.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiInfluencerMonths_autocompleteQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<string[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencerMonths_autocomplete.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get influencer dashboard data
 */
/**
* @package
*/
export const useGetMoiInfluencer_dashboard = (
  queryParams?: GetMoiInfluencer_dashboardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerProfileDashboard>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_dashboard.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInfluencer_dashboard.info = (
  queryParams?: GetMoiInfluencer_dashboardQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiInfluencer_dashboard.key, queryParams] as QueryKey,
    fun: () =>
      getMoiInfluencer_dashboard(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInfluencer_dashboard.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiInfluencer_dashboardQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerProfileDashboard>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_dashboard.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get todo's for influencer dashboard
 */
/**
* @package
*/
export const useGetMoiInfluencer_dashboardTodolist = (
  queryParams?: GetMoiInfluencer_dashboardTodolistQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerProfileTodo[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_dashboardTodolist.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInfluencer_dashboardTodolist.info = (
  queryParams?: GetMoiInfluencer_dashboardTodolistQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiInfluencer_dashboardTodolist.key, queryParams] as QueryKey,
    fun: () =>
      getMoiInfluencer_dashboardTodolist(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInfluencer_dashboardTodolist.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiInfluencer_dashboardTodolistQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerProfileTodo[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_dashboardTodolist.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get top campaigns for influencer dashboard
 */
/**
* @package
*/
export const useGetMoiInfluencer_dashboardTop_campaigns = (
  queryParams: GetMoiInfluencer_dashboardTop_campaignsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerDashboardTopCampaign[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_dashboardTop_campaigns.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInfluencer_dashboardTop_campaigns.info = (
  queryParams: GetMoiInfluencer_dashboardTop_campaignsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiInfluencer_dashboardTop_campaigns.key, queryParams] as QueryKey,
    fun: () =>
      getMoiInfluencer_dashboardTop_campaigns(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInfluencer_dashboardTop_campaigns.prefetch = (
  client: QueryClient,
  queryParams: GetMoiInfluencer_dashboardTop_campaignsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerDashboardTopCampaign[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_dashboardTop_campaigns.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const useGetMoiInfluencer_profile = (
  options?: SwaggerTypescriptUseQueryOptions<InfluencerProfilePage>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_profile.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInfluencer_profile.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiInfluencer_profile.key] as QueryKey,
    fun: () => getMoiInfluencer_profile(configOverride),
  };
};
useGetMoiInfluencer_profile.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerProfilePage>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_profile.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const useGetMoiInfluencer_profileInfluencer_username = (
  /**
   *
   * the influencer username
   */
  influencer_username: string,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerProfilePage>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_profileInfluencer_username.info(
    influencer_username,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInfluencer_profileInfluencer_username.info = (
  /**
   *
   * the influencer username
   */
  influencer_username: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiInfluencer_profileInfluencer_username.key,
      influencer_username,
    ] as QueryKey,
    fun: () =>
      getMoiInfluencer_profileInfluencer_username(
        influencer_username,

        configOverride,
      ),
  };
};
useGetMoiInfluencer_profileInfluencer_username.prefetch = (
  client: QueryClient,

  /**
   *
   * the influencer username
   */
  influencer_username: string,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerProfilePage>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_profileInfluencer_username.info(
    influencer_username,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get users who viewed my profile on the last 24hours
 */
/**
* @package
*/
export const useGetMoiInfluencer_profileProfile_statistics = (
  options?: SwaggerTypescriptUseQueryOptions<{
    daily_views?: DateAndCount[];
    endorsments?: DateAndCount[];
    social_network_jumps?: {
      daily_jumps?: DateAndCount[];
      social_network?: string;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiInfluencer_profileProfile_statistics.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInfluencer_profileProfile_statistics.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiInfluencer_profileProfile_statistics.key] as QueryKey,
    fun: () => getMoiInfluencer_profileProfile_statistics(configOverride),
  };
};
useGetMoiInfluencer_profileProfile_statistics.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    daily_views?: DateAndCount[];
    endorsments?: DateAndCount[];
    social_network_jumps?: {
      daily_jumps?: DateAndCount[];
      social_network?: string;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiInfluencer_profileProfile_statistics.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get users who viewed my profile on the last 24hours
 */
/**
* @package
*/
export const useGetMoiInfluencer_profileViewed_my_profile = (
  queryParams?: GetMoiInfluencer_profileViewed_my_profileQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<UserOnFeed[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_profileViewed_my_profile.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInfluencer_profileViewed_my_profile.info = (
  queryParams?: GetMoiInfluencer_profileViewed_my_profileQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiInfluencer_profileViewed_my_profile.key,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiInfluencer_profileViewed_my_profile(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInfluencer_profileViewed_my_profile.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiInfluencer_profileViewed_my_profileQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<UserOnFeed[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInfluencer_profileViewed_my_profile.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * returns a list of all insight reports
 */
/**
* @package
*/
export const useGetMoiInsights = (
  queryParams?: GetMoiInsightsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InsightsReportShallowInfo[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInsights.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInsights.info = (
  queryParams?: GetMoiInsightsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiInsights.key, queryParams] as QueryKey,
    fun: () =>
      getMoiInsights(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInsights.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiInsightsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InsightsReportShallowInfo[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInsights.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list all of the invites generated by the user
 */
/**
* @package
*/
export const useGetMoiInvitesExternal = (
  queryParams?: GetMoiInvitesExternalQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInvitesExternal.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiInvitesExternal.info = (
  queryParams?: GetMoiInvitesExternalQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiInvitesExternal.key, queryParams] as QueryKey,
    fun: () =>
      getMoiInvitesExternal(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiInvitesExternal.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiInvitesExternalQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiInvitesExternal.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get All tags for user

 */
/**
* @package
*/
export const useGetMoiMeta_tags = (
  options?: SwaggerTypescriptUseQueryOptions<AllUserTags>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiMeta_tags.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiMeta_tags.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiMeta_tags.key] as QueryKey,
    fun: () => getMoiMeta_tags(configOverride),
  };
};
useGetMoiMeta_tags.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<AllUserTags>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiMeta_tags.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get multi report
 */
/**
* @package
*/
export const useGetMoiMulti_reportReport_id = (
  /**
   *
   * The report id
   */
  report_id: number,
  options?: SwaggerTypescriptUseQueryOptions<MultiReport>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiMulti_reportReport_id.info(
    report_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiMulti_reportReport_id.info = (
  /**
   *
   * The report id
   */
  report_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiMulti_reportReport_id.key, report_id] as QueryKey,
    fun: () =>
      getMoiMulti_reportReport_id(
        report_id,

        configOverride,
      ),
  };
};
useGetMoiMulti_reportReport_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The report id
   */
  report_id: number,
  options?: SwaggerTypescriptUseQueryOptions<MultiReport>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiMulti_reportReport_id.info(
    report_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get current multi reports roi share parameters
 */
/**
* @package
*/
export const useGetMoiMulti_reportsReport_idShare = (
  /**
   *
   * The id of the multi report to get share details
   */
  report_id: number,
  options?: SwaggerTypescriptUseQueryOptions<MultiReportShareParameters[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiMulti_reportsReport_idShare.info(
    report_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiMulti_reportsReport_idShare.info = (
  /**
   *
   * The id of the multi report to get share details
   */
  report_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiMulti_reportsReport_idShare.key, report_id] as QueryKey,
    fun: () =>
      getMoiMulti_reportsReport_idShare(
        report_id,

        configOverride,
      ),
  };
};
useGetMoiMulti_reportsReport_idShare.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the multi report to get share details
   */
  report_id: number,
  options?: SwaggerTypescriptUseQueryOptions<MultiReportShareParameters[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiMulti_reportsReport_idShare.info(
    report_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * Get number of notifications that the server did not send already to the user

 */
/**
* @package
*/
export const useGetMoiNotificationsNew_notifications = (
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: date-time
     */
    last_date_user_marked_notifications_as_seen?: string;
    /**
     *
     * - Format: int64
     */
    not_sent_notifications_count?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiNotificationsNew_notifications.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiNotificationsNew_notifications.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiNotificationsNew_notifications.key] as QueryKey,
    fun: () => getMoiNotificationsNew_notifications(configOverride),
  };
};
useGetMoiNotificationsNew_notifications.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: date-time
     */
    last_date_user_marked_notifications_as_seen?: string;
    /**
     *
     * - Format: int64
     */
    not_sent_notifications_count?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiNotificationsNew_notifications.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list all payments for influencer
 */
/**
* @package
*/
export const useGetMoiPaymentInfluencerPayments = (
  options?: SwaggerTypescriptUseQueryOptions<{
    currencies_summary?: {
      currency?: Currency;
      /**
       *
       * - Format: double
       */
      total_paid?: number;
      /**
       *
       * - Format: double
       */
      total_waiting_for_payment?: number;
    }[];
    payments?: {
      /**
       *
       * - Format: float
       */
      amount?: number;
      /**
       *
       * InfluencerPaymentsCamapignPaymentDetails
       *
       */
      camapign_payment_details?: {
        currency?: Currency;
        /**
         *
         * - Format: int64
         */
        id?: number;
        image?: string;
        /**
         *
         * - Format: date
         */
        month?: string;
        name?: string;
      };
      currency?: Currency;
      /**
       *
       * - Format: date-time
       */
      due_date?: string;
      invoice_url?: string;
      invoices_urls?: string[];
      is_early_payout?: boolean;
      is_managed_by_agent?: boolean;
      is_upfront_payment?: boolean;
      paid?: boolean;
      /**
       *
       * - Format: date-time
       */
      paid_at?: string;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPaymentInfluencerPayments.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPaymentInfluencerPayments.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPaymentInfluencerPayments.key] as QueryKey,
    fun: () => getMoiPaymentInfluencerPayments(configOverride),
  };
};
useGetMoiPaymentInfluencerPayments.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    currencies_summary?: {
      currency?: Currency;
      /**
       *
       * - Format: double
       */
      total_paid?: number;
      /**
       *
       * - Format: double
       */
      total_waiting_for_payment?: number;
    }[];
    payments?: {
      /**
       *
       * - Format: float
       */
      amount?: number;
      /**
       *
       * InfluencerPaymentsCamapignPaymentDetails
       *
       */
      camapign_payment_details?: {
        currency?: Currency;
        /**
         *
         * - Format: int64
         */
        id?: number;
        image?: string;
        /**
         *
         * - Format: date
         */
        month?: string;
        name?: string;
      };
      currency?: Currency;
      /**
       *
       * - Format: date-time
       */
      due_date?: string;
      invoice_url?: string;
      invoices_urls?: string[];
      is_early_payout?: boolean;
      is_managed_by_agent?: boolean;
      is_upfront_payment?: boolean;
      paid?: boolean;
      /**
       *
       * - Format: date-time
       */
      paid_at?: string;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPaymentInfluencerPayments.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get dispute details
 */
/**
* @package
*/
export const useGetMoiPaymentPayment_from_advertiserDispute = (
  queryParams: GetMoiPaymentPayment_from_advertiserDisputeQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AdvertiserDisputeDetailsExtended>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPaymentPayment_from_advertiserDispute.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPaymentPayment_from_advertiserDispute.info = (
  queryParams: GetMoiPaymentPayment_from_advertiserDisputeQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiPaymentPayment_from_advertiserDispute.key,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiPaymentPayment_from_advertiserDispute(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiPaymentPayment_from_advertiserDispute.prefetch = (
  client: QueryClient,
  queryParams: GetMoiPaymentPayment_from_advertiserDisputeQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<AdvertiserDisputeDetailsExtended>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPaymentPayment_from_advertiserDispute.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get dispute details
 */
/**
* @package
*/
export const useGetMoiPaymentPayment_from_advertiserDisputes = (
  options?: SwaggerTypescriptUseQueryOptions<
    {
      /**
       *
       * - Format: int64
       */
      auction_id?: number;
      /**
       *
       * - Format: date-time
       */
      auction_start_time?: string;
      auction_title?: string;
      disputed_influencers?: AdvertiserDisputeDetailsExtended[];
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPaymentPayment_from_advertiserDisputes.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPaymentPayment_from_advertiserDisputes.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPaymentPayment_from_advertiserDisputes.key] as QueryKey,
    fun: () => getMoiPaymentPayment_from_advertiserDisputes(configOverride),
  };
};
useGetMoiPaymentPayment_from_advertiserDisputes.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      /**
       *
       * - Format: int64
       */
      auction_id?: number;
      /**
       *
       * - Format: date-time
       */
      auction_start_time?: string;
      auction_title?: string;
      disputed_influencers?: AdvertiserDisputeDetailsExtended[];
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPaymentPayment_from_advertiserDisputes.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list all invoices for advertiser
 */
/**
* @package
*/
export const useGetMoiPaymentPayment_from_advertiserInvoices = (
  options?: SwaggerTypescriptUseQueryOptions<
    {
      /**
       *
       * - Format: float
       */
      amount?: number;
      /**
       *
       * - Format: date-time
       */
      charge_time?: string;
      charge_type?:
        | "ambassador_report_additional"
        | "ambassador_report_init"
        | "ambassador_report_upgrade"
        | "brand_report"
        | "insights_package"
        | "marketplace_bid"
        | "media_fee"
        | "subscription";
      credit_card_issuer?: CreditCardIssuer;
      currency?: Currency;
      invoice_url?: string;
      /**
       *
       * - pattern: ^[\d]{4}$
       */
      last_digits?: string;
      transaction_id?: string;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPaymentPayment_from_advertiserInvoices.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPaymentPayment_from_advertiserInvoices.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPaymentPayment_from_advertiserInvoices.key] as QueryKey,
    fun: () => getMoiPaymentPayment_from_advertiserInvoices(configOverride),
  };
};
useGetMoiPaymentPayment_from_advertiserInvoices.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      /**
       *
       * - Format: float
       */
      amount?: number;
      /**
       *
       * - Format: date-time
       */
      charge_time?: string;
      charge_type?:
        | "ambassador_report_additional"
        | "ambassador_report_init"
        | "ambassador_report_upgrade"
        | "brand_report"
        | "insights_package"
        | "marketplace_bid"
        | "media_fee"
        | "subscription";
      credit_card_issuer?: CreditCardIssuer;
      currency?: Currency;
      invoice_url?: string;
      /**
       *
       * - pattern: ^[\d]{4}$
       */
      last_digits?: string;
      transaction_id?: string;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPaymentPayment_from_advertiserInvoices.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list credit cards in system for advertiser
 */
/**
* @package
*/
export const useGetMoiPaymentPayment_from_advertiserMethods = (
  options?: SwaggerTypescriptUseQueryOptions<{
    cards?: {
      credit_card_issuer?: CreditCardIssuer;
      currency?: Currency;
      /**
       *
       * - Format: date-time
       */
      expiry_date?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      /**
       *
       * - pattern: ^[\d]{4}$
       */
      last_digits?: string;
      name_on_card?: string;
      primary?: boolean;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPaymentPayment_from_advertiserMethods.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPaymentPayment_from_advertiserMethods.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPaymentPayment_from_advertiserMethods.key] as QueryKey,
    fun: () => getMoiPaymentPayment_from_advertiserMethods(configOverride),
  };
};
useGetMoiPaymentPayment_from_advertiserMethods.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    cards?: {
      credit_card_issuer?: CreditCardIssuer;
      currency?: Currency;
      /**
       *
       * - Format: date-time
       */
      expiry_date?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      /**
       *
       * - pattern: ^[\d]{4}$
       */
      last_digits?: string;
      name_on_card?: string;
      primary?: boolean;
    }[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPaymentPayment_from_advertiserMethods.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get expected charge
 */
/**
* @package
*/
export const useGetMoiPaymentPayment_from_advertiserNext_billing_period = (
  options?: SwaggerTypescriptUseQueryOptions<{
    currency?: Currency;
    /**
     *
     * ExpectedChargeServices
     *
     */
    services?: {
      /**
       *
       * - Format: date
       */
      due_date?: string;
      /**
       *
       * - Format: date
       */
      from_date?: string;
      items?: {
        charge_type?: "subscription" | "media_fee";
        /**
         *
         * - Format: date
         */
        due?: string;
        /**
         *
         * - Format: int64
         */
        id?: number;
        influencers?: {
          /**
           *
           * - Format: int64
           */
          auction_id?: number;
          /**
           *
           * - Format: int64
           */
          dispute_id?: number;
          /**
           *
           * - Format: date-time
           */
          dispute_time?: string;
          /**
           *
           * - Format: int64
           */
          influencer_id?: number;
          /**
           *
           * - Format: double
           */
          minimum_usage_fee?: number;
          /**
           *
           * - Format: date
           */
          month_relevance?: string;
          name?: string;
          /**
           *
           * - Format: int64
           */
          pbi_fk?: number;
          price_details?: AdditionalPrice;
        }[];
        name?: string;
        price_details?: AdditionalPrice;
      }[];
      /**
       *
       * - Format: date
       */
      payment_date?: string;
      total?: AdditionalPrice[];
    };
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPaymentPayment_from_advertiserNext_billing_period.info(
      configOverride,
    );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPaymentPayment_from_advertiserNext_billing_period.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiPaymentPayment_from_advertiserNext_billing_period.key,
    ] as QueryKey,
    fun: () =>
      getMoiPaymentPayment_from_advertiserNext_billing_period(configOverride),
  };
};
useGetMoiPaymentPayment_from_advertiserNext_billing_period.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    currency?: Currency;
    /**
     *
     * ExpectedChargeServices
     *
     */
    services?: {
      /**
       *
       * - Format: date
       */
      due_date?: string;
      /**
       *
       * - Format: date
       */
      from_date?: string;
      items?: {
        charge_type?: "subscription" | "media_fee";
        /**
         *
         * - Format: date
         */
        due?: string;
        /**
         *
         * - Format: int64
         */
        id?: number;
        influencers?: {
          /**
           *
           * - Format: int64
           */
          auction_id?: number;
          /**
           *
           * - Format: int64
           */
          dispute_id?: number;
          /**
           *
           * - Format: date-time
           */
          dispute_time?: string;
          /**
           *
           * - Format: int64
           */
          influencer_id?: number;
          /**
           *
           * - Format: double
           */
          minimum_usage_fee?: number;
          /**
           *
           * - Format: date
           */
          month_relevance?: string;
          name?: string;
          /**
           *
           * - Format: int64
           */
          pbi_fk?: number;
          price_details?: AdditionalPrice;
        }[];
        name?: string;
        price_details?: AdditionalPrice;
      }[];
      /**
       *
       * - Format: date
       */
      payment_date?: string;
      total?: AdditionalPrice[];
    };
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPaymentPayment_from_advertiserNext_billing_period.info(
      configOverride,
    );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get share parameters
 */
/**
* @package
*/
export const useGetMoiPremiumAuctionAuction_idShare = (
  /**
   *
   * The id of the roi auction to share
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<CampaignShareParameters>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumAuctionAuction_idShare.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumAuctionAuction_idShare.info = (
  /**
   *
   * The id of the roi auction to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumAuctionAuction_idShare.key, auction_id] as QueryKey,
    fun: () =>
      getMoiPremiumAuctionAuction_idShare(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumAuctionAuction_idShare.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the roi auction to share
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<CampaignShareParameters>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumAuctionAuction_idShare.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list auction todo items
 */
/**
* @package
*/
export const useGetMoiPremiumAuctionAuction_idTodo = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{ items?: AuctionToDoItem[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumAuctionAuction_idTodo.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumAuctionAuction_idTodo.info = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumAuctionAuction_idTodo.key, auction_id] as QueryKey,
    fun: () =>
      getMoiPremiumAuctionAuction_idTodo(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumAuctionAuction_idTodo.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{ items?: AuctionToDoItem[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumAuctionAuction_idTodo.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list auction todo items
 */
/**
* @package
*/
export const useGetMoiPremiumAuctionAuction_idTodo_status = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    completed?: number;
    items?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumAuctionAuction_idTodo_status.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumAuctionAuction_idTodo_status.info = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiPremiumAuctionAuction_idTodo_status.key,
      auction_id,
    ] as QueryKey,
    fun: () =>
      getMoiPremiumAuctionAuction_idTodo_status(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumAuctionAuction_idTodo_status.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    completed?: number;
    items?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumAuctionAuction_idTodo_status.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get list data with users
 */
/**
* @package
*/
export const useGetMoiPremiumCampaign_listsAuction_id = (
  /**
   *
   * The id of the list to add to
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ListData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumCampaign_listsAuction_id.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumCampaign_listsAuction_id.info = (
  /**
   *
   * The id of the list to add to
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumCampaign_listsAuction_id.key, auction_id] as QueryKey,
    fun: () =>
      getMoiPremiumCampaign_listsAuction_id(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumCampaign_listsAuction_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the list to add to
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ListData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumCampaign_listsAuction_id.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const useGetMoiPremiumGet_influencer_dataInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: string,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerExtendedData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumGet_influencer_dataInfluencer_id.info(
    influencer_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumGet_influencer_dataInfluencer_id.info = (
  /**
   *
   * the influencer id
   */
  influencer_id: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiPremiumGet_influencer_dataInfluencer_id.key,
      influencer_id,
    ] as QueryKey,
    fun: () =>
      getMoiPremiumGet_influencer_dataInfluencer_id(
        influencer_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumGet_influencer_dataInfluencer_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the influencer id
   */
  influencer_id: string,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerExtendedData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumGet_influencer_dataInfluencer_id.info(
    influencer_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get specific influencer posts from instagram api
 */
/**
* @package
*/
export const useGetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  /**
   *
   * auction_id
   */
  auction_id: number,
  queryParams?: GetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerInstagramPost[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id.info(
      influencer_id,
      auction_id,

      queryParams,
      configOverride,
    );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id.info = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  /**
   *
   * auction_id
   */
  auction_id: number,
  queryParams?: GetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_idQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id.key,
      influencer_id,
      auction_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id(
        influencer_id,
        auction_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the influencer id
   */
  influencer_id: number,
  /**
   *
   * auction_id
   */
  auction_id: number,
  queryParams?: GetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerInstagramPost[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id.info(
      influencer_id,
      auction_id,

      queryParams,
      configOverride,
    );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get specific influencer stories from instagram from the db
 */
/**
* @package
*/
export const useGetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id =
  (
    /**
     *
     * the influencer id
     */
    influencer_id: number,
    /**
     *
     * auction_id
     */
    auction_id: number,
    queryParams?: GetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_idQueryParams,
    options?: SwaggerTypescriptUseQueryOptions<InfluencerInstagramStories[]>,
    configOverride?: AxiosRequestConfig,
  ) => {
    const { key, fun } =
      useGetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id.info(
        influencer_id,
        auction_id,

        queryParams,
        configOverride,
      );
    return useQuery({
      queryKey: key,
      queryFn: fun,
      ...options,
    });
  };
useGetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id.info = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  /**
   *
   * auction_id
   */
  auction_id: number,
  queryParams?: GetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_idQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id.key,
      influencer_id,
      auction_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id(
        influencer_id,
        auction_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the influencer id
   */
  influencer_id: number,
  /**
   *
   * auction_id
   */
  auction_id: number,
  queryParams?: GetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerInstagramStories[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id.info(
      influencer_id,
      auction_id,

      queryParams,
      configOverride,
    );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Route for influencers to fetch their own stories
 */
/**
* @package
*/
export const useGetMoiPremiumInfluencer_stories = (
  queryParams?: GetMoiPremiumInfluencer_storiesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerInstagramStories[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumInfluencer_stories.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumInfluencer_stories.info = (
  queryParams?: GetMoiPremiumInfluencer_storiesQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumInfluencer_stories.key, queryParams] as QueryKey,
    fun: () =>
      getMoiPremiumInfluencer_stories(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiPremiumInfluencer_stories.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiPremiumInfluencer_storiesQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<InfluencerInstagramStories[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumInfluencer_stories.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all lists for logined user
 */
/**
* @package
*/
export const useGetMoiPremiumLists = (
  queryParams?: GetMoiPremiumListsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ListSummary[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumLists.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumLists.info = (
  queryParams?: GetMoiPremiumListsQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumLists.key, queryParams] as QueryKey,
    fun: () =>
      getMoiPremiumLists(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiPremiumLists.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiPremiumListsQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ListSummary[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumLists.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get requests data status on all influencers
 */
/**
* @package
*/
export const useGetMoiPremiumListsInfluencers_request_data_list = (
  options?: SwaggerTypescriptUseQueryOptions<RequestDataInfluencersList>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPremiumListsInfluencers_request_data_list.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumListsInfluencers_request_data_list.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumListsInfluencers_request_data_list.key] as QueryKey,
    fun: () => getMoiPremiumListsInfluencers_request_data_list(configOverride),
  };
};
useGetMoiPremiumListsInfluencers_request_data_list.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<RequestDataInfluencersList>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPremiumListsInfluencers_request_data_list.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get list data with users
 */
/**
* @package
*/
export const useGetMoiPremiumListsList_id = (
  /**
   *
   * The id of the list to add to
   */
  list_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ListData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumListsList_id.info(
    list_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumListsList_id.info = (
  /**
   *
   * The id of the list to add to
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumListsList_id.key, list_id] as QueryKey,
    fun: () =>
      getMoiPremiumListsList_id(
        list_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumListsList_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the list to add to
   */
  list_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ListData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumListsList_id.info(
    list_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get the list form data
 */
/**
* @package
*/
export const useGetMoiPremiumListsList_idForm_data = (
  /**
   *
   * The id of the list to get the form data
   */
  list_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    answers?: ListFormAnswer[];
    form_uid?: string;
    questions?: AuctionQuestions;
    /**
     *
     * - Format: int64
     */
    total_answers?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumListsList_idForm_data.info(
    list_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumListsList_idForm_data.info = (
  /**
   *
   * The id of the list to get the form data
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumListsList_idForm_data.key, list_id] as QueryKey,
    fun: () =>
      getMoiPremiumListsList_idForm_data(
        list_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumListsList_idForm_data.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the list to get the form data
   */
  list_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    answers?: ListFormAnswer[];
    form_uid?: string;
    questions?: AuctionQuestions;
    /**
     *
     * - Format: int64
     */
    total_answers?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumListsList_idForm_data.info(
    list_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get list users and data
 */
/**
* @package
*/
export const useGetMoiPremiumListsList_idFrequenices = (
  /**
   *
   * The id of the list
   */
  list_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    instagram?: Frequencies;
    twitter?: Frequencies;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumListsList_idFrequenices.info(
    list_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumListsList_idFrequenices.info = (
  /**
   *
   * The id of the list
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumListsList_idFrequenices.key, list_id] as QueryKey,
    fun: () =>
      getMoiPremiumListsList_idFrequenices(
        list_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumListsList_idFrequenices.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the list
   */
  list_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    instagram?: Frequencies;
    twitter?: Frequencies;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumListsList_idFrequenices.info(
    list_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get slist segemntation data
 */
/**
* @package
*/
export const useGetMoiPremiumListsList_idGet_list_segmentation_data = (
  /**
   *
   * the list id
   */
  list_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ListExtendedData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPremiumListsList_idGet_list_segmentation_data.info(
      list_id,

      configOverride,
    );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumListsList_idGet_list_segmentation_data.info = (
  /**
   *
   * the list id
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiPremiumListsList_idGet_list_segmentation_data.key,
      list_id,
    ] as QueryKey,
    fun: () =>
      getMoiPremiumListsList_idGet_list_segmentation_data(
        list_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumListsList_idGet_list_segmentation_data.prefetch = (
  client: QueryClient,

  /**
   *
   * the list id
   */
  list_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ListExtendedData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPremiumListsList_idGet_list_segmentation_data.info(
      list_id,

      configOverride,
    );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get current list share status
 */
/**
* @package
*/
export const useGetMoiPremiumListsList_idShare = (
  /**
   *
   * The id of the list to share
   */
  list_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ShareListParameters>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumListsList_idShare.info(
    list_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumListsList_idShare.info = (
  /**
   *
   * The id of the list to share
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumListsList_idShare.key, list_id] as QueryKey,
    fun: () =>
      getMoiPremiumListsList_idShare(
        list_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumListsList_idShare.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the list to share
   */
  list_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ShareListParameters>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumListsList_idShare.info(
    list_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all lists for logined user
 */
/**
* @package
*/
export const useGetMoiPremiumListsUsers_mapping = (
  queryParams?: GetMoiPremiumListsUsers_mappingQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      description?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image_url?: string;
      influencer_ids?: number[];
      is_favorite?: boolean;
      /**
       *
       * - Format: int64
       */
      linked_auction_id?: number;
      list_type?: "normal" | "campaign" | "list_fee";
      title?: string;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumListsUsers_mapping.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumListsUsers_mapping.info = (
  queryParams?: GetMoiPremiumListsUsers_mappingQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumListsUsers_mapping.key, queryParams] as QueryKey,
    fun: () =>
      getMoiPremiumListsUsers_mapping(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiPremiumListsUsers_mapping.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiPremiumListsUsers_mappingQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<
    {
      description?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image_url?: string;
      influencer_ids?: number[];
      is_favorite?: boolean;
      /**
       *
       * - Format: int64
       */
      linked_auction_id?: number;
      list_type?: "normal" | "campaign" | "list_fee";
      title?: string;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumListsUsers_mapping.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all lists for logined user
 */
/**
* @package
*/
export const useGetMoiPremiumLists_with_scrolling = (
  queryParams?: GetMoiPremiumLists_with_scrollingQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * UserLists
     *
     */
    normal?: MinimalListSummary[];
    request_data?: RequestDataSummary;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumLists_with_scrolling.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumLists_with_scrolling.info = (
  queryParams?: GetMoiPremiumLists_with_scrollingQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumLists_with_scrolling.key, queryParams] as QueryKey,
    fun: () =>
      getMoiPremiumLists_with_scrolling(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiPremiumLists_with_scrolling.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiPremiumLists_with_scrollingQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * UserLists
     *
     */
    normal?: MinimalListSummary[];
    request_data?: RequestDataSummary;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumLists_with_scrolling.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * open chat channel with influencer
 */
/**
* @package
*/
export const useGetMoiPremiumOpen_chatInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumOpen_chatInfluencer_id.info(
    influencer_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumOpen_chatInfluencer_id.info = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumOpen_chatInfluencer_id.key, influencer_id] as QueryKey,
    fun: () =>
      getMoiPremiumOpen_chatInfluencer_id(
        influencer_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumOpen_chatInfluencer_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the influencer id
   */
  influencer_id: number,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumOpen_chatInfluencer_id.info(
    influencer_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get request data summary
 */
/**
* @package
*/
export const useGetMoiPremiumRequest_data_summary = (
  options?: SwaggerTypescriptUseQueryOptions<RequestDataSummary>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPremiumRequest_data_summary.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumRequest_data_summary.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumRequest_data_summary.key] as QueryKey,
    fun: () => getMoiPremiumRequest_data_summary(configOverride),
  };
};
useGetMoiPremiumRequest_data_summary.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<RequestDataSummary>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetMoiPremiumRequest_data_summary.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get share parameters
 */
/**
* @package
*/
export const useGetMoiPremiumRoiAuction_idShare = (
  /**
   *
   * The id of the roi auction to share
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ShareReportParameters>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumRoiAuction_idShare.info(
    auction_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumRoiAuction_idShare.info = (
  /**
   *
   * The id of the roi auction to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiPremiumRoiAuction_idShare.key, auction_id] as QueryKey,
    fun: () =>
      getMoiPremiumRoiAuction_idShare(
        auction_id,

        configOverride,
      ),
  };
};
useGetMoiPremiumRoiAuction_idShare.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the roi auction to share
   */
  auction_id: number,
  options?: SwaggerTypescriptUseQueryOptions<ShareReportParameters>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumRoiAuction_idShare.info(
    auction_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list auction todo items
 */
/**
* @package
*/
export const useGetMoiPremiumTodo_templates = (
  options?: SwaggerTypescriptUseQueryOptions<{ items?: TodoTemplate[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumTodo_templates.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiPremiumTodo_templates.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiPremiumTodo_templates.key] as QueryKey,
    fun: () => getMoiPremiumTodo_templates(configOverride),
  };
};
useGetMoiPremiumTodo_templates.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{ items?: TodoTemplate[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiPremiumTodo_templates.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get all reviews for an influencer
 */
/**
* @package
*/
export const useGetMoiReviewsInfluencerId = (
  /**
   *
   * The influencer id to fetch
   */
  influencerId: number,
  queryParams?: GetMoiReviewsInfluencerIdQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * ReviewsArray
     *
     */
    reviews?: ReadReviewData[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiReviewsInfluencerId.info(
    influencerId,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiReviewsInfluencerId.info = (
  /**
   *
   * The influencer id to fetch
   */
  influencerId: number,
  queryParams?: GetMoiReviewsInfluencerIdQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiReviewsInfluencerId.key, influencerId, queryParams] as QueryKey,
    fun: () =>
      getMoiReviewsInfluencerId(
        influencerId,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiReviewsInfluencerId.prefetch = (
  client: QueryClient,

  /**
   *
   * The influencer id to fetch
   */
  influencerId: number,
  queryParams?: GetMoiReviewsInfluencerIdQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * ReviewsArray
     *
     */
    reviews?: ReadReviewData[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiReviewsInfluencerId.info(
    influencerId,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * List all shadow influencers of the current user (and enterprise if such exists)
 */
/**
* @package
*/
export const useGetMoiShadow_influencers = (
  options?: SwaggerTypescriptUseQueryOptions<ShadowInfluencerWithId[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiShadow_influencers.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiShadow_influencers.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiShadow_influencers.key] as QueryKey,
    fun: () => getMoiShadow_influencers(configOverride),
  };
};
useGetMoiShadow_influencers.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<ShadowInfluencerWithId[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiShadow_influencers.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const useGetMoiSharesShare_guidNotes = (
  /**
   *
   * The id of the share
   */
  share_guid: string,
  options?: SwaggerTypescriptUseQueryOptions<ShareNotesSummary>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSharesShare_guidNotes.info(
    share_guid,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiSharesShare_guidNotes.info = (
  /**
   *
   * The id of the share
   */
  share_guid: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiSharesShare_guidNotes.key, share_guid] as QueryKey,
    fun: () =>
      getMoiSharesShare_guidNotes(
        share_guid,

        configOverride,
      ),
  };
};
useGetMoiSharesShare_guidNotes.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the share
   */
  share_guid: string,
  options?: SwaggerTypescriptUseQueryOptions<ShareNotesSummary>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSharesShare_guidNotes.info(
    share_guid,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const useGetMoiSharesShare_guidNotesItem_typeNotesItem_id = (
  /**
   *
   * The id of the share
   */
  share_guid: string,
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{ notes?: NoteMessage[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSharesShare_guidNotesItem_typeNotesItem_id.info(
    share_guid,
    item_type,
    item_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiSharesShare_guidNotesItem_typeNotesItem_id.info = (
  /**
   *
   * The id of the share
   */
  share_guid: string,
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiSharesShare_guidNotesItem_typeNotesItem_id.key,
      share_guid,
      item_type,
      item_id,
    ] as QueryKey,
    fun: () =>
      getMoiSharesShare_guidNotesItem_typeNotesItem_id(
        share_guid,
        item_type,
        item_id,

        configOverride,
      ),
  };
};
useGetMoiSharesShare_guidNotesItem_typeNotesItem_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the share
   */
  share_guid: string,
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{ notes?: NoteMessage[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSharesShare_guidNotesItem_typeNotesItem_id.info(
    share_guid,
    item_type,
    item_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get sidebar of connected user
 */
/**
* @package
*/
export const useGetMoiSidebar = (
  options?: SwaggerTypescriptUseQueryOptions<SideBarData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSidebar.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiSidebar.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getMoiSidebar.key] as QueryKey,
    fun: () => getMoiSidebar(configOverride),
  };
};
useGetMoiSidebar.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<SideBarData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSidebar.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list requester tickets according to status (and organization tickets)
 */
/**
* @package
*/
export const useGetMoiSupport_center = (
  queryParams: GetMoiSupport_centerQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{ tickets?: SupportTicket[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSupport_center.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiSupport_center.info = (
  queryParams: GetMoiSupport_centerQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiSupport_center.key, queryParams] as QueryKey,
    fun: () =>
      getMoiSupport_center(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiSupport_center.prefetch = (
  client: QueryClient,
  queryParams: GetMoiSupport_centerQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{ tickets?: SupportTicket[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSupport_center.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * set last seen time of requester
 */
/**
* @package
*/
export const useGetMoiSupport_centerLast_seen_timeTicket_id = (
  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSupport_centerLast_seen_timeTicket_id.info(
    ticket_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiSupport_centerLast_seen_timeTicket_id.info = (
  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiSupport_centerLast_seen_timeTicket_id.key,
      ticket_id,
    ] as QueryKey,
    fun: () =>
      getMoiSupport_centerLast_seen_timeTicket_id(
        ticket_id,

        configOverride,
      ),
  };
};
useGetMoiSupport_centerLast_seen_timeTicket_id.prefetch = (
  client: QueryClient,

  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSupport_centerLast_seen_timeTicket_id.info(
    ticket_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * list specific ticket articles according to time filter (if provided)
 */
/**
* @package
*/
export const useGetMoiSupport_centerTicket_id = (
  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  queryParams?: GetMoiSupport_centerTicket_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    ticket_articles?: SupportTicketArticle[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSupport_centerTicket_id.info(
    ticket_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiSupport_centerTicket_id.info = (
  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  queryParams?: GetMoiSupport_centerTicket_idQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiSupport_centerTicket_id.key,
      ticket_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getMoiSupport_centerTicket_id(
        ticket_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetMoiSupport_centerTicket_id.prefetch = (
  client: QueryClient,

  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  queryParams?: GetMoiSupport_centerTicket_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<{
    ticket_articles?: SupportTicketArticle[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiSupport_centerTicket_id.info(
    ticket_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get task offers data
 */
/**
* @package
*/
export const useGetMoiTask_offersTask_offer_id = (
  /**
   *
   * The id of the offer
   */
  task_offer_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    tasks_modification?: TaskModification;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiTask_offersTask_offer_id.info(
    task_offer_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiTask_offersTask_offer_id.info = (
  /**
   *
   * The id of the offer
   */
  task_offer_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiTask_offersTask_offer_id.key, task_offer_id] as QueryKey,
    fun: () =>
      getMoiTask_offersTask_offer_id(
        task_offer_id,

        configOverride,
      ),
  };
};
useGetMoiTask_offersTask_offer_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The id of the offer
   */
  task_offer_id: number,
  options?: SwaggerTypescriptUseQueryOptions<{
    tasks_modification?: TaskModification;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiTask_offersTask_offer_id.info(
    task_offer_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get specific unfinished campaign
 */
/**
* @package
*/
export const useGetMoiUnfinished_campaignsUnfinished_campaign_id = (
  /**
   *
   * The unfinished campaign id
   */
  unfinished_campaign_id: number,
  options?: SwaggerTypescriptUseQueryOptions<UnfinishedCampaign>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiUnfinished_campaignsUnfinished_campaign_id.info(
    unfinished_campaign_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiUnfinished_campaignsUnfinished_campaign_id.info = (
  /**
   *
   * The unfinished campaign id
   */
  unfinished_campaign_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getMoiUnfinished_campaignsUnfinished_campaign_id.key,
      unfinished_campaign_id,
    ] as QueryKey,
    fun: () =>
      getMoiUnfinished_campaignsUnfinished_campaign_id(
        unfinished_campaign_id,

        configOverride,
      ),
  };
};
useGetMoiUnfinished_campaignsUnfinished_campaign_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The unfinished campaign id
   */
  unfinished_campaign_id: number,
  options?: SwaggerTypescriptUseQueryOptions<UnfinishedCampaign>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiUnfinished_campaignsUnfinished_campaign_id.info(
    unfinished_campaign_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get last updates
 */
/**
* @package
*/
export const useGetMoiUpdate_center = (
  queryParams?: GetMoiUpdate_centerQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<VersionUpdate[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiUpdate_center.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiUpdate_center.info = (
  queryParams?: GetMoiUpdate_centerQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiUpdate_center.key, queryParams] as QueryKey,
    fun: () =>
      getMoiUpdate_center(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiUpdate_center.prefetch = (
  client: QueryClient,
  queryParams?: GetMoiUpdate_centerQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<VersionUpdate[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiUpdate_center.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * check if any version updates available
 */
/**
* @package
*/
export const useGetMoiUpdate_centerCheck_updates = (
  options?: SwaggerTypescriptUseQueryOptions<UpdatesAvailable>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiUpdate_centerCheck_updates.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiUpdate_centerCheck_updates.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiUpdate_centerCheck_updates.key] as QueryKey,
    fun: () => getMoiUpdate_centerCheck_updates(configOverride),
  };
};
useGetMoiUpdate_centerCheck_updates.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<UpdatesAvailable>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiUpdate_centerCheck_updates.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * check if user feedback exists
 */
/**
* @package
*/
export const useGetMoiUser_feature_feedback = (
  queryParams: GetMoiUser_feature_feedbackQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<boolean>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiUser_feature_feedback.info(
    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetMoiUser_feature_feedback.info = (
  queryParams: GetMoiUser_feature_feedbackQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getMoiUser_feature_feedback.key, queryParams] as QueryKey,
    fun: () =>
      getMoiUser_feature_feedback(
        queryParams,

        configOverride,
      ),
  };
};
useGetMoiUser_feature_feedback.prefetch = (
  client: QueryClient,
  queryParams: GetMoiUser_feature_feedbackQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<boolean>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetMoiUser_feature_feedback.info(
    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get pixel wizard details by uuid
 */
/**
* @package
*/
export const useGetPixel_wizard = (
  headerParams?: {
    "x-pixel-wizard-pass": string;
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "x-pixel-wizard-uuid": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<PixelWizardDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetPixel_wizard.info(headerParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetPixel_wizard.info = (
  headerParams?: {
    "x-pixel-wizard-pass": string;
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "x-pixel-wizard-uuid": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getPixel_wizard.key, headerParams] as QueryKey,
    fun: () => getPixel_wizard(headerParams, configOverride),
  };
};
useGetPixel_wizard.prefetch = (
  client: QueryClient,
  headerParams?: {
    "x-pixel-wizard-pass": string;
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "x-pixel-wizard-uuid": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<PixelWizardDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetPixel_wizard.info(headerParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get top words and posts
 */
/**
* @package
*/
export const useGetSharesBrand_reportTop_words = (
  queryParams?: GetSharesBrand_reportTop_wordsQueryParams,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<
    {
      posts?: {
        instagram_profile_pic_url?: string;
        instagram_username?: string;
        media_id?: string;
        text?: string;
      }[];
      posts_amount?: number;
      word?: string;
      /**
       *
       * - Format: int64
       */
      word_id?: number;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesBrand_reportTop_words.info(
    queryParams,
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesBrand_reportTop_words.info = (
  queryParams?: GetSharesBrand_reportTop_wordsQueryParams,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getSharesBrand_reportTop_words.key,

      queryParams,
      headerParams,
    ] as QueryKey,
    fun: () =>
      getSharesBrand_reportTop_words(queryParams, headerParams, configOverride),
  };
};
useGetSharesBrand_reportTop_words.prefetch = (
  client: QueryClient,
  queryParams?: GetSharesBrand_reportTop_wordsQueryParams,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<
    {
      posts?: {
        instagram_profile_pic_url?: string;
        instagram_username?: string;
        media_id?: string;
        text?: string;
      }[];
      posts_amount?: number;
      word?: string;
      /**
       *
       * - Format: int64
       */
      word_id?: number;
    }[]
  >,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesBrand_reportTop_words.info(
    queryParams,
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get campaign share parameteres
 */
/**
* @package
*/
export const useGetSharesCampaign = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<CampaignShareParametersWithOwnerDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaign.info(headerParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesCampaign.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesCampaign.key, headerParams] as QueryKey,
    fun: () => getSharesCampaign(headerParams, configOverride),
  };
};
useGetSharesCampaign.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<CampaignShareParametersWithOwnerDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaign.info(headerParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get specific auction by id
 */
/**
* @package
*/
export const useGetSharesCampaignBrief = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ExtendedAuctionData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignBrief.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesCampaignBrief.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesCampaignBrief.key, headerParams] as QueryKey,
    fun: () => getSharesCampaignBrief(headerParams, configOverride),
  };
};
useGetSharesCampaignBrief.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ExtendedAuctionData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignBrief.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared list - to view influencers before campaign live
 */
/**
* @package
*/
export const useGetSharesCampaignList = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    list?: ListData;
    owner_details?: ShareOwnerDetails;
    shared_parameters?: ShareListParameters;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignList.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesCampaignList.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesCampaignList.key, headerParams] as QueryKey,
    fun: () => getSharesCampaignList(headerParams, configOverride),
  };
};
useGetSharesCampaignList.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    list?: ListData;
    owner_details?: ShareOwnerDetails;
    shared_parameters?: ShareListParameters;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignList.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get all board details
 */
/**
* @package
*/
export const useGetSharesCampaignManage = (
  queryParams?: GetSharesCampaignManageQueryParams,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ManageAuctionData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignManage.info(
    queryParams,
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesCampaignManage.info = (
  queryParams?: GetSharesCampaignManageQueryParams,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesCampaignManage.key, queryParams, headerParams] as QueryKey,
    fun: () =>
      getSharesCampaignManage(queryParams, headerParams, configOverride),
  };
};
useGetSharesCampaignManage.prefetch = (
  client: QueryClient,
  queryParams?: GetSharesCampaignManageQueryParams,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ManageAuctionData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignManage.info(
    queryParams,
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const useGetSharesCampaignNotes = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ShareNotesSummary>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignNotes.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesCampaignNotes.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesCampaignNotes.key, headerParams] as QueryKey,
    fun: () => getSharesCampaignNotes(headerParams, configOverride),
  };
};
useGetSharesCampaignNotes.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ShareNotesSummary>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignNotes.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const useGetSharesCampaignNotesItem_typeNotesItem_id = (
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{ notes?: NoteMessage[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignNotesItem_typeNotesItem_id.info(
    item_type,
    item_id,

    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesCampaignNotesItem_typeNotesItem_id.info = (
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getSharesCampaignNotesItem_typeNotesItem_id.key,
      item_type,
      item_id,

      headerParams,
    ] as QueryKey,
    fun: () =>
      getSharesCampaignNotesItem_typeNotesItem_id(
        item_type,
        item_id,

        headerParams,
        configOverride,
      ),
  };
};
useGetSharesCampaignNotesItem_typeNotesItem_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{ notes?: NoteMessage[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignNotesItem_typeNotesItem_id.info(
    item_type,
    item_id,

    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared campaign raw details
 */
/**
* @package
*/
export const useGetSharesCampaignShare_details = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    campaign_title?: string;
    company_name?: string;
    country?: string;
    is_password_protected?: boolean;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignShare_details.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesCampaignShare_details.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesCampaignShare_details.key, headerParams] as QueryKey,
    fun: () => getSharesCampaignShare_details(headerParams, configOverride),
  };
};
useGetSharesCampaignShare_details.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    campaign_title?: string;
    company_name?: string;
    country?: string;
    is_password_protected?: boolean;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignShare_details.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get tracker data
 */
/**
* @package
*/
export const useGetSharesCampaignTracker = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    events?: TrackerEvent[];
    /**
     *
     * CampaignTrackerDataKpi
     *
     */
    kpi?: { conversions?: KPI; engagements?: KPI; impressions?: KPI };
    /**
     *
     * CampaignTrackerSummary
     *
     */
    summary?: {
      /**
       *
       * - Format: int64
       */
      auction_id?: number;
      chosen?: KPI;
      content_approved?: KPI;
      content_published?: KPI;
      image?: string;
      title?: string;
    };
    top_content?: LinkedMediaForInfluencer[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignTracker.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesCampaignTracker.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesCampaignTracker.key, headerParams] as QueryKey,
    fun: () => getSharesCampaignTracker(headerParams, configOverride),
  };
};
useGetSharesCampaignTracker.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    events?: TrackerEvent[];
    /**
     *
     * CampaignTrackerDataKpi
     *
     */
    kpi?: { conversions?: KPI; engagements?: KPI; impressions?: KPI };
    /**
     *
     * CampaignTrackerSummary
     *
     */
    summary?: {
      /**
       *
       * - Format: int64
       */
      auction_id?: number;
      chosen?: KPI;
      content_approved?: KPI;
      content_published?: KPI;
      image?: string;
      title?: string;
    };
    top_content?: LinkedMediaForInfluencer[];
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesCampaignTracker.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get influencer details card
 */
/**
* @package
*/
export const useGetSharesInfluencer_card = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<InfluencerCardData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesInfluencer_card.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesInfluencer_card.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesInfluencer_card.key, headerParams] as QueryKey,
    fun: () => getSharesInfluencer_card(headerParams, configOverride),
  };
};
useGetSharesInfluencer_card.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<InfluencerCardData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesInfluencer_card.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Get shared campaign details for influencers
 */
/**
* @package
*/
export const useGetSharesInvite = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<AuctionDataForInfluencerExtended>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesInvite.info(headerParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesInvite.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesInvite.key, headerParams] as QueryKey,
    fun: () => getSharesInvite(headerParams, configOverride),
  };
};
useGetSharesInvite.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<AuctionDataForInfluencerExtended>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesInvite.info(headerParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared list - to view influencers before campaign live
 */
/**
* @package
*/
export const useGetSharesLists = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    list?: ListData;
    owner_details?: ShareOwnerDetails;
    shared_parameters?: ShareListParameters;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesLists.info(headerParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesLists.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesLists.key, headerParams] as QueryKey,
    fun: () => getSharesLists(headerParams, configOverride),
  };
};
useGetSharesLists.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    list?: ListData;
    owner_details?: ShareOwnerDetails;
    shared_parameters?: ShareListParameters;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesLists.info(headerParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get list users and data
 */
/**
* @package
*/
export const useGetSharesListsFrequenices = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    instagram?: Frequencies;
    twitter?: Frequencies;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesListsFrequenices.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesListsFrequenices.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesListsFrequenices.key, headerParams] as QueryKey,
    fun: () => getSharesListsFrequenices(headerParams, configOverride),
  };
};
useGetSharesListsFrequenices.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    instagram?: Frequencies;
    twitter?: Frequencies;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesListsFrequenices.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared list segementation
 */
/**
* @package
*/
export const useGetSharesListsGet_list_segmentation_data = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ListExtendedData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesListsGet_list_segmentation_data.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesListsGet_list_segmentation_data.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getSharesListsGet_list_segmentation_data.key,

      headerParams,
    ] as QueryKey,
    fun: () =>
      getSharesListsGet_list_segmentation_data(headerParams, configOverride),
  };
};
useGetSharesListsGet_list_segmentation_data.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ListExtendedData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesListsGet_list_segmentation_data.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const useGetSharesListsNotes = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ShareNotesSummary>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesListsNotes.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesListsNotes.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesListsNotes.key, headerParams] as QueryKey,
    fun: () => getSharesListsNotes(headerParams, configOverride),
  };
};
useGetSharesListsNotes.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ShareNotesSummary>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesListsNotes.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const useGetSharesListsNotesItem_typeNotesItem_id = (
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{ notes?: NoteMessage[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesListsNotesItem_typeNotesItem_id.info(
    item_type,
    item_id,

    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesListsNotesItem_typeNotesItem_id.info = (
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getSharesListsNotesItem_typeNotesItem_id.key,
      item_type,
      item_id,

      headerParams,
    ] as QueryKey,
    fun: () =>
      getSharesListsNotesItem_typeNotesItem_id(
        item_type,
        item_id,

        headerParams,
        configOverride,
      ),
  };
};
useGetSharesListsNotesItem_typeNotesItem_id.prefetch = (
  client: QueryClient,

  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{ notes?: NoteMessage[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesListsNotesItem_typeNotesItem_id.info(
    item_type,
    item_id,

    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared multi report
 */
/**
* @package
*/
export const useGetSharesMulti_reports = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    roi?: ManagedCampaignROI;
    shared_parameters?: MultiReportShareParameters;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesMulti_reports.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesMulti_reports.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesMulti_reports.key, headerParams] as QueryKey,
    fun: () => getSharesMulti_reports(headerParams, configOverride),
  };
};
useGetSharesMulti_reports.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    roi?: ManagedCampaignROI;
    shared_parameters?: MultiReportShareParameters;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesMulti_reports.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared multi report raw details
 */
/**
* @package
*/
export const useGetSharesMulti_reportsShare_details = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    company_name?: string;
    is_password_protected?: boolean;
    report_title?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesMulti_reportsShare_details.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesMulti_reportsShare_details.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesMulti_reportsShare_details.key, headerParams] as QueryKey,
    fun: () =>
      getSharesMulti_reportsShare_details(headerParams, configOverride),
  };
};
useGetSharesMulti_reportsShare_details.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    company_name?: string;
    is_password_protected?: boolean;
    report_title?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesMulti_reportsShare_details.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get shared roi - to view roi of a campaign (board)
 */
/**
* @package
*/
export const useGetSharesRoiLast_month_for_roi = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: date
     */
    last_month?: string;
    /**
     *
     * - Format: date
     */
    last_month_with_purchases?: string;
    one_month_campaign?: boolean;
    pixel_enabled?: boolean;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesRoiLast_month_for_roi.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSharesRoiLast_month_for_roi.info = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSharesRoiLast_month_for_roi.key, headerParams] as QueryKey,
    fun: () => getSharesRoiLast_month_for_roi(headerParams, configOverride),
  };
};
useGetSharesRoiLast_month_for_roi.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: date
     */
    last_month?: string;
    /**
     *
     * - Format: date
     */
    last_month_with_purchases?: string;
    one_month_campaign?: boolean;
    pixel_enabled?: boolean;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSharesRoiLast_month_for_roi.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * shopify health endpoint
 */
/**
* @package
*/
export const useGetShopify = (
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: int64
     */
    current_time?: number;
    message?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopify.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetShopify.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getShopify.key] as QueryKey,
    fun: () => getShopify(configOverride),
  };
};
useGetShopify.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: int64
     */
    current_time?: number;
    message?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopify.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get influencer data
 */
/**
* @package
*/
export const useGetShopifyInfluencerInfluencer_id = (
  /**
   *
   * the influencer id to get data for
   */
  influencer_id: number,
  options?: SwaggerTypescriptUseQueryOptions<WixInfluencerData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopifyInfluencerInfluencer_id.info(
    influencer_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetShopifyInfluencerInfluencer_id.info = (
  /**
   *
   * the influencer id to get data for
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getShopifyInfluencerInfluencer_id.key, influencer_id] as QueryKey,
    fun: () =>
      getShopifyInfluencerInfluencer_id(
        influencer_id,

        configOverride,
      ),
  };
};
useGetShopifyInfluencerInfluencer_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the influencer id to get data for
   */
  influencer_id: number,
  options?: SwaggerTypescriptUseQueryOptions<WixInfluencerData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopifyInfluencerInfluencer_id.info(
    influencer_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * manage influencers
 */
/**
* @package
*/
export const useGetShopifyManageInfluencers = (
  options?: SwaggerTypescriptUseQueryOptions<WixInfluencerManage[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopifyManageInfluencers.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetShopifyManageInfluencers.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getShopifyManageInfluencers.key] as QueryKey,
    fun: () => getShopifyManageInfluencers(configOverride),
  };
};
useGetShopifyManageInfluencers.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<WixInfluencerManage[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopifyManageInfluencers.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Returns ROI data for specific filter requested
 */
/**
* @package
*/
export const useGetShopifyRoiFilter = (
  queryParams: GetShopifyRoiFilterQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ManagedCampaignROI>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopifyRoiFilter.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetShopifyRoiFilter.info = (
  queryParams: GetShopifyRoiFilterQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getShopifyRoiFilter.key, queryParams] as QueryKey,
    fun: () =>
      getShopifyRoiFilter(
        queryParams,

        configOverride,
      ),
  };
};
useGetShopifyRoiFilter.prefetch = (
  client: QueryClient,
  queryParams: GetShopifyRoiFilterQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ManagedCampaignROI>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopifyRoiFilter.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * List saved ROI filters
 */
/**
* @package
*/
export const useGetShopifyRoiFilterSaved = (
  options?: SwaggerTypescriptUseQueryOptions<WixSavedRoiFilterData[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopifyRoiFilterSaved.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetShopifyRoiFilterSaved.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getShopifyRoiFilterSaved.key] as QueryKey,
    fun: () => getShopifyRoiFilterSaved(configOverride),
  };
};
useGetShopifyRoiFilterSaved.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<WixSavedRoiFilterData[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopifyRoiFilterSaved.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * current user settings
 */
/**
* @package
*/
export const useGetShopifySettings = (
  options?: SwaggerTypescriptUseQueryOptions<WixUserSettings>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopifySettings.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetShopifySettings.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getShopifySettings.key] as QueryKey,
    fun: () => getShopifySettings(configOverride),
  };
};
useGetShopifySettings.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<WixUserSettings>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetShopifySettings.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get global invite link for agent
 */
/**
* @package
*/
export const useGetSocialLoginAgentInvite_influencerAgent_uuid = (
  /**
   *
   * agent uuid
   */
  agent_uuid: string,
  options?: SwaggerTypescriptUseQueryOptions<AgentExternalInfluencerInviteDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSocialLoginAgentInvite_influencerAgent_uuid.info(
    agent_uuid,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSocialLoginAgentInvite_influencerAgent_uuid.info = (
  /**
   *
   * agent uuid
   */
  agent_uuid: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getSocialLoginAgentInvite_influencerAgent_uuid.key,
      agent_uuid,
    ] as QueryKey,
    fun: () =>
      getSocialLoginAgentInvite_influencerAgent_uuid(
        agent_uuid,

        configOverride,
      ),
  };
};
useGetSocialLoginAgentInvite_influencerAgent_uuid.prefetch = (
  client: QueryClient,

  /**
   *
   * agent uuid
   */
  agent_uuid: string,
  options?: SwaggerTypescriptUseQueryOptions<AgentExternalInfluencerInviteDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSocialLoginAgentInvite_influencerAgent_uuid.info(
    agent_uuid,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get login parameters for tiktok
 */
/**
* @package
*/
export const useGetSocialLoginAttach_socialTiktokInit = (
  options?: SwaggerTypescriptUseQueryOptions<{
    scopes:
      | "user.info.profile"
      | "video.list"
      | "user.info.stats"
      | "user.info.basic"[];
    state: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetSocialLoginAttach_socialTiktokInit.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSocialLoginAttach_socialTiktokInit.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSocialLoginAttach_socialTiktokInit.key] as QueryKey,
    fun: () => getSocialLoginAttach_socialTiktokInit(configOverride),
  };
};
useGetSocialLoginAttach_socialTiktokInit.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    scopes:
      | "user.info.profile"
      | "video.list"
      | "user.info.stats"
      | "user.info.basic"[];
    state: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetSocialLoginAttach_socialTiktokInit.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get link api invite details
 */
/**
* @package
*/
export const useGetSocialLoginExternal_registerLink_apiInvite = (
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ExternalInfluencerInviteDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSocialLoginExternal_registerLink_apiInvite.info(
    headerParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSocialLoginExternal_registerLink_apiInvite.info = (
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getSocialLoginExternal_registerLink_apiInvite.key,

      headerParams,
    ] as QueryKey,
    fun: () =>
      getSocialLoginExternal_registerLink_apiInvite(
        headerParams,
        configOverride,
      ),
  };
};
useGetSocialLoginExternal_registerLink_apiInvite.prefetch = (
  client: QueryClient,
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
  },
  options?: SwaggerTypescriptUseQueryOptions<ExternalInfluencerInviteDetails>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetSocialLoginExternal_registerLink_apiInvite.info(
    headerParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get the required permissions for facebook_instagram link
 */
/**
* @package
*/
export const useGetSocialLoginFacebook_instagramPermissions = (
  options?: SwaggerTypescriptUseQueryOptions<{ scopes?: string[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetSocialLoginFacebook_instagramPermissions.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetSocialLoginFacebook_instagramPermissions.info = (
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getSocialLoginFacebook_instagramPermissions.key] as QueryKey,
    fun: () => getSocialLoginFacebook_instagramPermissions(configOverride),
  };
};
useGetSocialLoginFacebook_instagramPermissions.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{ scopes?: string[] }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } =
    useGetSocialLoginFacebook_instagramPermissions.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get request details
 */
/**
* @package
*/
export const useGetTipaltiGenerate_external_linkRequest_id = (
  /**
   *
   * the request id
   */
  request_id: string,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: date-time
     */
    request_end_time?: string;
    /**
     *
     * - Format: date-time
     */
    request_filled_at?: string;
    request_valid?: boolean;
    url?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetTipaltiGenerate_external_linkRequest_id.info(
    request_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetTipaltiGenerate_external_linkRequest_id.info = (
  /**
   *
   * the request id
   */
  request_id: string,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getTipaltiGenerate_external_linkRequest_id.key,
      request_id,
    ] as QueryKey,
    fun: () =>
      getTipaltiGenerate_external_linkRequest_id(
        request_id,

        configOverride,
      ),
  };
};
useGetTipaltiGenerate_external_linkRequest_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the request id
   */
  request_id: string,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: date-time
     */
    request_end_time?: string;
    /**
     *
     * - Format: date-time
     */
    request_filled_at?: string;
    request_valid?: boolean;
    url?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetTipaltiGenerate_external_linkRequest_id.info(
    request_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * get app minimum versions

 */
/**
* @package
*/
export const useGetUtilsApp_min_versions = (
  options?: SwaggerTypescriptUseQueryOptions<{
    app_version?: string;
    tc_version?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUtilsApp_min_versions.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetUtilsApp_min_versions.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getUtilsApp_min_versions.key] as QueryKey,
    fun: () => getUtilsApp_min_versions(configOverride),
  };
};
useGetUtilsApp_min_versions.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    app_version?: string;
    tc_version?: number;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUtilsApp_min_versions.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * get minimum app version

 */
/**
* @package
*/
export const useGetUtilsMin_app_version = (
  options?: SwaggerTypescriptUseQueryOptions<string>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUtilsMin_app_version.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetUtilsMin_app_version.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getUtilsMin_app_version.key] as QueryKey,
    fun: () => getUtilsMin_app_version(configOverride),
  };
};
useGetUtilsMin_app_version.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<string>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUtilsMin_app_version.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * send notification about support ticket update
 */
/**
* @package
*/
export const useGetUtilsSend_support_notificationUser_id = (
  /**
   *
   * user id to notify
   */
  user_id: number,
  queryParams: GetUtilsSend_support_notificationUser_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUtilsSend_support_notificationUser_id.info(
    user_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetUtilsSend_support_notificationUser_id.info = (
  /**
   *
   * user id to notify
   */
  user_id: number,
  queryParams: GetUtilsSend_support_notificationUser_idQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [
      getUtilsSend_support_notificationUser_id.key,
      user_id,

      queryParams,
    ] as QueryKey,
    fun: () =>
      getUtilsSend_support_notificationUser_id(
        user_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetUtilsSend_support_notificationUser_id.prefetch = (
  client: QueryClient,

  /**
   *
   * user id to notify
   */
  user_id: number,
  queryParams: GetUtilsSend_support_notificationUser_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUtilsSend_support_notificationUser_id.info(
    user_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * success redirect for tipalti
 */
/**
* @package
*/
export const useGetUtilsTipaltiRequest_id = (
  /**
   *
   * the request id
   */
  request_id: string,
  queryParams?: GetUtilsTipaltiRequest_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUtilsTipaltiRequest_id.info(
    request_id,

    queryParams,
    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetUtilsTipaltiRequest_id.info = (
  /**
   *
   * the request id
   */
  request_id: string,
  queryParams?: GetUtilsTipaltiRequest_idQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getUtilsTipaltiRequest_id.key, request_id, queryParams] as QueryKey,
    fun: () =>
      getUtilsTipaltiRequest_id(
        request_id,

        queryParams,

        configOverride,
      ),
  };
};
useGetUtilsTipaltiRequest_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the request id
   */
  request_id: string,
  queryParams?: GetUtilsTipaltiRequest_idQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetUtilsTipaltiRequest_id.info(
    request_id,

    queryParams,
    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Verify user mail address
 */
/**
* @package
*/
export const useGetVerify_email = (
  queryParams: GetVerify_emailQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetVerify_email.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetVerify_email.info = (
  queryParams: GetVerify_emailQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getVerify_email.key, queryParams] as QueryKey,
    fun: () =>
      getVerify_email(
        queryParams,

        configOverride,
      ),
  };
};
useGetVerify_email.prefetch = (
  client: QueryClient,
  queryParams: GetVerify_emailQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<any>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetVerify_email.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * wix health endpoint
 */
/**
* @package
*/
export const useGetWix = (
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: int64
     */
    current_time?: number;
    message?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWix.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetWix.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getWix.key] as QueryKey,
    fun: () => getWix(configOverride),
  };
};
useGetWix.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<{
    /**
     *
     * - Format: int64
     */
    current_time?: number;
    message?: string;
  }>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWix.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * get influencer data
 */
/**
* @package
*/
export const useGetWixInfluencerInfluencer_id = (
  /**
   *
   * the influencer id to get data for
   */
  influencer_id: number,
  options?: SwaggerTypescriptUseQueryOptions<WixInfluencerData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWixInfluencerInfluencer_id.info(
    influencer_id,

    configOverride,
  );
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetWixInfluencerInfluencer_id.info = (
  /**
   *
   * the influencer id to get data for
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getWixInfluencerInfluencer_id.key, influencer_id] as QueryKey,
    fun: () =>
      getWixInfluencerInfluencer_id(
        influencer_id,

        configOverride,
      ),
  };
};
useGetWixInfluencerInfluencer_id.prefetch = (
  client: QueryClient,

  /**
   *
   * the influencer id to get data for
   */
  influencer_id: number,
  options?: SwaggerTypescriptUseQueryOptions<WixInfluencerData>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWixInfluencerInfluencer_id.info(
    influencer_id,

    configOverride,
  );

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * manage influencers
 */
/**
* @package
*/
export const useGetWixManageInfluencers = (
  options?: SwaggerTypescriptUseQueryOptions<WixInfluencerManage[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWixManageInfluencers.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetWixManageInfluencers.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getWixManageInfluencers.key] as QueryKey,
    fun: () => getWixManageInfluencers(configOverride),
  };
};
useGetWixManageInfluencers.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<WixInfluencerManage[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWixManageInfluencers.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * Returns ROI data for specific filter requested
 */
/**
* @package
*/
export const useGetWixRoiFilter = (
  queryParams: GetWixRoiFilterQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ManagedCampaignROI>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWixRoiFilter.info(queryParams, configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetWixRoiFilter.info = (
  queryParams: GetWixRoiFilterQueryParams,
  configOverride?: AxiosRequestConfig,
) => {
  return {
    key: [getWixRoiFilter.key, queryParams] as QueryKey,
    fun: () =>
      getWixRoiFilter(
        queryParams,

        configOverride,
      ),
  };
};
useGetWixRoiFilter.prefetch = (
  client: QueryClient,
  queryParams: GetWixRoiFilterQueryParams,
  options?: SwaggerTypescriptUseQueryOptions<ManagedCampaignROI>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWixRoiFilter.info(queryParams, configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * List saved ROI filters
 */
/**
* @package
*/
export const useGetWixRoiFilterSaved = (
  options?: SwaggerTypescriptUseQueryOptions<WixSavedRoiFilterData[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWixRoiFilterSaved.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetWixRoiFilterSaved.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getWixRoiFilterSaved.key] as QueryKey,
    fun: () => getWixRoiFilterSaved(configOverride),
  };
};
useGetWixRoiFilterSaved.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<WixSavedRoiFilterData[]>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWixRoiFilterSaved.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 *
 * current user settings
 */
/**
* @package
*/
export const useGetWixSettings = (
  options?: SwaggerTypescriptUseQueryOptions<WixUserSettings>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWixSettings.info(configOverride);
  return useQuery({
    queryKey: key,
    queryFn: fun,
    ...options,
  });
};
useGetWixSettings.info = (configOverride?: AxiosRequestConfig) => {
  return {
    key: [getWixSettings.key] as QueryKey,
    fun: () => getWixSettings(configOverride),
  };
};
useGetWixSettings.prefetch = (
  client: QueryClient,
  options?: SwaggerTypescriptUseQueryOptions<WixUserSettings>,
  configOverride?: AxiosRequestConfig,
) => {
  const { key, fun } = useGetWixSettings.info(configOverride);

  return client.getQueryData(key)
    ? Promise.resolve()
    : client.prefetchQuery({
        queryKey: key,
        queryFn: () => fun(),
        ...options,
      });
};

/**
 * 
 * save lesson

 */
/**
* @package
*/
export const usePostAcademyChaptersChapter_idLessonLesson_ordinal_num = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the current chapter id
       */
      chapter_id: number;
      /**
       *
       * the lesson ordinal number
       */
      lesson_ordinal_num: number;
      requestBody: { markdown_text?: string; title?: string };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        chapter_id,
        lesson_ordinal_num,
        requestBody,

        configOverride,
      } = _o || {};

      return postAcademyChaptersChapter_idLessonLesson_ordinal_num(
        chapter_id,
        lesson_ordinal_num,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * subscribe/unsubscribe to course

 */
/**
* @package
*/
export const usePostAcademyCoursesCourse_translation_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the course translation id
       */
      course_translation_id: number;
      requestBody: { subscribe?: boolean };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        course_translation_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postAcademyCoursesCourse_translation_id(
        course_translation_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Answer on test

 */
/**
* @package
*/
export const usePostAcademyQuizQuiz_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    QuizResult,
    {
      /**
       *
       * the chapter quiz id
       */
      quiz_id: string;
      requestBody: {
        answers?: {
          /**
           *
           * - Format: int32
           */
          chosen_answer?: number;
          /**
           *
           * - Format: int64
           */
          id?: number;
        }[];
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        quiz_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postAcademyQuizQuiz_id(
        quiz_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * archive or unarchive a campaign for agent
 */
/**
* @package
*/
export const usePostAgentAuctionsAuction_idArchive = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction to archive
       */
      auction_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,

        configOverride,
      } = _o || {};

      return postAgentAuctionsAuction_idArchive(
        auction_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Generate path to upload direct to storage
 */
/**
* @package
*/
export const usePostAgentGenerate_upload_path = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyFileToUploadDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postAgentGenerate_upload_path(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * move temp influencer to archive
 */
/**
* @package
*/
export const usePostAgentInfluencerArchiveInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * influencer id to add to archive
       */
      influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,

        configOverride,
      } = _o || {};

      return postAgentInfluencerArchiveInfluencer_id(
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create external influencer page uid
 */
/**
* @package
*/
export const usePostAgentInfluencerExternal_pageInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The influencer id of the page
       */
      influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,

        configOverride,
      } = _o || {};

      return postAgentInfluencerExternal_pageInfluencer_id(
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add influencer to agent management list
 */
/**
* @package
*/
export const usePostAgentInfluencerInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * influencer id to add
       */
      influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,

        configOverride,
      } = _o || {};

      return postAgentInfluencerInfluencer_id(
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * influencer response for agent invite
 */
/**
* @package
*/
export const usePostAgentInfluencerStatus = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: InfluencerAgentInviteResponse },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postAgentInfluencerStatus(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add invoice
 */
/**
* @package
*/
export const usePostAgentInvoices = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: RequestBodyExternalInvoiceData;
      queryParams?: PostAgentInvoicesQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return postAgentInvoices(requestBody, queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * Search influencer by social network
 */
/**
* @package
*/
export const usePostAgentSearch_influencer = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int64
       */
      humanz_fk?: number;
      image?: string;
      /**
       *
       * - Format: int64
       */
      influencer_id?: number;
      social_handle?: string;
      social_network?: SocialNetworkOptions;
    }[],
    {
      requestBody: {
        social_network: SocialNetworkOptions;
        /**
         *
         * - minLength: 2
         */
        username: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postAgentSearch_influencer(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create a challenge for phone
 */
/**
* @package
*/
export const usePostChallengeCreate_phone_challenge = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: { captcha?: string; phone?: string; phone_id?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postChallengeCreate_phone_challenge(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create a challenge for phone
 */
/**
* @package
*/
export const usePostChallengeCreate_social_login_challenge = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * OwnDataExtracted
         *
         */
        own_data_extracted?: {
          /**
           *
           * - Format: int64
           */
          followers?: number;
          full_name?: string;
          /**
           *
           * - Format: int64
           */
          id?: number;
          image?: string;
          username?: string;
        };
        social_id?: string;
        social_network?: "youtube" | "twitter" | "instagram" | "tiktok";
        social_username?: string;
        /**
         *
         * - Format: int64
         */
        user_id?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postChallengeCreate_social_login_challenge(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * call the influencer
 */
/**
* @package
*/
export const usePostChallengeRequest_call = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        captcha?: string;
        challenge_id?: string;
        phone_id?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postChallengeRequest_call(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * check email challenge validation
 */
/**
* @package
*/
export const usePostChallengeTest_email_challenge = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyChallengeDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postChallengeTest_email_challenge(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * check phone challenge validation
 */
/**
* @package
*/
export const usePostChallengeTest_phone_challenge = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyChallengeDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postChallengeTest_phone_challenge(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * mark seen match

 */
/**
* @package
*/
export const usePostCollaborateMatchMatch_idSeen = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The match id you want to chat with
       */
      match_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        match_id,

        configOverride,
      } = _o || {};

      return postCollaborateMatchMatch_idSeen(
        match_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Unmatch
 */
/**
* @package
*/
export const usePostCollaborateMatchMatch_idUnmatch = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The math id
       */
      match_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        match_id,

        configOverride,
      } = _o || {};

      return postCollaborateMatchMatch_idUnmatch(
        match_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * new contact us request on site, notify us with mail.

 */
/**
* @package
*/
export const usePostContact_email = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
         */
        contact_email?: string;
        message?: string;
        name?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postContact_email(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * response to invite

 */
/**
* @package
*/
export const usePostEnterprise_inviteInvite_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the invite id
       */
      invite_id: string;
      requestBody: { accept?: boolean };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        invite_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postEnterprise_inviteInvite_id(
        invite_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create email verification
 */
/**
* @package
*/
export const usePostForgot_passwordCreate_email_verification = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { queryParams: PostForgot_passwordCreate_email_verificationQueryParams },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { queryParams, configOverride } = _o || {};

      return postForgot_passwordCreate_email_verification(
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create a new password
 */
/**
* @package
*/
export const usePostForgot_passwordReset = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - Format: uuid
         */
        challenge?: string;
        /**
         *
         * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
         */
        email?: string;
        /**
         *
         * - Format: password
         */
        password?: string;
        user_type?: UserType;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postForgot_passwordReset(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add invoice
 */
/**
* @package
*/
export const usePostInvoices = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: RequestBodyExternalInvoiceData;
      headerParams?: {
        /**
         *
         * - Format: uuid
         * - minLength: 1
         */
        "auth-key": string;
        /**
         *
         * - minLength: 1
         */
        "auth-key-password": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postInvoices(
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Generate path to upload direct to storage
 */
/**
* @package
*/
export const usePostInvoicesGenerate_upload_path = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: RequestBodyFileToUploadDetails;
      headerParams?: {
        /**
         *
         * - Format: uuid
         * - minLength: 1
         */
        "auth-key": string;
        /**
         *
         * - minLength: 1
         */
        "auth-key-password": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postInvoicesGenerate_upload_path(
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Login using MOI's internal details

 */
/**
* @package
*/
export const usePostLogin = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { advertiser?: AllAdvertiserDetails; agent?: AllAgentDetails },
    { requestBody: RequestBodyLoginData },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postLogin(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Login using MOI's internal details for agent

 */
/**
* @package
*/
export const usePostLoginAgent_mimic = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { advertiser?: AllAdvertiserDetails; agent?: AllAgentDetails },
    {
      requestBody: {
        email?: string;
        /**
         *
         * - Format: int64
         */
        user_id?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postLoginAgent_mimic(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Login using MOI's internal details

 */
/**
* @package
*/
export const usePostLoginApp = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    UserSocialLoginSuccess,
    { requestBody: RequestBodyLoginData },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postLoginApp(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * First time log-in using token from email password signup

 */
/**
* @package
*/
export const usePostLoginFirst_time_login = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    UserSocialLoginSuccess,
    { requestBody: { signupToken?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postLoginFirst_time_login(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Login using MOI's internal details

 */
/**
* @package
*/
export const usePostLoginMimic = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AllAdvertiserDetails,
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        auction_id?: number;
        email?: string;
        /**
         *
         * - Format: int64
         */
        user_id?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postLoginMimic(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Login using MOI's internal details

 */
/**
* @package
*/
export const usePostLoginMimic_app = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerDetails,
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        user_id?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postLoginMimic_app(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Mimic a user by a special role, like "sales", "tagger", etc.

 */
/**
* @package
*/
export const usePostLoginMimic_role = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AllAdvertiserDetails,
    { requestBody: { role?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postLoginMimic_role(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Refresh token
 */
/**
* @package
*/
export const usePostLoginRefreshToken = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: { refresh_token?: string };
      headerParams?: { "X-MoiBackendAuthorization": string };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postLoginRefreshToken(
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add feature request
 */
/**
* @package
*/
export const usePostMoiAdd_feature_request = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    number,
    { requestBody: { country?: string; feature?: string; message?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAdd_feature_request(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Adding password to a registered verified user (via google for example)

 */
/**
* @package
*/
export const usePostMoiAdd_password = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - Format: password
         */
        password?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAdd_password(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Allow or Deny all sub users access to all lists, reports, and campaigns

 */
/**
* @package
*/
export const usePostMoiAdvertiserMini_users = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<boolean, TExtra>,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { configOverride } = _o || {};

      return postMoiAdvertiserMini_users(configOverride);
    },
    ...options,
  });
};

/**
 *
 * advertiser support request
 */
/**
* @package
*/
export const usePostMoiAdvertiser_support = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodySupportRequestDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAdvertiser_support(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * advertiser support request
 */
/**
* @package
*/
export const usePostMoiAdvertiser_supportExtended = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: SupportRequestDetailsExtended },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAdvertiser_supportExtended(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add influencer agent
 */
/**
* @package
*/
export const usePostMoiAgent = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyNewAgentInfo },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAgent(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * block app user

 */
/**
* @package
*/
export const usePostMoiApp_block_userUser_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the user id to block
       */
      user_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        user_id,

        configOverride,
      } = _o || {};

      return postMoiApp_block_userUser_id(
        user_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * add app user report

 */
/**
* @package
*/
export const usePostMoiApp_user_report = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: AppUserReport },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiApp_user_report(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create a new target
 */
/**
* @package
*/
export const usePostMoiAuctionAuction_idTargetsCreate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      requestBody: TargetEdit;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionAuction_idTargetsCreate(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update existing target
 */
/**
* @package
*/
export const usePostMoiAuctionAuction_idTargetsUpdate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      requestBody: TargetEdit;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionAuction_idTargetsUpdate(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Influencer's reminder for task
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The auction id for proposal
         */
        auction_id: number;
        /**
         *
         * The influencer id for proposal
         */
        influencer_id: number;
        /**
         *
         * The auction id for proposal
         */
        task_id: number;
        requestBody: TaskReminderData;
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          influencer_id,
          task_id,
          requestBody,

          configOverride,
        } = _o || {};

        return postMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id(
          auction_id,
          influencer_id,
          task_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * post a link to a task influencer
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idAttach_storyTask_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The task
       */
      task_id: number;
      /**
       *
       * The auction id for task
       */
      auction_id: number;
      requestBody: {
        fb_id?: string;
        /**
         *
         * - Format: uuid
         */
        id?: string;
        image?: UploadedImage;
        is_subtask?: boolean;
      };
      queryParams?: PostMoiAuctionManageAuction_idAttach_storyTask_idQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { task_id, auction_id, requestBody, queryParams, configOverride } =
        _o || {};

      return postMoiAuctionManageAuction_idAttach_storyTask_id(
        task_id,
        auction_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create custom link
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idCustom_link = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id
       */
      auction_id: number;
      requestBody: RequestBodyInfluencerCustomLink;
      queryParams?: PostMoiAuctionManageAuction_idCustom_linkQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { auction_id, requestBody, queryParams, configOverride } = _o || {};

      return postMoiAuctionManageAuction_idCustom_link(
        auction_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Complete the file upload process
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * ID of the influencer to whom the file is to be attached
       */
      influencer_id: number;
      /**
       *
       * Campaign ID to which the file is associated with the user
       */
      auction_id: number;
      /**
       *
       * UUID of the upload request (received in the GET request response)
       */
      file_uuid: string;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        auction_id,
        file_uuid,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid(
        influencer_id,
        auction_id,
        file_uuid,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add extra payout to influencer
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idInfluencer_idExtra_payout = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the influencers
       */
      influencer_id: number;
      requestBody: ExtraPayoutDetails;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idInfluencer_idExtra_payout(
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * pay for marketplace auction bid
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idPayInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    SuccessfulCharge,
    {
      /**
       *
       * The auction id of the bid you want to pay for
       */
      auction_id: number;
      /**
       *
       * The influencer id of the bid you want to pay for
       */
      influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idPayInfluencer_id(
        auction_id,
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * change recurring payment
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idPaymentsInfluencer_idChange_recurring_payment =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The id of the auction
         */
        auction_id: number;
        /**
         *
         * The id of the influencer
         */
        influencer_id: number;
        requestBody: {
          /**
           *
           * - Format: double
           */
          change_to?: number;
          change_type?: "only_this_month" | "all_months" | "next_months";
          id?: string;
        };
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          influencer_id,
          requestBody,

          configOverride,
        } = _o || {};

        return postMoiAuctionManageAuction_idPaymentsInfluencer_idChange_recurring_payment(
          auction_id,
          influencer_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * add dispute to payment
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int64
       */
      dispute_id?: number;
    },
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the influencer
       */
      influencer_id: number;
      requestBody: { dispute_reason?: string; ids?: string[] };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes(
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * stop affiliation for next month
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idPaymentsInfluencer_idStop_affiliation =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The id of the auction
         */
        auction_id: number;
        /**
         *
         * The id of the influencer
         */
        influencer_id: number;
        requestBody: { from_now?: boolean; stop?: boolean };
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          influencer_id,
          requestBody,

          configOverride,
        } = _o || {};

        return postMoiAuctionManageAuction_idPaymentsInfluencer_idStop_affiliation(
          auction_id,
          influencer_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * add upfront payment to influencer
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the influencer
       */
      influencer_id: number;
      requestBody: {
        id?: string;
        /**
         *
         * - Format: double
         */
        upfront_price?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront(
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * post a link to a task influencer
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idPost_linkTask_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The task
       */
      task_id: number;
      /**
       *
       * The auction id for task
       */
      auction_id: number;
      requestBody: {
        internal_media_id?: string;
        is_subtask?: boolean;
        post_type?: PostTypes;
        post_uri?: string;
      };
      queryParams?: PostMoiAuctionManageAuction_idPost_linkTask_idQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { task_id, auction_id, requestBody, queryParams, configOverride } =
        _o || {};

      return postMoiAuctionManageAuction_idPost_linkTask_id(
        task_id,
        auction_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Enable share all uploads for user
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idProposal_feedbackInfluencer_idShare_all_for_user =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The auction id
         */
        auction_id: number;
        /**
         *
         * The influencer id
         */
        influencer_id: number;
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          influencer_id,

          configOverride,
        } = _o || {};

        return postMoiAuctionManageAuction_idProposal_feedbackInfluencer_idShare_all_for_user(
          auction_id,
          influencer_id,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * Advertiser's response for influencer's proposal for campaign
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idProposal_feedbackProposal_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id for proposal
       */
      auction_id: number;
      /**
       *
       * The influencer's proposal id
       */
      proposal_id: number;
      requestBody: RequestBodyTaskProposalFeedbackData;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        proposal_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idProposal_feedbackProposal_id(
        auction_id,
        proposal_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Advertisers sharing or disable share of specific proposal on the system
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idProposal_feedbackProposal_idShare =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The campaign id for proposal
         */
        auction_id: number;
        /**
         *
         * The influencer's proposal id
         */
        proposal_id: number;
        requestBody: { note?: string; share?: boolean };
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          proposal_id,
          requestBody,

          configOverride,
        } = _o || {};

        return postMoiAuctionManageAuction_idProposal_feedbackProposal_idShare(
          auction_id,
          proposal_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * Disable or Enable share uploads
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idProposal_feedbackProposal_idToggle_share_uploads =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The auction id
         */
        auction_id: number;
        /**
         *
         * The proposal id
         */
        proposal_id: number;
        requestBody: { uploads?: string[] };
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          proposal_id,
          requestBody,

          configOverride,
        } = _o || {};

        return postMoiAuctionManageAuction_idProposal_feedbackProposal_idToggle_share_uploads(
          auction_id,
          proposal_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * Influencer's proposal for campaign
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idProposal_uploadTask_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int64
       */
      proposal_id?: number;
    },
    {
      /**
       *
       * The auction id for proposal
       */
      auction_id: number;
      /**
       *
       * The auction id for proposal
       */
      task_id: number;
      requestBody: RequestBodyInfluencerTaskProposalData;
      queryParams?: PostMoiAuctionManageAuction_idProposal_uploadTask_idQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { auction_id, task_id, requestBody, queryParams, configOverride } =
        _o || {};

      return postMoiAuctionManageAuction_idProposal_uploadTask_id(
        auction_id,
        task_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Influencer's proposal for campaign
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idProposal_upload_as_advertiserInfluencer_idTaskTask_id =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      {
        /**
         *
         * - Format: int64
         */
        proposal_id?: number;
      },
      {
        /**
         *
         * The auction id for proposal
         */
        auction_id: number;
        /**
         *
         * The influencer id for proposal
         */
        influencer_id: number;
        /**
         *
         * The auction id for proposal
         */
        task_id: number;
        requestBody: RequestBodyInfluencerTaskProposalData;
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          influencer_id,
          task_id,
          requestBody,

          configOverride,
        } = _o || {};

        return postMoiAuctionManageAuction_idProposal_upload_as_advertiserInfluencer_idTaskTask_id(
          auction_id,
          influencer_id,
          task_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * proposal history - route for influencer
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idProposals_history = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      current_proposal?: ProposalData;
      /**
       *
       * - Format: date
       */
      month_relevance?: string;
      old_proposals?: ProposalData[];
      post_type?: PostTypes;
      /**
       *
       * - Format: int64
       */
      subtask_of?: number;
      /**
       *
       * - Format: int64
       */
      task_id?: number;
    }[],
    {
      /**
       *
       * The auction id
       */
      auction_id: number;
      queryParams?: PostMoiAuctionManageAuction_idProposals_historyQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,

        queryParams,
        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idProposals_history(
        auction_id,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * proposal history - route for advertiser
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idProposals_historyInfluencer_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      current_proposal?: ProposalData;
      /**
       *
       * - Format: date
       */
      month_relevance?: string;
      old_proposals?: ProposalData[];
      post_type?: PostTypes;
      /**
       *
       * - Format: int64
       */
      subtask_of?: number;
      /**
       *
       * - Format: int64
       */
      task_id?: number;
    }[],
    {
      /**
       *
       * The auction id
       */
      auction_id: number;
      /**
       *
       * The influencer id you want the history for
       */
      influencer_id: number;
      queryParams?: PostMoiAuctionManageAuction_idProposals_historyInfluencer_idQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,

        queryParams,
        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idProposals_historyInfluencer_id(
        auction_id,
        influencer_id,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * query influencers
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idSearch_bids = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    number[],
    {
      /**
       *
       * the auction id to search in
       */
      auction_id: number;
      requestBody: InfluencerSearchParametersWithQuestions;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idSearch_bids(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Advertiser's response for influencer's proposal for campaign
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idSeen_column_notification = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id for proposal
       */
      auction_id: number;
      requestBody: { column_name?: string };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idSeen_column_notification(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create new tag
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idTag = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      requestBody: RequestBodyCampaignTag2;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idTag(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * stop affiliation for next month
 */
/**
* @package
*/
export const usePostMoiAuctionManageAuction_idToggle_influencers_next_month = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      requestBody: { influencers_ids?: number[]; stop?: boolean };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageAuction_idToggle_influencers_next_month(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * post in behalf of influencer
 */
/**
* @package
*/
export const usePostMoiAuctionManageBoard_idApprove_bidInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The influencer id that post in behalf
       */
      influencer_id: number;
      /**
       *
       * The board
       */
      board_id: number;
      requestBody: {
        bid_id?: number;
        express?: boolean;
        from_next_month?: boolean;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        board_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageBoard_idApprove_bidInfluencer_id(
        influencer_id,
        board_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * post in behalf of influencer
 */
/**
* @package
*/
export const usePostMoiAuctionManageBoard_idInfluencer_idPost_link = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The influencer id that post in behalf
       */
      influencer_id: number;
      /**
       *
       * The board
       */
      board_id: number;
      requestBody: LinkedTaskPost[];
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        board_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageBoard_idInfluencer_idPost_link(
        influencer_id,
        board_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create task and link to post in the same function
 */
/**
* @package
*/
export const usePostMoiAuctionManageBoard_idInfluencer_idTask_create_link = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The influencer id that post in behalf
       */
      influencer_id: number;
      /**
       *
       * The board
       */
      board_id: number;
      requestBody: TaskWithPost;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        board_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageBoard_idInfluencer_idTask_create_link(
        influencer_id,
        board_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * negotiate a bid , this mean suggest influencer another price for his job
 */
/**
* @package
*/
export const usePostMoiAuctionManageBoard_idNegotiate_bidInfluencer_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The influencer id we are goin to negotiate with
       */
      influencer_id: number;
      /**
       *
       * The board
       */
      board_id: number;
      requestBody: {
        bid_id?: number;
        from_next_month?: boolean;
        price?: number;
        task_modifications?: TaskModification;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        board_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageBoard_idNegotiate_bidInfluencer_id(
        influencer_id,
        board_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update story count
 */
/**
* @package
*/
export const usePostMoiAuctionManageBoard_idStory_view_countInfluencer_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    TaskManageData[],
    {
      /**
       *
       * The id of the board to add to
       */
      board_id: number;
      /**
       *
       * The id of the influencer to change story count
       */
      influencer_id: number;
      requestBody: StoryViewsUpload;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        board_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionManageBoard_idStory_view_countInfluencer_id(
        board_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Insert new auction
 */
/**
* @package
*/
export const usePostMoiAuctions = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: NewAuctionData; queryParams: PostMoiAuctionsQueryParams },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return postMoiAuctions(requestBody, queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * attach invoice to influencer and auction id
 */
/**
* @package
*/
export const usePostMoiAuctionsAttach_invoice = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        auction_id?: number;
        file_url?: string;
        influencer_id?: string;
        /**
         *
         * - Format: date
         */
        month?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAttach_invoice(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit Auction copuns only
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idCoupons = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to edit sales section
       */
      auction_id: number;
      requestBody: CouponInSales[];
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idCoupons(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Inject events to merger queue
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idCouponsInfluencer_idPixel_inject = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id that requested the injection
       */
      auction_id: number;
      /**
       *
       * the influencer id to inject his coupons
       */
      influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idCouponsInfluencer_idPixel_inject(
        auction_id,
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit Auction tasks
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idEdit_tasks = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to edit tasks to
       */
      auction_id: number;
      requestBody: {
        /**
         *
         * AuctionCampaignDates
         *
         */
        campaign_dates?: {
          /**
           *
           * - Format: date-time
           */
          end?: string;
          /**
           *
           * - Format: date-time
           */
          start?: string;
        };
        changed_tasks?: TaskWithId[];
        deleted_tasks?: number[];
        new_tasks?: Task[];
        tasks_payments_instructions?: TasksPaymentsInstructions;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idEdit_tasks(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit Auction instructions
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idInstructions = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to edit products to
       */
      auction_id: number;
      requestBody: DosAndDonts;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idInstructions(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit Auction kpis
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idKpis = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to edit overview to
       */
      auction_id: number;
      requestBody: EditedKPIS;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idKpis(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add tasks for influencers in campaign
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idMultiple_tasks = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { auction_data?: ManageAuctionData; failed_influencers_ids?: number[] },
    {
      /**
       *
       * the auction id
       */
      auction_id: number;
      requestBody: {
        influencers?: number[];
        /**
         *
         * - Format: date
         */
        month_relevance?: string;
        /**
         *
         * - Format: int64
         */
        price?: number;
        tasks?: TaskWithAdditonalPrice[];
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idMultiple_tasks(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit Auction Overview
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idOverview = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to edit overview to
       */
      auction_id: number;
      requestBody: EditAuctionOverview;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idOverview(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit Auction payments
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idPayment = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int64
       */
      auction_id?: number;
      /**
       *
       * - Format: date-time
       */
      expiration_time?: string;
      /**
       *
       * - Format: int64
       */
      list_id?: number;
    },
    {
      /**
       *
       * the auction id to edit payment to
       */
      auction_id: number;
      requestBody: EditAuctionPayments;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idPayment(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add auction phase
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idPixel_phases = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuctionPhase,
    {
      /**
       *
       * the auction id to get the phases for
       */
      auction_id: number;
      requestBody: RequestBodyAuctionPhase;
      queryParams?: PostMoiAuctionsAuction_idPixel_phasesQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { auction_id, requestBody, queryParams, configOverride } = _o || {};

      return postMoiAuctionsAuction_idPixel_phases(
        auction_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit Auction products
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idProduct = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to edit products to
       */
      auction_id: number;
      requestBody: EditAuctionProducts;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idProduct(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit Auction questions
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idQuestions = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to edit questions to
       */
      auction_id: number;
      requestBody: AuctionQuestions;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idQuestions(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit Auction sales
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idSales = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to edit sales section
       */
      auction_id: number;
      requestBody: AuctionSales;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idSales(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * upload file to server multipart
 */
/**
* @package
*/
export const usePostMoiAuctionsAuction_idUpload_invoice = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id
       */
      auction_id: number;
      requestBody: {
        /**
         *
         * The invoice to upload.
         * - Format: binary
         */
        file?: string;
        /**
         *
         * The invoice id
         */
        id?: string;
        /**
         *
         * The invoice month
         * - Format: date
         */
        month?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsAuction_idUpload_invoice(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * check link availability , in unfinished it will reserve this short link until created or deleted
 */
/**
* @package
*/
export const usePostMoiAuctionsCheck_link_availability = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { available?: boolean },
    { requestBody: LinkAvailableCheck },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsCheck_link_availability(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Returns ROI data for specific auction
 */
/**
* @package
*/
export const usePostMoiAuctionsIdProducts_sales_roi = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { have_more?: boolean; items?: PurchaseClicksType[] },
    {
      /**
       *
       * The auction id to return ROI to
       */
      id: number;
      requestBody: RequestBodyFiltersAndScrollProducts;
      queryParams?: PostMoiAuctionsIdProducts_sales_roiQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { id, requestBody, queryParams, configOverride } = _o || {};

      return postMoiAuctionsIdProducts_sales_roi(
        id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Restart an auction that expired and didn't complete
 */
/**
* @package
*/
export const usePostMoiAuctionsIdRevert = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int64
       */
      id?: number;
      /**
       *
       * - Format: date-time
       */
      new_expiration_time?: string;
    },
    {
      /**
       *
       * The auction id to revert
       */
      id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        id,

        configOverride,
      } = _o || {};

      return postMoiAuctionsIdRevert(
        id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Returns ROI data for specific auction
 */
/**
* @package
*/
export const usePostMoiAuctionsIdRoi_filtered = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ManagedCampaignROI,
    {
      /**
       *
       * The auction id to return ROI to
       */
      id: number;
      requestBody: CampaignReportFilterParameters;
      queryParams?: PostMoiAuctionsIdRoi_filteredQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { id, requestBody, queryParams, configOverride } = _o || {};

      return postMoiAuctionsIdRoi_filtered(
        id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Returns ROI data for specific auction
 */
/**
* @package
*/
export const usePostMoiAuctionsIdSales_roi_breakdownBreakdown = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { have_more?: boolean; items?: PurchaseClicksType[] },
    {
      /**
       *
       * The auction id to return ROI to
       */
      id: number;
      /**
       *
       * The breakdown for the sale
       */
      breakdown: string;
      requestBody: RequestBodyFiltersAndScrollProducts;
      queryParams?: PostMoiAuctionsIdSales_roi_breakdownBreakdownQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { id, breakdown, requestBody, queryParams, configOverride } =
        _o || {};

      return postMoiAuctionsIdSales_roi_breakdownBreakdown(
        id,
        breakdown,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get media image by media info
 */
/**
* @package
*/
export const usePostMoiAuctionsRoiMedia = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerWithMedias[],
    { requestBody: InfluencerWithMedias[] },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsRoiMedia(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * generate a slides report from the campaign roi
 */
/**
* @package
*/
export const usePostMoiAuctionsRoiSlides = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: ROISlidesInfo },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsRoiSlides(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Calculate Reach  & Frequency for posts from ROI
 */
/**
* @package
*/
export const usePostMoiAuctionsRoi_reach_frequencies = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      campaign?: ReachAndFrequency;
      per_influencer?: {
        /**
         *
         * - Format: int64
         */
        influencer_id?: number;
        reach_frequency?: ReachAndFrequency;
      }[];
    },
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        influencer_id?: number;
        post_id?: string;
        /**
         *
         * - Format: date-time
         */
        post_time?: string;
        post_type?: PostTypes;
        /**
         *
         * - Format: int64
         */
        reach?: number;
        social_network?: SocialNetworkOptions;
      }[];
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAuctionsRoi_reach_frequencies(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Search brand/sku for autocomplete
 */
/**
* @package
*/
export const usePostMoiAutocomplete_sales = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int64
       */
      amount?: number;
      result?: string;
    }[],
    {
      requestBody: {
        app_id?: string;
        query?: string;
        search_type?: "brand" | "sku";
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiAutocomplete_sales(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add or edit influencer age group
 */
/**
* @package
*/
export const usePostMoiChange_age_groupInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to add to
       */
      influencer_id: string;
      requestBody: { age_group?: "teen" | "child" | "adult" | "elderly" };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiChange_age_groupInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add or edit influencer category
 */
/**
* @package
*/
export const usePostMoiChange_categoriesInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to add to
       */
      influencer_id: string;
      requestBody: {
        categories?: string[];
        social_network?: "twitter" | "instagram" | "youtube" | "tiktok";
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiChange_categoriesInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add or edit influencer gender
 */
/**
* @package
*/
export const usePostMoiChange_connectionInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the influencer
       */
      influencer_id: string;
      requestBody: {
        change_to?: string;
        social_network_from?: "youtube" | "twitter" | "instagram" | "tiktok";
        social_network_to?: "youtube" | "twitter" | "instagram" | "tiktok";
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiChange_connectionInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add or edit influencer country
 */
/**
* @package
*/
export const usePostMoiChange_countryInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to add to
       */
      influencer_id: string;
      requestBody: { country?: string };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiChange_countryInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add or edit influencer gender
 */
/**
* @package
*/
export const usePostMoiChange_genderInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to add to
       */
      influencer_id: string;
      requestBody: { gender?: "female" | "male"; registered?: boolean };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiChange_genderInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Check last eligible agent worked with the influencer
 */
/**
* @package
*/
export const usePostMoiCheck_influencers_agents = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerAgentEntity[],
    { requestBody: { influencers?: number[] } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiCheck_influencers_agents(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Advertiser's response for influencer's proposal for campaign
 */
/**
* @package
*/
export const usePostMoiCity_search = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    string[],
    { requestBody: { name?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiCity_search(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * upload post to feed
 */
/**
* @package
*/
export const usePostMoiCommunity_feed = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    CommunityFeedItem,
    { requestBody: RequestBodyCreateCommunityFeedItem },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiCommunity_feed(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * only for approvers , give your response
 */
/**
* @package
*/
export const usePostMoiCommunity_feedApprovalPost_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
      requestBody: {
        comment?: string;
        is_approved?: boolean;
        send_to_wp?: boolean;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiCommunity_feedApprovalPost_id(
        post_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add comment to feed item
 */
/**
* @package
*/
export const usePostMoiCommunity_feedPost_idComments = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
      requestBody: {
        comment?: string;
        mentions?: string[];
        raw_comment?: string;
      };
      queryParams?: PostMoiCommunity_feedPost_idCommentsQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { post_id, requestBody, queryParams, configOverride } = _o || {};

      return postMoiCommunity_feedPost_idComments(
        post_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * like comment in feed
 */
/**
* @package
*/
export const usePostMoiCommunity_feedPost_idCommentsComment_idLike = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
      /**
       *
       * comment id
       */
      comment_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,
        comment_id,

        configOverride,
      } = _o || {};

      return postMoiCommunity_feedPost_idCommentsComment_idLike(
        post_id,
        comment_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * like post in feed
 */
/**
* @package
*/
export const usePostMoiCommunity_feedPost_idLike = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,

        configOverride,
      } = _o || {};

      return postMoiCommunity_feedPost_idLike(
        post_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add link click
 */
/**
* @package
*/
export const usePostMoiCommunity_feedPost_idLink_clicked = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
      requestBody: { url?: string };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiCommunity_feedPost_idLink_clicked(
        post_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add vote on poll
 */
/**
* @package
*/
export const usePostMoiCommunity_feedPost_idVote_on_poll = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
      requestBody: { option?: number };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiCommunity_feedPost_idVote_on_poll(
        post_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Add a new suggestion
 */
/**
* @package
*/
export const usePostMoiCommunity_feedSuggestionSuggestion_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: { content: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiCommunity_feedSuggestionSuggestion_id(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Apply coupons for user
 */
/**
* @package
*/
export const usePostMoiCoupons = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int32
       */
      amount_of_usages_left?: number;
      /**
       *
       * - Format: double
       */
      campaign_discount?: number;
      /**
       *
       * - pattern: ^[a-zA-Z0-9]{3,12}$
       */
      coupon?: string;
      /**
       *
       * - Format: date-time
       */
      expiration_time?: string;
      /**
       *
       * - Format: int32
       */
      max_amount_of_usages?: number;
      /**
       *
       * - Format: date-time
       */
      usage_time?: string;
      /**
       *
       * - Format: int64
       */
      used_in_auction_id?: number;
    },
    {
      requestBody: {
        /**
         *
         * - pattern: ^[a-zA-Z0-9]{3,12}$
         */
        coupon?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiCoupons(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * delete user

 */
/**
* @package
*/
export const usePostMoiDelete_userCancel = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { configOverride } = _o || {};

      return postMoiDelete_userCancel(configOverride);
    },
    ...options,
  });
};

/**
 *
 * creating new brand for advertiser
 */
/**
* @package
*/
export const usePostMoiDetailsBrand = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: EditBrandInfo },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiDetailsBrand(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * set influencer rate card
 */
/**
* @package
*/
export const usePostMoiDetailsInfluencerRate_card = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: InfluencerRateCard },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiDetailsInfluencerRate_card(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit current billing address
 */
/**
* @package
*/
export const usePostMoiEdit_detailsAdvertiserBilling = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    BillingAddress,
    { requestBody: RequestBodyBillingAddress },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiEdit_detailsAdvertiserBilling(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit current billing address
 */
/**
* @package
*/
export const usePostMoiEdit_detailsCurrent_userBilling = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    BillingAddress,
    { requestBody: RequestBodyBillingAddress },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiEdit_detailsCurrent_userBilling(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Add tax number to payout details
 */
/**
* @package
*/
export const usePostMoiEdit_detailsInfluencerBankEdit_tax_numberBank_details_id =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The id of the bank details
         */
        bank_details_id: number;
        requestBody: { tax_number?: string };
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          bank_details_id,
          requestBody,

          configOverride,
        } = _o || {};

        return postMoiEdit_detailsInfluencerBankEdit_tax_numberBank_details_id(
          bank_details_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * change user name for influencer
 */
/**
* @package
*/
export const usePostMoiEdit_detailsInfluencerUser_name = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: { user_name?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiEdit_detailsInfluencerUser_name(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add user as sub user
 */
/**
* @package
*/
export const usePostMoiEnterpriseUsersAdd_user = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      already_had_user?: boolean;
      invite_url?: string;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    },
    { requestBody: { email?: string; name?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiEnterpriseUsersAdd_user(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add user as sub user
 */
/**
* @package
*/
export const usePostMoiEnterpriseUsersChange_enterprise = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AllAdvertiserDetails,
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        id?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiEnterpriseUsersChange_enterprise(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Get information about the user from social network
 */
/**
* @package
*/
export const usePostMoiExact_username_search = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerUserDetails,
    { requestBody: { social_network?: string; username?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiExact_username_search(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Change influencer external page settings
 */
/**
* @package
*/
export const usePostMoiExternal_page_settings = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: ExternalPageSettings },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiExternal_page_settings(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get frequencies of influencers
 */
/**
* @package
*/
export const usePostMoiFrequenices = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { instagram?: Frequencies; twitter?: Frequencies },
    { requestBody: RequestBodyInfluencerIds },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiFrequenices(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Generate path to upload direct to storage
 */
/**
* @package
*/
export const usePostMoiGenerate_upload_path = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyFileToUploadDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiGenerate_upload_path(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * check media id for post url
 */
/**
* @package
*/
export const usePostMoiGet_media_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: { original_url?: string; post_type?: PostTypes } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiGet_media_id(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Search username for autocomplete
 */
/**
* @package
*/
export const usePostMoiHumanz_username_search = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int64
       */
      id?: number;
      image?: string;
      name?: string;
      username?: string;
    }[],
    { requestBody: { username?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiHumanz_username_search(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * send multi message on board
 */
/**
* @package
*/
export const usePostMoiInboxBoard_idMulti_message = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The board
       */
      board_id: number;
      requestBody: {
        /**
         *
         * ChatAttachment
         *
         */
        attachment?: {
          attachment_type?: "image" | "video" | "pdf" | "doc" | "audio";
          url?: string;
        };
        influencers?: {
          /**
           *
           * - Format: int64
           */
          agent_user_id?: number;
          /**
           *
           * - Format: int64
           */
          influencer_id?: number;
          /**
           *
           * - Format: int64
           */
          influencer_user_id?: number;
        }[];
        message?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        board_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiInboxBoard_idMulti_message(
        board_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Get messages from this chat.
Caller is advertiser then :id is influencer_id
Caller is influencer then :id is advertiser_id
Caller is agent then :id is influencer_id and :campaign_id must be set

 */
/**
* @package
*/
export const usePostMoiInboxId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    Message[],
    {
      /**
       *
       * The user id you want to chat with
       */
      id: number;
      requestBody: RequestBodyOrderedTimeFilter;
      queryParams?: PostMoiInboxIdQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { id, requestBody, queryParams, configOverride } = _o || {};

      return postMoiInboxId(id, requestBody, queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * Add attachment to chat
 */
/**
* @package
*/
export const usePostMoiInboxIdAttachment = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
 * 
 * Get messages from this chat.
Caller is advertiser then :id is influencer_id, if :agent_private_chat and :campaign_id is not null then :id is agent_id
Caller is influencer then :id is advertiser_id
Caller is agent then :id is influencer_id and :campaign_id must be set

 */
      id: number;
      requestBody: {
        /**
         *
         * The attachment type
         */
        attachment_type?: "image" | "video" | "pdf" | "doc" | "audio";
        /**
         *
         * The attachment to upload.
         * - Format: binary
         */
        file?: string;
        /**
         *
         * The inbox type
         */
        inbox_type?: "normal" | "collaborate";
        /**
         *
         * The message id
         */
        message_id?: string;
        /**
         *
         * The message id
         */
        reply_object_type?: string;
        /**
         *
         * Reply to id If the message in reply to
         */
        reply_to_id?: string;
      };
      queryParams?: PostMoiInboxIdAttachmentQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { id, requestBody, queryParams, configOverride } = _o || {};

      return postMoiInboxIdAttachment(
        id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * star chat
 */
/**
* @package
*/
export const usePostMoiInboxIdStar = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The user id you want to chat with
       */
      id: number;
      queryParams?: PostMoiInboxIdStarQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        id,

        queryParams,
        configOverride,
      } = _o || {};

      return postMoiInboxIdStar(
        id,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Get messages from this chat.
Caller is advertiser then :id is influencer_id
Caller is influencer then :id is advertiser_id
Caller is agent then :id is influencer_id and :campaign_id must be set

 */
/**
* @package
*/
export const usePostMoiInbox_with_first_timeId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: date-time
       */
      first_message_time?: string;
      /**
       *
       * MessagesArray
       *
       */
      messages?: Message[];
      /**
       *
       * - Format: int64
       */
      unread_count?: number;
    },
    {
      /**
       *
       * The user id you want to chat with
       */
      id: number;
      requestBody: RequestBodyOrderedTimeFilter;
      queryParams?: PostMoiInbox_with_first_timeIdQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { id, requestBody, queryParams, configOverride } = _o || {};

      return postMoiInbox_with_first_timeId(
        id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update details
 */
/**
* @package
*/
export const usePostMoiInfluencerUpdate_details_on_login = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: InfluencerDetailsRegsitration },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiInfluencerUpdate_details_on_login(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * endorse user
 */
/**
* @package
*/
export const usePostMoiInfluencer_profileEndorseInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the influencer id
       */
      influencer_id: number;
      requestBody: RequestBodyEndorseBody;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiInfluencer_profileEndorseInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add links to profile
 */
/**
* @package
*/
export const usePostMoiInfluencer_profilePosts_link = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyPorfileMediaAttachedInsertArray },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiInfluencer_profilePosts_link(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * un-endorse a user
 */
/**
* @package
*/
export const usePostMoiInfluencer_profileUnendorseInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the influencer id
       */
      influencer_id: number;
      requestBody: RequestBodyEndorseBody;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiInfluencer_profileUnendorseInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * mark the influencer as app rated
 */
/**
* @package
*/
export const usePostMoiInfluencer_rated_app = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { configOverride } = _o || {};

      return postMoiInfluencer_rated_app(configOverride);
    },
    ...options,
  });
};

/**
 *
 * call influencer update
 */
/**
* @package
*/
export const usePostMoiInfluencer_request_update = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<
    {
      /**
       *
       * - Format: date-time
       */
      next_date?: string;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { configOverride } = _o || {};

      return postMoiInfluencer_request_update(configOverride);
    },
    ...options,
  });
};

/**
 *
 * generates invite link to connect to social network
 */
/**
* @package
*/
export const usePostMoiInvitesExternal = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: ExternalInfluencerInviteDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiInvitesExternal(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * change tags

 */
/**
* @package
*/
export const usePostMoiMeta_tags = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    number,
    { requestBody: MetaTag },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiMeta_tags(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create a multi report
 */
/**
* @package
*/
export const usePostMoiMulti_report = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyMultiReport },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiMulti_report(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get multi report with filters
 */
/**
* @package
*/
export const usePostMoiMulti_reportReport_idReport = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ManagedCampaignROI,
    {
      /**
       *
       * The report id
       */
      report_id: number;
      requestBody: MultiReportFilterParameters;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        report_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiMulti_reportReport_idReport(
        report_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create a multi report
 */
/**
* @package
*/
export const usePostMoiMulti_reportSuggest_kpis_target = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { kpi?: EditedKPIS; target_audience?: AuctionTargetAudience },
    { requestBody: { auction_ids?: number[] } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiMulti_reportSuggest_kpis_target(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Get user notifications, filter optional

 */
/**
* @package
*/
export const usePostMoiNotifications = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      amount?: number;
      /**
       *
       * - Format: int64
       */
      auctionId?: number;
      auctionTitle?: string;
      auction_type?: string;
      /**
       *
       * - Format: date-time
       */
      creation_time?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image_uri?: string;
      notification_data?: NotificationData;
      /**
       *
       * - Format: int64
       */
      objectId?: number;
      objectTitle?: string;
      objectType?: string;
      seen?: boolean;
      /**
       *
       * - Format: int64
       */
      userId?: number;
      was_sent_before?: boolean;
    }[],
    { requestBody: TimeFilter },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiNotifications(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Request early payout
 */
/**
* @package
*/
export const usePostMoiPaymentEarly_payoutRequest = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        auction_id: number;
        /**
         *
         * - Format: uuid
         */
        deal_uuid: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPaymentEarly_payoutRequest(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Payout from moi to influencer
 */
/**
* @package
*/
export const usePostMoiPaymentPay_influencer = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        campaign_id?: number;
        /**
         *
         * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
         */
        paypal_email?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPaymentPay_influencer(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Pays for chosing an influencer
 */
/**
* @package
*/
export const usePostMoiPaymentPayment_from_advertiser = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { paypalPaymentUrl?: string },
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        auction_id?: number;
        /**
         *
         * - Format: int64
         */
        bid_id?: number;
        /**
         *
         * - Format: int64
         */
        bidder_id?: number;
        from_mobile?: boolean;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPaymentPayment_from_advertiser(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Paypal response - got back from client after redirect. on success complete payment
 */
/**
* @package
*/
export const usePostMoiPaymentPayment_from_advertiserPaypal_response = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { message?: string; transaction_success?: boolean },
    {
      requestBody: {
        item_id?: string;
        operation_success?: boolean;
        payer_id?: string;
        /**
         *
         * - Format: int64
         */
        related_bid_id?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPaymentPayment_from_advertiserPaypal_response(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * archive or unarchive a campaign
 */
/**
* @package
*/
export const usePostMoiPremiumAuctionAuction_idArchive = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction to archive
       */
      auction_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,

        configOverride,
      } = _o || {};

      return postMoiPremiumAuctionAuction_idArchive(
        auction_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create auction todo item
 */
/**
* @package
*/
export const usePostMoiPremiumAuctionAuction_idTodo = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuctionToDoItem,
    {
      /**
       *
       * The id of the auction to share
       */
      auction_id: number;
      requestBody: RequestBodyAuctionToDoItem;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumAuctionAuction_idTodo(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit auction todo item
 */
/**
* @package
*/
export const usePostMoiPremiumAuctionAuction_idTodoTo_do_idComplete = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction to share
       */
      auction_id: number;
      /**
       *
       * The to do item you want to edit
       */
      to_do_id: number;
      queryParams: PostMoiPremiumAuctionAuction_idTodoTo_do_idCompleteQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        to_do_id,

        queryParams,
        configOverride,
      } = _o || {};

      return postMoiPremiumAuctionAuction_idTodoTo_do_idComplete(
        auction_id,
        to_do_id,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create auction todo item
 */
/**
* @package
*/
export const usePostMoiPremiumAuctionAuction_idTodo_from_template = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { items?: AuctionToDoItem[] },
    {
      /**
       *
       * The id of the auction to share
       */
      auction_id: number;
      queryParams?: PostMoiPremiumAuctionAuction_idTodo_from_templateQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,

        queryParams,
        configOverride,
      } = _o || {};

      return postMoiPremiumAuctionAuction_idTodo_from_template(
        auction_id,

        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * reorder todo items in campaign
 */
/**
* @package
*/
export const usePostMoiPremiumAuctionAuction_idTodo_reorder = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuctionToDoItem,
    {
      /**
       *
       * The id of the auction to share
       */
      auction_id: number;
      requestBody: RequestBodyReorderToDoItemsTodoandorder;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumAuctionAuction_idTodo_reorder(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * search Auction content
 */
/**
* @package
*/
export const usePostMoiPremiumAuctionsAuction_idSearch_content = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerWithMedias[],
    {
      /**
       *
       * the auction id
       */
      auction_id: number;
      requestBody: RequestBodyContentSearchParameters;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumAuctionsAuction_idSearch_content(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * post in behalf of influencer
 */
/**
* @package
*/
export const usePostMoiPremiumBoard_idInfluencer_idPost_link = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The influencer id that post in behalf
       */
      influencer_id: number;
      /**
       *
       * The board
       */
      board_id: number;
      requestBody: { post_uri?: string };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        board_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumBoard_idInfluencer_idPost_link(
        influencer_id,
        board_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * modify task for specific influencer
 */
/**
* @package
*/
export const usePostMoiPremiumBoardsBoard_idModify_taskInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      couldnt_delete_tasks?: number[];
      influencer?: InfluencerInManageAuctionData;
      /**
       *
       * - Format: int64
       */
      offer_id?: number;
      tasks?: TaskWithId[];
    },
    {
      /**
       *
       * The id of the board
       */
      board_id: number;
      /**
       *
       * The influencer id
       */
      influencer_id: number;
      requestBody: RequestBodyTaskModification;
      queryParams?: PostMoiPremiumBoardsBoard_idModify_taskInfluencer_idQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        board_id,
        influencer_id,
        requestBody,
        queryParams,
        configOverride,
      } = _o || {};

      return postMoiPremiumBoardsBoard_idModify_taskInfluencer_id(
        board_id,
        influencer_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * mark approval tasks as seen
 */
/**
* @package
*/
export const usePostMoiPremiumBoardsBoard_idSeen_approval_tasksInfluencer_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the board
       */
      board_id: number;
      /**
       *
       * The influencer id
       */
      influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        board_id,
        influencer_id,

        configOverride,
      } = _o || {};

      return postMoiPremiumBoardsBoard_idSeen_approval_tasksInfluencer_id(
        board_id,
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get the list form data
 */
/**
* @package
*/
export const usePostMoiPremiumForm_dataConnect_user = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      already_in_community?: boolean;
      already_used?: boolean;
      connected?: boolean;
    },
    { requestBody: { code?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumForm_dataConnect_user(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const usePostMoiPremiumGet_influencer_dataInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      influencer_data?: InfluencerExtendedData;
      /**
       *
       * PostIdsBySocialMedia
       *
       */
      posts?: {
        instagram?: string[];
        tiktok?: string[];
        twitter?: string[];
        youtube?: string[];
      };
    },
    {
      /**
       *
       * the influencer id
       */
      influencer_id: string;
      requestBody: RequestBodyExtraDataFilter;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumGet_influencer_dataInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Advertiser general influnecer note
 */
/**
* @package
*/
export const usePostMoiPremiumInfluencer_noteInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the influencer
       */
      influencer_id: number;
      requestBody: { note?: string };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumInfluencer_noteInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * archive or unarchive a list
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idArchive = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ListSummary,
    {
      /**
       *
       * The id of the list to archive
       */
      list_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idArchive(
        list_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit lists tasks amounts
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idEdit_lists_tasks = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ListSummary,
    {
      /**
       *
       * the list id to search in
       */
      list_id: number;
      requestBody: ListTasksAmountAndPercentages;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idEdit_lists_tasks(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * toggle favorite
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idFavorite = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list
       */
      list_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idFavorite(
        list_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get the list form data
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idForm_dataMore_answers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { answers?: ListFormAnswer[] },
    {
      /**
       *
       * The id of the list to get the form answers
       */
      list_id: number;
      requestBody: {
        amount?: number;
        form_uuid?: string;
        /**
         *
         * - Format: date-time
         */
        last_seen_time?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idForm_dataMore_answers(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add or edit note
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idInfluencer_idNote = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to add to
       */
      list_id: number;
      /**
       *
       * The id of the list to add to
       */
      influencer_id: number;
      requestBody: { note?: string };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idInfluencer_idNote(
        list_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add or edit influencer price
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idInfluencer_idPrice = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ListSummary,
    {
      /**
       *
       * The id of the list to add to
       */
      list_id: number;
      /**
       *
       * The id of the list to add to
       */
      influencer_id: number;
      requestBody: {
        amounts?: TasksAmounts;
        /**
         *
         * InfluencerPriceEdit
         *
         */
        prices?: {
          /**
           *
           * - Format: int64
           */
          ig_post?: number;
          /**
           *
           * - Format: int64
           */
          ig_reel?: number;
          /**
           *
           * - Format: int64
           */
          ig_story?: number;
          /**
           *
           * - Format: int64
           */
          tk_video?: number;
          /**
           *
           * - Format: int64
           */
          tw_tweet?: number;
          /**
           *
           * - Format: int64
           */
          yt_video?: number;
        };
      };
      queryParams?: PostMoiPremiumListsList_idInfluencer_idPriceQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        influencer_id,
        requestBody,
        queryParams,
        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idInfluencer_idPrice(
        list_id,
        influencer_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * change list order

 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idInfluencers_order = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The list id
       */
      list_id: number;
      requestBody: {
        /**
         *
         * - Format: int64
         */
        influencer_id?: number;
        /**
         *
         * - Format: int32
         */
        position?: number;
      }[];
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idInfluencers_order(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * modify task for specific influencer
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idModify_taskInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      couldnt_delete_tasks?: number[];
      influencer?: InfluencerInManageAuctionData;
      /**
       *
       * - Format: int64
       */
      offer_id?: number;
      tasks?: TaskWithId[];
    },
    {
      /**
       *
       * The id of the list
       */
      list_id: number;
      /**
       *
       * The influencer id
       */
      influencer_id: number;
      requestBody: RequestBodyTaskModification;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idModify_taskInfluencer_id(
        list_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add links to profile
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idPosts_linkInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the list id
       */
      list_id: number;
      /**
       *
       * the influencer id
       */
      influencer_id: number;
      requestBody: RequestBodyPorfileMediaAttachedInsertArray;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idPosts_linkInfluencer_id(
        list_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * query influencers
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idSearch = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    number[],
    {
      /**
       *
       * the list id to search in
       */
      list_id: number;
      requestBody: RequestBodyInfluencerSearchParameters;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idSearch(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * search List content
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idSearch_content = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerWithMedias[],
    {
      /**
       *
       * the list id
       */
      list_id: number;
      requestBody: RequestBodyContentSearchParameters;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idSearch_content(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create new tag
 */
/**
* @package
*/
export const usePostMoiPremiumListsList_idTag = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list
       */
      list_id: number;
      requestBody: RequestBodyCampaignTag2;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumListsList_idTag(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add influencer to request data list
 */
/**
* @package
*/
export const usePostMoiPremiumRequest_data = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - Format: int64
         */
        influencer_id?: number;
        social_network?: SocialNetworkOptions;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumRequest_data(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * query influencers
 */
/**
* @package
*/
export const usePostMoiPremiumSearch_influencers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerShallowData[],
    {
      requestBody: RequestBodyInfluencerSearchParameters;
      queryParams?: PostMoiPremiumSearch_influencersQueryParams;
      headerParams?: { "x-no-search-depth": boolean };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, headerParams, configOverride } =
        _o || {};

      return postMoiPremiumSearch_influencers(
        requestBody,
        queryParams,
        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * query influencers
 */
/**
* @package
*/
export const usePostMoiPremiumSearch_similar_influencersInfluencer_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerShallowData[],
    {
      /**
       *
       * The id you want to search similar to
       */
      influencer_id: number;
      requestBody: {
        same_country?: boolean;
        search_type?: EmbeddingSearchType;
        with_followers?: boolean;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumSearch_similar_influencersInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * query influencers
 */
/**
* @package
*/
export const usePostMoiPremiumSearch_similar_list_influencersList_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { by_ai?: SimilarListInfluencers; by_goes_with?: SimilarListInfluencers },
    {
      /**
       *
       * The list id you want to search similar to
       */
      list_id: number;
      requestBody: {
        high_engagement?: boolean;
        high_impressions?: boolean;
        search_type?: EmbeddingSearchType;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumSearch_similar_list_influencersList_id(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create auction todo template
 */
/**
* @package
*/
export const usePostMoiPremiumTodo_templates = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    TodoTemplate,
    { requestBody: TodoTemplate },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumTodo_templates(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create auction todo item in template
 */
/**
* @package
*/
export const usePostMoiPremiumTodo_templatesTemplate_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuctionToDoItem,
    {
      /**
       *
       * The id of the template to create item in
       */
      template_id: number;
      requestBody: RequestBodyAuctionToDoItem;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        template_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumTodo_templatesTemplate_id(
        template_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create auction todo item in template
 */
/**
* @package
*/
export const usePostMoiPremiumTodo_templatesTemplate_idTodo_reorder = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the template to reorder
       */
      template_id: number;
      requestBody: RequestBodyReorderToDoItemsTodoandorder;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        template_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiPremiumTodo_templatesTemplate_idTodo_reorder(
        template_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * remove social connection - influencer
 */
/**
* @package
*/
export const usePostMoiRemove_social = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyRemoveSocialConnection },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiRemove_social(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * remove social connection - tagger
 */
/**
* @package
*/
export const usePostMoiRemove_social_connectionInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the influencer
       */
      influencer_id: string;
      requestBody: RequestBodyRemoveSocialConnection;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiRemove_social_connectionInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add new review
 */
/**
* @package
*/
export const usePostMoiReviewsInfluencerId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The campaign id to fetch
       */
      influencerId: number;
      requestBody: NewReviewData;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencerId,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiReviewsInfluencerId(
        influencerId,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * mark influencer as page
 */
/**
* @package
*/
export const usePostMoiSet_as_pageInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to add to
       */
      influencer_id: string;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,

        configOverride,
      } = _o || {};

      return postMoiSet_as_pageInfluencer_id(
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Create new shadow influencer
 */
/**
* @package
*/
export const usePostMoiShadow_influencers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: ShadowInfluencer },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiShadow_influencers(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit shadow influencer by id
 */
/**
* @package
*/
export const usePostMoiShadow_influencersShadow_influencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ShadowInfluencer,
    {
      /**
       *
       * Shadow influencer id to edit
       */
      shadow_influencer_id: number;
      requestBody: ShadowInfluencer;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        shadow_influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiShadow_influencersShadow_influencer_id(
        shadow_influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const usePostMoiSharesShare_guidNotesItem_typeNotesItem_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int64
       */
      note_id?: number;
    },
    {
      /**
       *
       * The id of the share
       */
      share_guid: string;
      /**
       *
       * The item type
       */
      item_type: string;
      /**
       *
       * The item id
       */
      item_id: number;
      requestBody: RequestBodyNoteToAdd;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        share_guid,
        item_type,
        item_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiSharesShare_guidNotesItem_typeNotesItem_id(
        share_guid,
        item_type,
        item_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * subscribe your affiliate
 */
/**
* @package
*/
export const usePostMoiSubscribe_affiliateAffiliateCode = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The affiliate code to subscribe to
       */
      affiliateCode: string;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        affiliateCode,

        configOverride,
      } = _o || {};

      return postMoiSubscribe_affiliateAffiliateCode(
        affiliateCode,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add ticket article
 */
/**
* @package
*/
export const usePostMoiSupport_centerTicket_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * ticket id to query
       */
      ticket_id: number;
      requestBody: NewSupportTicketArticle;
      queryParams?: PostMoiSupport_centerTicket_idQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { ticket_id, requestBody, queryParams, configOverride } = _o || {};

      return postMoiSupport_centerTicket_id(
        ticket_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * response to task offer
 */
/**
* @package
*/
export const usePostMoiTask_offersTask_offer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the offer
       */
      task_offer_id: number;
      requestBody: RequestBodyResponseToTaskOfferResponse;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        task_offer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiTask_offersTask_offer_id(
        task_offer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Create new unfinished campaign
 */
/**
* @package
*/
export const usePostMoiUnfinished_campaigns = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: { json_data?: string; title?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiUnfinished_campaigns(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update unfinished campaign
 */
/**
* @package
*/
export const usePostMoiUnfinished_campaignsUnfinished_campaign_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The unfinished campaign id
       */
      unfinished_campaign_id: number;
      requestBody: { image_url?: string; json_data?: string; title?: string };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        unfinished_campaign_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiUnfinished_campaignsUnfinished_campaign_id(
        unfinished_campaign_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update unfinished campaign image uploaded successfully
 */
/**
* @package
*/
export const usePostMoiUnfinished_campaignsUnfinished_campaign_idImage_uploaded =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The unfinished campaign id
         */
        unfinished_campaign_id: number;
        requestBody: {
          /**
           *
           * - Format: uuid
           */
          image_id?: string;
          image_url?: string;
        };
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          unfinished_campaign_id,
          requestBody,

          configOverride,
        } = _o || {};

        return postMoiUnfinished_campaignsUnfinished_campaign_idImage_uploaded(
          unfinished_campaign_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * upload auction image to server
 */
/**
* @package
*/
export const usePostMoiUpload_imageUpload_type = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      upload_type: AllowedImageUploadTypes;
      requestBody: {
        /**
         *
         * - Format: uuid
         */
        id?: string;
        image?: UploadedImage;
      };
      queryParams?: PostMoiUpload_imageUpload_typeQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { upload_type, requestBody, queryParams, configOverride } =
        _o || {};

      return postMoiUpload_imageUpload_type(
        upload_type,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * upload video to server multipart
 */
/**
* @package
*/
export const usePostMoiUpload_video = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * The video to upload.
         * - Format: binary
         */
        file?: string;
        /**
         *
         * The video id
         */
        id?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiUpload_video(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * user add feature feedback
 */
/**
* @package
*/
export const usePostMoiUser_feature_feedback = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: UserFeatureFeedback },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiUser_feature_feedback(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * new log from fe

 */
/**
* @package
*/
export const usePostMoiWrite_log = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: string },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postMoiWrite_log(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * trigger pixel validations
 */
/**
* @package
*/
export const usePostPixel_wizard = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    PixelEventsCheckResponse,
    {
      requestBody: PixelEventData;
      headerParams?: {
        "x-pixel-wizard-pass": string;
        /**
         *
         * - Format: uuid
         * - minLength: 1
         */
        "x-pixel-wizard-uuid": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postPixel_wizard(
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update plugin's pixel app id into pixel app id
 */
/**
* @package
*/
export const usePostPixel_wizardUpdate_platform = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - Format: uuid
         */
        search_uuid?: string;
      };
      headerParams?: {
        "x-pixel-wizard-pass": string;
        /**
         *
         * - Format: uuid
         * - minLength: 1
         */
        "x-pixel-wizard-uuid": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postPixel_wizardUpdate_platform(
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get frequencies of influencers
 */
/**
* @package
*/
export const usePostSharesAmbassador_reportFrequencies = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { instagram?: Frequencies; twitter?: Frequencies },
    { requestBody: RequestBodyInfluencerIds },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postSharesAmbassador_reportFrequencies(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Advertiser's response for influencer's proposal for campaign
 */
/**
* @package
*/
export const usePostSharesCampaignManageProposal_feedbackProposal_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The influencer's proposal id
       */
      proposal_id: number;
      requestBody: RequestBodyTaskProposalFeedbackData;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        proposal_id,
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postSharesCampaignManageProposal_feedbackProposal_id(
        proposal_id,
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const usePostSharesCampaignNotesItem_typeNotesItem_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int64
       */
      note_id?: number;
    },
    {
      /**
       *
       * The item type
       */
      item_type: string;
      /**
       *
       * The item id
       */
      item_id: number;
      requestBody: RequestBodyNoteToAdd;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        item_type,
        item_id,
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postSharesCampaignNotesItem_typeNotesItem_id(
        item_type,
        item_id,
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * proposal history - route for advertiser
 */
/**
* @package
*/
export const usePostSharesCampaignProposals_historyInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      current_proposal?: ProposalData;
      /**
       *
       * - Format: date
       */
      month_relevance?: string;
      old_proposals?: ProposalData[];
      post_type?: PostTypes;
      /**
       *
       * - Format: int64
       */
      subtask_of?: number;
      /**
       *
       * - Format: int64
       */
      task_id?: number;
    }[],
    {
      /**
       *
       * The influencer id you want the history for
       */
      influencer_id: number;
      queryParams?: PostSharesCampaignProposals_historyInfluencer_idQueryParams;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,

        queryParams,
        headerParams,
        configOverride,
      } = _o || {};

      return postSharesCampaignProposals_historyInfluencer_id(
        influencer_id,

        queryParams,
        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get shared roi - to view roi of a campaign (board)
 */
/**
* @package
*/
export const usePostSharesCampaignRoi = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ManageCampaignRoiWithParameters,
    {
      requestBody: RequestBodyCampaignReportFilterParameters;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postSharesCampaignRoi(
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * finish working on list - notify the agency/user who shared this link
 */
/**
* @package
*/
export const usePostSharesLists = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { headerParams, configOverride } = _o || {};

      return postSharesLists(headerParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const usePostSharesListsGet_influencer_dataInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      influencer_data?: InfluencerExtendedData;
      /**
       *
       * PostIdsBySocialMedia
       *
       */
      posts?: {
        instagram?: string[];
        tiktok?: string[];
        twitter?: string[];
        youtube?: string[];
      };
    },
    {
      /**
       *
       * the influencer id
       */
      influencer_id: string;
      requestBody: RequestBodyExtraDataFilter;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postSharesListsGet_influencer_dataInfluencer_id(
        influencer_id,
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const usePostSharesListsNotesItem_typeNotesItem_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: int64
       */
      note_id?: number;
    },
    {
      /**
       *
       * The item type
       */
      item_type: string;
      /**
       *
       * The item id
       */
      item_id: number;
      requestBody: RequestBodyNoteToAdd;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        item_type,
        item_id,
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postSharesListsNotesItem_typeNotesItem_id(
        item_type,
        item_id,
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * query influencers
 */
/**
* @package
*/
export const usePostSharesListsSearch = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    number[],
    {
      requestBody: RequestBodyInfluencerSearchParameters;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postSharesListsSearch(
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get shared roi - to view roi of a campaign (board)
 */
/**
* @package
*/
export const usePostSharesRoi = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ManageCampaignRoiWithParameters,
    {
      requestBody: RequestBodyCampaignReportFilterParameters;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postSharesRoi(
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Returns ROI data for specific auction
 */
/**
* @package
*/
export const usePostSharesRoiProducts_sales_roi = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { have_more?: boolean; items?: PurchaseClicksType[] },
    {
      requestBody: RequestBodyFiltersAndScrollProducts;
      queryParams?: PostSharesRoiProducts_sales_roiQueryParams;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, headerParams, configOverride } =
        _o || {};

      return postSharesRoiProducts_sales_roi(
        requestBody,
        queryParams,
        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Generate path to upload direct to storage
 */
/**
* @package
*/
export const usePostShopifyGenerate_upload_path = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyFileToUploadDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postShopifyGenerate_upload_path(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Returns ROI data for specific wix user influencers
 */
/**
* @package
*/
export const usePostShopifyRoi = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ManagedCampaignROI,
    { requestBody: RequestBodyCampaignReportFilterParameters },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postShopifyRoi(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * query influencers
 */
/**
* @package
*/
export const usePostShopifySearch_influencers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerShallowData[],
    {
      requestBody: RequestBodyInfluencerSearchParameters;
      queryParams?: PostShopifySearch_influencersQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return postShopifySearch_influencers(
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * check link availability, will not reserve this short link until created or deleted
 */
/**
* @package
*/
export const usePostShopifySettingsLink = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { available?: boolean },
    { requestBody: RequestBodyLinkAvailableCheck },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postShopifySettingsLink(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update influencer short link
 */
/**
* @package
*/
export const usePostShopifySettingsLinkInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The influencer_id
       */
      influencer_id: number;
      requestBody: RequestBodyInfluencerShortLinkUpdateData2;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postShopifySettingsLinkInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * wix user support request
 */
/**
* @package
*/
export const usePostShopifyUser_support = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodySupportRequestDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postShopifyUser_support(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Register using Humanz internal details
 */
/**
* @package
*/
export const usePostSignup = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * - Format: uuid
       */
      challenge_id?: string;
      disposable_token?: string;
      picture_url?: string;
      provider?:
        | "local"
        | "email"
        | "instagram"
        | "google"
        | "facebook"
        | "twitter"
        | "youtube"
        | "apple";
      /**
       *
       * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
       */
      user_email?: string;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
      user_kind?: UserType;
      user_name?: string;
    },
    {
      requestBody: {
        /**
         *
         * - minLength: 1
         * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
         */
        email: string;
        /**
         *
         * - Format: password
         * - minLength: 1
         */
        password: string;
        agent_details?: EditAgentDetails;
        captcha?: string;
        /**
         *
         * - Format: uuid
         */
        invite_uuid?: string;
        is_influencer?: boolean;
        user_type?: UserType;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postSignup(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Register using MOI's internal details

 */
/**
* @package
*/
export const usePostSignupFrom_influencer_challenge = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerDetails,
    { requestBody: InfluencerDetailsRegsitration },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postSignupFrom_influencer_challenge(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Login using third-party oauth provider

 */
/**
* @package
*/
export const usePostSocialLoginAdvertiser_login_with_social = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      first_time?: boolean;
      returning_user?: AllAdvertiserDetails;
      social_login_data?: SocialLoginDataExtracted;
      waiting_enterprise_invite?: string;
    },
    {
      requestBody: {
        /**
         *
         * - Format: password
         */
        access_token?: string;
        challenge_token?: string;
        profile_id?: string;
        provider?: SocialLoginProvider;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postSocialLoginAdvertiser_login_with_social(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Social api linkage on global agent invite
 */
/**
* @package
*/
export const usePostSocialLoginAgentInvite_influencerAgent_uuid = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { username?: string },
    {
      /**
       *
       * agent uuid
       */
      agent_uuid: string;
      requestBody: RequestBodySocialRegisteredLinkData;
      queryParams: PostSocialLoginAgentInvite_influencerAgent_uuidQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { agent_uuid, requestBody, queryParams, configOverride } = _o || {};

      return postSocialLoginAgentInvite_influencerAgent_uuid(
        agent_uuid,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Login using third-party oauth provider
 */
/**
* @package
*/
export const usePostSocialLoginAgent_login_with_social = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      returning_user?: AllAgentDetails;
      social_login_data?: SocialLoginDataExtracted;
      waiting_enterprise_invite?: string;
    },
    {
      requestBody: {
        /**
         *
         * - Format: password
         */
        access_token?: string;
        challenge_token?: string;
        /**
         *
         * - Format: uuid
         */
        invite_uuid?: string;
        profile_id?: string;
        provider?: SocialLoginProvider;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postSocialLoginAgent_login_with_social(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Login using third-party oauth provider

 */
/**
* @package
*/
export const usePostSocialLoginAttach_social = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * AlreadyBelongToUserDetails
       *
       */
      belong_to_user?: {
        provider?: "google" | "apple" | "facebook" | "phone" | "local";
        /**
         *
         * - Format: int64
         */
        user_id?: number;
      };
      link_suggestions?: {
        /**
         *
         * - Format: int64
         */
        followers?: number;
        image_url?: string;
        name?: string;
        profile_id?: string;
        social_network?: string;
        username?: string;
      }[];
      returning_user?: InfluencerDetails;
      social_link_attached?: MinimalSocialData;
    },
    { requestBody: RequestBodySocialRegisteredLinkData },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postSocialLoginAttach_social(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Login using third-party oauth provider

 */
/**
* @package
*/
export const usePostSocialLoginAttach_social_unregistered = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      /**
       *
       * AlreadyBelongToUserDetails
       *
       */
      belong_to_user?: {
        provider?: "google" | "apple" | "facebook" | "phone" | "local";
        /**
         *
         * - Format: int64
         */
        user_id?: number;
      };
      link_suggestions?: {
        /**
         *
         * - Format: int64
         */
        followers?: number;
        image_url?: string;
        name?: string;
        profile_id?: string;
        social_network?: string;
        username?: string;
      }[];
      returning_user?: InfluencerDetails;
      social_link_attached?: MinimalSocialData;
    },
    {
      requestBody: {
        /**
         *
         * - Format: password
         */
        access_token?: string;
        /**
         *
         * - Format: password
         */
        access_token_secret?: string;
        challenge_token?: string;
        profile_id?: string;
        provider?: "youtube" | "instagram" | "twitter" | "tiktok";
        social_challenge_id?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postSocialLoginAttach_social_unregistered(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Login using third-party oauth provider

 */
/**
* @package
*/
export const usePostSocialLoginExternal_attach_social = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        api_key?: string;
        /**
         *
         * - Format: int64
         */
        influencer_id?: number;
        is_registered?: boolean;
        profile_id?: string;
        provider?: "youtube" | "instagram" | "twitter" | "tiktok";
        social_challenge_id?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postSocialLoginExternal_attach_social(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * External Register business discovert
 */
/**
* @package
*/
export const usePostSocialLoginExternal_registerBusiness_discovery = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { id?: string },
    {
      requestBody: RequestBodyRegisterExternalInfluencerBusinessDiscoveryUsername;
      queryParams?: PostSocialLoginExternal_registerBusiness_discoveryQueryParams;
      headerParams?: {
        /**
         *
         * - Format: uuid
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, headerParams, configOverride } =
        _o || {};

      return postSocialLoginExternal_registerBusiness_discovery(
        requestBody,
        queryParams,
        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Connect external influencer
 */
/**
* @package
*/
export const usePostSocialLoginExternal_registerLink_api = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { username?: string },
    {
      requestBody: RequestBodySocialRegisteredLinkData;
      queryParams: PostSocialLoginExternal_registerLink_apiQueryParams;
      headerParams?: {
        /**
         *
         * - Format: uuid
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, headerParams, configOverride } =
        _o || {};

      return postSocialLoginExternal_registerLink_api(
        requestBody,
        queryParams,
        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * get the required permissions for facebook_instagram link
 */
/**
* @package
*/
export const usePostSocialLoginFacebook_instagramBusiness_discovery = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { id?: string },
    {
      requestBody: RequestBodyRegisterExternalInfluencerBusinessDiscoveryUsername;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postSocialLoginFacebook_instagramBusiness_discovery(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Login using third-party oauth provider

 */
/**
* @package
*/
export const usePostSocialLoginUnregistered_login_with_social = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      challenge_id?: string;
      returning_user?: InfluencerDetails;
      social_login_data?: SocialLoginDataExtracted;
    },
    {
      requestBody: {
        /**
         *
         * - Format: password
         */
        access_token?: string;
        challenge_token?: string;
        phone_id?: string;
        profile_id?: string;
        provider?: SocialLoginProvider;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postSocialLoginUnregistered_login_with_social(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * save device info

 */
/**
* @package
*/
export const usePostUtilsDevice_info = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: DeviceInfo },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postUtilsDevice_info(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * send notification about targets achievment
 */
/**
* @package
*/
export const usePostUtilsSend_targets_notification = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: TargetNotification[] },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postUtilsSend_targets_notification(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * response to task offer external (BO / Tools)
 */
/**
* @package
*/
export const usePostUtilsTask_offersTask_offer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the offer
       */
      task_offer_id: number;
      requestBody: RequestBodyResponseToTaskOfferResponse;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "x-api-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        task_offer_id,
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return postUtilsTask_offersTask_offer_id(
        task_offer_id,
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * new log from fe

 */
/**
* @package
*/
export const usePostUtilsWrite_log = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        app_window?: string;
        event_name?: string;
        /**
         *
         * - Format: double
         */
        event_value_number?: number;
        event_value_str?: string;
        /**
         *
         * - Format: int64
         */
        feed_post_id?: number;
        log_level?: "info" | "error";
        message?: string;
        method_name?: string;
        phone_id?: string;
        phone_type?: "ios" | "android";
        user_country?: string;
        /**
         *
         * - Format: int64
         */
        user_id?: number;
        version?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postUtilsWrite_log(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Generate path to upload direct to storage
 */
/**
* @package
*/
export const usePostWixGenerate_upload_path = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyFileToUploadDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postWixGenerate_upload_path(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Returns ROI data for specific wix user influencers
 */
/**
* @package
*/
export const usePostWixRoi = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ManagedCampaignROI,
    { requestBody: RequestBodyCampaignReportFilterParameters },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postWixRoi(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * query influencers
 */
/**
* @package
*/
export const usePostWixSearch_influencers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InfluencerShallowData[],
    {
      requestBody: RequestBodyInfluencerSearchParameters;
      queryParams?: PostWixSearch_influencersQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return postWixSearch_influencers(
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * check link availability, will not reserve this short link until created or deleted
 */
/**
* @package
*/
export const usePostWixSettingsLink = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { available?: boolean },
    { requestBody: RequestBodyLinkAvailableCheck },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postWixSettingsLink(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update influencer short link
 */
/**
* @package
*/
export const usePostWixSettingsLinkInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The influencer_id
       */
      influencer_id: number;
      requestBody: RequestBodyInfluencerShortLinkUpdateData2;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return postWixSettingsLinkInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * wix user support request
 */
/**
* @package
*/
export const usePostWixUser_support = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodySupportRequestDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return postWixUser_support(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Mark lesson as completed

 */
/**
* @package
*/
export const usePutAcademyChaptersChapter_idLessonLesson_ordinal_num = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the current chapter id
       */
      chapter_id: number;
      /**
       *
       * the lesson ordinal number
       */
      lesson_ordinal_num: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        chapter_id,
        lesson_ordinal_num,

        configOverride,
      } = _o || {};

      return putAcademyChaptersChapter_idLessonLesson_ordinal_num(
        chapter_id,
        lesson_ordinal_num,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Toggle the active state of an invoice
 */
/**
* @package
*/
export const usePutAgentPaymentsExternal_page = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      headerParams?: {
        /**
         *
         * - Format: uuid
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { headerParams, configOverride } = _o || {};

      return putAgentPaymentsExternal_page(headerParams, configOverride);
    },
    ...options,
  });
};

/**
 * 
 * edit influencer filter paarmeters for collaborate

 */
/**
* @package
*/
export const usePutCollaborateFilter_parameters = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: InfluencerCollaborateFilterParameters },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putCollaborateFilter_parameters(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * messages to resend

 */
/**
* @package
*/
export const usePutCollaborateInboxMatch_idResend_messages = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The match id you want to chat with
       */
      match_id: number;
      requestBody: {
        message?: string;
        message_content_type?: string;
        /**
         *
         * - Format: uuid
         */
        message_guid?: string;
        /**
         *
         * - Format: int64
         */
        send_to?: number;
      }[];
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        match_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putCollaborateInboxMatch_idResend_messages(
        match_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * remove yourself from collaborate - will remove all your matches and you won't appear on swipes
 */
/**
* @package
*/
export const usePutCollaborateOptout = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptionsVoid<any, TExtra>,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { configOverride } = _o || {};

      return putCollaborateOptout(configOverride);
    },
    ...options,
  });
};

/**
 * 
 * swipe influencer

 */
/**
* @package
*/
export const usePutCollaborateSwipe_influencerInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { limits?: SwipeLimits; match?: boolean },
    {
      /**
       *
       * The influencer id to swipe
       */
      influencer_id: number;
      requestBody: { accept?: boolean };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putCollaborateSwipe_influencerInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add new advertiser query
 */
/**
* @package
*/
export const usePutMoiAdvertiser_saved_queries = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    number,
    { requestBody: { data?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAdvertiser_saved_queries(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update influencer agent
 */
/**
* @package
*/
export const usePutMoiAgentAgent_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The agent id to update
       */
      agent_id: number;
      requestBody: RequestBodyNewAgentInfo;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        agent_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAgentAgent_id(
        agent_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add a bank account for agent
 */
/**
* @package
*/
export const usePutMoiAgentAgent_idBank = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The agent id to query
       */
      agent_id: number;
      requestBody: RequestBodyBankAccountDetails;
      queryParams?: PutMoiAgentAgent_idBankQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { agent_id, requestBody, queryParams, configOverride } = _o || {};

      return putMoiAgentAgent_idBank(
        agent_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add a bank turkish account for agent
 */
/**
* @package
*/
export const usePutMoiAgentAgent_idBankTurkey = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The agent id to query
       */
      agent_id: number;
      requestBody: RequestBodyTurkishBankAccount;
      queryParams?: PutMoiAgentAgent_idBankTurkeyQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { agent_id, requestBody, queryParams, configOverride } = _o || {};

      return putMoiAgentAgent_idBankTurkey(
        agent_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add a uk bank account for agent
 */
/**
* @package
*/
export const usePutMoiAgentAgent_idBankUk = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The agent id to query
       */
      agent_id: number;
      requestBody: RequestBodyUKBankAccount;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        agent_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAgentAgent_idBankUk(
        agent_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add a us bank account for agent
 */
/**
* @package
*/
export const usePutMoiAgentAgent_idBankUs = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ExistingUSAgentBankAccount,
    {
      /**
       *
       * The agent id to query
       */
      agent_id: number;
      requestBody: { address?: BillingAddress; ein?: string };
      queryParams?: PutMoiAgentAgent_idBankUsQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { agent_id, requestBody, queryParams, configOverride } = _o || {};

      return putMoiAgentAgent_idBankUs(
        agent_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit custom link
 */
/**
* @package
*/
export const usePutMoiAuctionManageAuction_idCustom_link = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id
       */
      auction_id: number;
      requestBody: RequestBodyInfluencerCustomLink;
      queryParams?: PutMoiAuctionManageAuction_idCustom_linkQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { auction_id, requestBody, queryParams, configOverride } = _o || {};

      return putMoiAuctionManageAuction_idCustom_link(
        auction_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * change influencer tags on managed campaign
 */
/**
* @package
*/
export const usePutMoiAuctionManageAuction_idInfluencer_idTags = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the influencers
       */
      influencer_id: number;
      requestBody: { tags?: string[] };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionManageAuction_idInfluencer_idTags(
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * put influencer into trash
 */
/**
* @package
*/
export const usePutMoiAuctionManageAuction_idInfluencer_idTrash = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the influencers
       */
      influencer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,

        configOverride,
      } = _o || {};

      return putMoiAuctionManageAuction_idInfluencer_idTrash(
        auction_id,
        influencer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * remove influencer from trash
 */
/**
* @package
*/
export const usePutMoiAuctionManageAuction_idInfluencer_idUntrash = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the influencers
       */
      influencer_id: number;
      requestBody: InfluencerInvite;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionManageAuction_idInfluencer_idUntrash(
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * invite influencers to your campaign
 */
/**
* @package
*/
export const usePutMoiAuctionManageAuction_idInvite = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      requestBody: InfluencerInvite[];
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionManageAuction_idInvite(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add agent to influencer on campaign
 */
/**
* @package
*/
export const usePutMoiAuctionManageAuction_idManage_influencerInfluencer_idAdd_agentAgent_id =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      InfluencerInManageAuctionData,
      {
        /**
         *
         * The id of the board
         */
        auction_id: number;
        /**
         *
         * The id of the influencer
         */
        influencer_id: number;
        /**
         *
         * The id of the agent
         */
        agent_id: number;
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          auction_id,
          influencer_id,
          agent_id,

          configOverride,
        } = _o || {};

        return putMoiAuctionManageAuction_idManage_influencerInfluencer_idAdd_agentAgent_id(
          auction_id,
          influencer_id,
          agent_id,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * create or edit influencer short link
 */
/**
* @package
*/
export const usePutMoiAuctionManageAuction_idShort_linkInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the influencer
       */
      influencer_id: number;
      requestBody: {
        description?: string;
        /**
         *
         * - Format: int64
         */
        extra_id?: number;
        link_type?: "campaign";
        long_link?: string;
        short_link?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionManageAuction_idShort_linkInfluencer_id(
        auction_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * change board tag
 */
/**
* @package
*/
export const usePutMoiAuctionManageAuction_idTagTag_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the tag
       */
      tag_id: number;
      requestBody: RequestBodyCampaignTag;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        tag_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionManageAuction_idTagTag_id(
        auction_id,
        tag_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
* @package
*/
export const usePutMoiAuctionManageBoard_idInfluencer_idChange_note = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the board
       */
      board_id: number;
      /**
       *
       * The id of the influencer
       */
      influencer_id: number;
      requestBody: { new_note?: string };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        board_id,
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionManageBoard_idInfluencer_idChange_note(
        board_id,
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * advertiser response for influencer negotiation
 */
/**
* @package
*/
export const usePutMoiAuctionManageBoard_idInvite_influencersInfluencer_idAdvertiser_response =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The id of the board
         */
        board_id: number;
        /**
         *
         * The id of the influencer
         */
        influencer_id: number;
        requestBody: RequestBodyInviteResponse2;
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          board_id,
          influencer_id,
          requestBody,

          configOverride,
        } = _o || {};

        return putMoiAuctionManageBoard_idInvite_influencersInfluencer_idAdvertiser_response(
          board_id,
          influencer_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * influencer response for invite
 */
/**
* @package
*/
export const usePutMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The id of the board
         */
        board_id: number;
        /**
         *
         * The id of the influencer
         */
        influencer_id: number;
        requestBody: RequestBodyInviteResponse;
        queryParams?: PutMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_responseQueryParams;
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          board_id,
          influencer_id,
          requestBody,
          queryParams,
          configOverride,
        } = _o || {};

        return putMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response(
          board_id,
          influencer_id,
          requestBody,
          queryParams,
          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * advertiser response for influencer negotiation
 */
/**
* @package
*/
export const usePutMoiAuctionManageInfluencer_idInvite_taskTask_idAdvertiser_response =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      InfluencerInManageAuctionData,
      {
        /**
         *
         * The id of the board
         */
        influencer_id: number;
        /**
         *
         * The id of the influencer
         */
        task_id: number;
        requestBody: RequestBodyInviteResponse2;
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          influencer_id,
          task_id,
          requestBody,

          configOverride,
        } = _o || {};

        return putMoiAuctionManageInfluencer_idInvite_taskTask_idAdvertiser_response(
          influencer_id,
          task_id,
          requestBody,

          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * influencer response for invite
 */
/**
* @package
*/
export const usePutMoiAuctionManageInvite_taskTask_idInfluencer_response = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the task
       */
      task_id: number;
      requestBody: RequestBodyInviteResponse;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        task_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionManageInvite_taskTask_idInfluencer_response(
        task_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Create new bid for auction
 */
/**
* @package
*/
export const usePutMoiAuctionsAuction_idBidsEdit_bid = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id to create bid for
       */
      auction_id: number;
      requestBody: RequestBodyNewBidForAuctionData;
      queryParams?: PutMoiAuctionsAuction_idBidsEdit_bidQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { auction_id, requestBody, queryParams, configOverride } = _o || {};

      return putMoiAuctionsAuction_idBidsEdit_bid(
        auction_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Add auction instruction to existing auction
 */
/**
* @package
*/
export const usePutMoiAuctionsAuction_idInstructions = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to add instructions to
       */
      auction_id: number;
      requestBody: DosAndDonts;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionsAuction_idInstructions(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * update mini users

 */
/**
* @package
*/
export const usePutMoiAuctionsAuction_idMini_users = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { auction_id: number; requestBody: RequestBodyMiniUsersIds },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionsAuction_idMini_users(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit auction phase
 */
/**
* @package
*/
export const usePutMoiAuctionsAuction_idPixel_phasesPhase_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to get the phases for
       */
      auction_id: number;
      /**
       *
       * the phase id get edit
       */
      phase_id: number;
      requestBody: RequestBodyAuctionPhase;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        phase_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionsAuction_idPixel_phasesPhase_id(
        auction_id,
        phase_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit Auction question answers for influencer
 */
/**
* @package
*/
export const usePutMoiAuctionsAuction_idQuestions_answers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the auction id to edit questions to
       */
      auction_id: number;
      requestBody: QuestionAnswer[];
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionsAuction_idQuestions_answers(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit an existing auction
 */
/**
* @package
*/
export const usePutMoiAuctionsId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id to revert
       */
      id: number;
      requestBody: {
        acquiring_product_instructions?: string;
        /**
         *
         * editAuctionAttachedBrief
         *
         */
        attached_brief?: {
          /**
           *
           * - Format: uuid
           */
          replace_with?: string;
        };
        auction_link?: AuctionLink;
        auction_type?: AuctionTypes;
        /**
         *
         * - Format: date-time
         */
        creation_time?: string;
        description?: string;
        /**
         *
         * - Format: date-time
         */
        expiration_time?: string;
        followers?: FollowersData;
        images?: {
          /**
           *
           * - Format: uuid
           */
          id?: string;
          /**
           *
           * - Format: int32
           */
          index?: number;
        }[];
        order_type?: string;
        /**
         *
         * PriceData
         *
         */
        price?: {
          /**
           *
           * - Format: double
           */
          max?: number;
          /**
           *
           * - Format: double
           */
          min?: number;
        };
        product_description?: string;
        product_included?: boolean;
        /**
         *
         * - Format: double
         */
        product_value?: number;
        should_arrive_to_business?: boolean;
        tags?: string[];
        title?: string;
        /**
         *
         * - Format: int64
         */
        user_id?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionsId(
        id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Influencer want to add task in campaign
 */
/**
* @package
*/
export const usePutMoiAuctionsIdAdd_new_task_as_an_influencer = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id to create for
       */
      id: number;
      requestBody: { post_type?: PostTypes };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiAuctionsIdAdd_new_task_as_an_influencer(
        id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Create new bid for auction as Agent for unregistered influencers
 */
/**
* @package
*/
export const usePutMoiAuctionsIdBid_invite_as_agent = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id to create bid for
       */
      id: number;
      requestBody: RequestBodyNewBidForAuctionData;
      queryParams?: PutMoiAuctionsIdBid_invite_as_agentQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { id, requestBody, queryParams, configOverride } = _o || {};

      return putMoiAuctionsIdBid_invite_as_agent(
        id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Create new bid for auction
 */
/**
* @package
*/
export const usePutMoiAuctionsIdBids = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id to create bid for
       */
      id: number;
      requestBody: RequestBodyNewBidForAuctionData;
      queryParams?: PutMoiAuctionsIdBidsQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { id, requestBody, queryParams, configOverride } = _o || {};

      return putMoiAuctionsIdBids(id, requestBody, queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * Influencer want to participate in campaign
 */
/**
* @package
*/
export const usePutMoiAuctionsIdWant_to_participate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The auction id to create bid for
       */
      id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        id,

        configOverride,
      } = _o || {};

      return putMoiAuctionsIdWant_to_participate(
        id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * hide bid

 */
/**
* @package
*/
export const usePutMoiBidsBid_idHide = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The bid id you want to hide
       */
      bid_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        bid_id,

        configOverride,
      } = _o || {};

      return putMoiBidsBid_idHide(
        bid_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * feed approver pin community post
 */
/**
* @package
*/
export const usePutMoiCommunity_feedPin_postPost_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,

        configOverride,
      } = _o || {};

      return putMoiCommunity_feedPin_postPost_id(
        post_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit feed Post
 */
/**
* @package
*/
export const usePutMoiCommunity_feedPost_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
      requestBody: RequestBodyCreateCommunityFeedItem;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiCommunity_feedPost_id(
        post_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add view to view in feed
 */
/**
* @package
*/
export const usePutMoiCommunity_feedPost_idVideo = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * post id
       */
      post_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        post_id,

        configOverride,
      } = _o || {};

      return putMoiCommunity_feedPost_idVideo(
        post_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * toggle read status of a suggestion
 */
/**
* @package
*/
export const usePutMoiCommunity_feedSuggestionSuggestion_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { is_read_updated_state?: boolean },
    {
      /**
       *
       * toggles is_read flag (true<->false)
       */
      suggestion_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        suggestion_id,

        configOverride,
      } = _o || {};

      return putMoiCommunity_feedSuggestionSuggestion_id(
        suggestion_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit brand by brand ID
 */
/**
* @package
*/
export const usePutMoiDetailsBrandBrand_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The ID of the brand to edit
       */
      brand_id: number;
      requestBody: EditBrandInfo;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        brand_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiDetailsBrandBrand_id(
        brand_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit influencer details for current logged in advertiser
 */
/**
* @package
*/
export const usePutMoiEdit_detailsAdvertiser = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      can_open_auctions?: boolean;
      finished_basic_details?: boolean;
      new_image_url?: string;
    },
    {
      requestBody: {
        about_us?: string;
        advertiser_type?: "brand" | "agency";
        categories?: string[];
        company_address?: string;
        company_country?: string;
        company_name?: string;
        contact_details?: string;
        currency?: Currency;
        /**
         *
         * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
         */
        email?: string;
        /**
         *
         * - pattern: ^(?:https?:\/\/)?(?:www\.|m\.|touch\.)?(?:facebook\.com|fb(?:\.me|\.com))\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*?(\/)?([^/?\s]*)(?:/|&|\?)?.*$
         */
        facebook_page?: string;
        full_name?: string;
        image?: UploadedImage;
        image_changed?: boolean;
        /**
         *
         * - pattern: ^(?:(?:http|https)://)?(?:www.)?(?:instagram.com|instagr.am)/([A-Za-z0-9-_]+)(/?)?(.*)
         */
        instagram_page?: string;
        invoice_email?: string;
        new_password?: string;
        old_password?: string;
        original_image_url?: string;
        phone?: string;
        show_bids_notifications?: boolean;
        /**
         *
         * - pattern: ^(?:(?:http|https)://)?(?:www.)?(?:twitter.com)/([A-Za-z0-9-_]+)(/?)?(.*)
         */
        twitter_page?: string;
        youtube_page?: string;
      };
      queryParams?: PutMoiEdit_detailsAdvertiserQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return putMoiEdit_detailsAdvertiser(
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Edit agent details for current logged in agent
 */
/**
* @package
*/
export const usePutMoiEdit_detailsAgent = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    { finished_basic_details?: boolean; humanz_verified_agent?: boolean },
    {
      requestBody: EditAgentDetails;
      queryParams?: PutMoiEdit_detailsAgentQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return putMoiEdit_detailsAgent(requestBody, queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * edit user country
 */
/**
* @package
*/
export const usePutMoiEdit_detailsCountry = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: { country?: string } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiEdit_detailsCountry(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Update terms and condition flag
 */
/**
* @package
*/
export const usePutMoiEdit_detailsCurrent_userTocUpdate = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: { flag_value?: boolean } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiEdit_detailsCurrent_userTocUpdate(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit influencer details for current logged in influencer
 */
/**
* @package
*/
export const usePutMoiEdit_detailsInfluencer = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: InfluencerDetailsEdit },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiEdit_detailsInfluencer(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add a bank account
 */
/**
* @package
*/
export const usePutMoiEdit_detailsInfluencerBank = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyBankAccountDetails },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiEdit_detailsInfluencerBank(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Add a IL bank account
 */
/**
* @package
*/
export const usePutMoiEdit_detailsInfluencerBankIl = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: ILBankAccount },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiEdit_detailsInfluencerBankIl(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Add an Indian bank account
 */
/**
* @package
*/
export const usePutMoiEdit_detailsInfluencerBankIndia = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: IndianBankAccount },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiEdit_detailsInfluencerBankIndia(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Add an Turkish bank account
 */
/**
* @package
*/
export const usePutMoiEdit_detailsInfluencerBankTurkey = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyTurkishBankAccount },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiEdit_detailsInfluencerBankTurkey(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Add a UK bank account
 */
/**
* @package
*/
export const usePutMoiEdit_detailsInfluencerBankUk = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyUKBankAccount },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiEdit_detailsInfluencerBankUk(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Add a US bank account
 */
/**
* @package
*/
export const usePutMoiEdit_detailsInfluencerBankUs = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    InitUSBankAccountSuccess,
    {
      requestBody: InitUSBankAccount;
      queryParams?: PutMoiEdit_detailsInfluencerBankUsQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return putMoiEdit_detailsInfluencerBankUs(
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit influencer password for current logged in influencer
 */
/**
* @package
*/
export const usePutMoiEdit_detailsInfluencerPassword = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: InfluencerChangePassword;
      queryParams?: PutMoiEdit_detailsInfluencerPasswordQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return putMoiEdit_detailsInfluencerPassword(
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * Add message from this chat.
Caller is advertiser then :id is influencer_id
Caller is influencer then :id is advertiser_id
Caller is agent then :id is influencer_id and :campaign_id must be set

 */
/**
* @package
*/
export const usePutMoiInboxId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The user id you want to chat with
       */
      id: number;
      requestBody: { message?: string };
      queryParams?: PutMoiInboxIdQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { id, requestBody, queryParams, configOverride } = _o || {};

      return putMoiInboxId(id, requestBody, queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * Messages to resend
 */
/**
* @package
*/
export const usePutMoiInboxIdResend_messages = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The user id you want to chat with
       */
      id: number;
      requestBody: {
        /**
         *
         * - Format: int64
         */
        campaign_id?: number;
        is_private?: boolean;
        message?: string;
        message_content_type?: string;
        /**
         *
         * - Format: uuid
         */
        message_guid?: string;
        reply_object_type?: string;
        reply_to_id?: string;
      }[];
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiInboxIdResend_messages(
        id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update influencer intro video
 */
/**
* @package
*/
export const usePutMoiInfluencer_intro_video = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        /**
         *
         * - Format: float
         */
        ratio?: number;
        thumbnail_url?: string;
        video_url?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiInfluencer_intro_video(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * update mini users allowed for reports

 */
/**
* @package
*/
export const usePutMoiInsightsReport_idMini_users = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { report_id: number; requestBody: RequestBodyMiniUsersIds },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        report_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiInsightsReport_idMini_users(
        report_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update multi report
 */
/**
* @package
*/
export const usePutMoiMulti_reportReport_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The report id
       */
      report_id: number;
      requestBody: RequestBodyMultiReport;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        report_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiMulti_reportReport_id(
        report_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * share roi for ambasador report
 */
/**
* @package
*/
export const usePutMoiMulti_reportsReport_idShare = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    PremiumListSharedLink,
    {
      /**
       *
       * The id of the auction to share
       */
      report_id: number;
      requestBody: RequestBodyMultiReportShareParameters;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        report_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiMulti_reportsReport_idShare(
        report_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * share roi for ambasador report
 */
/**
* @package
*/
export const usePutMoiMulti_reportsReport_idShareShared_guid = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    PremiumListSharedLink,
    {
      /**
       *
       * The id of the auction to share
       */
      report_id: number;
      /**
       *
       * the shared guid id
       */
      shared_guid: string;
      requestBody: RequestBodyMultiReportShareParameters;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        report_id,
        shared_guid,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiMulti_reportsReport_idShareShared_guid(
        report_id,
        shared_guid,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Mark notification as seen
 */
/**
* @package
*/
export const usePutMoiNotificationsNotificationId = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * Notification id to mark as seen
       */
      notificationId: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        notificationId,

        configOverride,
      } = _o || {};

      return putMoiNotificationsNotificationId(
        notificationId,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Register Firebase Cloud Messaging token for push notification for mobile
 */
/**
* @package
*/
export const usePutMoiNotificationsRegister_push_token = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        device_language?: "hebrew" | "english" | "turkish" | "portuguese";
        device_os?: "android" | "ios";
        phone_id?: string;
        token?: string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiNotificationsRegister_push_token(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * create a dispute for specific usage billing
 */
/**
* @package
*/
export const usePutMoiPaymentPayment_from_advertiserDispute = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        custom_message?: string;
        /**
         *
         * - Format: int64
         */
        pbi_fk?: number;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPaymentPayment_from_advertiserDispute(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * delete payment method
 */
/**
* @package
*/
export const usePutMoiPaymentPayment_from_advertiserPrimaryPayment_method_id = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The payment method to delete
       */
      payment_method_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        payment_method_id,

        configOverride,
      } = _o || {};

      return putMoiPaymentPayment_from_advertiserPrimaryPayment_method_id(
        payment_method_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Save tokenized credit card details
 */
/**
* @package
*/
export const usePutMoiPaymentPayment_from_advertiserTokenize_credit_card = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    SuccessfulCreditCardTokenization,
    {
      requestBody: {
        /**
         *
         * YaadPaySoftInitResponse
         *
         */
        init?: {
          /**
           *
           * YaadResponseData
           *
           */
          yaad_soft_response_data?: string;
          yaad_soft_response_headers?: {
            header_key?: string;
            header_value?: string;
          }[];
          /**
           *
           * YaadResponseStatusCode
           *
           * - Format: int32
           * - max: 1000
           */
          yaad_soft_status?: number;
        };
        provider?: "yaad" | "peach";
        /**
         *
         * YaadPayTokenResponse
         *
         */
        token?: {
          /**
           *
           * YaadTokenResponseData
           *
           */
          yaad_token_response_data?: string;
          yaad_token_response_headers?: {
            header_key?: string;
            header_value?: string;
          }[];
          /**
           *
           * YaadTokenResponseStatusCode
           *
           * - Format: int32
           * - max: 1000
           */
          yaad_token_status?: number;
        };
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPaymentPayment_from_advertiserTokenize_credit_card(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * pay for premium subscription
 */
/**
* @package
*/
export const usePutMoiPaymentPayment_from_advertiserUpgrade_to_premium = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      queryParams?: PutMoiPaymentPayment_from_advertiserUpgrade_to_premiumQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { queryParams, configOverride } = _o || {};

      return putMoiPaymentPayment_from_advertiserUpgrade_to_premium(
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * share roi for auction (or reanable)
 */
/**
* @package
*/
export const usePutMoiPremiumAuctionAuction_idShare = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      campaign?: PremiumListSharedLink;
      list?: PremiumListSharedLink;
      roi?: PremiumListSharedLink;
      tracker?: PremiumListSharedLink;
    },
    {
      /**
       *
       * The id of the auction to share
       */
      auction_id: number;
      requestBody: CampaignShareParameters;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumAuctionAuction_idShare(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Generate open link, inviting influencers to join a campaign
 */
/**
* @package
*/
export const usePutMoiPremiumAuctionAuction_idShareInvite_influencers = <
  TExtra,
>(
  options?: SwaggerTypescriptUseMutationOptions<
    string,
    {
      /**
       *
       * The id of the auction to share
       */
      auction_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,

        configOverride,
      } = _o || {};

      return putMoiPremiumAuctionAuction_idShareInvite_influencers(
        auction_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit auction todo item
 */
/**
* @package
*/
export const usePutMoiPremiumAuctionAuction_idTodoTo_do_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuctionToDoItem,
    {
      /**
       *
       * The id of the auction to share
       */
      auction_id: number;
      /**
       *
       * The to do item you want to edit
       */
      to_do_id: number;
      requestBody: RequestBodyAuctionToDoItem;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        to_do_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumAuctionAuction_idTodoTo_do_id(
        auction_id,
        to_do_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 * 
 * update mini users

 */
/**
* @package
*/
export const usePutMoiPremiumListList_idMini_users = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { list_id: number; requestBody: RequestBodyMiniUsersIds },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumListList_idMini_users(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Create new list for this user
 */
/**
* @package
*/
export const usePutMoiPremiumLists = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: {
        description?: string;
        name?: string;
        upload_image?: UploadedImage;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumLists(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add to list
 */
/**
* @package
*/
export const usePutMoiPremiumListsAdd_to_lists = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    {
      changed_ids?: { new_id?: string; old_id?: string }[];
      lists_summary?: ListSummary[];
    },
    {
      requestBody: {
        influencers?: string[];
        /**
         *
         * - Format: int64
         */
        list_id?: number;
      }[];
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumListsAdd_to_lists(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add to list
 */
/**
* @package
*/
export const usePutMoiPremiumListsList_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ListSummary,
    {
      /**
       *
       * The id of the list to add to
       */
      list_id: number;
      requestBody: RequestBodyUsersToOperate;
      queryParams?: PutMoiPremiumListsList_idQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { list_id, requestBody, queryParams, configOverride } = _o || {};

      return putMoiPremiumListsList_id(
        list_id,
        requestBody,
        queryParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit list
 */
/**
* @package
*/
export const usePutMoiPremiumListsList_idEdit = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list to edit
       */
      list_id: number;
      requestBody: {
        description?: string;
        name?: string;
        upload_image?: UploadedImage;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumListsList_idEdit(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * remove users from list
 */
/**
* @package
*/
export const usePutMoiPremiumListsList_idRemove_influencers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ListSummary,
    {
      /**
       *
       * The id of the list to operate on
       */
      list_id: number;
      requestBody: RequestBodyUsersToOperate;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumListsList_idRemove_influencers(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * share list (or reanable)
 */
/**
* @package
*/
export const usePutMoiPremiumListsList_idShare = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    PremiumListSharedLink,
    {
      /**
       *
       * The id of the list to share
       */
      list_id: number;
      requestBody: ShareListParameters;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumListsList_idShare(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * hide/unhide users on list
 */
/**
* @package
*/
export const usePutMoiPremiumListsList_idSwitch_hide_influencers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ListSummary,
    {
      /**
       *
       * The id of the list to operate on
       */
      list_id: number;
      requestBody: RequestBodyUsersToOperate;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumListsList_idSwitch_hide_influencers(
        list_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * change board tag
 */
/**
* @package
*/
export const usePutMoiPremiumListsList_idTagTag_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the list
       */
      list_id: number;
      /**
       *
       * The id of the tag
       */
      tag_id: number;
      requestBody: RequestBodyCampaignTag;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        list_id,
        tag_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumListsList_idTagTag_id(
        list_id,
        tag_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * share roi for auction (or reanable)
 */
/**
* @package
*/
export const usePutMoiPremiumRoiAuction_idShare = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    PremiumListSharedLink,
    {
      /**
       *
       * The id of the auction to share
       */
      auction_id: number;
      requestBody: ShareReportParameters;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumRoiAuction_idShare(
        auction_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * edit auction todo item
 */
/**
* @package
*/
export const usePutMoiPremiumTodo_templatesTemplate_idTodoTo_do_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    AuctionToDoItem,
    {
      /**
       *
       * The id of the template to edit
       */
      template_id: number;
      /**
       *
       * The to do item you want to edit
       */
      to_do_id: number;
      requestBody: RequestBodyAuctionToDoItem;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        template_id,
        to_do_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putMoiPremiumTodo_templatesTemplate_idTodoTo_do_id(
        template_id,
        to_do_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * pin or unpin specific ticket
 */
/**
* @package
*/
export const usePutMoiSupport_centerTicket_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * ticket id to query
       */
      ticket_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        ticket_id,

        configOverride,
      } = _o || {};

      return putMoiSupport_centerTicket_id(
        ticket_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update task offer
 */
/**
* @package
*/
export const usePutMoiTask_offersTask_offer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the offer
       */
      task_offer_id: number;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        task_offer_id,

        configOverride,
      } = _o || {};

      return putMoiTask_offersTask_offer_id(
        task_offer_id,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * update user wizard status to complete \ uncomplete
 */
/**
* @package
*/
export const usePutPixel_wizard = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: { status: boolean };
      headerParams?: {
        "x-pixel-wizard-pass": string;
        /**
         *
         * - Format: uuid
         * - minLength: 1
         */
        "x-pixel-wizard-uuid": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return putPixel_wizard(
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add or edit note
 */
/**
* @package
*/
export const usePutSharesListsInfluencer_idNote = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * the influencer to operate on
       */
      influencer_id: number;
      requestBody: { note?: string };
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return putSharesListsInfluencer_idNote(
        influencer_id,
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * approve or disaprove influencer
 */
/**
* @package
*/
export const usePutSharesListsInfluencer_idResponse = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    ListSummary,
    {
      /**
       *
       * the influencer to operate on
       */
      influencer_id: number;
      requestBody: { approve?: boolean };
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "auth-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return putSharesListsInfluencer_idResponse(
        influencer_id,
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add influencers to user
 */
/**
* @package
*/
export const usePutShopifyManageInfluencers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: { influencers?: number[] } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putShopifyManageInfluencers(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add media influencer
 */
/**
* @package
*/
export const usePutShopifyManageInfluencersMediaInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    WixInfluencerManage,
    {
      /**
       *
       * the influencer id
       */
      influencer_id: number;
      requestBody: {
        deleted_media?: WixInfluencerMedia[];
        deleted_stories?: WixStoryView[];
        new_media?: WixInfluencerMedia[];
        new_stories?: WixStoryView[];
        updated_media?: WixInfluencerMedia[];
        updated_stories?: WixStoryView[];
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putShopifyManageInfluencersMediaInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Save ROI filter
 */
/**
* @package
*/
export const usePutShopifyRoiFilter = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: RequestBodyCampaignReportFilterParameters;
      queryParams: PutShopifyRoiFilterQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return putShopifyRoiFilter(requestBody, queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * update user settings
 */
/**
* @package
*/
export const usePutShopifySettings = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyWixUserSettings },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putShopifySettings(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * influencer response for invite
 */
/**
* @package
*/
export const usePutUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response =
  <TExtra>(
    options?: SwaggerTypescriptUseMutationOptions<
      any,
      {
        /**
         *
         * The id of the board
         */
        board_id: number;
        /**
         *
         * The id of the influencer
         */
        influencer_id: number;
        requestBody: RequestBodyInviteResponse;
        queryParams?: PutUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_responseQueryParams;
        headerParams?: {
          /**
           *
           * - minLength: 1
           */
          "x-api-key": string;
        };
      },
      TExtra
    >,
  ) => {
    return useMutation({
      mutationFn: (_o) => {
        const {
          board_id,
          influencer_id,
          requestBody,
          queryParams,
          headerParams,
          configOverride,
        } = _o || {};

        return putUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response(
          board_id,
          influencer_id,
          requestBody,
          queryParams,
          headerParams,
          configOverride,
        );
      },
      ...options,
    });
  };

/**
 *
 * Response on behalf of a influencer for task invite
 */
/**
* @package
*/
export const usePutUtilsTask_invite_responseInfluencer_idTask_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the task
       */
      task_id: number;
      /**
       *
       * The id of the influencer
       */
      influencer_id: number;
      requestBody: RequestBodyInviteResponse;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "x-api-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        task_id,
        influencer_id,
        requestBody,

        headerParams,
        configOverride,
      } = _o || {};

      return putUtilsTask_invite_responseInfluencer_idTask_id(
        task_id,
        influencer_id,
        requestBody,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Response on behalf of a influencer for task invite
 */
/**
* @package
*/
export const usePutUtilsTrash_influencerAuction_idInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      /**
       *
       * The id of the auction
       */
      auction_id: number;
      /**
       *
       * The id of the influencer
       */
      influencer_id: number;
      headerParams?: {
        /**
         *
         * - minLength: 1
         */
        "x-api-key": string;
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        auction_id,
        influencer_id,

        headerParams,
        configOverride,
      } = _o || {};

      return putUtilsTrash_influencerAuction_idInfluencer_id(
        auction_id,
        influencer_id,

        headerParams,
        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add influencers to user
 */
/**
* @package
*/
export const usePutWixManageInfluencers = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: { influencers?: number[] } },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putWixManageInfluencers(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * add media influencer
 */
/**
* @package
*/
export const usePutWixManageInfluencersMediaInfluencer_id = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    WixInfluencerManage,
    {
      /**
       *
       * the influencer id
       */
      influencer_id: number;
      requestBody: {
        deleted_media?: WixInfluencerMedia[];
        deleted_stories?: WixStoryView[];
        new_media?: WixInfluencerMedia[];
        new_stories?: WixStoryView[];
        updated_media?: WixInfluencerMedia[];
        updated_stories?: WixStoryView[];
      };
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        influencer_id,
        requestBody,

        configOverride,
      } = _o || {};

      return putWixManageInfluencersMediaInfluencer_id(
        influencer_id,
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};

/**
 *
 * Save ROI filter
 */
/**
* @package
*/
export const usePutWixRoiFilter = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    {
      requestBody: RequestBodyCampaignReportFilterParameters;
      queryParams: PutWixRoiFilterQueryParams;
    },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const { requestBody, queryParams, configOverride } = _o || {};

      return putWixRoiFilter(requestBody, queryParams, configOverride);
    },
    ...options,
  });
};

/**
 *
 * update user settings
 */
/**
* @package
*/
export const usePutWixSettings = <TExtra>(
  options?: SwaggerTypescriptUseMutationOptions<
    any,
    { requestBody: RequestBodyWixUserSettings },
    TExtra
  >,
) => {
  return useMutation({
    mutationFn: (_o) => {
      const {
        requestBody,

        configOverride,
      } = _o || {};

      return putWixSettings(
        requestBody,

        configOverride,
      );
    },
    ...options,
  });
};
