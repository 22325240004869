// TODO - lint
/* eslint-disable import/order, react/no-deprecated, react/no-deprecated, class-methods-use-this, prefer-destructuring, no-underscore-dangle, no-shadow, consistent-return, consistent-return, consistent-return, consistent-return, no-underscore-dangle, class-methods-use-this, react/no-direct-mutation-state, react/no-direct-mutation-state, class-methods-use-this, react/jsx-no-target-blank, react/jsx-no-target-blank, react/jsx-no-target-blank, no-nested-ternary, no-return-assign, no-underscore-dangle, camelcase, camelcase, camelcase, camelcase, no-param-reassign, camelcase, no-useless-escape, react/jsx-no-target-blank, react/jsx-no-target-blank, import/no-default-export */
import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import moment from "moment";
import Tooltip from "rc-tooltip";
import React from "react";
import ReactLinkify from "react-linkify";
import Recorder from "react-mp3-recorder";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { uploadMedia } from "src/actions/mediaActions";
import HumanzButtonGroup from "src/ui/humanz/HumanzButtonGroup";
import uuidv4 from "uuid/v4";
import {
  MAX_MESSAGES_AMOUNT,
  changeStarChat,
  exitChat,
  resetFirstTimeMessage,
  retrySendMessage,
  sendFileMessageWithSocket,
  sendNewMessage,
  updateChatMessages,
} from "../../../actions/inboxActions";
import { DeleteChatMessage } from "../../../api/actions/chatActions";
import { MessageDialog } from "../dialogs/MessageDialog";
import NoDataMessage from "../general_components/NoDataMessage";
import ChatInput from "./ChatInput";
import ChatMessages from "./ChatMessages";
import Message from "./Message";
import StartNewChat from "./StartNewChat";

const REPLY_ENABLED = false;
class ChatSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChat: {},
      selectedChatCampaignId: props.selectedChatCampaignId,
      selectedChatInfluencerId: props.selectedChatInfluencerId,
      chatLoading: false,
      scrollDown: true,
      innerChat: {},
      textAreaHeight: 32,
      replyImage: null,
      imageToSend: null,
      sending: false,
      showEmojiPicker: false,
      message: "",
      chatDetails: {},
      audioBlob: null,
    };
    this.system_message_to_label = {
      1: "system_message_bid_on_auction_",
      2: "system_message_selected_for_campaign_",
      3: "system_message_selected_for_express_campaign_",
      4: "system_message_proposal_created_",
      5: "system_message_proposal_approved_",
      6: "system_message_proposal_declined_",
      7: "system_message_post_linked_",
    };

    this.columns = [
      { label: "BIDS", name: "bids" },
      { label: "INVITES", name: "wait_influencer" },
      {
        label: "APPROVAL",
        name: "wait_approval",
      },
      { label: "PUBLISH", name: "done" },
      { label: "TRASHED", name: "trash" },
    ];
  }

  componentWillMount() {
    this.initChatTimers(true);
    this.initChatDetails();
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.selectedChatCampaignId !== this.state.selectedChatCampaignId ||
      newProps.selectedChatInfluencerId !==
        this.state.selectedChatInfluencerId ||
      newProps.innerChat !== this.state.innerChat
    ) {
      this.setState(
        {
          selectedChatCampaignId: newProps.selectedChatCampaignId,
          selectedChatInfluencerId: newProps.selectedChatInfluencerId,
          chatDetails: {},
        },
        () => {
          this.initChatDetails();
        },
      );
    }
  }

  initChatDetails() {
    this.setState(
      {
        selectedChat:
          this.props.inbox?.users_chats?.influencers?.filter(
            (chat) =>
              chat?.influencer_id === this.state.selectedChatInfluencerId &&
              (this.props.isPrivate
                ? chat?.agent?.is_private === this.props.isPrivate
                : !chat?.agent?.is_private),
          )?.[0] || [],
      },
      () => {
        this.setState({ chatDetails: this.state.selectedChat });
      },
    );
    // if (this.state.selectedChatInfluencerId) {
    //   getInfluencerChat(
    //     this.state.selectedChatInfluencerId,
    //     this.state.selectedChatCampaignId,
    //     (res) => {
    //       if (res && res.influencers && res.influencers.length === 1) {
    //         this.setState({ chatDetails: res.influencers[0] });
    //       }
    //     },
    //   );
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.scrollDown !== this.state.scrollDown) return;

    if (this.state.scrollDown === true) {
      this.handleScrollDown();
    }
  }

  handleScrollDown() {
    window.setTimeout(() => {
      const objDiv = document.getElementById("chat_container");
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }, 500);
  }

  componentWillUnmount() {
    this.initChatTimers(false);
    this.props.exitChat(
      this.state.selectedChatInfluencerId,
      this.state.selectedChatCampaignId,
    );
  }

  initChatTimers(start) {
    if (this.unSeenTimer) {
      clearInterval(this.unSeenTimer);
    }
    // if (this.reloadChatTimer) {
    //     clearInterval(this.reloadChatTimer)
    // }
    if (start) {
      this.unSeenTimer = setInterval(this.checkUnsendMessages.bind(this), 4000);
      // this.reloadChatTimer = setInterval(this.getLastMessages.bind(this), 10000);
    }
  }

  checkUnsendMessages() {
    const { selectedChatInfluencerId, selectedChatCampaignId } = this.state;
    const messages = this.props.inbox.chat_messages.filter(
      (x) => x.sending && new Date() - x.creation_time > 3500,
    );
    if (messages.length > 0) {
      this.props.retrySendMessage(
        selectedChatInfluencerId,
        messages.map((x) => {
          return {
            message_guid: x.id,
            message: x.message,
            message_content_type: x.message_content_type || "text",
            campaign_id: selectedChatCampaignId,
            reply_to_id: x.reply_to_id,
            reply_object_type: x.reply_to_type,
            influencer_user_id: this.state.selectedChat?.influencer_id,
            is_private: this.state.selectedChat?.agent?.is_private,
            agent_id: this.state.selectedChat?.agent?.agent_id,
          };
        }),
      );
    }
  }

  //   this.state.selectedChatInfluencerId,
  // campaignId,
  // value,
  // "NORMAL",
  // message.id,
  // message.reply_to_id,
  // message.reply_to_type,
  // message.reply_to_content,
  // null,
  // this.state.selectedChat.influencer_id,
  // this.state.selectedChat.is_private,
  // this.state.selectedChat.agentId,

  getLastMessages() {
    const { selectedChatInfluencerId, selectedChatCampaignId } = this.state;
    let message = this.props.inbox.chat_messages.filter((x) => !x.mine);
    if (message.length > 0) {
      message = message[0];
      if (message && message.creation_time) {
        this.props.updateChatMessages(
          selectedChatInfluencerId,
          selectedChatCampaignId,
          new Date(message.creation_time).toISOString(),
          null,
          30,
          null,
          "desc",
        );
      }
    }
  }

  handleKeyPress = (e) => {
    if (e.nativeEvent.keyCode === 13 && !e.nativeEvent.shiftKey) {
      this.sendNewMessage(e);
    }
  };

  sendNewMessage = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (this.state.file) {
      this.sendFileMessage();
      return;
    }
    const value = this.state.message?.trim();
    if (value === "" || value === null || value === undefined) return;

    const campaignId =
      this.state.selectedChatCampaignId === -1
        ? null
        : this.state.selectedChatCampaignId;
    const message = {
      id: uuidv4(),
      message: value,
      creation_time: new Date(),
      mine: true,
      seen: false,
      seen_at: null,
      is_system_message: false,
      message_content_type: "text",
      reply_to_id: this.state.replyImage
        ? this.state.replyImage.upload_id
        : this.state.messageForReply?.id,
      reply_to_type: this.state.replyImage ? "task_upload" : "message",
      reply_to_content: this.state.replyImage
        ? this.state.replyImage.upload_url
        : this.state.messageForReply?.message,
      reply_message_time:
        this.state.messageForReply || this.state.replyImage
          ? moment().utc().format()
          : null,
      sending: true,
      influencerUserId: this.state.selectedChat?.influencer_id,
      isPrivate: this.state.selectedChat?.agent?.is_private,
      agentId: this.state.selectedChat?.agent?.agent_id,
      reply: this.state.replyImage
        ? // If it's a proposal reply to
          {
            reply_to_id: this.state.replyImage?.upload_id,
            content: this.state.replyImage.upload_url,
            content_type: this.state.replyImage.upload_type,
            sender_fk: this.props.user.id,
            sender_user_type: "advertiser",
          }
        : // If it's a regular reply to
        !this.state.messageForReply
        ? // If it's a regular message - send null
          null
        : {
            reply_to_id: this.state.messageForReply?.id,
            content: this.state.messageForReply?.message,
            content_type: this.state.messageForReply?.message_content_type,
            sender_fk: this.state.messageForReply.mine
              ? this.state.selectedChat?.user_id
              : this.state.messageForReply.from_user_type === "agent"
              ? this.state.selectedChat?.agent?.agent_id
              : this.state.selectedChat?.influencer_id,
            sender_user_type:
              this.state.messageForReply?.from_user ===
              this.state.selectedChat?.user_id
                ? "advertiser"
                : this.state.messageForReply?.from_user ===
                  this.state.selectedChat?.agent?.agent_id
                ? "agent"
                : "influencer",
          },
    };
    this.props.inbox.chat_messages.push(message);

    this.setState(
      {
        scrollDown: true,
        replyImage: null,
        message: "",
        isSendingRecord: false,
        messageForReply: null,
      },
      () => {
        sendNewMessage(
          this.state.selectedChatInfluencerId,
          campaignId,
          value,
          "NORMAL",
          message.id,
          message.reply_to_id,
          message.reply_to_type,
          message.reply_to_content,
          message.reply_message_time,
          this.state.selectedChat?.user_id,
          this.props.isPrivate,
          this.state.selectedChat?.agent?.agent_id,
          this.state.selectedChat?.influencer_id,
          message.reply,
          this.updateChat.bind(this),
        );
      },
    );
  };

  updateChat(messageToSend) {
    let from;
    if (this.props.inbox.chat_messages.length > 0) {
      from =
        this.props.inbox.chat_messages[
          this.props.inbox.chat_messages.length - 1
        ].creation_time;
    } else {
      from = new Date();
      from.setDate(from.getDate() - 1);
    }
    const date = new Date();
    date.setDate(date.getDate() + 1 / 24);
    // this.props.updateChatMessages(this.state.selectedChatId,from,date,10,null,"desc");
    // this._newMessageContent?.current?.focus();
    document.getElementById("chat-textarea")?.focus();
    this.props.updateMessageSent(messageToSend);

    this.handleScrollDown();
  }

  getMoreMessages = () => {
    const campaignId =
      this.state.selectedChatCampaignId === -1
        ? null
        : this.state.selectedChatCampaignId;

    const time = this.props.inbox.chat_messages[0].creation_time;
    const objDiv = document.getElementById("chat_container");
    const currentScrollHeihgt = objDiv.scrollHeight;
    this.setState({ scrollDown: false, updateMessagesLoader: true }, () => {
      this.props.updateChatMessages(
        this.state.selectedChatInfluencerId,
        campaignId,
        null,
        time,
        MAX_MESSAGES_AMOUNT,
        null,
        "desc",
        (success) => {
          if (success) {
            const objDiv = document.getElementById("chat_container");
            objDiv.scrollTop = objDiv.scrollHeight - currentScrollHeihgt;
            this.setState({ updateMessagesLoader: false });
          } else {
            this.setState({ updateMessagesLoader: false });
          }
        },
      );
    });
  };

  checkLoadMore() {
    if (!this.state?.chatDetails?.influencer_id) return;
    if (
      !this.props.inbox?.chat_messages ||
      this.props.inbox.chat_messages.length < MAX_MESSAGES_AMOUNT
    ) {
      return false;
    }
    let firstMessageTime = this.props.inbox?.chat_messages?.first_message_time;
    if (!firstMessageTime) {
      return false;
    }
    firstMessageTime = new Date(firstMessageTime);

    if (
      firstMessageTime.getTime() ===
      new Date(this.props.inbox.chat_messages[0].creation_time).getTime()
    ) {
      return false;
    }
    return true;
  }

  getStatusLabel(column) {
    const selected = this.columns.find((x) => x.name === column);

    if (selected) return selected.label;
    return "";
  }

  handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop >
      50
    ) {
      if (this.state.scrollDown) {
        this.setState({ scrollDown: false });
      }
    } else if (!this.state.scrollDown) {
      this.setState({ scrollDown: true });
    }
  };

  replyOnImage = (replyImage) => {
    this.setState({ replyImage }, () => {
      document.getElementById("chat-textarea")?.focus();
    });
  };

  clickOnReply = (message) => {
    if (
      this.props.onReplyClick &&
      (message.reply_to_type === "task_upload" ||
        message.reply_object_type === "task_upload")
    ) {
      this.props.onReplyClick(message.reply_to_id);
    }
  };

  openSegmentatios = () => {
    if (this.props.openSegmentatios) this.props.openSegmentatios();
  };

  sendFileMessage = (callback) => {
    if (this.state.sending) {
      return;
    }
    const { file } = this.state;
    const id = uuidv4();
    this.setState({ sending: true });

    let type = "pdf";
    if (file.type.includes("image")) {
      type = "image";
    } else if (file.type.includes("video")) {
      type = "video";
    } else if (file.type.includes("audio")) {
      type = "audio";
      file.filename = "temp_audio.wav";
    }

    uploadMedia(
      file,
      type,
      "chat_attachment",
      (progress) => {
        const progressPercentage = Math.round(progress * 100);
        this.setState({ uploadProgress: progressPercentage });
      },
      (_, _2, file_url) => {
        if (file_url) {
          this.setState(
            {
              file: null,
              imageToSend: null,
              sending: false,
              uploadProgress: 0,
            },
            () => {
              sendFileMessageWithSocket(
                this.state.selectedChat.user_id,
                this.state.selectedChatCampaignId,
                file_url,
                type,
                "NORMAL",
                id,
                null,
                null,
                null,
                null,
                this.state.selectedChat?.user_id,
                this.props.isPrivate,
                this.state.selectedChat?.agent?.agent_id,
                this.state.selectedChat?.influencer_id,
                () => {
                  const message = {
                    id: id || uuidv4(),
                    message: file_url,
                    creation_time: new Date(),
                    mine: true,
                    seen: false,
                    seen_at: null,
                    is_system_message: false,
                    message_content_type: type,
                    reply_to_id: this.state.replyImage
                      ? this.state.replyImage.upload_id
                      : null,
                    reply_to_type: this.state.replyImage ? "task_upload" : null,
                    reply_to_content: this.state.replyImage
                      ? this.state.replyImage.upload_url
                      : null,
                    reply_message_time: null,
                    sending: true,
                    influencerUserId: this.state.selectedChat?.influencer_id,
                    isPrivate: this.state.selectedChat?.agent?.is_private,
                    agentId: this.state.selectedChat?.agent?.agent_id,
                  };
                  this.props.inbox.chat_messages.push(message);
                  this.updateChat.bind(this);
                },
              );
              if (callback) callback(file_url);
            },
          );
        } else {
          this.setState({ sending: false, uploadProgress: 0 });
          toast.error("Failed to upload file, please try again.");
        }
      },
    );
  };

  openFilePicker = () => {
    document.getElementById("load-image").click();
  };

  addFile = (e) => {
    try {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      this.setState({ file, imageToSend: { src: url } });
    } catch (e) {
      console.log(e);
      alert("Failed to upload file, please try again.");
    }
  };

  addEmoji = (e) => {
    const emoji = e.native;
    this.setState({
      message: this.state.message + emoji,
    });
  };

  toggleShowEmojis = () => {
    this.setState({ showEmojiPicker: !this.state.showEmojiPicker });
  };

  onStarChat = () => {
    const id = this.state.selectedChatInfluencerId;
    const campaignId = this.state.selectedChatCampaignId;
    const starred = this.state.chatDetails.is_starred;
    this.props.changeStarChat(id, campaignId, starred, (r) => {
      if (!r) {
        this.state.chatDetails.is_starred = starred;
        this.setState({});
      }
    });
    this.state.chatDetails.is_starred = !starred;
    this.setState({});
  };

  handleRecordError = (err) => {
    console.log(err);
    alert(
      "Something went wrong while recording, please hold the microphone icon while recording and make sure you have approved your browser the ability to use your microphone.",
    );
  };

  handleRecordComplete = (blob) => {
    this.setState({ isSendingRecord: true, isRecording: false }, () => {
      const file = ChatSection.blobToFile(blob, "record.mp3");
      this.setState({
        audioBlob: window.URL.createObjectURL(new Blob([blob])),
      });
      this.setState({ file });
    });
  };

  static blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, {
      lastModified: new Date(),
      type: "audio",
    });
  }

  deleteChatMessage = (message) => {
    this.setState({ chatLoading: true });
    this.props.deleteChatMessage(
      message.message_guid ?? message.event_guid,
      this.props.isPrivate,
      (res) => {
        if (!res) {
          console.log("Failed to delete message", message);
        }
        this.setState({ messageForDeletion: null, chatLoading: false });
      },
    );
  };
  renderReplyMessage = (message) => {
    let fileName = "";
    if (
      message.message_content_type === "pdf" ||
      message.message_content_type === "document" ||
      message.message_content_type === "doc"
    ) {
      fileName = message.message.replace(/^.*[\\\/]/, "");
      fileName = fileName.substring(fileName.indexOf("_") + 1);
    }
    return (
      <div
        onClick={() => this.clickOnReply(message)}
        style={{
          borderTop: "1px solid #EEE",
        }}
      >
        <div
          style={{
            background: "#FAFAFA",
            padding: 10,
            display: "flex",
            gap: 8,
            alignItems: "center",
            width: "100%",
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          <div
            style={{
              background: "#EEE",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: 10,
              borderRadius: 8,
            }}
          >
            <div>
              <div className="reply-message-header">
                <strong>
                  {message?.mine
                    ? "You"
                    : message?.from_user_type === "agent"
                    ? this.state.chatDetails?.agent?.agent_name
                    : this.state.chatDetails.name ||
                      this.state.chatDetails?.influencer_social_network_data
                        ?.name}
                </strong>
              </div>
              {message.message_content_type === "video" && (
                <video style={{ height: 150, width: "auto" }} controls>
                  <source src={message.message}></source>
                </video>
              )}
              {message.message_content_type === "audio" && (
                <audio className={"audio"} controls>
                  <source src={message.message}></source>
                </audio>
              )}
              {message.message_content_type === "image" && (
                <a href={message.message} target="_blank">
                  <img
                    src={message.message}
                    style={{ height: 100, width: "auto" }}
                  />
                </a>
              )}
              {(message.message_content_type === "pdf" ||
                message.message_content_type === "document" ||
                message.message_content_type === "doc") && (
                <Box
                  style={{
                    padding: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      justifyItems: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fad fa-file-alt message-file"
                      style={{
                        fontSize: 36,
                        padding: 10,
                      }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                      }}
                    >
                      <a
                        href={message.message}
                        target="_blank"
                        style={{
                          alignContent: "center",
                          color: "#333",
                          fontWeight: "bold",
                        }}
                      >
                        {fileName}
                      </a>
                      <Box>
                        <span style={{ textTransform: "uppercase" }}>
                          {message?.message_content_type}
                        </span>{" "}
                        document
                      </Box>
                    </Box>
                  </Box>
                  <a download={message.message} href={message.message}>
                    <Button
                      variant={"roundedWhite"}
                      leftIcon={<i className="fa fa-arrow-down" />}
                    >
                      Download
                    </Button>
                  </a>
                </Box>
              )}
              {message.message_content_type === "text" && (
                <ReactLinkify>
                  <div
                    dir="auto"
                    className="chat-message"
                    style={{
                      padding: 0,
                      background: "transparent",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {message.message}
                  </div>
                </ReactLinkify>
              )}
            </div>
          </div>
          <i
            className="fa fa-close"
            onClick={() => this.setState({ messageForReply: null })}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    );
  };

  renderDeleteMessageDialog = () => {
    return (
      this.state.messageForDeletion && (
        <MessageDialog
          handleClose={() => {
            this.setState({ messageForDeletion: false });
          }}
          icon="moi-trash"
        >
          <div style={{ textAlign: "center", padding: 20 }}>
            <strong>Notice</strong>
            <p style={{ marginTop: 15 }}>
              This message will be deleted for you and the influencer. <br />
              Are you sure you want to delete this message?
            </p>
            <Button
              mr={2}
              onClick={() =>
                this.deleteChatMessage(this.state.messageForDeletion)
              }
              disabled={this.state.chatLoading}
              isLoading={this.state.chatLoading}
            >
              Delete
            </Button>
            <Button
              onClick={() => this.setState({ messageForDeletion: false })}
              variant="outline"
              isDisabled={this.state.chatLoading}
            >
              Cancel
            </Button>
          </div>
        </MessageDialog>
      )
    );
  };

  render() {
    const { chatDetails } = this.state;
    const socialData =
      chatDetails && chatDetails.influencer_social_network_data
        ? chatDetails.influencer_social_network_data
        : {};
    const disabled =
      chatDetails?.campaign_column === "trash" || this.props.disabled;

    return (
      <div
        className="inbox-right-side"
        style={{
          width: this.props.containerWidth,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="chat-header" style={{ height: 65, paddingTop: 10 }}>
          <div style={{ display: "flex", gap: 1 }}>
            <div style={{ display: "flex" }}>
              <Avatar
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  marginRight: 5,
                }}
                src={
                  this.state.chatDetails.picture_url ||
                  this.state.selectedChatUser?.picture_url ||
                  undefined
                }
              />
              <div>
                <div
                  style={{
                    cursor: "pointer",

                    alignItems: "center",
                    maxWidth: this.props?.fromCampaign ? "100px" : "200px",
                  }}
                  className="clickable"
                >
                  <span onClick={this.openSegmentatios} className="ellipsis">
                    {this.state.chatDetails.name ||
                      this.state.selectedChatUser?.name}
                  </span>
                  {/* <i
                    onClick={() => this.onStarChat(chatDetails)}
                    className={`${
                      this.state.chatDetails.is_starred ? "fas" : "fal"
                    } fa-star`}
                  ></i> */}
                </div>

                <div className="chat-header-social-links">
                  <span style={{ color: "#aaaaaa", fontSize: 14 }}>
                    {`${this.getStatusLabel(
                      this.state.chatDetails.campaign_column,
                    )}`}
                  </span>
                </div>
              </div>
            </div>
            {this.props.innerChat?.agent &&
              this.props.innerChat?.user_registered && (
                <Flex
                  alignSelf={"center"}
                  placeSelf={"center"}
                  flex={7}
                  justifyContent={"center"}
                  paddingRight={this.props?.fromCampaign ? null : "50px"}
                >
                  <HumanzButtonGroup
                    containerProps={{ className: "top-view-buttons-container" }}
                  >
                    {["Agent", "Influencer & Agent"].map((v) => (
                      <Button
                        key={v}
                        rounded="full"
                        variant={"roundedWhite"}
                        isActive={
                          this.props.isPrivate
                            ? v === "Agent"
                            : v === "Influencer & Agent"
                        }
                        onClick={() => {
                          if (
                            !(this.props.isPrivate
                              ? v === "Agent"
                              : v === "Influencer & Agent")
                          )
                            this.props.setIsPrivate(v === "Agent");
                        }}
                        style={{
                          height: 25,
                          background:
                            this.props.isPrivate && v === "Agent"
                              ? "var(--main-baby-blue-color)"
                              : !this.props.isPrivate && v !== "Agent"
                              ? "var(--main-baby-blue-color)"
                              : "white",
                          color:
                            this.props.isPrivate && v === "Agent"
                              ? "white"
                              : !this.props.isPrivate && v !== "Agent"
                              ? "white"
                              : "#808080",
                        }}
                      >
                        {v}
                      </Button>
                    ))}
                  </HumanzButtonGroup>
                </Flex>
              )}
          </div>
        </div>
        {this.renderDeleteMessageDialog()}
        {!disabled && (
          <div
            className="chat-container"
            id="chat_container"
            onScroll={this.handleScroll}
            style={{
              height: this.props.fromCampaign ? "calc(100vh - 182px)" : "auto",
            }}
          >
            {this.props.isPrivate && (
              <Box
                style={{
                  position: "sticky",
                  flex: 1,
                  top: 0,
                  zIndex: 10,
                  backdropFilter: "blur(10px)",
                }}
                paddingY={2}
                paddingX={2}
              >
                <Alert
                  style={{
                    borderRadius: 4,
                    backdropFilter: "blur(10px)",
                    width: "calc(100%)",
                    border: "1px solid #D9EDF7",
                    background: "rgba(231, 243, 251, 0.5)",
                    alignContent: "center",
                    display: "flex",
                    justifyContent: "center",
                    color: "var(--main-baby-blue-color)",
                  }}
                >
                  <AlertIcon
                    style={{
                      fontSize: 18,
                      alignSelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <i className="fad fa-info-circle" />
                  </AlertIcon>
                  The influencer cannot see this chat. You are privately
                  chatting with the influencer&apos;s talent agent
                </Alert>
              </Box>
            )}
            {!this.props.chatLoading && this.checkLoadMore() && (
              <div className="load-more-container">
                <Button
                  isLoading={this.state.updateMessagesLoader}
                  className={` ${
                    this.state.updateMessagesLoader ? "disabled" : ""
                  }`}
                  onClick={this.getMoreMessages}
                >
                  Load Earlier Messages
                </Button>
              </div>
            )}
            {!this.props.inbox.chat_messages.length ? (
              <StartNewChat />
            ) : this.props.chatLoading ? (
              <Flex justifyContent={"center"} p={4}>
                <Spinner margin={"0 auto"} textA />
              </Flex>
            ) : (
              <ChatMessages renderMessages={this.renderMessages} />
            )}
          </div>
        )}
        {this.state.selectedChatCampaignId &&
        this.props?.selectedChatUser &&
        !(
          this.state.chatDetails.user_id ||
          this.props?.selectedChatUser?.influencer_agent?.agent_user_id
        ) ? (
          <NoDataMessage
            style={{
              fontSize: 18,
              textAlign: "center",
              marginTop: 10,
              alignContent: "center",
            }}
          >
            Chat is not available.
            <br />
            The user is not registered.
          </NoDataMessage>
        ) : (
          <div>
            {disabled ? (
              <div
                style={{
                  zIndex: 5,
                  textAlign: "center",
                  fontSize: 15,
                  marginBottom: -5,
                }}
              >
                <strong className="error-text">The Chat Is Closed</strong>
              </div>
            ) : null}
            {this.state.messageForReply &&
              this.renderReplyMessage(this.state.messageForReply)}
            <div className="chat-sends clickable" disabled={disabled}>
              {this.state.replyImage && (
                <div className="chat-reply-image">
                  <img src={this.state.replyImage.upload_url} />
                  <i
                    className="fal fa-times"
                    onClick={() => this.setState({ replyImage: null })}
                  />
                </div>
              )}

              <div>
                <span
                  onClick={this.openFilePicker}
                  className={`clickable far fa-file-plus ${!!this.state.file}`}
                  style={{ marginRight: 5 }}
                />
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="load-image"
                  accept="image/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  onChange={this.addFile}
                />
                <span
                  className={`far fa-laugh chat-emoji-btn ${this.state.showEmojiPicker}`}
                  onClick={this.toggleShowEmojis}
                ></span>
                {this.state.showEmojiPicker && (
                  <div className="chat-emoji-container">
                    <Picker
                      onSelect={this.addEmoji}
                      title=""
                      showPreview={false}
                    />
                  </div>
                )}
                <span
                  className={`record ${
                    this.state.isRecording ? "recording" : ""
                  }`}
                >
                  <Tooltip
                    placement={"top"}
                    overlay={
                      this.state.isRecording === true
                        ? "Recording..."
                        : "Long press to record"
                    }
                  >
                    <Recorder
                      onMouseDown={() => this.setState({ isRecording: true })}
                      onMouseUp={() => this.setState({ isRecording: true })}
                      onRecordingComplete={this.handleRecordComplete}
                      onRecordingError={this.handleRecordError}
                      recorderParams={{ width: 10, height: 10 }}
                    />
                  </Tooltip>
                </span>
                {this.state.file && this.state.imageToSend ? (
                  <div className="chat-send-image">
                    {this.state.file.type.includes("image") && (
                      <img src={this.state.imageToSend.src} />
                    )}
                    <span className="filename">{this.state.file.name}</span>
                  </div>
                ) : this.state.file && this.state.isSendingRecord ? (
                  <div
                    className={"recording-approve"}
                    style={{ display: "inline-flex" }}
                  >
                    <button
                      className="btn cancel-recording"
                      onClick={() =>
                        this.setState({ file: null, isSendingRecord: false })
                      }
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                    {
                      <audio
                        src={this.state.audioBlob}
                        className={"audio"}
                        controls
                      ></audio>
                    }
                  </div>
                ) : (
                  <ChatInput
                    handleKeyPress={this.handleKeyPress.bind(this)}
                    message={this.state.message}
                    setState={this.setState.bind(this)}
                  />
                  // <Textarea
                  //   onFocus={() => this.setState({ showEmojiPicker: false })}
                  //   value={this.state.message}
                  //   onChange={(e) => this.setState({ message: e.target.value })}
                  //   className="clickable"
                  //   placeholder="Type your message..."
                  //   minRows={1}
                  //   maxRows={7}
                  //   onHeightChange={(e) => this.setState({ textAreaHeight: e })}
                  //   dir="auto"
                  //   id="chat-textarea"
                  //   inputRef={(a) => (this._newMessageContent = a)}
                  //   onKeyPress={this.handleKeyPress}
                  // />
                )}
                {this.state.sending ? (
                  <div style={{ width: 100 }}>
                    {this.state.uploadProgress ? (
                      <CircularProgress
                        size="24px"
                        value={this.state.uploadProgress}
                        color="blue.500"
                        trackColor="gray.200"
                      />
                    ) : (
                      <div className="send-buttons-loader"></div>
                    )}
                  </div>
                ) : (
                  <span
                    onClick={this.sendNewMessage}
                    className={`clickable fa fa-paper-plane ${
                      !!this.state.message?.trim() +
                      (this.state.isSendingRecord ? " record_send" : "")
                    }`}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  renderReplyMessageView = (fullMessage) => {
    const message = fullMessage.reply;
    const name =
      message.sender_user_type === "advertiser"
        ? "You"
        : message.sender_user_type === "agent"
        ? this.state.chatDetails?.agent?.agent_name
        : this.state.chatDetails?.influencer_social_network_data?.name;

    let fileName = "";
    if (
      message.content_type === "pdf" ||
      message.content_type === "document" ||
      message.content_type === "doc"
    ) {
      fileName = message.content.replace(/^.*[\\\/]/, "");
      fileName = fileName.substring(fileName.indexOf("_") + 1);
    }
    return (
      <div
        style={{
          background: fullMessage.mine ? "#E3F2FD" : "#EAEEF2",
          marginBottom: 5,
          borderRadius: 8,
          marginLeft: -5,
        }}
      >
        <div
          style={{
            padding: 10,
          }}
        >
          <strong>{name}</strong>
          <div
            style={{
              // display: "flex",
              // justifyContent: "space-between",
              width: "100%",
              borderRadius: 8,
              fontSize: 12,
            }}
          >
            {message.content_type === "text" && (
              <ReactLinkify>
                <div dir="auto">{message.content}</div>
              </ReactLinkify>
            )}
            {message.content_type === "image" && (
              <a href={message.content} target="_blank">
                <img src={message.content} />
              </a>
            )}
            {(message.content_type === "pdf" ||
              message.content_type === "document" ||
              message.content_type === "doc") && (
              <Box
                style={{
                  padding: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    // justifyContent: "space-between",
                    justifyItems: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="fad fa-file-alt message-file"
                    style={{
                      fontSize: 36,
                      padding: 10,
                    }}
                  />
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                    }}
                  >
                    <a
                      href={message.content}
                      target="_blank"
                      style={{
                        alignContent: "center",
                        color: "#333",
                        fontWeight: "bold",
                      }}
                    >
                      {fileName}
                    </a>
                    <Box>
                      <span style={{ textTransform: "uppercase" }}>
                        {message?.content_type}
                      </span>{" "}
                      document
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <a download={message.content} href={message.content}>
                    <Button
                      variant={"roundedWhite"}
                      leftIcon={<i className="fa fa-arrow-down" />}
                    >
                      Download
                    </Button>
                  </a>
                </Box>
              </Box>
            )}
            {message.content_type === "video" && (
              <video controls>
                <source src={message.content}></source>
              </video>
            )}
            {message.content_type === "audio" && (
              <audio className={"audio"} controls>
                <source src={message.content}></source>
              </audio>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderMessages = (message, index) => {
    return (
      <Message
        chatDetails={this.state.chatDetails}
        message={message}
        clickOnReply={this.clickOnReply}
        index={index}
        key={message.id}
        onReplyClick={this.replyOnImage}
        renderReplyMessageView={this.renderReplyMessageView}
        isPrivate={this.props.isPrivate}
        user={this.props.user}
        replyCallback={() => {
          this.setState({
            messageForReply: message,
          });
          document.getElementById("chat-textarea")?.focus();
        }}
        deleteCallback={() => {
          this.setState({
            messageForDeletion: message,
          });
          document.getElementById("chat-textarea")?.focus();
        }}
        replyEnabled={REPLY_ENABLED}
        system_message_to_label={this.system_message_to_label}
        selectedChatTitle={this.state.selectedChatTitle}
      />
    );
  };
}

const mapStateToProps = (state) => {
  return {
    inbox: state.inboxReducer,
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMessageSent: (messageToSend) => {
      dispatch({ type: "MESSAGE_SENT", payload: [messageToSend] });
    },
    updateChatMessages: (
      id,
      campaignId,
      from,
      to,
      amount,
      offset,
      order,
      callback,
    ) => {
      dispatch(
        updateChatMessages(
          id,
          campaignId,
          from,
          to,
          amount,
          offset,
          order,
          callback,
        ),
      );
    },
    retrySendMessage: (id, data, callback) => {
      dispatch(retrySendMessage(id, data, callback));
    },
    exitChat: (id, campaignId) => {
      dispatch(exitChat(id, campaignId));
    },
    changeStarChat: (userId, campaignId, starred, callback) => {
      dispatch(changeStarChat(userId, campaignId, starred, callback));
    },
    deleteChatMessage: (guid, isPrivate, callback) => {
      dispatch(DeleteChatMessage(guid, isPrivate, callback));
    },
    resetFirstTimeMessage: () => {
      resetFirstTimeMessage();
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ChatSection);
