/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Tooltip as ChakraTooltip,
  Flex,
  Link,
  Spinner,
} from "@chakra-ui/react";
import Dialog from "rc-dialog";
import "rc-dialog/assets/bootstrap.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Tabs, { TabPane } from "rc-tabs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InkTabBar from "rc-tabs/lib/InkTabBar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TabContent from "rc-tabs/lib/TabContent";
import Tooltip from "rc-tooltip";
import React, { useEffect, useState } from "react";
import Clipboard from "react-clipboard.js";
import { connect } from "react-redux";
import Select from "react-select";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Slider from "react-slick";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Toggle from "react-toggle";
import YouTube from "react-youtube";
import { ArrayElement } from "src/declarations/UtilityTypes";
import uuidv4 from "uuid/v4";
import {
  getProposals,
  responseForProposal,
  revertProposalResponse,
  shareAllProposals,
  shareProposal,
  toggleUploadsShare,
  uploadImageToBE,
  uploadProposal,
} from "../../../../actions/auctionsActions";
import {
  MAX_MESSAGES_AMOUNT,
  getAvailableInfluencersChats,
  getChatMessages,
} from "../../../../actions/inboxActions";
import { uploadMedia } from "../../../../actions/mediaActions";
import {
  getSharedProposalsHistory,
  responseToShareProposal,
} from "../../../../actions/shareActions";
import {
  InfluencerInManageAuctionData,
  ManageAuctionState,
  PostSubTypes,
  ProposalData,
} from "../../../../api/types";
import ChatSection from "../../chats_components/ChatSection";
import NoDataMessage from "../../general_components/NoDataMessage";
import { SideNotes } from "../../shared_components/SideNotes";
import { getYouTubeId } from "../../utilities/general";

const EXPEND_VIEW_LENGTH = 50;
const ChatSectionComponent: any = ChatSection;

const TextExpandable = ({ text, id }: { text: string; id: number }) => {
  const [expandView, setExpandView] = useState(false);

  useEffect(() => {
    setExpandView(false);
  }, [id]);

  const showExpandBtn = text?.length > EXPEND_VIEW_LENGTH;

  return (
    <div style={{ position: "relative" }}>
      <div
        className={`preview-content ${expandView ? "read-more-active" : ""}`}
        dir="auto"
      >
        <div style={{ overflowY: "auto", wordBreak: "break-word" }}>{text}</div>
        {showExpandBtn && (
          <Link
            onClick={() => {
              setExpandView((prevExpandView) => !prevExpandView);
            }}
            style={{
              fontSize: 15,
              color: "var(--main-baby-blue-color)",
            }}
          >
            {expandView ? "Collapse" : "Expand"}{" "}
            <i
              className={`fa-regular fa-chevron-${expandView ? "up" : "down"}`}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

interface OwnProps {
  influencer_id: number;
  columns: Array<ManageAuctionState>;
  boardId: number;
  share: boolean;
  newOnly: boolean;
  parent: boolean;
  shareActive: boolean | undefined;
  getChatMessages: any;
  responseForProposal: any;
  handleClose: any;
  uploadImageToBE: any;
  allowedUploadContent: boolean;
  revertProposalResponse: any;
  isShowingModal: boolean;
  shareGuid?: string;
  waitingNotes: any;
  updateColumns: any;
  onlyChat?: boolean;
}

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type PostType =
  | "ig_post"
  | "ig_story"
  | "tk_video"
  | "yt_video"
  | "tw_tweet"
  | "influencer_content";

enum SaveButtonDisabledReasons {
  TwitterTask = "TwitterTask",
  ContentTask = "ContentTask",
  Loading = "Loading",
}

interface Proposal extends ProposalData {
  task_id: number;
  new_proposal?: Omit<ProposalData, "uploads"> & {
    uploads?: Array<
      ArrayElement<NonNullable<ProposalData["uploads"]>> & {
        loading?: boolean;
      }
    >;
  };
  description?: string;
  post_type: PostType;
  task_completed?: boolean;
  post_sub_type?: PostSubTypes;
}

interface State {
  sliderIndex: number;
  notImprove: any;
  loadingResponse: boolean;
  proposals: Array<Proposal>;
  chatLoading: boolean;
  activeTab: string;
  oldProposals: Array<Proposal>;
  proposalTab: string;
  selectedStatus: string;
  selectedUser: number;
  influencer: InfluencerInManageAuctionData | null;
  search: string;
  showSideMenu: boolean;
  loadingProposals: boolean;
  openProposalNote?: boolean;
  selectedHistoryDate?: string;
  sharingAll?: boolean;
  showShareAllTooltip?: boolean;
  isPrivate?: boolean;
}

class CampaignPreviewDialog extends React.Component<Props, State> {
  private textRemarksInput: HTMLTextAreaElement | null = null;

  private imagesCommentsInput: HTMLTextAreaElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      sliderIndex: 0,
      notImprove: null,
      loadingResponse: false,
      proposals: [],
      chatLoading: true,
      activeTab: "0",
      oldProposals: [],
      proposalTab: "recently",
      selectedStatus: "waiting",
      selectedUser: -1,
      influencer: null,
      search: "",
      showSideMenu: true,
      loadingProposals: true,
      isPrivate: false,
    };
  }

  SliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
  };

  POST_TYPES = {
    ig_post: "Instagram Post",
    ig_story: "Instagram Story",
    tw_tweet: "Twitter Tweet",
    yt_video: "YouTube Video",
    tk_video: "TikTok Video",
    influencer_content: "Content only",
  };

  POST_SUB_TYPE = {
    reel: "Instagram Reel",
    igtv: "IG TV",
    post: "Instagram Post",
  };

  POSTS_ICONS = {
    ig_post: "fa fa-instagram",
    ig_story: "fa moi-stories",
    tw_tweet: "fa fa-twitter",
    yt_video: "fa fa-youtube-play",
    tk_video: "fa-brands fa-tiktok",
    influencer_content: "fas fa-photo-video",
  };

  STATUS_OPTIONS = [
    { label: "All", value: "all", count: 0 },
    { label: "Waiting Approval", value: "waiting", count: 0 },
    { label: "Approved", value: "approved", count: 0 },
    { label: "Not Approved", value: "not_approved", count: 0 },
    { label: "Waiting Influencer", value: "waiting_influ", count: 0 },
  ];

  chatSectionRef = React.createRef();

  slider: any;

  oneMonthCampaign = this.props?.boardReducer?.board?.one_month_campaign;

  componentDidMount() {
    this.setInfluencer(this.props.influencer_id);
  }

  setInfluencer(id: number, force = false) {
    if (id === this.state.selectedUser && !force) return;
    if (force) {
      this.setState({ loadingProposals: true });
    }
    const { columns } = this.props;
    if (
      !columns ||
      !columns[0].influencers ||
      !columns[1].influencers ||
      !columns[2].influencers ||
      !columns[4].influencers
    )
      return;
    const influencers = columns[0].influencers
      .concat(columns[1].influencers)
      .concat(columns[2].influencers)
      .concat(columns[4].influencers);
    if (influencers) {
      const influencer = influencers.find((x) => x.influencer_id === id);
      if (!influencer || !influencer.influencer_id) return;
      this.setState({ selectedUser: id, influencer, showSideMenu: false });
      if (!this.props.onlyChat) {
        this.getProposalsDispatcher(
          this.props.boardId,
          influencer.influencer_id,
          (data: any) => {
            if (data) {
              let proposals = data
                .filter((x: any) => !x.subtask_of)
                .map((x: any) => {
                  const task = influencer?.tasks_details?.tasks?.find(
                    (i) => i.id === x.task_id,
                  );
                  const taskCompleted = task?.completed;
                  const subType = task?.post_sub_type;
                  let newProposal: Proposal;
                  if (x.current_proposal) {
                    newProposal = {
                      ...x.current_proposal,
                      task_id: x.task_id,
                      post_type: x.post_type,
                      task_completed: taskCompleted,
                      post_sub_type: subType,
                    };
                  } else {
                    newProposal = {
                      ...x,
                      post_sub_type: subType,
                      task_completed: taskCompleted,
                      new_proposal: {
                        uploads: [],
                        post_type: x.post_type,
                        text: "",
                        post_sub_type: subType,
                      },
                    };
                  }
                  return newProposal;
                });
              let selectedStatus = "";
              let index = proposals.findIndex(
                (x: any) => x.approved === null && !x.task_completed,
              );
              if (proposals && index > -1) {
                selectedStatus = "waiting";
              } else {
                index = "0";
                selectedStatus = "all";
              }
              index = index.toString();
              const currentMonth = this.props.boardReducer?.month;
              if (!this.oneMonthCampaign) {
                proposals = proposals?.filter((x: any) => {
                  const task = influencer?.tasks_details?.tasks?.find(
                    (t) => t.id === x.task_id,
                  );
                  if (task && task.month_relevance) {
                    return (
                      task.month_relevance === currentMonth ||
                      (x.old_proposals &&
                        x.old_proposals.some(
                          (y: any) => y.month_relevance === currentMonth,
                        ))
                    );
                  }
                  return false;
                });
              }

              this.STATUS_OPTIONS.forEach((option: any) => {
                let count;
                if (option.value === "all") {
                  count = proposals.length;
                } else if (option.value === "waiting") {
                  count = proposals.filter(
                    (x: any) => x.approved === null && !x.task_completed,
                  ).length;
                } else if (option.value === "approved") {
                  count = proposals.filter(
                    (x: any) => x.approved === true,
                  ).length;
                } else if (option.value === "not_approved") {
                  count = proposals.filter(
                    (x: any) => x.approved === false && !x.task_completed,
                  ).length;
                } else if (option.value === "waiting_influ") {
                  count = proposals.filter(
                    (x: any) => x.approved === undefined && !x.task_completed,
                  ).length;
                } else {
                  count = 0;
                }
                // eslint-disable-next-line no-param-reassign
                option.count = count;
              });

              this.setState({
                loadingProposals: false,
                selectedStatus,
                activeTab: index,
                proposals,
                isPrivate:
                  influencer?.influencer_agent?.agent_user_id &&
                  !influencer.user_registered,
                oldProposals: data
                  .filter((x: any) => x.old_proposals)
                  .map((x: any) => {
                    // eslint-disable-next-line no-param-reassign
                    x.old_proposals.task_id = x.task_id;
                    return x.old_proposals;
                  }),
              });
            }
          },
        );
      }
      this.getInfluencersChat(influencer);
    }
  }

  getInfluencersChat = (influencer: InfluencerInManageAuctionData) => {
    if (
      !this.props.share &&
      (influencer.user_registered || influencer.influencer_agent?.agent_user_id)
    )
      this.props.getAvailableInfluencersChats(
        true,
        false,
        null,
        influencer?.name,
        this.props.boardId,
        null,
        (res: any) => {
          this.props.getChatMessages(
            influencer.influencer_id,
            this.props.boardId,
            null,
            null,
            MAX_MESSAGES_AMOUNT,
            null,
            "desc",
            this.state.isPrivate,
            this.openChatCallback,
          );
        },
      );
  };

  getProposalsDispatcher(boardId: number, influencerId: number, callback: any) {
    if (this.props.share) {
      getSharedProposalsHistory(influencerId, callback);
    } else {
      getProposals(boardId, influencerId, callback);
    }
  }

  setStatus = (status: string) => {
    if (status === this.state.selectedStatus) return;

    const { proposals } = this.state;
    let index;
    switch (status) {
      case "waiting":
        index = proposals.findIndex(
          (x) => x.approved === null && !x.task_completed,
        );
        break;
      case "approved":
        index = proposals.findIndex((x) => x.approved === true);
        break;
      case "not_approved":
        index = proposals.findIndex(
          (x) => x.approved === false && !x.task_completed,
        );
        break;
      case "waiting_influ":
        index = proposals.findIndex(
          (x) => x.approved === undefined && !x.task_completed,
        );
        break;
      case "all":
      default:
        index = 0;
        break;
    }
    index = index.toString();
    this.setState({
      selectedStatus: status,
      activeTab: index,
      proposalTab: "recently",
    });
  };

  openChatCallback = () => {
    if (this.state.influencer?.chat_status) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.influencer.chat_status.last_message_seen = true;
    }
    this.setState({ chatLoading: false });
    window.setTimeout(() => {
      const objDiv = document.getElementById("chat_container");
      if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
    }, 1);
  };

  next = () => {
    if (this.slider) this.slider.slickGoTo(2);
  };

  previous = () => {
    if (this.slider) this.slider.slickPrev();
  };

  // eslint-disable-next-line class-methods-use-this
  downloadFile(file: any) {
    const name = file.replace(/^.*[\\/]/, "");
    try {
      fetch(file, {
        credentials: "omit",
        headers: {
          "sec-fetch-mode": "no-cors",
        },
        method: "GET",
        mode: "cors",
        cache: "no-store",
      })
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); // or any other extension
          document.body.appendChild(link);
          link.click();
        });
    } catch (e) {
      alert("Error downloading file, please try again.");
    }
  }

  responseToProposal(proposal: any, accepted: boolean) {
    if (!this.state.loadingResponse) {
      this.setState({ loadingResponse: true }, () => {
        const { newOnly } = this.props;

        const response = {
          accepted,
          text_remark: accepted ? null : this.textRemarksInput?.value,
          image_remark: accepted ? null : this.imagesCommentsInput?.value,
          images_feedback: proposal?.uploads
            ? proposal.uploads.map((x: any) => {
                return { feeback: x.approved, upload_id: x.upload_id };
              })
            : [],
        };

        const propsProposal: any = this.state.influencer?.tasks_details?.tasks
          ?.filter((x) => x.proposal && !x.subtask_of)
          .map((x) => x.proposal)
          .find((x) => x?.id === proposal.id);

        if (this.props.share) {
          responseToShareProposal(proposal.id, response, () =>
            this.responseCallback(newOnly, proposal, propsProposal, accepted),
          );
        } else {
          this.props.responseForProposal(
            this.props.boardId,
            proposal.id,
            response,
            () =>
              this.responseCallback(newOnly, proposal, propsProposal, accepted),
          );
        }
      });
    }
  }

  responseCallback(
    newOnly: boolean,
    proposal: any,
    propsProposal: any,
    accepted: boolean,
  ) {
    let proposals;
    if (newOnly)
      proposals = this.state.proposals.filter((x) => x.id !== proposal.id);
    else {
      proposals = this.state.proposals;
      const stateProposal = this.state.proposals.find(
        (x) => x.id === proposal.id,
      );
      if (stateProposal) {
        stateProposal.approved = accepted;
        stateProposal.response_at = new Date() as any;
      }
    }
    if (propsProposal) {
      // eslint-disable-next-line no-param-reassign
      propsProposal.uploads = proposal?.uploads;
      // eslint-disable-next-line no-param-reassign
      propsProposal.approved = accepted;
    }
    this.setState({ loadingResponse: false, notImprove: null, proposals });
    if (newOnly && proposals.length === 0) {
      this.props.handleClose();
    }
    this.props.updateColumns();
  }

  replyOnImage = (image: any) => {
    if (this.chatSectionRef && this.chatSectionRef.current) {
      (this.chatSectionRef.current as any).replyOnImage(image);
    }
  };

  handleReplyClick = (id: number) => {
    let upload = null;
    let index = 0;
    let historyIndex = -1;
    // eslint-disable-next-line no-restricted-syntax
    for (const p of this.state.proposals) {
      upload = p?.uploads
        ? p.uploads.find((j) => (j.upload_id as any) === id)
        : false;
      if (upload) {
        break;
      }
      index += 1;
    }

    if (!upload) {
      historyIndex = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const p of this.state.oldProposals) {
        let found = false;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line no-restricted-syntax
        for (const i of p) {
          if (i?.uploads) {
            upload = i.uploads.find((j: any) => (j.upload_id as any) === id);
            if (upload) {
              found = true;
              break;
            }
          }
        }
        if (found) {
          break;
        } else {
          historyIndex += 1;
        }
      }
    }

    if (upload) {
      if (historyIndex > -1 && historyIndex < this.state.oldProposals.length) {
        index = this.state.proposals.findIndex(
          (x) => x.task_id === this.state.oldProposals[historyIndex].task_id,
        );
      }
      const status = this.state.proposals[index].approved;
      const selectedStatusFallback =
        status === true ? "approved" : "not_approved";
      const selectedStatus =
        status === null ? "waiting" : selectedStatusFallback;
      this.setState({
        activeTab: index.toString(),
        selectedStatus,
        proposalTab: historyIndex > -1 ? "history" : "recenlty",
        selectedHistoryDate:
          historyIndex > -1
            ? (this.state.oldProposals[historyIndex].upload_at as any)
            : "",
      });
      let element = document.getElementById(upload.upload_id);
      try {
        if (element) {
          element = element.parentElement;
          element?.classList.add("glower");
        }
        // eslint-disable-next-line no-empty
      } catch {}
      window.setTimeout(() => {
        try {
          element?.classList.remove("glower");
          // eslint-disable-next-line no-empty
        } catch {}
      }, 4500);
    }
  };

  shareProposal(proposal: any, shared: any) {
    // if (this.state.loadingShares.includes(proposal.id)) return;

    // eslint-disable-next-line no-param-reassign
    proposal.shared = shared;
    const data = {
      note: proposal.share_note,
      share: proposal.shared,
    };

    // this.state.loadingShares.push(proposal.id);

    this.setState({});

    shareProposal(this.props.boardId, proposal.id, data, () => {});
  }

  // eslint-disable-next-line class-methods-use-this
  openMediaPicker = () => {
    document.getElementById("media-picker")?.click();
  };

  // eslint-disable-next-line class-methods-use-this
  toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  addMedia = async (e: any, uploads: any, proposal: any) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    const id = uuidv4();
    const media = {
      upload_id: id,
      url,
      upload_type: file.type.includes("video") ? "video" : "image",
      loading: true,
    };
    uploads.push(media);
    // eslint-disable-next-line no-param-reassign
    proposal.new_proposal.uploads = uploads;
    this.setState({});
    uploadMedia(
      file,
      media.upload_type,
      "proposal",
      (progress: any) => {
        console.log("p", progress);
      },
      (_: any, _2: any, file_url: any) => {
        const index = uploads.findIndex((x: any) => x.upload_id === id);
        if (file_url && index > -1) {
          // eslint-disable-next-line no-param-reassign
          uploads[index].loading = false;
          // eslint-disable-next-line no-param-reassign
          uploads[index].upload_url = file_url;
          this.setState({});
          this.props.updateColumns();
        }
        this.setState({});
      },
    );
    // if (media.upload_type === "image") {

    //     const base64 = await this.toBase64(file);
    //     const image = {
    //         id,
    //         image: { data: base64.split(',')[1], mime_type: file.type }
    //     }
    //     this.props.uploadImageToBE(image, 'proposal', (success) => {
    //         let index;

    //     }, (fail) => {

    //     })
    // } else {
    //     UploadVideo(id, file, (success) => {
    //         let index
    //         if (success && ((index = uploads.findIndex(x => x.upload_id === id)) > -1)) {
    //             uploads[index].loading = false;
    //             this.setState({});
    //         }
    //     })
    // }
  };

  uploadProposal = (proposal: any) => {
    this.setState({ loadingResponse: true });
    if (this.state.loadingResponse) {
      return;
    }
    uploadProposal(
      this.props.boardId,
      this.state.influencer?.influencer_id,
      proposal.task_id,
      proposal.new_proposal,
      () => {
        this.setState({ loadingResponse: false });
        this.setInfluencer(this.state.influencer?.influencer_id as any, true);
        this.props.updateColumns();
      },
    );
  };

  shareAllProposals = () => {
    this.setState({ sharingAll: true, showShareAllTooltip: false }, () => {
      shareAllProposals(
        this.props.boardId,
        this.state.influencer?.influencer_id,
        ((changed: any) => {
          if (changed) {
            const { proposals } = this.state;
            proposals.forEach((p) => {
              // eslint-disable-next-line no-param-reassign
              p.shared = true;
            });
          }
          this.setState({ sharingAll: false });
        }) as any,
      );
    });
  };

  revertResponse = (proposal: Proposal) => {
    this.setState({ loadingResponse: true });
    if (this.state.loadingResponse) {
      return;
    }

    this.props.revertProposalResponse(this.props.boardId, proposal.id, () => {
      this.setState({ loadingResponse: false });
      this.setInfluencer(this.state.influencer?.influencer_id as any, true);
    });
  };

  renderShareNotes = () => {
    if (this.state.influencer) {
      const notesType = `campaign_content_approval_${this.state.influencer.influencer_id}`;
      // eslint-disable-next-line radix
      const proposal: any =
        this.state.proposals[parseInt(this.state.activeTab, 10)];
      if (proposal) {
        return (
          <SideNotes
            handleClose={() => this.setState({ openProposalNote: false })}
            objectType="campaign"
            name={""}
            notesType={notesType}
            isFromShare={this.props.share}
            itemId={proposal.task_id}
            guid={this.props.shareGuid as any}
            containerClassName="preview-side-notes"
          />
        );
      }
    }
    return null;
  };

  // eslint-disable-next-line
  triggerMonthChange = (_month: any) => {
    this.setInfluencer(this.props.influencer_id, true);
  };

  render() {
    const { influencer } = this.state;
    const { proposals } = this.state;
    const proposal: any = (
      this.state.notImprove ? proposals[this.state.notImprove.index] : {}
    ) as Proposal;

    const innerChat = influencer
      ? {
          picture_url: influencer.picture_url,
          name: influencer.name,
          agent: influencer?.influencer_agent || influencer?.agent,
          user_id: influencer?.influencer_id,
          user_registered: influencer?.user_registered,
        }
      : null;
    const showShareAll = !!(
      !this.props.share &&
      this.state.selectedStatus === "waiting" &&
      this.props.shareActive &&
      proposals.find((x) => !x.shared)
    );
    const collapseChat =
      !influencer?.user_registered &&
      !influencer?.influencer_agent?.agent_user_id &&
      !(this.props.share || this.state.openProposalNote);

    return (
      <Dialog
        className={`campaign-preview-dialog ${
          this.state.notImprove ? "not-approved-container" : ""
        }`}
        title={""}
        onClose={this.props.handleClose}
        visible={this.props.isShowingModal}
        style={{
          width: this.props.parent
            ? "700px"
            : this.props.onlyChat
            ? "520px"
            : "1230px",
          marginTop: 10,
        }}
        bodyStyle={{
          height: "100%",
          overflowY: "hidden",
          overflowX: "hidden",
        }}
        animation="slide-fade"
        maskAnimation="fade"
      >
        <Flex gap={2} alignItems={"center"}>
          {/* {!this.oneMonthCampaign && (
            <CampaignMonthSelector
              months={this.props?.boardReducer?.board?.available_months}
              allMonths={true}
              onChange={(month: string) => {
                this.triggerMonthChange(month);
              }}
              style={{
                borderColor: "none",
                borderWidth: 0,
                padding: "5px 10px",
                boxShadow: "0px 1.33469px 2.33571px rgba(0, 0, 0, 0.17)",
                fontSize: 12,
                marginTop: 5,
                borderRadius: 7,
                marginLeft: 0,
              }}
            />
          )} */}
          <Button
            variant={"small-action-white"}
            leftIcon={<i className="fad fa-user" />}
            m={2}
            marginLeft={0}
            marginBottom={0}
            onClick={() => this.setState({ showSideMenu: true })}
          >
            Select different influencer
          </Button>
        </Flex>
        <Flex gap={1} m={1}>
          {!this.props.onlyChat && (
            <div
              style={{
                flex: 2,
                maxWidth: !collapseChat ? 700 : "80%",
                minHeight: "87vh",
              }}
              id="preview-inner"
            >
              {this.state.showSideMenu && this.renderSideMenu()}

              {this.state.notImprove ? (
                this.renderNotApprovedArea(proposal)
              ) : (
                <div style={{ height: "100%" }}>
                  {this.state.loadingProposals ? (
                    <Box
                      className="flex"
                      alignItems={"center"}
                      height={"100%"}
                      flexDir={"column"}
                      justifyContent={"center"}
                    >
                      <Spinner style={{ margin: "0 auto" }} />
                      <Box color={"#3f3f3f"} fontSize={12} mt={2}>
                        Loading content
                      </Box>
                    </Box>
                  ) : !proposals || proposals?.length === 0 ? (
                    <NoDataMessage
                      style={{
                        filter: "grayscale(1)",
                        margin: 2,
                        height: "100%",
                        alignContent: "center",
                      }}
                    >
                      <img src={"/images/no_tasks.png"} />
                      <div>No tasks were found in the selected month.</div>
                    </NoDataMessage>
                  ) : (
                    <div className="preview-tabs">
                      <div style={{ flexDirection: "row" }}>
                        <Select
                          className="preview-dialog-status"
                          options={this.STATUS_OPTIONS}
                          value={this.state.selectedStatus as any}
                          onChange={this.setStatus as any}
                          placeholder={""}
                          simpleValue
                          clearable={false}
                          disabled={false}
                          searchable={false}
                          optionRenderer={(option: any) => {
                            return (
                              <option value={option.value}>
                                {`${option.label} (${option?.count})`}
                              </option>
                            );
                          }}
                        />
                        <Tabs
                          activeKey={this.state.activeTab}
                          renderTabBar={() => (
                            <ScrollableInkTabBar
                              style={{ borderBottom: "none" }}
                            />
                          )}
                          renderTabContent={() => <TabContent />}
                          onChange={(key: any) =>
                            this.setState({
                              activeTab: key,
                              selectedHistoryDate: "",
                              proposalTab: "recently",
                            })
                          }
                          className="preview-proposal-tabs"
                          style={
                            showShareAll ? { width: "calc(100% - 262px)" } : {}
                          }
                        >
                          {proposals.map(this.renderTabs)}
                        </Tabs>
                        {showShareAll && (
                          <Tooltip
                            visible={this.state.showShareAllTooltip}
                            onVisibleChange={(showShareAllTooltip) =>
                              this.setState({ showShareAllTooltip })
                            }
                            placement="bottom"
                            overlayClassName="edit-popup"
                            trigger={["click"]}
                            overlay={this.renderShareAllTaskTooltip()}
                          >
                            <button
                              className={"btn btn-primary"}
                              style={{
                                background: "#249ff0",
                                borderWidth: 0,
                                height: 30,
                                marginLeft: 5,
                              }}
                            >
                              SHARE ALL
                            </button>
                          </Tooltip>
                        )}
                      </div>
                      {proposals.map(this.renderProposal)}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className={`preview-dialog-right-side ${
              collapseChat ? "chat-collapsed" : null
            }`}
          >
            {this.state.openProposalNote || this.props.share
              ? this.renderShareNotes()
              : !this.props.share &&
                !this.props.parent &&
                innerChat && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                      maxHeight: "90vh",
                    }}
                    className="campaign-preview-chat-container"
                  >
                    <ChatSectionComponent
                      ref={this.chatSectionRef}
                      onReplyClick={this.handleReplyClick}
                      selectedChatTitle={""}
                      chatContainerHeight={715}
                      selectedChatCampaignId={this.props.boardId}
                      selectedChatInfluencerId={influencer?.influencer_id}
                      selectedChatUser={influencer}
                      chatLoading={this.state.chatLoading}
                      innerChat={innerChat}
                      disabled={
                        !influencer?.user_registered &&
                        !influencer?.influencer_agent?.agent_user_id
                      }
                      setIsPrivate={() => {
                        this.setState(
                          {
                            chatLoading: true,
                            isPrivate: !this.state.isPrivate,
                          },
                          () => {
                            innerChat.user_id =
                              influencer?.influencer_agent?.agent_user_id;
                            this.setInfluencer(
                              influencer?.influencer_id,
                              false,
                            );
                            this.getInfluencersChat(influencer);
                          },
                        );
                      }}
                      isPrivate={this.state.isPrivate}
                      fromCampaign={true}
                    />
                  </div>
                )}
          </div>
        </Flex>
      </Dialog>
    );
  }

  renderNotApprovedArea(proposal: Proposal) {
    const isShare = this.state.notImprove.share;
    return (
      <div className="not-approved-container">
        <div className="campaign-preview-header">
          <i className="far fa-file-alt"></i>Not Approved Explanations
        </div>
        <div className="campaign-preview-container">
          <div style={{ display: "flex" }}>
            <div style={{ width: "45%", marginRight: 40 }}>
              {" "}
              <div
                style={{ borderBottom: "1px solid #ebebeb", marginBottom: 10 }}
              >
                Post Text
              </div>
              <div className="preview-content">
                <span style={{ userSelect: "text" }}>{proposal.text}</span>
              </div>
            </div>
            <div style={{ width: "55%" }}>
              {" "}
              <div
                style={{ borderBottom: "1px solid #ebebeb", marginBottom: 10 }}
              >
                Text Comments
              </div>
              <textarea
                disabled={isShare}
                defaultValue={
                  isShare ? proposal.shared_rejection?.text_comments : ""
                }
                ref={(a) => {
                  this.textRemarksInput = a;
                }}
              />
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 10 }}>
            <div style={{ width: "45%", marginRight: 40 }}>
              {" "}
              <div
                style={{ borderBottom: "1px solid #ebebeb", marginBottom: 10 }}
              >
                Post Images
              </div>
              {this.renderSmallPreview(proposal, isShare, isShare, false)}
            </div>

            <div style={{ width: "55%" }}>
              {" "}
              <div
                style={{ borderBottom: "1px solid #ebebeb", marginBottom: 10 }}
              >
                Images Comments
              </div>
              <textarea
                disabled={isShare}
                defaultValue={
                  isShare ? proposal.shared_rejection?.uploads_comments : ""
                }
                ref={(a) => {
                  this.imagesCommentsInput = a;
                }}
              />
            </div>
          </div>
        </div>

        {isShare ? (
          <div
            className="preview-footer-buttons"
            style={{ marginTop: 20, justifyContent: "center" }}
          >
            <button
              className={"btn btn-pirmary cancel "}
              onClick={() =>
                this.setState({ notImprove: null, sliderIndex: 0 })
              }
            >
              BACK
            </button>
          </div>
        ) : (
          <div
            className="preview-footer-buttons"
            style={{ marginTop: 40, justifyContent: "center" }}
          >
            <button
              className={`btn btn-pirmary ${
                this.state.loadingResponse && "disabled"
              }`}
              onClick={() => this.responseToProposal(proposal, false)}
            >
              Send
            </button>
            <button
              className={`btn btn-pirmary cancel ${
                this.state.loadingResponse && "disabled"
              }`}
              onClick={() =>
                this.setState({ notImprove: null, sliderIndex: 0 })
              }
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  }

  toggleUploadShare = (upload: any, proposal: Proposal) => {
    const data = { uploads: [upload.upload_id] };
    // eslint-disable-next-line no-param-reassign
    upload.upload_shared = !(upload.upload_shared !== false);
    this.setState({});
    toggleUploadsShare(this.props.boardId, proposal.id, data, ((
      success: any,
    ) => {
      if (!success) {
        // eslint-disable-next-line no-param-reassign
        upload.upload_shared = !(upload.upload_shared !== false);
        this.setState({});
      }
    }) as any);
  };

  renderSmallPreview(
    proposal: any,
    responded: any,
    share: any,
    clickable: any,
    editMode = false,
  ) {
    const uploads = proposal?.uploads ? proposal.uploads : [];
    return (
      <div
        className="preview-small-images"
        style={{ justifyContent: editMode ? "normal" : "space-between" }}
      >
        {uploads.map((x: any, index: number) => {
          const key = x.upload_id ? x.upload_id : index;
          const url = x.upload_url ? x.upload_url : x.url;
          const type = getYouTubeId(url) ? "link" : x.upload_type;
          const { loading } = x;
          const uploadShared = x.upload_shared !== false;
          return (
            <div key={key}>
              {!editMode && (
                <div
                  className={`preview-pic-buttons ${
                    // eslint-disable-next-line no-nested-ternary
                    share
                      ? proposal.shared_rejection &&
                        proposal.shared_rejection.uploads_responses
                        ? proposal.shared_rejection.uploads_responses[index]
                            .approved
                        : x.approved
                      : x.approved
                  }`}
                >
                  <li
                    className="fa fa-times"
                    onClick={() => {
                      if (responded) {
                        return;
                      }
                      // eslint-disable-next-line no-param-reassign
                      x.approved = false;
                      this.setState({});
                    }}
                  />
                  <li
                    className="fa fa-check"
                    onClick={() => {
                      if (responded) {
                        return;
                      }
                      // eslint-disable-next-line no-param-reassign
                      x.approved = true;
                      this.setState({});
                    }}
                  />
                </div>
              )}
              {editMode ? (
                <i
                  className="fal fa-trash-alt"
                  aria-label="Delete"
                  onClick={() => {
                    uploads.splice(index, 1);
                    this.setState({});
                  }}
                ></i>
              ) : null}
              {!editMode && !share && this.state.influencer?.user_registered ? (
                <i
                  className="fa fa-reply"
                  onClick={() => this.replyOnImage(x)}
                  lower-aria-label={"Reply"}
                />
              ) : null}
              {!share && proposal.shared_approved === null ? (
                <Tooltip
                  placement="top"
                  trigger={["hover"]}
                  overlay={uploadShared ? "Don't Share" : "Share"}
                >
                  <i
                    className={`fa upload-share ${
                      uploadShared ? "fa-ban" : "fa-share"
                    }`}
                    onClick={() => this.toggleUploadShare(x, proposal)}
                  />
                </Tooltip>
              ) : null}
              <div
                onClick={() => {
                  if (this.slider && clickable) this.slider.slickGoTo(index);
                }}
                style={{ opacity: loading ? 0.5 : 1 }}
              >
                {(() => {
                  switch (type) {
                    case "video":
                      return (
                        <video>
                          <source src={url}></source>
                        </video>
                      );
                    case "link":
                      return (
                        <img
                          src={`https://img.youtube.com/vi/${getYouTubeId(
                            url,
                          )}/0.jpg`}
                          id={x.upload_id}
                        />
                      );

                    default:
                      return <img src={url} id={url} />;
                  }
                })()}
              </div>
              {loading && (
                <div
                  className="send-buttons-loader"
                  style={{
                    position: "absolute",
                    marginTop: -100,
                    marginLeft: 36,
                  }}
                ></div>
              )}
            </div>
          );
        })}
        {editMode && (
          <div
            style={{
              textAlign: "center",
              paddingTop: 45,
              paddingLeft: 2,
              paddingRight: 2,
              fontSize: 15,
              cursor: "pointer",
            }}
            onClick={() => this.openMediaPicker()}
          >
            <div style={{ fontSize: 25 }} className="fal fa-plus"></div>
            <br />
            Upload Image / Video
            <input
              type="file"
              style={{ display: "none" }}
              id="media-picker"
              accept="image/*,video/mp4"
              onChange={(e) => this.addMedia(e, uploads, proposal)}
            />
          </div>
        )}
      </div>
    );
  }

  closeShareAllTooltip = () => {
    this.setState({ showShareAllTooltip: false });
  };

  renderShareAllTaskTooltip = () => {
    return (
      <>
        <div className="edit-tags-popup-title" style={{ textAlign: "center" }}>
          Share Tasks
        </div>
        <div style={{ width: 250, padding: "5px 10px" }}>
          <div
            style={{
              color: "black",
              fontSize: 16,
              fontWeight: 300,
            }}
          >
            Are you sure you want to share all current tasks of this
            influencers?
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 10,
            }}
          >
            <button
              onClick={this.shareAllProposals}
              className="btn btn-primary"
              style={{ background: "#22b573", borderColor: "#22b573" }}
            >
              Yes
            </button>
            <button
              onClick={this.closeShareAllTooltip}
              className="btn btn-primary"
              style={{ background: "#f15b23", borderColor: "#f15b23" }}
            >
              No
            </button>
          </div>
        </div>
      </>
    );
  };

  renderShareTaskTooltip = (proposal: any) => {
    return (
      <>
        <div className="edit-tags-popup-title" style={{ textAlign: "center" }}>
          Share Task
        </div>
        <div style={{ width: 250, padding: "0 10px" }}>
          <div style={{ marginTop: 5 }}>
            <strong className="label-title" style={{ marginBottom: 7 }}>
              Active
            </strong>
            <div className="toggle-input">
              <label>
                <div className="toggle-label">
                  <div className="fa fa-times" />
                  <div className="fa fa-check" />
                </div>
                <Toggle
                  defaultChecked={proposal.shared}
                  icons={false}
                  onChange={() =>
                    this.shareProposal(proposal, !proposal.shared)
                  }
                />
              </label>
            </div>
          </div>
          <div
            style={{ marginTop: 5 }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            disabled={!proposal.shared}
          >
            <strong
              className="label-title"
              style={{ marginTop: -5, marginBottom: 5 }}
            >
              Comment
            </strong>
            <div>
              <textarea
                value={proposal.share_note}
                onChange={(e) => {
                  const newValue = e.target.value;
                  // eslint-disable-next-line no-param-reassign
                  proposal.share_note = newValue;
                  this.setState({});
                  setTimeout(() => {
                    if (proposal.share_note === newValue) {
                      this.shareProposal(proposal, proposal.shared);
                    }
                  }, 1000);
                }}
                style={{
                  height: 60,
                  color: "black",
                  width: "100%",
                  borderRadius: 4,
                  borderColor: "#aaa",
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  renderPreview(
    proposal: Proposal,
    responded: boolean,
    share: boolean,
    clickable: boolean,
    editMode = false,
  ) {
    let uploads: ProposalData["uploads"] = [];
    if (editMode && proposal.new_proposal?.uploads) {
      uploads = proposal.new_proposal.uploads;
    } else if (proposal.uploads) {
      uploads = proposal.uploads;
    }
    return (
      <div className="row-preview-small-images">
        {uploads.map((x, index) => {
          const key = x.upload_id ? x.upload_id : index;
          const url = x.upload_url ? x.upload_url : (x as any).url;
          const type = getYouTubeId(url) ? "link" : x.upload_type;
          const { loading } = x as any;
          const uploadShared = x.upload_shared !== false;
          return (
            <div key={key}>
              <div
                className="preview-image-container"
                onClick={() => {
                  if (this.slider && clickable) this.slider.slickGoTo(index);
                }}
                style={{ opacity: loading ? 0.5 : 1 }}
              >
                {this.renderMedia(x, type)}
              </div>

              <div className="row-preview-right-side">
                {/* Top Buttons */}
                <div>
                  {!share &&
                    !editMode &&
                    (typeof proposal.shared_approved === "boolean" ? (
                      <div
                        className={`client-approval client-${
                          proposal.shared_rejection?.uploads_responses &&
                          proposal.shared_rejection?.uploads_responses[index]
                            .approved
                            ? "approved"
                            : "rejected"
                        }`}
                      >
                        Client{" "}
                        {proposal.shared_rejection?.uploads_responses &&
                        proposal.shared_rejection?.uploads_responses[index]
                          .approved
                          ? "Approved"
                          : "rejected"}
                      </div>
                    ) : proposal.shared ? (
                      <div
                        className={`client-approval client-${
                          uploadShared ? "shared" : "not-shared"
                        }`}
                      >
                        {uploadShared ? "Waiting client" : "Not shared"}
                      </div>
                    ) : null)}

                  {editMode ? (
                    <i
                      className="fal fa-trash-alt"
                      aria-label="Delete"
                      onClick={() => {
                        uploads?.splice(index, 1);
                        this.setState({});
                      }}
                    ></i>
                  ) : share ? null : (
                    this.state.influencer?.user_registered && (
                      <i
                        className="fa fa-reply"
                        onClick={() => this.replyOnImage(x)}
                        lower-aria-label={"Reply"}
                      />
                    )
                  )}
                  {!share && proposal.shared_approved === null ? (
                    <Tooltip
                      placement="top"
                      trigger={["hover"]}
                      overlay={uploadShared ? "Don't Share" : "Share"}
                    >
                      <i
                        className={`fa upload-share ${
                          uploadShared ? "fa-ban" : "fa-share"
                        }`}
                        onClick={() => this.toggleUploadShare(x, proposal)}
                      />
                    </Tooltip>
                  ) : null}
                </div>
                {/* Approve / Disapprove buttons */}
                {!editMode && (
                  <div
                    className={`row-preview-pic-buttons ${
                      share
                        ? proposal.shared_rejection &&
                          proposal.shared_rejection.uploads_responses
                          ? proposal.shared_rejection.uploads_responses[index]
                              .approved
                          : x.approved
                        : x.approved
                    }`}
                  >
                    <strong className="label-title">
                      {responded ? "Your Choice" : "Select your choice"}
                    </strong>
                    <li
                      className="fa fa-times"
                      onClick={() => {
                        if (responded) {
                          return;
                        }
                        // eslint-disable-next-line no-param-reassign
                        x.approved = false;
                        this.setState({});
                      }}
                    />
                    <li
                      className="fa fa-check"
                      onClick={() => {
                        if (responded) {
                          return;
                        }
                        // eslint-disable-next-line no-param-reassign
                        x.approved = true;
                        this.setState({});
                      }}
                    />
                  </div>
                )}
              </div>
              {/* Image upload loader */}
              {loading && (
                <div
                  className="send-buttons-loader"
                  style={{
                    position: "absolute",
                    marginTop: 50,
                    marginLeft: 25,
                  }}
                ></div>
              )}
            </div>
          );
        })}
        {editMode && (
          <div
            style={{
              textAlign: "center",
              height: 100,
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 2,
              paddingRight: 2,
              fontSize: 15,
              cursor: "pointer",
            }}
            onClick={() => this.openMediaPicker()}
          >
            <div
              style={{ fontSize: 25, marginRight: 10 }}
              className="fal fa-plus"
            ></div>
            <span>Upload Image / Video</span>
            <input
              type="file"
              style={{ display: "none" }}
              id="media-picker"
              accept="image/*,video/*"
              onChange={(e) => this.addMedia(e, uploads, proposal)}
            />
          </div>
        )}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderMedia(media: any, type: any, display = "") {
    const maxUrlLength = 30;
    switch (type) {
      case "video":
        return (
          <video
            height="380px"
            width="300px"
            controls={display === "post preview"}
          >
            <source src={media.upload_url}></source>
          </video>
        );
      case "link":
        return (
          <YouTube
            videoId={getYouTubeId(media.upload_url) ?? undefined}
            opts={{ width: "300px", height: "380px" }}
          />
        );
      case "image":
        return <img src={media.upload_url} />;
      case "external_website":
        return (
          <div>
            <a
              href={media.upload_url}
              className="external_link"
              target={"_blank"}
              rel="noreferrer"
            >
              External link <i className="fa-regular fa-up-right-from-square" />{" "}
              <span>
                <br />
                {media.upload_url && media.upload_url.length > maxUrlLength
                  ? `${media.upload_url.substr(0, maxUrlLength)}...`
                  : media.upload_url}
              </span>
            </a>
          </div>
        );
      default:
        if (media.upload_url) return <img src={media.upload_url} />;
    }
    return null;
  }

  renderProposal = (recent_proposal: Proposal, idx: number) => {
    const { selectedStatus, influencer } = this.state;
    const status =
      recent_proposal.response_at === null
        ? "waiting"
        : recent_proposal.approved
        ? "approved"
        : "not_approved";
    const currentPost = {
      ig_post: "instagram",
      ig_story: "instagram",
      tk_video: "tiktok",
      yt_video: "youtube",
      tw_tweet: "twitter",
      influencer_content: "influencerContent",
    }[recent_proposal.post_type];
    const socialMedia = influencer?.social_network_data;
    const isUploadContentEnabled =
      influencer?.shadow_influencer ||
      (socialMedia && !!socialMedia[currentPost as keyof typeof socialMedia]) ||
      currentPost === "influencerContent";
    if (
      idx.toString() !== this.state.activeTab ||
      !recent_proposal ||
      (selectedStatus !== "all" &&
        selectedStatus !== status &&
        !recent_proposal.new_proposal)
    ) {
      return null;
    }

    const { post_type: postType, uploads, text } = recent_proposal;

    const hasContent = !!uploads || !!text;

    if (!hasContent) {
      return (
        <div key={idx} className="preview-no-data">
          {recent_proposal.task_completed ? (
            <strong
              style={{
                fontSize: 18,
                color: "#22b573",
                marginTop: 10,
                marginBottom: -5,
              }}
            >
              Task Completed
            </strong>
          ) : (
            <>
              <NoDataMessage m={10}>
                The influencer hasn&#39;t submitted a proposal for this task yet
              </NoDataMessage>
              {this.props.allowedUploadContent ? (
                <ChakraTooltip
                  label={`The influencer doesn't have ${
                    {
                      instagram: "Instagram",
                      tiktok: "TikTok",
                      youtube: "YouTube",
                      twitter: "Twitter",
                    }[currentPost]
                  } connected`}
                  hasArrow
                  placement="top"
                  isDisabled={isUploadContentEnabled}
                >
                  <Box>
                    <Button
                      size={"lg"}
                      onClick={() => {
                        // eslint-disable-next-line no-param-reassign
                        recent_proposal.uploads = [];
                        // eslint-disable-next-line no-param-reassign
                        recent_proposal.new_proposal = {
                          uploads: [],
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          post_type: recent_proposal.post_type,
                          text: "",
                        };
                        this.setState({});
                      }}
                      disabled={!isUploadContentEnabled}
                    >
                      Upload influencer content
                    </Button>
                  </Box>
                </ChakraTooltip>
              ) : null}
            </>
          )}
        </div>
      );
    }

    const history = this.state.oldProposals.find(
      (x) => x.task_id === recent_proposal.task_id,
    );

    let selectedHistoryDate;
    let selectedHistory: Proposal | undefined;
    if (this.state.proposalTab.includes("history")) {
      selectedHistoryDate = this.state.selectedHistoryDate;
      if (history) {
        if (!selectedHistoryDate) {
          selectedHistoryDate = (history as any)[0].upload_at;
          // eslint-disable-next-line prefer-destructuring
          selectedHistory = (history as any)[0];
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selectedHistory = history.find(
            (x: any) => x.upload_at === this.state.selectedHistoryDate,
          );
        }
      }
    }

    const proposal: any = selectedHistory || recent_proposal;

    const responded =
      proposal.approved !== null ||
      (this.props.share && proposal.shared_rejection);
    const imagesMarked = !(
      proposal.uploads && proposal.uploads.find((x: any) => x.approved === null)
    );

    const editMode = !!proposal.new_proposal;

    const label =
      postType === "ig_post" && proposal.post_sub_type
        ? (this.POST_SUB_TYPE as any)[proposal.post_sub_type]
        : this.POST_TYPES[postType];
    const waitingNote =
      this.state.influencer?.influencer_id !== undefined &&
      this.props.waitingNotes[this.state.influencer.influencer_id]?.includes(
        proposal.task_id,
      );

    const isLoading = proposal?.new_proposal?.uploads?.some(
      (u: any) => u.loading,
    );
    let isSavedButtonDisabled: SaveButtonDisabledReasons | false = false;
    if (isLoading) {
      isSavedButtonDisabled = SaveButtonDisabledReasons.Loading;
    } else if (postType === "tw_tweet") {
      if (!proposal.new_proposal?.text) {
        isSavedButtonDisabled = SaveButtonDisabledReasons.TwitterTask;
      }
    } else if (proposal.new_proposal?.uploads?.length === 0) {
      isSavedButtonDisabled = SaveButtonDisabledReasons.ContentTask;
    }

    const disabledSaveBtnErrorMessage = isSavedButtonDisabled
      ? {
          [SaveButtonDisabledReasons.TwitterTask]:
            "Please fill text to continue",
          [SaveButtonDisabledReasons.ContentTask]:
            "Please attach an image or video to continue",
          [SaveButtonDisabledReasons.Loading]: "Loading",
        }[isSavedButtonDisabled]
      : "";
    return (
      <div style={{ marginTop: 5 }} key={idx}>
        <div className="campaign-preview-container">
          <div className="preview-top">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 10,
              }}
            >
              {proposal.task_completed ? (
                <div style={{ alignSelf: "center" }}>
                  <strong
                    style={{
                      fontSize: 18,
                      color: "#22b573",
                      marginTop: 10,
                      marginBottom: -5,
                    }}
                  >
                    Task Completed
                  </strong>
                </div>
              ) : null}
              {postType === "influencer_content" && (
                <div className="alert alert-warning" style={{ height: "auto" }}>
                  This task contains external content. Please note that we do
                  not save or backup external content on our servers and it is
                  your responsibility to download it, we cannot recover deleted
                  external content.
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: 10,
                }}
              >
                <div
                  style={{
                    fontSize: 17,
                    paddingTop: 10,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    display: "flex",
                    paddingRight: 15,
                  }}
                >
                  <div>{label}</div>
                  {(this.props.allowedUploadContent ||
                    !influencer?.user_registered) &&
                    !editMode &&
                    !proposal.task_completed && (
                      <div
                        title="Edit Task"
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginTop: 0,
                          marginLeft: 10,
                        }}
                        onClick={() => {
                          proposal.new_proposal = {
                            uploads: proposal?.uploads?.filter(
                              (x: any) =>
                                x.approved === null || x.approved === true,
                            ),
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            post_type: proposal.post_type,
                            text: proposal.text,
                          };
                          this.setState({});
                        }}
                        className={"fas fa-pen"}
                      ></div>
                    )}
                </div>
                {!this.props.share && this.props.shareActive && (
                  <div style={{ display: "flex" }}>
                    <button
                      className={"btn btn-primary"}
                      style={{
                        borderWidth: 0,
                        paddingRight: 15,
                        paddingLeft: 15,
                        height: 30,
                        marginTop: 3,
                        marginRight: 10,
                      }}
                      onClick={() => {
                        if (
                          waitingNote &&
                          this.state.influencer?.influencer_id !== undefined
                        ) {
                          this.props.waitingNotes[
                            this.state.influencer?.influencer_id
                          ].addOrRemove(proposal.task_id);
                        }
                        this.setState({ openProposalNote: true });
                      }}
                    >
                      <i
                        style={{ color: "white", marginRight: 5 }}
                        className="fa fa-comment indication-icon"
                      />
                      SHARED NOTES
                      {waitingNote && (
                        <div
                          style={{
                            position: "absolute",
                            width: 11,
                            height: 11,
                            marginTop: -30,
                            marginLeft: -20,
                            borderRadius: "50%",
                            background: "red",
                          }}
                        />
                      )}
                    </button>
                    <Tooltip
                      placement="bottom"
                      overlayClassName="edit-popup"
                      trigger={["click"]}
                      overlay={this.renderShareTaskTooltip(proposal)}
                    >
                      <button
                        className={"btn btn-primary"}
                        style={{
                          background: proposal.shared
                            ? "rgb(37, 189, 126)"
                            : "#fbb943",
                          borderWidth: 0,
                          paddingRight: 15,
                          paddingLeft: 15,
                          height: 30,
                          marginTop: 3,
                        }}
                      >
                        {proposal.shared ? "TASK SHARED" : "SHARE TASK"}
                      </button>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            {history ? (
              <>
                <Tabs
                  activeKey={this.state.proposalTab}
                  renderTabBar={() => <InkTabBar />}
                  renderTabContent={() => <TabContent />}
                  onChange={(tab: string) =>
                    this.setState({ proposalTab: tab })
                  }
                >
                  <TabPane tab={"CURRENT"} key={"recently"}></TabPane>
                  <TabPane
                    tab={"HISTORY"}
                    key={"history"}
                    disabled={!history}
                  ></TabPane>
                </Tabs>

                {selectedHistoryDate && (
                  <div>
                    <Select
                      options={(history as any).map((x: any) => {
                        return {
                          value: x.upload_at,
                          label: new Date(x.upload_at).toDateString(),
                        };
                      })}
                      value={selectedHistoryDate}
                      onChange={(value) =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        this.setState({ selectedHistoryDate: value })
                      }
                      placeholder={""}
                      simpleValue
                      clearable={false}
                      disabled={false}
                      searchable={false}
                    />
                    <div className="influencer-cube-spacer" />
                  </div>
                )}
              </>
            ) : null}

            {this.props.share ? (
              <>
                {!proposal.share_note && proposal.description && (
                  <div>
                    <strong>Task description</strong>
                    <div
                      style={{
                        overflow: "hidden",
                        overflowY: "auto",
                        maxHeight: 50,
                      }}
                    >
                      {proposal.description}
                    </div>
                    <div className="influencer-cube-spacer" />
                  </div>
                )}
                {proposal.share_note && (
                  <div>
                    <strong>Notes</strong>
                    <div
                      style={{
                        overflow: "hidden",
                        overflowY: "auto",
                        maxHeight: 50,
                      }}
                    >
                      {proposal.share_note}
                    </div>
                    <div className="influencer-cube-spacer" />
                  </div>
                )}
              </>
            ) : (
              proposal.description && (
                <div>
                  <strong>Task description</strong>
                  <div
                    style={{
                      overflow: "hidden",
                      overflowY: "auto",
                      maxHeight: 50,
                    }}
                  >
                    {proposal.description}
                  </div>
                  <div className="influencer-cube-spacer" />
                </div>
              )
            )}

            <div className="influencer-cube-spacer" />

            {/* {this.renderSmallPreview(editMode ? proposal.new_proposal : proposal, responded, this.props.share, true, editMode)} */}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* Left side */}
            <div style={{ width: "48%" }}>
              <strong
                className="label-title"
                style={{ display: "block", textAlign: "center", fontSize: 14 }}
              >
                Contents
              </strong>
              {this.renderPreview(
                proposal,
                !!responded,
                this.props.share,
                true,
                editMode,
              )}
            </div>
            {/* End Left side */}

            {/* Right side */}
            <div className="preview-right-side">
              <strong
                className="label-title"
                style={{ display: "block", textAlign: "center", fontSize: 14 }}
              >
                Post Preview
              </strong>
              <div
                style={{
                  width: "auto",
                  border: "1px solid #ccc",
                  margin: "auto",
                  borderRadius: 8,
                  paddingBottom: 10,
                  // height: postType === "influencer_content" ? 506 : 590,
                }}
              >
                <div
                  style={{
                    padding: 15,
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: 0,
                  }}
                >
                  <img
                    src={
                      influencer?.social_network_data?.instagram?.image_url
                        ? influencer.social_network_data.instagram.image_url
                        : influencer?.picture_url
                    }
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                      marginRight: 15,
                    }}
                  />
                  <strong
                    style={{
                      fontSize: 16,
                      color: "var(--main-baby-blue-color)",
                    }}
                  >
                    {influencer?.social_network_data?.instagram?.username}
                  </strong>
                </div>

                <div style={{ padding: 15, paddingBottom: 0 }}>
                  {editMode ? (
                    <div className="preview-content">
                      <textarea
                        placeholder="Write task content (include hashtags and mentions) ..."
                        value={proposal.new_proposal?.text}
                        onChange={(e) => {
                          if (proposal.new_proposal) {
                            proposal.new_proposal.text = e.target.value;
                            this.setState({});
                          }
                        }}
                      ></textarea>
                    </div>
                  ) : (
                    <TextExpandable
                      id={proposal.id}
                      text={proposal?.text || ""}
                    />
                  )}
                </div>
                <div
                  className="influencer-cube-spacer"
                  style={{ marginTop: 5, marginBottom: 5 }}
                />
                {editMode ? null : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <Slider
                      ref={(c: any) => {
                        this.slider = c;
                      }}
                      {...this.SliderSettings}
                      className="preview-slider"
                      beforeChange={() => {
                        document
                          .querySelectorAll("video")
                          .forEach((e) => e.pause());
                      }}
                    >
                      {proposal.uploads &&
                        proposal.uploads.map((x: any, index: number) => {
                          const type =
                            x.upload_url !== undefined &&
                            getYouTubeId(x.upload_url.toLowerCase())
                              ? "link"
                              : x.upload_type;
                          return (
                            <div
                              className="proposal-media"
                              key={`proposalimg${index}`}
                            >
                              {/* <strong style={{ fontSize: 20, color: "#249ff0", marginBottom: 5 }}>Media {index + 1} / {proposal.uploads?.length} </strong> */}
                              {this.renderMedia(x, type, "post preview")}
                              {proposal.post_type !== "influencer_content" && (
                                <div className="preview-download">
                                  <button
                                    className="btn btn-primary"
                                    disabled={type === "link"}
                                    onClick={() => {
                                      this.downloadFile(x.upload_url);
                                    }}
                                  >
                                    Download
                                  </button>
                                  <Clipboard
                                    className="btn btn-primary"
                                    data-clipboard-text={x.upload_url}
                                  >
                                    Copy Link
                                  </Clipboard>
                                </div>
                              )}
                              {/*
                                                <div style={{ margin: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', borderTop: '1px solid #ccc', width: '100%', paddingTop: 5 }}>
                                                    <strong style={{ fontSize: 16 }}>{responded ? "Your choice was" : "Please select your answer about this media"}</strong>
                                                    <div style={{ display: 'flex', cursor: 'pointer', marginTop: 5 }}>
                                                        <div onClick={() => { if (responded) { return } x.approved = true; this.slider.slickNext(); this.setState({}) }} style={{ padding: 6, fontSize: 16, border: '1px solid #aaa', borderRadius: 6, width: 100, textAlign: 'center', borderTopRightRadius: 0, borderBottomRightRadius: 0, background: x.approved ? "#17a086" : "white", color: x.approved ? "white" : "var(--main-baby-blue-color)" }}><i className="far fa-thumbs-up" style={{ marginRight: 5 }} /><strong>Like</strong></div>
                                                        <div onClick={() => { if (responded) { return } x.approved = false; this.slider.slickNext(); this.setState({}) }} style={{ padding: 6, fontSize: 16, border: '1px solid #aaa', borderRadius: 6, width: 100, textAlign: 'center', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, background: x.approved === false ? "#cf4f4e" : "white", color: x.approved === false ? "white" : "var(--main-baby-blue-color)" }}><i className="far fa-thumbs-down" style={{ marginRight: 5 }} /><strong>Dislike</strong></div>
                                                    </div>
                                                </div> */}
                            </div>
                          );
                        })}
                    </Slider>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* End Right side */}
          <div className="influencer-cube-spacer" />
        </div>

        {/* Footer */}
        {editMode && !proposal.approved ? (
          <div className="preview-footer-buttons">
            <ChakraTooltip
              label={disabledSaveBtnErrorMessage}
              hasArrow
              placement="top"
              isDisabled={!isSavedButtonDisabled}
            >
              <div>
                <button
                  className={"btn btn-pirmary"}
                  onClick={() => {
                    this.uploadProposal(proposal);
                  }}
                  disabled={!!isSavedButtonDisabled}
                >
                  <li className="fa fa-check" />
                  SAVE
                </button>
              </div>
            </ChakraTooltip>
          </div>
        ) : responded ? (
          <div
            className="preview-footer-buttons"
            style={{
              flexDirection: "column",
              alignItems: "center",
              marginTop: -10,
            }}
          >
            {proposal.approved ? (
              <span style={{ color: "#22B573", fontSize: 25 }}>Approved</span>
            ) : (
              <span style={{ color: "#F15A24", fontSize: 25 }}>
                Not Approved
              </span>
            )}
            {!selectedHistoryDate &&
              !this.props.share &&
              !proposal.task_completed && (
                <button
                  className={`btn btn-pirmary ${
                    this.state.loadingResponse && "disabled"
                  }`}
                  style={{ marginTop: 15, marginLeft: 0 }}
                  onClick={() => this.revertResponse(proposal)}
                >
                  Revert your answer
                </button>
              )}
          </div>
        ) : this.props.share && proposal.shared_rejection ? (
          <div className="preview-footer-buttons">
            <span style={{ color: "#F15A24", fontSize: 25 }}>Not Approved</span>
          </div>
        ) : (
          <div>
            <div style={{ textAlign: "center" }}>
              In order to continue make sure you mark Like/DisLike on all the
              images
            </div>

            <div className="preview-footer-buttons">
              <button
                className={`btn btn-pirmary ${
                  (!imagesMarked || this.state.loadingResponse) && "disabled"
                }`}
                onClick={() => this.responseToProposal(proposal, true)}
              >
                <li className="fa fa-check" />
                APPROVE
              </button>
              <button
                className={`btn btn-pirmary ${
                  (!imagesMarked || this.state.loadingResponse) && "disabled"
                }`}
                onClick={() =>
                  this.setState({ notImprove: { index: idx, share: false } })
                }
              >
                <li className="fa fa-times" />
                NOT APPROVE
              </button>
              {!this.state.openProposalNote && proposal.shared_rejection && (
                <button
                  className={"btn btn-pirmary "}
                  onClick={() => this.setState({ openProposalNote: true })}
                >
                  <li className="fal fa-comment-alt-lines" />
                  SHARE COMMENTS
                </button>
              )}
            </div>
          </div>
        )}
        {/* End Footer */}
      </div>
    );
  };

  renderTabs = (proposal: Proposal, idx: number) => {
    if (proposal) {
      const status = proposal.task_completed
        ? "all"
        : proposal.response_at === undefined
        ? "waiting_influ"
        : proposal.response_at === null
        ? "waiting"
        : proposal.approved
        ? "approved"
        : "not_approved";
      if (
        this.state.selectedStatus !== "all" &&
        this.state.selectedStatus !== status
      )
        return null;
      const { post_type: postType } = proposal;
      const label =
        postType === "ig_post" && proposal.post_sub_type
          ? (this.POST_SUB_TYPE as any)[proposal.post_sub_type]
          : this.POST_TYPES[postType];
      const waitingNote =
        this.state.influencer?.influencer_id !== undefined &&
        this.props.waitingNotes[this.state.influencer?.influencer_id]?.includes(
          proposal.task_id,
        );
      return (
        <TabPane
          tab={
            <div style={{ display: "flex" }}>
              <i
                style={{ marginTop: 3 }}
                className={`${this.POSTS_ICONS[postType]}  proposal-tab-title`}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ fontWeight: 600 }}>{label}</span>
                <span style={{ fontSize: 10 }}>(TASK {idx + 1})</span>
              </div>
              {waitingNote ? (
                <div
                  style={{
                    position: "absolute",
                    width: 11,
                    height: 11,
                    marginTop: 3.5,
                    marginLeft: -15,
                    borderRadius: "50%",
                    background: "red",
                  }}
                />
              ) : null}
            </div>
          }
          key={idx.toString()}
        ></TabPane>
      );
    }
    return null;
  };

  renderSideMenu = () => {
    const { columns } = this.props;
    // const influencers = columns[0].influencers.concat(columns[1].influencers).concat(columns[2].influencers);
    return (
      <div className="camp-pre-side-menu">
        <div
          className="far fa-arrow-left"
          onClick={() => this.setState({ showSideMenu: false })}
        />
        <div> Search by influencer name / username </div>
        <div
          style={{
            marginTop: 15,
            paddingBottom: 10,
            borderBottom: "1px solid #ebebeb",
          }}
          className="inner-toolbar-search-input"
        >
          {" "}
          <div className="moi-search-bold" />
          <input
            onChange={(e) =>
              this.setState({ search: e.target.value.toLowerCase() })
            }
            className="clickable form-control"
          />
        </div>
        <div className="pre-side-influs">
          {columns &&
            columns.length > 1 &&
            columns[2].influencers &&
            columns[2].influencers.map(this.renderUserRow)}
          {columns &&
            columns.length > 0 &&
            columns[1].influencers &&
            columns[1].influencers.map(this.renderUserRow)}
          {columns &&
            columns &&
            columns[0].influencers &&
            columns[0].influencers.map(this.renderUserRow)}
        </div>
      </div>
    );
  };

  renderUserRow = (influencer: any) => {
    const { search } = this.state;

    if (search && !influencer.name.toLowerCase().includes(search)) {
      const hasSocial =
        (influencer.social_network_data.instagram &&
          influencer.social_network_data.instagram.username.includes(search)) ||
        (influencer.social_network_data.twitter &&
          influencer.social_network_data.twitter.username.includes(search)) ||
        (influencer.social_network_data.youtube &&
          influencer.social_network_data.youtube.username.includes(search));
      if (!hasSocial) return null;
    }
    let current = false;
    if (this.state.selectedUser) {
      current = this.state.selectedUser === influencer.influencer_id;
    }

    let unseen =
      influencer.chat_status &&
      !influencer.chat_status.no_messages &&
      !influencer.chat_status.last_message_seen &&
      !influencer.chat_status.last_message_mine;
    if (!unseen && influencer.approved_at) {
      const proposals = influencer?.tasks?.tasks_details
        ? influencer.tasks_details.tasks
            .filter((x: any) => !x.subtask_of)
            .map((x: any) => x.proposal)
            .filter((x: any) => x)
        : [];
      unseen = proposals.filter((x: any) => x.approved === null).length > 0;
    }

    return (
      <div
        className={`preview-side-row ${current}`}
        key={`pre_row_${influencer.influencer_id}`}
        onClick={() => this.setInfluencer(influencer.influencer_id)}
      >
        <img
          className="avatar"
          src={
            influencer.picture_url &&
            influencer.picture_url.replace(".jpg", "_small.jpg")
          }
        />
        <span title={influencer.name}> {influencer.name} </span>
        {unseen && <div className="notification-chat-badge"></div>}
      </div>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    parent: state.userReducer.parent,
    boardReducer: state.boardReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAvailableInfluencersChats: (
      cleanMessages: any,
      cleanStars: any,
      max_seen_time: any,
      nameFilter: any,
      auctionId: any,
      starred: any,
      callBack: any,
    ) => {
      dispatch(
        getAvailableInfluencersChats(
          cleanMessages,
          cleanStars,
          max_seen_time,
          nameFilter,
          auctionId,
          starred,
          callBack,
        ),
      );
    },
    responseForProposal: (
      board_id: number,
      proposal_id: number,
      response: any,
      callback: any,
    ) => {
      dispatch(responseForProposal(board_id, proposal_id, response, callback));
    },
    getChatMessages: (
      id: number,
      campaignId: number,
      from: any,
      to: any,
      amount: number,
      offset: number,
      order: any,
      privateChat: boolean,
      callback: any,
    ) => {
      dispatch(
        getChatMessages(
          id,
          campaignId,
          from,
          to,
          amount,
          offset,
          order,
          privateChat,
          callback,
        ),
      );
    },
    uploadImageToBE: (image: any, type: any, callback: any) => {
      dispatch(uploadImageToBE(image, type, callback));
    },
    revertProposalResponse: (
      board_id: number,
      proposal_id: number,
      callback: any,
    ) => {
      dispatch(revertProposalResponse(board_id, proposal_id, callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignPreviewDialog);
