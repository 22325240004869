/* eslint-disable no-useless-escape */
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  Tag,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import Tooltip from "rc-tooltip";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReactLinkify from "react-linkify";

type Props = {
  message: any;
  index: number;
  system_message_to_label: any;
  selectedChatTitle?: string;
  deleteCallback: any;
  replyCallback: any;
  replyEnabled: boolean;
  user: any;
  chatDetails: any;
  isPrivate: boolean;
  renderReplyMessageView: any;
  clickOnReply: any;
};

const Message = ({
  message,
  index,
  system_message_to_label,
  selectedChatTitle,
  deleteCallback,
  replyCallback,
  replyEnabled,
  user,
  chatDetails,
  isPrivate,
  renderReplyMessageView,
  clickOnReply,
}: Props) => {
  if (!message) return null;
  const { isOpen, onClose, onOpen } = useDisclosure();
  let additonalMessageClass = message?.is_system_message
    ? "system"
    : message.mine;
  if (message.is_system_message) {
    additonalMessageClass = "system";
    const auction_message_label =
      system_message_to_label[message.system_message_type];
    const auction_title = message.system_message_auction_title;
    const length = 14;
    const trimmedString =
      selectedChatTitle?.length || 0 > length
        ? `${auction_title.substring(0, length - 3)}...`
        : auction_title;
    message.message = `"${trimmedString}" - ${window.translate(
      "",
      `${auction_message_label}adv`,
    )}`;
    // textColor = 'white';
  }
  const is_deleted = message?.mark_deleted;

  let name = "";
  if (
    message.message_content_type === "pdf" ||
    message.message_content_type === "document" ||
    message.message_content_type === "doc"
  ) {
    name = message.message.replace(/^.*[\\\/]/, "");
    name = name.substring(name.indexOf("_") + 1);
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        padding: "0px 10px",
        position: "relative",
        justifyContent:
          additonalMessageClass === true ? "flex-end" : "flex-start",
      }}
      className="clickable"
      key={message.id}
    >
      <div
        className="message-wrapper"
        key={`message${index}`}
        // onMouseEnter={onOpen}
        // onMouseLeave={onClose}
      >
        {!message?.is_system_message && (
          <div className="message-header" style={{ overflow: "visible" }}>
            <div className="flex relative">
              {message?.from_user_type !== "agent" && (
                <Avatar
                  style={{
                    width: 25,
                    height: 25,
                    borderRadius: "50%",
                    marginRight: 5,
                  }}
                  src={
                    message?.mine
                      ? user?.image
                      : message?.from_user_type === "agent"
                      ? chatDetails?.agent?.agent_image
                      : chatDetails?.influencer_social_network_data?.image ||
                        undefined
                  }
                />
              )}
              {message?.from_user_type === "agent" && (
                <Tooltip
                  overlay={`Agent: ${
                    chatDetails?.agent?.agent_name || chatDetails?.agent?.name
                  }`}
                >
                  <div
                    className="agent-icon"
                    style={{
                      position: "relative",
                      left: 0,
                      top: 0,
                      marginRight: 5,
                      width: 25,
                      height: 25,
                    }}
                  >
                    <i className="fas fa-user-tie" style={{ fontSize: 16 }} />
                  </div>
                </Tooltip>
              )}
              <strong
                style={{
                  color: "#333333",
                }}
              >
                {message?.mine
                  ? "You"
                  : message?.from_user_type === "agent"
                  ? chatDetails?.agent?.agent_name
                  : chatDetails.name ||
                    chatDetails?.influencer_social_network_data?.name}
              </strong>
              {message?.from_user_type === "agent" && (
                <Tag marginX={2} colorScheme="blue">
                  Agent
                </Tag>
              )}
              <div className="time" style={{ fontSize: 12 }}>
                {moment(message.creation_time).format(`HH:mm`)}
                {message.mine &&
                  ((isPrivate ? message?.agent_seen : true) &&
                  message.influencer_seen ? (
                    <div className="moi-two-vi" />
                  ) : (
                    <div className="moi-vi" />
                  ))}
                {message.mine && message.sending && (
                  <div className="far fa-clock" />
                )}
              </div>
            </div>
            {!is_deleted && (
              <>
                <Tooltip overlay={"Options"}>
                  <IconButton
                    className="message-option"
                    onClick={onOpen}
                    variant={"ghost"}
                    aria-label="Options"
                    icon={<i className="fas fa-ellipsis-v" />}
                  />
                </Tooltip>
                <Popover isOpen={isOpen} onClose={onClose} placement="bottom">
                  <PopoverContent
                    width="auto"
                    maxWidth={"inherit"}
                    style={{
                      background: "transparent",
                      boxShadow: "none !important",
                      border: "none !important",
                      flexDirection: "row-reverse",
                      display: "flex",
                      padding: 10,
                    }}
                  >
                    <PopoverBody
                      width="auto"
                      p={1}
                      onMouseLeave={onClose}
                      style={{
                        background: "transparent",
                        boxShadow: "none !important",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
                          background: "white",
                          borderRadius: 8,
                        }}
                      >
                        {message?.mine && (
                          <Tooltip placement={"top"} overlay={"Delete message"}>
                            <Button
                              className="message-option-action"
                              variant="ghost-non-focusable"
                              color={"#333"}
                              outline={"none"}
                              boxShadow={"none"}
                              onClick={() => {
                                deleteCallback(message);
                              }}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          </Tooltip>
                        )}
                        {message.id &&
                          (message.message_guid || message.event_guid) && (
                            <Tooltip
                              placement={"top"}
                              overlay={"Reply message"}
                            >
                              <Button
                                className="message-option-action"
                                variant="ghost-non-focusable"
                                color={"#333"}
                                outline={"none"}
                                boxShadow={"none"}
                                onClick={() => {
                                  replyCallback(message);
                                }}
                                isDisabled={!replyEnabled}
                              >
                                <i className="fas fa-share" />
                              </Button>
                            </Tooltip>
                          )}
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </>
            )}
          </div>
        )}
        <div
          className={`chat-message ${additonalMessageClass} ${
            is_deleted ? "deleted-message" : ""
          }`}
        >
          {is_deleted ? (
            <div dir="auto">
              This message was deleted by{" "}
              {message.mine ? "you" : "the influener"}
            </div>
          ) : (
            <>
              {message.reply && renderReplyMessageView(message)}
              {message.reply_to_content && !message.reply && (
                <div
                  className="reply-container"
                  onClick={() => clickOnReply(message)}
                >
                  <div>Task media</div>
                  <img src={message.reply_to_content} />
                </div>
              )}

              {message.message_content_type === "text" && (
                <ReactLinkify>
                  <div dir="auto">{message.message}</div>
                </ReactLinkify>
              )}
              {message.message_content_type === "image" && (
                <a href={message.message} target="_blank" rel="noreferrer">
                  <img src={message.message} />
                </a>
              )}
              {(message.message_content_type === "pdf" ||
                message.message_content_type === "doc" ||
                message.message_content_type === "document") && (
                <Box
                  style={{
                    padding: 2,
                    display: "flex",
                    justifyContent: "space-between",

                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      justifyItems: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="fad fa-file-alt message-file"
                      style={{
                        fontSize: 36,
                        padding: 10,
                      }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center",
                      }}
                    >
                      <a
                        href={message.message}
                        target="_blank"
                        style={{
                          alignContent: "center",
                          color: "#333",
                          fontWeight: "bold",
                        }}
                        rel="noreferrer"
                      >
                        {name}
                      </a>
                      <Box>
                        <span style={{ textTransform: "uppercase" }}>
                          {message?.message_content_type}
                        </span>{" "}
                        document
                      </Box>
                    </Box>
                  </Box>
                  <a download={message.message} href={message.message}>
                    <Button
                      variant={"roundedWhite"}
                      leftIcon={<i className="fa fa-arrow-down" />}
                    >
                      Download
                    </Button>
                  </a>
                </Box>
              )}
              {message.message_content_type === "video" && (
                <video controls>
                  <source src={message.message}></source>
                </video>
              )}
              {message.message_content_type === "audio" && (
                <audio className={"audio"} controls>
                  <source src={message.message}></source>
                </audio>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
