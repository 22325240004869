import { toast } from "react-toastify";
import { deleteMoiInboxMessageMessage_guid } from "../services";

export const DeleteChatMessage = (
  guid: string,
  isPrivate: boolean,
  callback?: any,
) => {
  return (dispatch: any) => {
    deleteMoiInboxMessageMessage_guid(guid, {
      is_private_agent_chat: isPrivate,
    })
      .then((res: any) => {
        callback(res);
        toast.success("Message was deleted");
        dispatch({
          type: "CHAT_MESSAGE_DELETED",
          payload: { message_guid: guid },
        });
      })
      .catch(() => {
        toast.error("Error deleting message");
        callback(false);
        dispatch({ type: "CHAT_MESSAGE_DELETE_ERROR" });
      });
  };
};
