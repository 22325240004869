// TODO - lint

import { removeDuplicates } from "../components/feed_components/utilities/general";

/* eslint-disable func-style, func-style, func-style, consistent-return, no-unused-vars, camelcase, no-plusplus, eqeqeq, camelcase, vars-on-top, no-var, block-scoped-var, block-scoped-var, vars-on-top, no-var, block-scoped-var, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, block-scoped-var, camelcase, no-param-reassign, vars-on-top, no-var, no-redeclare, block-scoped-var, no-param-reassign, block-scoped-var, vars-on-top, no-var, no-redeclare, block-scoped-var, block-scoped-var, func-style, camelcase, no-restricted-syntax, no-restricted-syntax, guard-for-in, camelcase, camelcase, default-param-last, no-param-reassign, no-param-reassign, no-param-reassign, no-case-declarations, camelcase, no-param-reassign, camelcase, no-param-reassign, no-param-reassign, no-case-declarations, camelcase, no-case-declarations, camelcase, camelcase, camelcase, no-param-reassign, camelcase, no-param-reassign, vars-on-top, no-var, camelcase, no-restricted-syntax, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, camelcase, no-param-reassign, camelcase, vars-on-top, no-var, vars-on-top, no-var, vars-on-top, no-var, block-scoped-var, block-scoped-var, vars-on-top, no-var, camelcase, block-scoped-var, camelcase, camelcase, no-param-reassign, block-scoped-var, camelcase, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, vars-on-top, no-var, vars-on-top, no-var, no-redeclare, vars-on-top, no-var, eqeqeq, no-param-reassign, no-cond-assign, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, vars-on-top, no-var, no-redeclare, no-param-reassign, no-empty, no-param-reassign, camelcase, camelcase, no-param-reassign, eqeqeq, eqeqeq, no-param-reassign, no-param-reassign, no-param-reassign, no-param-reassign, import/no-default-export */
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

function changeChatCounter(chats, campaignId, user_id) {
  for (let i = 0; i < chats.length; i++) {
    if (campaignId) {
      if (
        chats[i].campaign_id === campaignId &&
        chats[i].users_chats.length > 0
      ) {
        const chat = chats[i].users_chats.find((x) => x.user_id == user_id);
        if (!chat) return chats;

        chat.unseen_messages_count = chat.unseen_messages_count
          ? chat.unseen_messages_count + 1
          : 1;
        var time = new Date();
        chat.last_message_time = time.toISOString();
        // sorting users chats inner
        chats[i].users_chats.sort(function (a, b) {
          return new Date(b.last_message_time) - new Date(a.last_message_time);
        });
        // sorting all campaign chats
        var newChats = chats.sort(function (a, b) {
          a = a.users_chats
            ? a.users_chats.map((x) =>
                x.last_message_time ? x.last_message_time : 0,
              )
            : [0];
          a = Math.max(a);
          b = b.users_chats
            ? b.users_chats.map((x) =>
                x.last_message_time ? x.last_message_time : 0,
              )
            : [0];
          b = Math.max(a);
          return new Date(b.last_message_time) - new Date(a.last_message_time);
        });
        return newChats;
      }
    } else if (chats[i].user_id === user_id) {
      chats[i].unseen_messages_count = chats[i].unseen_messages_count
        ? chats[i].unseen_messages_count + 1
        : 1;
      var time = new Date();
      chats[i].last_message_time = time.toISOString();
      var newChats = chats.sort(function (a, b) {
        return new Date(b.last_message_time) - new Date(a.last_message_time);
      });
      return newChats;
    }
  }
}

const inboxReducer = (
  state = {
    users_chats: [],
    chats: [],
    new_chats: {},
    chat_messages: [],
    open_chat_campaign: -1,
    open_chat: -1,
    user_id: -1,
    read_messages: {},
  },
  action,
) => {
  let array;
  switch (action.type) {
    case "SET_USER":
      state = {
        ...state,
        user_id: action.payload.user_id,
      };
      break;
    case "SET_USER_FROM_LOCALSTORAGE":
      state = {
        ...state,
        user_id: action.payload.id,
      };
      break;
    case "GET_AVALIABLECHATS_FULFILLED":
      state = {
        ...state,
        chats: action.payload,
      };
      break;
    case "GET_MORE_AVALIABLECHATS_FULFILLED":
      const all_chats = [...state.chats] || [];
      state = {
        ...state,
        chats: all_chats.concat(action.payload),
      };
      break;

    case "GET_AVALIABLE_INFLUENCERS_CHATS_FULFILLED":
      state = {
        ...state,
        users_chats: action.payload
          ? action.payload
          : { influencers: [], starred_count: 0 },
      };
      break;

    case "RESET_INFLU_CHAT":
      state = {
        ...state,
        users_chats: {
          influencers: [],
          starred_count: action.payload
            ? null
            : state.users_chats.starred_count,
        },
      };
      break;

    case "GET_MORE_AVALIABLE_INFLUENCERS_CHATS_FULFILLED":
      const all_inf_chats = [...state.users_chats.influencers] || [];
      const users_chats = { ...state.users_chats };
      users_chats.influencers = all_inf_chats.concat(
        action.payload.influencers,
      );
      state = {
        ...state,
        users_chats,
      };

      break;

    case "GET_AVALIABLECHATS_REJECTED":
      state = {
        ...state,
        chats: [],
      };
      break;
    case "GET_NEW_CHATS_COUNT_FULFILLED":
      var new_chats = {};
      for (const new_chat_count of action.payload.new_chats) {
        let campaignId = new_chat_count.campaign_id;
        if (!campaignId) {
          campaignId = 0;
        }
        if (!new_chats[campaignId]) {
          new_chats[campaignId] = [new_chat_count.user_id];
        } else {
          new_chats[campaignId].push(new_chat_count.user_id);
        }
      }
      if (JSON.stringify(state.new_chats) !== JSON.stringify(new_chats)) {
        state = {
          ...state,

          new_chats,
        };
      }
      break;
    case "GET_CHAT_MESSAGES_FULFILLED":
      var chatId = action.payload.chat_id;
      var campaignId = action.payload.campaign_id;
      var newChatsCampaignId = campaignId;
      if (!campaignId) {
        newChatsCampaignId = 0;
      }
      var new_new_chats = state.new_chats[newChatsCampaignId];
      if (new_new_chats) {
        const indexChat = new_new_chats.indexOf(chatId);
        if (indexChat !== -1) {
          state.new_chats[newChatsCampaignId] = new_new_chats.filter(
            (_, i) => i !== indexChat,
          );
        }
      }
      array = action.payload
        ? action.payload.sort(function (a, b) {
            return new Date(a.creation_time) - new Date(b.creation_time);
          })
        : [];
      array.first_message_time = action.payload.first_message_time;
      state = {
        ...state,
        chat_messages: array,
        open_chat: chatId,
        open_chat_campaign: campaignId,
      };
      break;
    case "GET_CHAT_MESSAGES_REJECTED":
      state = {
        ...state,
        chat_messages: [],
      };
      break;
    case "UPDATE_CHAT_MESSAGES_FULFILLED":
      if (
        state.open_chat === action.payload.chat_id &&
        state.open_chat_campaign === action.payload.campaign_id
      ) {
        array = state.chat_messages.concat(action.payload.messages);
        array = removeDuplicates(array, "id").sort(function (a, b) {
          return new Date(a.creation_time) - new Date(b.creation_time);
        });
        array.first_message_time = action.payload.first_message_time;
        array.campaign_id = state.chat_messages.campaign_id;
        array.user_id = state.chat_messages.user_id;
        if (state.users_chats && state.users_chats.influencers) {
          const index = state.users_chats.influencers.findIndex(
            (x) => x.user_id === action.payload.chat_id,
          );
          if (index > -1) {
            state.users_chats.influencers[index].unseen_messages_count =
              action.payload.unread_count;
          }
        }
        state = {
          ...state,
          chat_messages: array,
        };
      }
      break;
    case "ADD_TO_INBOX_COUNTERS":
      console.log("ADD_TO_INBOX_COUNTERS", action.payload);
      var userId = state.user_id;
      var campaignId = action.payload.campaign_id;
      var sameCampaign = action.payload.campaign_id
        ? action.payload.campaign_id == state.open_chat_campaign
        : !state.open_chat_campaign;
      if (
        (state.open_chat === action.payload.influencer_fk ||
          state.open_chat === action.payload.to_user_id) &&
        sameCampaign
      ) {
        array = state.chat_messages;

        let index;
        const myMessage = userId === action.payload.user_id;
        action.payload.mine = myMessage;
        if (
          (index = array.findIndex((x) => action.payload.event_guid === x.id)) >
          -1
        ) {
          action.payload.id = action.payload.event_guid;
          array[index] = action.payload;
        } else {
          action.payload.id = guid();
          array.push(action.payload);

          if (!myMessage) {
            const eventGuid = action.payload.event_guid;
            state.read_messages[eventGuid] = {
              from_user: action.payload.user_id,
              to_user: userId,
            };
          }
        }
        state = {
          ...state,
          chat_messages: array,
        };
      } else if (userId !== action.payload.user_id) {
        var newChatsCampaignId = campaignId;
        if (!campaignId) {
          newChatsCampaignId = 0;
        }
        const newChats = state.new_chats[newChatsCampaignId];
        if (!newChats) {
          state.new_chats[newChatsCampaignId] = [action.payload.user_id];
        } else if (newChats.indexOf(action.payload.user_id) < 0) {
          newChats.push(action.payload.user_id);
        }

        // array = changeChatCounter(action.payload.campaign_id ? state.chats : state.users_chats.influencers, action.payload.campaign_id, action.payload.user_id);
        const title = `Message from - ${action.payload.sender_name}`;
        const body = action.payload.message;
        try {
          window.showNotificaion(title, body);
        } catch (e) {}
      } else {
        // array = changeChatCounter(action.payload.campaign_id ? state.chats : state.users_chats.influencers, action.payload.campaign_id, action.payload.to_user_id);
      }
      state = {
        ...state,
      };

      break;
    case "MESSAGE_SENT":
      action.payload.forEach((chat_message) => {
        const message = state.chat_messages.find(
          (m) => m.message_guid === chat_message.event_guid,
        );
        if (message) {
          message.sending = false;
        }
      });
      state = {
        ...state,
      };
      break;
    case "UPDATE_MESSAGE_ID":
      array = JSON.parse(JSON.stringify(state.chat_messages));

      const messageIndexUpdate = array.findIndex(
        (x) =>
          action.payload.event_guid === x.event_guid ||
          action.payload.event_guid === x.id,
      );
      console.log("UPDATE_MESSAGE_ID", messageIndexUpdate);
      if (messageIndexUpdate > -1) {
        console.log("UPDATE_MESSAGE_ID_BEFORE", array[messageIndexUpdate]);
        const updatedMessage = {
          ...array[messageIndexUpdate],
          message_guid: array[messageIndexUpdate].id,
          id: action.payload.inbox_message_id,
          from_user: action.payload.from_user,
        };
        array[messageIndexUpdate] = updatedMessage;
        console.log("UPDATE_MESSAGE_ID_AFTER", array[messageIndexUpdate]);
      }
      state = {
        ...state,
        chat_messages: array,
      };
      break;
    case "RESEND_MESSAGE_FULLFILLED":
      action.payload.forEach((chat_message) => {
        const message = state.chat_messages.find(
          (m) => m.id === chat_message.message_guid,
        );
        if (message) {
          message.sending = false;
        }
      });
      state = {
        ...state,
      };
      break;
    case "EXIT_CHAT":
      if (
        (state.open_chat == action.payload.id &&
          state.open_chat_campaign == action.payload.campaign_id) ||
        (state.open_chat_campaign === null && action.payload.campaign_id === -1)
      ) {
        state = {
          ...state,
          open_chat: -1,
          open_chat_campaign: -1,
        };
      }
      break;
    case "CHANGE_CHAT_STARRED":
      if (
        state.users_chats &&
        state.users_chats.influencers &&
        state.open_chat === action.payload.userId &&
        state.open_chat_campaign === action.payload.campaignId
      ) {
        state.users_chats.starred_count += action.payload.star ? 1 : -1;
        const index = state.users_chats.influencers.findIndex(
          (x) => x.user_id === action.payload.userId,
        );
        if (index > -1) {
          state.users_chats.influencers[index].is_starred = action.payload.star;
        }
      }
      state = {
        ...state,
      };
      break;
    case "CHAT_MESSAGE_DELETED":
      const index = state.chat_messages.findIndex(
        (x) =>
          x.message_guid === action.payload.message_guid ||
          x.event_guid === action.payload.message_guid,
      );
      if (index > -1) {
        state.chat_messages[index].mark_deleted = true;
      }
      state = {
        ...state,
      };
      break;
    case "CHAT_MESSAGE_DELETE_ERROR":
      state = {
        ...state,
      };
      break;
    case "RESET_FIRST_TIME_MESSAGE":
      const chat_messages = JSON.parse(JSON.stringify(state.chat_messages));
      chat_messages.first_message_time = null;
      state = {
        ...state,
        chat_messages: chat_messages,
      };
      break;
    default:
      break;
  }
  return state;
};
export default inboxReducer;
