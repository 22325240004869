/* eslint-disable */
//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from "axios";
import type { SwaggerResponse } from "./config";
import { Http } from "./httpRequest";
//@ts-ignore
import qs from "qs";
import type {
  GetLogoutQueryParams,
  GetForgot_passwordQueryParams,
  GetForgot_passwordValidate_influencerQueryParams,
  GetForgot_passwordValidateQueryParams,
  PostForgot_passwordCreate_email_verificationQueryParams,
  PostMoiAuctionsQueryParams,
  GetMoiAuctionsInfluencerQueryParams,
  GetMoiAuctionsAuction_idInfluencer_pixel_analyticsQueryParams,
  GetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdownQueryParams,
  GetMoiAuctionsIdBidsQueryParams,
  PutMoiAuctionsIdBidsQueryParams,
  GetMoiAuctionsIdRoiQueryParams,
  PostMoiAuctionsIdRoi_filteredQueryParams,
  PostMoiAuctionsIdProducts_sales_roiQueryParams,
  PostMoiAuctionsIdSales_roi_breakdownBreakdownQueryParams,
  GetMoiFollowers_segmentationHumanz_fkQueryParams,
  GetMoiInboxInfluencersQueryParams,
  GetMoiInboxInfluencersInfluencer_idQueryParams,
  GetMoiInboxCampaignsQueryParams,
  PostMoiInbox_with_first_timeIdQueryParams,
  PostMoiInboxIdQueryParams,
  PutMoiInboxIdQueryParams,
  PutMoiEdit_detailsInfluencerPasswordQueryParams,
  PutMoiEdit_detailsInfluencerBankUsQueryParams,
  GetMoiEdit_detailsInfluencerBankUsQueryParams,
  PutMoiEdit_detailsAdvertiserQueryParams,
  PutMoiEdit_detailsAgentQueryParams,
  PutMoiPaymentPayment_from_advertiserUpgrade_to_premiumQueryParams,
  GetMoiPaymentPayment_from_advertiserDisputeQueryParams,
  GetMoiReviewsInfluencerIdQueryParams,
  GetVerify_emailQueryParams,
  PutMoiAuctionsAuction_idBidsEdit_bidQueryParams,
  PostMoiPremiumSearch_influencersQueryParams,
  GetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_idQueryParams,
  GetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_idQueryParams,
  GetMoiPremiumInfluencer_storiesQueryParams,
  GetMoiPremiumListsQueryParams,
  PutMoiPremiumListsList_idQueryParams,
  PutMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_responseQueryParams,
  PostMoiPremiumListsList_idInfluencer_idPriceQueryParams,
  PostSocialLoginExternal_registerLink_apiQueryParams,
  PostSocialLoginAgentInvite_influencerAgent_uuidQueryParams,
  PostSharesRoiProducts_sales_roiQueryParams,
  GetMoiInvitesExternalQueryParams,
  PostMoiUpload_imageUpload_typeQueryParams,
  GetMoiAuctionManageAuction_idQueryParams,
  PostMoiAuctionManageAuction_idProposal_uploadTask_idQueryParams,
  PostMoiAuctionManageAuction_idPost_linkTask_idQueryParams,
  PostMoiAuctionManageAuction_idCustom_linkQueryParams,
  PutMoiAuctionManageAuction_idCustom_linkQueryParams,
  PostMoiAuctionManageAuction_idAttach_storyTask_idQueryParams,
  DeleteMoiAuctionsAuction_idUpload_invoiceQueryParams,
  PostMoiInboxIdAttachmentQueryParams,
  PostMoiPremiumBoardsBoard_idModify_taskInfluencer_idQueryParams,
  GetSharesCampaignManageQueryParams,
  GetCollaborateGet_matchesQueryParams,
  GetCollaborateInboxMatch_idQueryParams,
  PostMoiAuctionManageAuction_idProposals_historyInfluencer_idQueryParams,
  PostMoiAuctionManageAuction_idProposals_historyQueryParams,
  PostSharesCampaignProposals_historyInfluencer_idQueryParams,
  GetMoiInsightsQueryParams,
  GetSharesBrand_reportTop_wordsQueryParams,
  PostMoiInboxIdStarQueryParams,
  DeleteMoiInboxIdStarQueryParams,
  GetMoiAuctionsLiteInfluencerQueryParams,
  GetMoiCommunity_feedQueryParams,
  GetMoiCommunity_feedPost_idLikeQueryParams,
  GetMoiCommunity_feedPost_idCommentsQueryParams,
  PostMoiCommunity_feedPost_idCommentsQueryParams,
  GetMoiCommunity_feedApprovalQueryParams,
  GetMoiCommunity_feedSuggestionAllQueryParams,
  GetMoiInfluencer_profileViewed_my_profileQueryParams,
  GetMoiCampaignsQueryParams,
  GetUtilsTipaltiRequest_idQueryParams,
  PutUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_responseQueryParams,
  GetMoiPremiumListsUsers_mappingQueryParams,
  GetMoiPremiumLists_with_scrollingQueryParams,
  GetMoiAgentQueryParams,
  GetMoiAgentAgent_idBankQueryParams,
  PutMoiAgentAgent_idBankQueryParams,
  PutMoiAgentAgent_idBankTurkeyQueryParams,
  GetMoiAgentAgent_idBankUsQueryParams,
  PutMoiAgentAgent_idBankUsQueryParams,
  GetMoiAgentAgent_idBankIdQueryParams,
  DeleteMoiAgentAgent_idBankIdQueryParams,
  GetAcademyCoursesQueryParams,
  GetMoiEnterpriseUsers_logsQueryParams,
  PostWixSearch_influencersQueryParams,
  GetWixRoiFilterQueryParams,
  DeleteWixRoiFilterQueryParams,
  PutWixRoiFilterQueryParams,
  PostShopifySearch_influencersQueryParams,
  GetShopifyRoiFilterQueryParams,
  DeleteShopifyRoiFilterQueryParams,
  PutShopifyRoiFilterQueryParams,
  PostMoiAuctionsAuction_idPixel_phasesQueryParams,
  PostSocialLoginExternal_registerBusiness_discoveryQueryParams,
  DeleteMoiInboxMessageMessage_guidQueryParams,
  GetAgentInfluencerInfluencer_idQueryParams,
  GetAgentInfluencersQueryParams,
  GetAgentInfluencerStatusQueryParams,
  GetMoiUser_feature_feedbackQueryParams,
  GetMoiSupport_centerQueryParams,
  GetUtilsSend_support_notificationUser_idQueryParams,
  GetMoiSupport_centerTicket_idQueryParams,
  PostMoiSupport_centerTicket_idQueryParams,
  GetAgentAuctionsQueryParams,
  GetAgentAuctionsIdQueryParams,
  GetAgentInvoicesQueryParams,
  PostAgentInvoicesQueryParams,
  DeleteAgentInvoicesQueryParams,
  PutMoiAuctionsIdBid_invite_as_agentQueryParams,
  PostMoiPremiumAuctionAuction_idTodo_from_templateQueryParams,
  PostMoiPremiumAuctionAuction_idTodoTo_do_idCompleteQueryParams,
  GetMoiHomepage_dashboardQueryParams,
  GetMoiAgents_dashboardQueryParams,
  GetMoiHomepage_dashboardTop_influencersQueryParams,
  GetMoiAgents_dashboardTop_influencers_campaginsQueryParams,
  GetMoiAgents_dashboardSales_graphQueryParams,
  GetMoiAuctionManageAuction_idFilesInfluencer_idQueryParams,
  GetMoiAuctionManageAuction_idInfluencer_paymentsQueryParams,
  GetMoiUpdate_centerQueryParams,
  DeleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfrontQueryParams,
  GetMoiAuctionsRoiSlidesQueryParams,
  DeleteMoiAuctionsRoiSlidesQueryParams,
  GetMoiAuctionAuction_idTargetsQueryParams,
  GetMoiAuctionAuction_idTargetsMonthsQueryParams,
  DeleteMoiAuctionAuction_idTargetsDeleteQueryParams,
  GetMoiInfluencerMonths_autocompleteQueryParams,
  GetMoiInfluencerCampaigns_autocompleteQueryParams,
  GetMoiInfluencer_dashboardQueryParams,
  GetMoiInfluencer_dashboardTop_campaignsQueryParams,
  GetMoiInfluencer_dashboardTodolistQueryParams,
  UploadedImage,
  FollowersData,
  TimeFilter,
  Campaign,
  SideBarData,
  NotificationData,
  RegisteredBankAccount,
  IndianBankAccount,
  AllowedForUSBankAccount,
  InitUSBankAccount,
  InitUSBankAccountSuccess,
  ExistingUSAgentBankAccount,
  ILBankAccount,
  RegisteredUKAgentBankAccount,
  RegisteredUSAgentBankAccount,
  InfluencerShallowData,
  ReviewsStatistics,
  AuctionLink,
  StoryViewsUpload,
  InfluencerFollowersSegmentation,
  InfluencerActivityData,
  InfluencerExtendedData,
  ListSummary,
  Frequencies,
  ListData,
  PremiumListSharedLink,
  DosAndDonts,
  NewAuctionData,
  AuctionDataForInfluencer,
  AuctionDataForInfluencerExtended,
  LinkedMediaForInfluencer,
  AllowedImageUploadTypes,
  ExtendedAuctionData,
  CampaignSummary,
  InfluencerInManageAuctionData,
  TaskManageData,
  ManageAuctionState,
  MinimalSocialData,
  EditAuctionOverview,
  EditBrandInfo,
  EditAuctionPayments,
  Currency,
  EditAuctionProducts,
  Task,
  TaskWithId,
  TaskWithAdditonalPrice,
  InfluencerInvite,
  EditAuctionProduct,
  ProductIdsToDelete,
  InstructionIndicesToDelete,
  KPI,
  ManagedCampaignROI,
  EditedKPIS,
  TaskModification,
  UnfinishedCampaign,
  Chats,
  ShareListParameters,
  ShareReportParameters,
  TrackerEvent,
  UserSocialLoginSuccess,
  InfluencerDetailsRegsitration,
  CreditCardIssuer,
  InfluencerDetails,
  InfluencerDetailsEdit,
  AllAdvertiserDetails,
  BillingAddress,
  SuccessfulCreditCardTokenization,
  SuccessfulCharge,
  InfluencerDashboardSocialData,
  CampaignShareParameters,
  InfluencerCollaborateFilterParameters,
  CollaborateInfluencerProfile,
  CollaborateMessagesReceived,
  ProposalData,
  SwipeLimits,
  InfluencerUserDetails,
  InsightsReportShallowInfo,
  LinkedTaskPost,
  InfluencerWithMedias,
  PostTypes,
  AuctionTypes,
  ListTasksAmountAndPercentages,
  ShareOwnerDetails,
  CampaignShareParametersWithOwnerDetails,
  AdditionalPrice,
  MinimalAuctionDataForInfluencer,
  AffiliateTimeline,
  InfluencerProfilePage,
  CommunityFeedItem,
  CommunityFeedComment,
  UserOnFeed,
  DateAndCount,
  AuctionQuestions,
  QuestionIdsToDelete,
  QuestionAnswer,
  InfluencerSearchParametersWithQuestions,
  UserOnFeedAndActionTime,
  MinimalListSummary,
  DeviceInfo,
  NewReviewData,
  ReadReviewData,
  ExternalPageSettings,
  TasksAmounts,
  Owner,
  InfluencerAgentInfo,
  TaskReminderData,
  SupportRequestDetailsExtended,
  AdvertiserDisputeDetailsExtended,
  ListExtendedData,
  TaskWithPost,
  CourseSummary,
  Course,
  Lesson,
  AcademyQuizQuestion,
  QuizResult,
  AuctionTargetAudience,
  MultiReport,
  MultiReportFilterParameters,
  MultiReportShareParameters,
  CampaignReportFilterParameters,
  ManageAuctionData,
  MetaTag,
  AllUserTags,
  Message,
  NoteMessage,
  ShareNotesSummary,
  PurchaseClicksType,
  AuctionSales,
  CouponInSales,
  WixUserSettings,
  WixInfluencerManage,
  WixInfluencerMedia,
  WixStoryView,
  LinkAvailableCheck,
  WixSavedRoiFilterData,
  WixInfluencerData,
  ValidPasswordResetToken,
  InfluencerChangePassword,
  AppUserReport,
  RequestDataSummary,
  SocialNetworkOptions,
  RequestDataInfluencersList,
  AuctionDataForInfluencerLink,
  ExtraPayoutDetails,
  InfluencerInstagramStories,
  ManageCampaignRoiWithParameters,
  CommunitySuggestion,
  AuctionPhase,
  InfluencerInstagramPost,
  InfluencerRateCard,
  PixelWizardDetails,
  PixelEventData,
  PixelEventsCheckResponse,
  PayoutInvoiceData,
  AgeSegmentationData,
  ExternalInfluencerInviteDetails,
  AgentExternalInfluencerInviteDetails,
  ShadowInfluencer,
  ShadowInfluencerWithId,
  ListFormAnswer,
  InfluencerAgentEntity,
  UserType,
  SocialLoginDataExtracted,
  EmbeddingSearchType,
  UserFeatureFeedback,
  NewSupportTicketArticle,
  SupportTicketArticle,
  SupportTicket,
  SimilarListInfluencers,
  SocialLoginProvider,
  AllAgentDetails,
  InfluencerAgentInviteStatus,
  InfluencerAgentInviteResponse,
  InfluencerStatusInAgentList,
  InfluencerCardData,
  MinimalAuctionDataForAgent,
  AuctionDataForAgentExtended,
  AuctionToDoItem,
  TodoTemplate,
  HomepageDashboard,
  AgentsDashboard,
  InfluencerCampaignPictureMetric,
  AgentsDashboardTopCampaignsInfluencers,
  DashboardDailySales,
  CampaignFileIdentifier,
  AuctionPaymentsData,
  EditAgentDetails,
  UpdatesAvailable,
  VersionUpdate,
  TasksPaymentsInstructions,
  ROISlidesInfo,
  ROISlidesReportStatus,
  ProductDetails,
  ReachAndFrequency,
  AgentInChatInfo,
  Target,
  TargetEdit,
  TargetNotification,
  InfluencerProfileDashboard,
  InfluencerDashboardTopCampaign,
  InfluencerProfileTodo,
  CampaignIdNamePicture,
  RequestBodyInfluencerSearchParameters,
  RequestBodyInfluencerShortLinkUpdateData,
  RequestBodyTurkishBankAccount,
  RequestBodyInfluencerCustomLink,
  RequestBodyNoteToAdd,
  RequestBodyChallengeDetails,
  RequestBodyCampaignReportFilterParameters,
  RequestBodyExternalInvoiceData,
  RequestBodyBillingAddress,
  RequestBodyOrderedTimeFilter,
  RequestBodyFileToUploadDetails,
  RequestBodySupportRequestDetails,
  RequestBodyCampaignTag,
  RequestBodyPorfileMediaAttachedInsertArray,
  RequestBodyUKBankAccount,
  RequestBodyInviteResponse,
  RequestBodyInviteResponse2,
  RequestBodyBankAccountDetails,
  RequestBodyContentSearchParameters,
  RequestBodyCampaignTag2,
  RequestBodyRemoveSocialConnection,
  RequestBodyTaskModification,
  RequestBodyLoginData,
  RequestBodyExtraDataFilter,
  RequestBodySocialRegisteredLinkData,
  RequestBodyAuctionToDoItem,
  RequestBodyNewAgentInfo,
  RequestBodyMiniUsersIds,
  RequestBodyNewBidForAuctionData,
  RequestBodyFiltersAndScrollProducts,
  RequestBodyUsersToOperate,
  RequestBodyTaskProposalFeedbackData,
  RequestBodyInfluencerTaskProposalData,
  RequestBodyInfluencerIds,
  RequestBodyEndorseBody,
  RequestBodyCreateCommunityFeedItem,
  RequestBodyMultiReport,
  RequestBodyMultiReportShareParameters,
  RequestBodyWixUserSettings,
  RequestBodyLinkAvailableCheck,
  RequestBodyInfluencerShortLinkUpdateData2,
  RequestBodyAuctionPhase,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== "production";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfig,
  configOverride?: AxiosRequestConfig,
): AxiosRequestConfig {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/**
* @package
*/
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, "i");
    path = path.replace(re, obj[key]);
  });

  return path;
}

function isFormData(obj: any) {
  // This checks for the append method which should exist on FormData instances
  return (
    (typeof obj === "object" &&
      typeof obj.append === "function" &&
      obj[Symbol.toStringTag] === undefined) ||
    obj[Symbol.toStringTag] === "FormData"
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  if (isFormData(requestBody)) {
    return requestBody;
  }
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

/**
 *
 * Remove influencer from active list
 */
/**
* @package
*/
export const deleteAgentInfluencerArchiveInfluencer_id = (
  /**
   *
   * Influencer ID to remove
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteAgentInfluencerArchiveInfluencer_id.key, { influencer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteAgentInfluencerArchiveInfluencer_id.key =
  "/agent/influencer/archive/{influencer_id}";

/**
 *
 * remove influencer from agent management list - influencer request
 */
/**
* @package
*/
export const deleteAgentInfluencerDetach = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteAgentInfluencerDetach.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteAgentInfluencerDetach.key = "/agent/influencer/detach";

/**
 *
 * remove influencer from agent exclusive list
 */
/**
* @package
*/
export const deleteAgentInfluencerInfluencer_id = (
  /**
   *
   * the influencer id to remove
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteAgentInfluencerInfluencer_id.key, { influencer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteAgentInfluencerInfluencer_id.key = "/agent/influencer/{influencer_id}";

/**
 *
 * decline all agent invites for influencer
 */
/**
* @package
*/
export const deleteAgentInfluencerStatusDecline_all = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteAgentInfluencerStatusDecline_all.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteAgentInfluencerStatusDecline_all.key =
  "/agent/influencer/status/decline_all";

/**
 *
 * remove invoice
 */
/**
* @package
*/
export const deleteAgentInvoices = (
  requestBody: RequestBodyExternalInvoiceData,
  queryParams?: DeleteAgentInvoicesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteAgentInvoices.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteAgentInvoices.key = "/agent/invoices";

/**
 *
 * mark message as deleted for both sides of the match
 */
/**
* @package
*/
export const deleteCollaborateMatchInboxMatch_idMessage_id = (
  /**
   *
   * The match id you want to delete the message from
   */
  match_id: number,
  /**
   *
   * The mesasge id you want to delete
   */
  message_id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteCollaborateMatchInboxMatch_idMessage_id.key, {
      match_id,
      message_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteCollaborateMatchInboxMatch_idMessage_id.key =
  "/collaborate/match/inbox/{match_id}/{message_id}";

/**
 *
 * return to collaboration - will not return your matches
 */
/**
* @package
*/
export const deleteCollaborateOptout = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteCollaborateOptout.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteCollaborateOptout.key = "/collaborate/optout";

/**
 *
 * Delete agent by ID
 */
/**
* @package
*/
export const deleteMoiAgentAgent_id = (
  /**
   *
   * The agent id to delete
   */
  agent_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAgentAgent_id.key, { agent_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAgentAgent_id.key = "/moi/agent/{agent_id}";

/**
 *
 * delete specific agent registered bank account details
 */
/**
* @package
*/
export const deleteMoiAgentAgent_idBankId = (
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: DeleteMoiAgentAgent_idBankIdQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAgentAgent_idBankId.key, { id, agent_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAgentAgent_idBankId.key = "/moi/agent/{agent_id}/bank/{id}";

/**
 *
 * delete a target
 */
/**
* @package
*/
export const deleteMoiAuctionAuction_idTargetsDelete = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams: DeleteMoiAuctionAuction_idTargetsDeleteQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionAuction_idTargetsDelete.key, { auction_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionAuction_idTargetsDelete.key =
  "/moi/auction/{auction_id}/targets/delete";

/**
 *
 * Remove reminder
 */
/**
* @package
*/
export const deleteMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id =
  (
    /**
     *
     * The auction id for proposal
     */
    auction_id: number,
    /**
     *
     * The influencer id for proposal
     */
    influencer_id: number,
    /**
     *
     * The auction id for proposal
     */
    task_id: number,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
      template(
        deleteMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id.key,
        { auction_id, influencer_id, task_id },
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
deleteMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id.key =
  "/moi/auction/manage/{auction_id}/add_reminder_influencer/{influencer_id}/task/{task_id}";

/**
 *
 * delete files processed
 */
/**
* @package
*/
export const deleteMoiAuctionManageAuction_idFilesInfluencer_id = (
  /**
   *
   * ID of the influencer to whom the file is to be attached
   */
  influencer_id: number,
  /**
   *
   * Campaign ID to which the file is associated with the user
   */
  auction_id: number,
  requestBody: { uuids?: string[] },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionManageAuction_idFilesInfluencer_id.key, {
      influencer_id,
      auction_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionManageAuction_idFilesInfluencer_id.key =
  "/moi/auction/manage/{auction_id}/files/{influencer_id}";

/**
 *
 * mark extra payout as deleted.
 */
/**
* @package
*/
export const deleteMoiAuctionManageAuction_idInfluencer_idExtra_payoutExtra_payout_id =
  (
    /**
     *
     * The id of the auction
     */
    auction_id: number,
    /**
     *
     * The id of the influencers
     */
    influencer_id: number,
    /**
     *
     * Id of the extra payout
     */
    extra_payout_id: number,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
      template(
        deleteMoiAuctionManageAuction_idInfluencer_idExtra_payoutExtra_payout_id.key,
        { auction_id, influencer_id, extra_payout_id },
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
deleteMoiAuctionManageAuction_idInfluencer_idExtra_payoutExtra_payout_id.key =
  "/moi/auction/manage/{auction_id}/{influencer_id}/extra_payout/{extra_payout_id}";

/**
 *
 * delete dispute for payment
 */
/**
* @package
*/
export const deleteMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencer
   */
  influencer_id: number,
  requestBody: { ids?: string[] },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(
      deleteMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes.key,
      { auction_id, influencer_id },
    ),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes.key =
  "/moi/auction/manage/{auction_id}/payments/{influencer_id}/disputes";

/**
 *
 * delete upfront payment
 */
/**
* @package
*/
export const deleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencer
   */
  influencer_id: number,
  queryParams: DeleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfrontQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront.key, {
      auction_id,
      influencer_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront.key =
  "/moi/auction/manage/{auction_id}/payments/{influencer_id}/upfront";

/**
 *
 * Advertiser's delete response for influencer proposal
 */
/**
* @package
*/
export const deleteMoiAuctionManageAuction_idProposal_feedbackProposal_id = (
  /**
   *
   * The campaign id for proposal
   */
  auction_id: number,
  /**
   *
   * The influencer's proposal id
   */
  proposal_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionManageAuction_idProposal_feedbackProposal_id.key, {
      auction_id,
      proposal_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionManageAuction_idProposal_feedbackProposal_id.key =
  "/moi/auction/manage/{auction_id}/proposal_feedback/{proposal_id}";

/**
 *
 * delete influencer short link
 */
/**
* @package
*/
export const deleteMoiAuctionManageAuction_idShort_linkInfluencer_id = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencer
   */
  influencer_id: number,
  requestBody: {
    /**
     *
     * - Format: int64
     */
    extra_id?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionManageAuction_idShort_linkInfluencer_id.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionManageAuction_idShort_linkInfluencer_id.key =
  "/moi/auction/manage/{auction_id}/short_link/{influencer_id}";

/**
 *
 * Delete board tag
 */
/**
* @package
*/
export const deleteMoiAuctionManageAuction_idTagTag_id = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the tag
   */
  tag_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionManageAuction_idTagTag_id.key, {
      auction_id,
      tag_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionManageAuction_idTagTag_id.key =
  "/moi/auction/manage/{auction_id}/tag/{tag_id}";

/**
 *
 * Advertiser delete influencer linked media
 */
/**
* @package
*/
export const deleteMoiAuctionManageBoard_idInfluencer_idPost_linkTask_id = (
  /**
   *
   * The board id for proposal
   */
  board_id: number,
  /**
   *
   * The influencer's task id
   */
  task_id: number,
  /**
   *
   * The influencer's id
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionManageBoard_idInfluencer_idPost_linkTask_id.key, {
      board_id,
      task_id,
      influencer_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionManageBoard_idInfluencer_idPost_linkTask_id.key =
  "/moi/auction/manage/{board_id}/{influencer_id}/post_link/{task_id}";

/**
 *
 * Advertiser delete influencer linked story
 */
/**
* @package
*/
export const deleteMoiAuctionManageBoard_idStory_view_countInfluencer_idTask_id =
  (
    /**
     *
     * The board id for proposal
     */
    board_id: number,
    /**
     *
     * The influencer's task id
     */
    task_id: number,
    /**
     *
     * The influencer's id
     */
    influencer_id: number,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
      template(
        deleteMoiAuctionManageBoard_idStory_view_countInfluencer_idTask_id.key,
        { board_id, task_id, influencer_id },
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
deleteMoiAuctionManageBoard_idStory_view_countInfluencer_idTask_id.key =
  "/moi/auction/manage/{board_id}/story_view_count/{influencer_id}/{task_id}";

/**
 *
 * mark coupon as deleted
 */
/**
* @package
*/
export const deleteMoiAuctionsAuction_idCoupons = (
  /**
   *
   * the auction id to get sales coupons
   */
  auction_id: number,
  requestBody: {
    /**
     *
     * - Format: int64
     */
    coupon_auction_id?: number;
    /**
     *
     * - Format: uuid
     */
    coupon_uuid?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionsAuction_idCoupons.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionsAuction_idCoupons.key = "/moi/auctions/{auction_id}/coupons";

/**
 *
 * Delete auction instructions for existing auction
 */
/**
* @package
*/
export const deleteMoiAuctionsAuction_idInstructions = (
  /**
   *
   * the auction id to remove instruction from
   */
  auction_id: number,
  requestBody: InstructionIndicesToDelete,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionsAuction_idInstructions.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionsAuction_idInstructions.key =
  "/moi/auctions/{auction_id}/instructions";

/**
 *
 * edit auction phase
 */
/**
* @package
*/
export const deleteMoiAuctionsAuction_idPixel_phasesPhase_id = (
  /**
   *
   * the auction id to get the phases for
   */
  auction_id: number,
  /**
   *
   * the phase id get edit
   */
  phase_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionsAuction_idPixel_phasesPhase_id.key, {
      auction_id,
      phase_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionsAuction_idPixel_phasesPhase_id.key =
  "/moi/auctions/{auction_id}/pixel_phases/{phase_id}";

/**
 *
 * Delete auction products for existing auction
 */
/**
* @package
*/
export const deleteMoiAuctionsAuction_idProduct = (
  /**
   *
   * the auction id to add products to
   */
  auction_id: number,
  requestBody: ProductIdsToDelete,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionsAuction_idProduct.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionsAuction_idProduct.key = "/moi/auctions/{auction_id}/product";

/**
 *
 * Delete auction questions for existing auction
 */
/**
* @package
*/
export const deleteMoiAuctionsAuction_idQuestions = (
  /**
   *
   * the auction id to remomve questions from
   */
  auction_id: number,
  requestBody: QuestionIdsToDelete,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionsAuction_idQuestions.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionsAuction_idQuestions.key =
  "/moi/auctions/{auction_id}/questions";

/**
 *
 * delete invoice file to server multipart
 */
/**
* @package
*/
export const deleteMoiAuctionsAuction_idUpload_invoice = (
  /**
   *
   * The auction id
   */
  auction_id: number,
  queryParams?: DeleteMoiAuctionsAuction_idUpload_invoiceQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionsAuction_idUpload_invoice.key, { auction_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionsAuction_idUpload_invoice.key =
  "/moi/auctions/{auction_id}/upload_invoice";

/**
 *
 * Delete an existing auction
 */
/**
* @package
*/
export const deleteMoiAuctionsId = (
  /**
   *
   * The auction id to delete
   */
  id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiAuctionsId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionsId.key = "/moi/auctions/{id}";

/**
 *
 * Soft delete a specific report
 */
/**
* @package
*/
export const deleteMoiAuctionsRoiSlides = (
  queryParams?: DeleteMoiAuctionsRoiSlidesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteMoiAuctionsRoiSlides.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiAuctionsRoiSlides.key = "/moi/auctions/roi/slides";

/**
 * 
 * hide bid

 */
/**
* @package
*/
export const deleteMoiBidsBid_idHide = (
  /**
   *
   * The bid id you want to unhide
   */
  bid_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiBidsBid_idHide.key, { bid_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiBidsBid_idHide.key = "/moi/bids/{bid_id}/hide";

/**
 * 
 * Mark bid as_deleted to true

 */
/**
* @package
*/
export const deleteMoiBidsDeleteBid_id = (
  /**
   *
   * The bid_id to mark as undeleted
   */
  bid_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiBidsDeleteBid_id.key, { bid_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiBidsDeleteBid_id.key = "/moi/bids/delete/{bid_id}";

/**
 *
 * only for approvers , revert response
 */
/**
* @package
*/
export const deleteMoiCommunity_feedApprovalPost_id = (
  /**
   *
   * post id
   */
  post_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiCommunity_feedApprovalPost_id.key, { post_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiCommunity_feedApprovalPost_id.key =
  "/moi/community_feed/approval/{post_id}";

/**
 *
 * delete post in feed
 */
/**
* @package
*/
export const deleteMoiCommunity_feedPost_id = (
  /**
   *
   * post id
   */
  post_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiCommunity_feedPost_id.key, { post_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiCommunity_feedPost_id.key = "/moi/community_feed/{post_id}";

/**
 *
 * add comment to feed item
 */
/**
* @package
*/
export const deleteMoiCommunity_feedPost_idCommentsComment_id = (
  /**
   *
   * post id
   */
  post_id: number,
  /**
   *
   * comment id
   */
  comment_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiCommunity_feedPost_idCommentsComment_id.key, {
      post_id,
      comment_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiCommunity_feedPost_idCommentsComment_id.key =
  "/moi/community_feed/{post_id}/comments/{comment_id}";

/**
 *
 * unlike post in feed
 */
/**
* @package
*/
export const deleteMoiCommunity_feedPost_idCommentsComment_idLike = (
  /**
   *
   * post id
   */
  post_id: number,
  /**
   *
   * comment id
   */
  comment_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiCommunity_feedPost_idCommentsComment_idLike.key, {
      post_id,
      comment_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiCommunity_feedPost_idCommentsComment_idLike.key =
  "/moi/community_feed/{post_id}/comments/{comment_id}/like";

/**
 *
 * unlike post in feed
 */
/**
* @package
*/
export const deleteMoiCommunity_feedPost_idLike = (
  /**
   *
   * post id
   */
  post_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiCommunity_feedPost_idLike.key, { post_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiCommunity_feedPost_idLike.key = "/moi/community_feed/{post_id}/like";

/**
 *
 * mark suggestion as deleted
 */
/**
* @package
*/
export const deleteMoiCommunity_feedSuggestionSuggestion_id = (
  /**
   *
   * marks suggestion as deleted
   */
  suggestion_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiCommunity_feedSuggestionSuggestion_id.key, {
      suggestion_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiCommunity_feedSuggestionSuggestion_id.key =
  "/moi/community_feed/suggestion/{suggestion_id}";

/**
 * 
 * delete user

 */
/**
* @package
*/
export const deleteMoiDelete_user = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteMoiDelete_user.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiDelete_user.key = "/moi/delete_user";

/**
 *
 * delete brand
 */
/**
* @package
*/
export const deleteMoiDetailsBrandBrand_id = (
  /**
   *
   * The brand to delete
   */
  brand_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiDetailsBrandBrand_id.key, { brand_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiDetailsBrandBrand_id.key = "/moi/details/brand/{brand_id}";

/**
 *
 * delete specific registered bank account details
 */
/**
* @package
*/
export const deleteMoiEdit_detailsInfluencerBankId = (
  id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiEdit_detailsInfluencerBankId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiEdit_detailsInfluencerBankId.key =
  "/moi/edit_details/influencer/bank/{id}";

/**
 *
 * remove user from your sub users
 */
/**
* @package
*/
export const deleteMoiEnterpriseUsersRemove_user = (
  requestBody: { email?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteMoiEnterpriseUsersRemove_user.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiEnterpriseUsersRemove_user.key = "/moi/enterprise/users/remove_user";

/**
 *
 * unstar chat
 */
/**
* @package
*/
export const deleteMoiInboxIdStar = (
  /**
   *
   * The user id you want to chat with
   */
  id: number,
  queryParams?: DeleteMoiInboxIdStarQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiInboxIdStar.key, { id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiInboxIdStar.key = "/moi/inbox/{id}/star";

/**
 * 
 * Delete messages from this chat

 */
/**
* @package
*/
export const deleteMoiInboxMessageMessage_guid = (
  /**
   *
   * The message guid to delete
   */
  message_guid: string,
  queryParams?: DeleteMoiInboxMessageMessage_guidQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiInboxMessageMessage_guid.key, { message_guid }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiInboxMessageMessage_guid.key = "/moi/inbox/message/{message_guid}";

/**
 * 
 * Delete meta tag

 */
/**
* @package
*/
export const deleteMoiMeta_tagsTag_typeTag_id = (
  /**
   *
   * The tag type
   */
  tag_type: string,
  /**
   *
   * The tag id
   */
  tag_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiMeta_tagsTag_typeTag_id.key, { tag_type, tag_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiMeta_tagsTag_typeTag_id.key = "/moi/meta_tags/{tag_type}/{tag_id}";

/**
 *
 * Soft delete all user notifications
 */
/**
* @package
*/
export const deleteMoiNotifications = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteMoiNotifications.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiNotifications.key = "/moi/notifications";

/**
 *
 * Soft delete notification
 */
/**
* @package
*/
export const deleteMoiNotificationsNotificationId = (
  /**
   *
   * Notification id to delete
   */
  notificationId: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiNotificationsNotificationId.key, { notificationId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiNotificationsNotificationId.key =
  "/moi/notifications/{notificationId}";

/**
 *
 * delete payment method
 */
/**
* @package
*/
export const deleteMoiPaymentPayment_from_advertiserPayment_method_id = (
  /**
   *
   * The payment method to delete
   */
  payment_method_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiPaymentPayment_from_advertiserPayment_method_id.key, {
      payment_method_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiPaymentPayment_from_advertiserPayment_method_id.key =
  "/moi/payment/payment_from_advertiser/{payment_method_id}";

/**
 *
 * disable roi share
 */
/**
* @package
*/
export const deleteMoiPremiumAuctionAuction_idShare = (
  /**
   *
   * The id of the list to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiPremiumAuctionAuction_idShare.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiPremiumAuctionAuction_idShare.key =
  "/moi/premium/auction/{auction_id}/share";

/**
 *
 * edit auction todo item
 */
/**
* @package
*/
export const deleteMoiPremiumAuctionAuction_idTodoTo_do_id = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  /**
   *
   * The to do item you want to delete
   */
  to_do_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiPremiumAuctionAuction_idTodoTo_do_id.key, {
      auction_id,
      to_do_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiPremiumAuctionAuction_idTodoTo_do_id.key =
  "/moi/premium/auction/{auction_id}/todo/{to_do_id}";

/**
 *
 * delete list
 */
/**
* @package
*/
export const deleteMoiPremiumListsList_id = (
  /**
   *
   * The id of the list to add to
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiPremiumListsList_id.key, { list_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiPremiumListsList_id.key = "/moi/premium/lists/{list_id}";

/**
 *
 * disable link share
 */
/**
* @package
*/
export const deleteMoiPremiumListsList_idShare = (
  /**
   *
   * The id of the list to share
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiPremiumListsList_idShare.key, { list_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiPremiumListsList_idShare.key = "/moi/premium/lists/{list_id}/share";

/**
 *
 * Delete board tag
 */
/**
* @package
*/
export const deleteMoiPremiumListsList_idTagTag_id = (
  /**
   *
   * The id of the list
   */
  list_id: number,
  /**
   *
   * The id of the tag
   */
  tag_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiPremiumListsList_idTagTag_id.key, { list_id, tag_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiPremiumListsList_idTagTag_id.key =
  "/moi/premium/lists/{list_id}/tag/{tag_id}";

/**
 *
 * disable roi share
 */
/**
* @package
*/
export const deleteMoiPremiumRoiAuction_idShare = (
  /**
   *
   * The id of the list to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiPremiumRoiAuction_idShare.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiPremiumRoiAuction_idShare.key = "/moi/premium/roi/{auction_id}/share";

/**
 *
 * delete template
 */
/**
* @package
*/
export const deleteMoiPremiumTodo_templatesTemplate_id = (
  /**
   *
   * The id of the template to create item in
   */
  template_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiPremiumTodo_templatesTemplate_id.key, { template_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiPremiumTodo_templatesTemplate_id.key =
  "/moi/premium/todo_templates/{template_id}";

/**
 *
 * delete auction todo item
 */
/**
* @package
*/
export const deleteMoiPremiumTodo_templatesTemplate_idTodoTo_do_id = (
  /**
   *
   * The id of the template to edit
   */
  template_id: number,
  /**
   *
   * The to do item you want to delete
   */
  to_do_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiPremiumTodo_templatesTemplate_idTodoTo_do_id.key, {
      template_id,
      to_do_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiPremiumTodo_templatesTemplate_idTodoTo_do_id.key =
  "/moi/premium/todo_templates/{template_id}/todo/{to_do_id}";

/**
 * 
 * Delete the last instagram challenge of a user

 */
/**
* @package
*/
export const deleteMoiRemove_last_user_instagram_challengeChallenge_id = (
  /**
   *
   * the challenge id
   */
  challenge_id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiRemove_last_user_instagram_challengeChallenge_id.key, {
      challenge_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiRemove_last_user_instagram_challengeChallenge_id.key =
  "/moi/remove_last_user_instagram_challenge/{challenge_id}";

/**
 *
 * Delete shadow influencer by id
 */
/**
* @package
*/
export const deleteMoiShadow_influencersShadow_influencer_id = (
  /**
   *
   * Shadow influencer id to edit
   */
  shadow_influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiShadow_influencersShadow_influencer_id.key, {
      shadow_influencer_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiShadow_influencersShadow_influencer_id.key =
  "/moi/shadow_influencers/{shadow_influencer_id}";

/**
 *
 * delete specific ticket
 */
/**
* @package
*/
export const deleteMoiSupport_centerTicket_id = (
  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiSupport_centerTicket_id.key, { ticket_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiSupport_centerTicket_id.key = "/moi/support_center/{ticket_id}";

/**
 *
 * delete task offer
 */
/**
* @package
*/
export const deleteMoiTask_offersTask_offer_id = (
  /**
   *
   * The id of the offer
   */
  task_offer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiTask_offersTask_offer_id.key, { task_offer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiTask_offersTask_offer_id.key = "/moi/task_offers/{task_offer_id}";

/**
 *
 * Delete unfinished campaign
 */
/**
* @package
*/
export const deleteMoiUnfinished_campaignsUnfinished_campaign_id = (
  /**
   *
   * The unfinished campaign id
   */
  unfinished_campaign_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteMoiUnfinished_campaignsUnfinished_campaign_id.key, {
      unfinished_campaign_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiUnfinished_campaignsUnfinished_campaign_id.key =
  "/moi/unfinished_campaigns/{unfinished_campaign_id}";

/**
 *
 * cancel ongoing uploads
 */
/**
* @package
*/
export const deleteMoiUpload_imageUpload_type = (
  upload_type: AllowedImageUploadTypes,
  requestBody: string[],
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      /**
       *
       * - Format: uuid
       */
      id?: string;
      status?: "cancel" | "not_found" | "used";
    }[]
  >
> => {
  return Http.deleteRequest(
    template(deleteMoiUpload_imageUpload_type.key, { upload_type }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteMoiUpload_imageUpload_type.key = "/moi/upload_image/{upload_type}";

/**
 *
 * add influencers to user
 */
/**
* @package
*/
export const deleteShopifyManageInfluencers = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteShopifyManageInfluencers.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteShopifyManageInfluencers.key = "/shopify/manage/influencers";

/**
 *
 * Delete saved filter by id
 */
/**
* @package
*/
export const deleteShopifyRoiFilter = (
  queryParams: DeleteShopifyRoiFilterQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteShopifyRoiFilter.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteShopifyRoiFilter.key = "/shopify/roi/filter";

/**
 *
 * Delete short link extra link for influencer
 */
/**
* @package
*/
export const deleteShopifySettingsLinkInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  requestBody: RequestBodyInfluencerShortLinkUpdateData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteShopifySettingsLinkInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteShopifySettingsLinkInfluencer_id.key =
  "/shopify/settings/link/{influencer_id}";

/**
 *
 * add influencers to user
 */
/**
* @package
*/
export const deleteWixManageInfluencers = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteWixManageInfluencers.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteWixManageInfluencers.key = "/wix/manage/influencers";

/**
 *
 * Delete saved filter by id
 */
/**
* @package
*/
export const deleteWixRoiFilter = (
  queryParams: DeleteWixRoiFilterQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    deleteWixRoiFilter.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteWixRoiFilter.key = "/wix/roi/filter";

/**
 *
 * Delete short link extra link for influencer
 */
/**
* @package
*/
export const deleteWixSettingsLinkInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  requestBody: RequestBodyInfluencerShortLinkUpdateData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.deleteRequest(
    template(deleteWixSettingsLinkInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
deleteWixSettingsLinkInfluencer_id.key = "/wix/settings/link/{influencer_id}";

/**
 * 
 * Get specific course translation id => only if susbcribed to it

 */
/**
* @package
*/
export const getAcademyChaptersChapter_idLessonLesson_ordinal_num = (
  /**
   *
   * the current chapter id
   */
  chapter_id: number,
  /**
   *
   * the lesson ordinal number
   */
  lesson_ordinal_num: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Lesson>> => {
  return Http.getRequest(
    template(getAcademyChaptersChapter_idLessonLesson_ordinal_num.key, {
      chapter_id,
      lesson_ordinal_num,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAcademyChaptersChapter_idLessonLesson_ordinal_num.key =
  "/academy/chapters/{chapter_id}/lesson/{lesson_ordinal_num}";

/**
 * 
 * Get all available courses for me

 */
/**
* @package
*/
export const getAcademyCourses = (
  queryParams?: GetAcademyCoursesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CourseSummary[]>> => {
  return Http.getRequest(
    getAcademyCourses.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAcademyCourses.key = "/academy/courses";

/**
 * 
 * Get specific course translation id => only if susbcribed to it

 */
/**
* @package
*/
export const getAcademyCoursesCourse_translation_id = (
  /**
   *
   * the course translation id
   */
  course_translation_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Course>> => {
  return Http.getRequest(
    template(getAcademyCoursesCourse_translation_id.key, {
      course_translation_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAcademyCoursesCourse_translation_id.key =
  "/academy/courses/{course_translation_id}";

/**
 * 
 * Get quiz

 */
/**
* @package
*/
export const getAcademyQuizQuiz_id = (
  /**
   *
   * the chapter quiz id
   */
  quiz_id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AcademyQuizQuestion[]>> => {
  return Http.getRequest(
    template(getAcademyQuizQuiz_id.key, { quiz_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAcademyQuizQuiz_id.key = "/academy/quiz/{quiz_id}";

/**
 *
 * Get all auctions for agent
 */
/**
* @package
*/
export const getAgentAuctions = (
  queryParams: GetAgentAuctionsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MinimalAuctionDataForAgent[]>> => {
  return Http.getRequest(
    getAgentAuctions.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAgentAuctions.key = "/agent/auctions";

/**
 *
 * Get auction by id for agent
 */
/**
* @package
*/
export const getAgentAuctionsId = (
  /**
   *
   * the auction id to query
   */
  id: number,
  queryParams?: GetAgentAuctionsIdQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionDataForAgentExtended>> => {
  return Http.getRequest(
    template(getAgentAuctionsId.key, { id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAgentAuctionsId.key = "/agent/auctions/{id}";

/**
 *
 * Get auction by id for agent from influencer view
 */
/**
* @package
*/
export const getAgentAuctionsIdInfluencerInfluencer_id = (
  /**
   *
   * the auction id to query
   */
  id: number,
  /**
   *
   * specific influencer id to filter
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionDataForInfluencerExtended>> => {
  return Http.getRequest(
    template(getAgentAuctionsIdInfluencerInfluencer_id.key, {
      id,
      influencer_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAgentAuctionsIdInfluencerInfluencer_id.key =
  "/agent/auctions/{id}/influencer/{influencer_id}";

/**
 *
 * Get current agent details
 */
/**
* @package
*/
export const getAgentCurrent_user = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AllAgentDetails>> => {
  return Http.getRequest(
    getAgentCurrent_user.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAgentCurrent_user.key = "/agent/current_user";

/**
 *
 * query influencer status and info by id
 */
/**
* @package
*/
export const getAgentInfluencerInfluencer_id = (
  /**
   *
   * the influencer id to query
   */
  influencer_id: number,
  queryParams?: GetAgentInfluencerInfluencer_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerStatusInAgentList>> => {
  return Http.getRequest(
    template(getAgentInfluencerInfluencer_id.key, { influencer_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAgentInfluencerInfluencer_id.key = "/agent/influencer/{influencer_id}";

/**
 *
 * list all of agent users linked to influencer, defaults to only currently pending
 */
/**
* @package
*/
export const getAgentInfluencerStatus = (
  queryParams?: GetAgentInfluencerStatusQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      agent_display_name?: string;
      /**
       *
       * - Format: int64
       */
      agent_user_id?: number;
      history?: {
        /**
         *
         * - Format: date-time
         */
        end_time?: string;
        /**
         *
         * - Format: date-time
         */
        start_time?: string;
        status?: InfluencerAgentInviteStatus;
      }[];
    }[]
  >
> => {
  return Http.getRequest(
    getAgentInfluencerStatus.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAgentInfluencerStatus.key = "/agent/influencer/status";

/**
 *
 * list all agent's influencers
 */
/**
* @package
*/
export const getAgentInfluencers = (
  queryParams?: GetAgentInfluencersQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerStatusInAgentList[]>> => {
  return Http.getRequest(
    getAgentInfluencers.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAgentInfluencers.key = "/agent/influencers/";

/**
 *
 * get external invoices by shared guid
 */
/**
* @package
*/
export const getAgentInvoices = (
  queryParams?: GetAgentInvoicesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PayoutInvoiceData[]>> => {
  return Http.getRequest(
    getAgentInvoices.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAgentInvoices.key = "/agent/invoices";

/**
 *
 * get/create payments page id by agent user id
 */
/**
* @package
*/
export const getAgentPaymentsExternal_page = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    company_id?: string;
    is_active?: boolean;
    /**
     *
     * - Format: uuid
     */
    uuid?: string;
  }>
> => {
  return Http.getRequest(
    getAgentPaymentsExternal_page.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getAgentPaymentsExternal_page.key = "/agent/payments/external_page";

/**
 *
 * Get influencer collaborate filter paramteres
 */
/**
* @package
*/
export const getCollaborateFilter_parameters = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerCollaborateFilterParameters>> => {
  return Http.getRequest(
    getCollaborateFilter_parameters.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCollaborateFilter_parameters.key = "/collaborate/filter_parameters";

/**
 *
 * Get intersection of followers between me and another influencer
 */
/**
* @package
*/
export const getCollaborateGet_followers_intersectionUser_id = (
  /**
   *
   * The influencer id you want to chat with
   */
  user_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: double
     */
    percentage?: number;
  }>
> => {
  return Http.getRequest(
    template(getCollaborateGet_followers_intersectionUser_id.key, { user_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCollaborateGet_followers_intersectionUser_id.key =
  "/collaborate/get_followers_intersection/{user_id}";

/**
 *
 * Get collaborate likes the user
 */
/**
* @package
*/
export const getCollaborateGet_likers = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CollaborateInfluencerProfile[]>> => {
  return Http.getRequest(
    getCollaborateGet_likers.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCollaborateGet_likers.key = "/collaborate/get_likers";

/**
 *
 * Get collaborate matches
 */
/**
* @package
*/
export const getCollaborateGet_matches = (
  queryParams?: GetCollaborateGet_matchesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      influencer?: CollaborateInfluencerProfile;
      /**
       *
       * - Format: date-time
       */
      match_activity_time?: string;
      /**
       *
       * - Format: int64
       */
      match_id?: number;
      /**
       *
       * MatchLastMessage
       *
       */
      messages_details?: {
        last_message?: string;
        last_message_content_type?: string;
        last_message_mine?: boolean;
        /**
         *
         * - Format: date-time
         */
        last_message_time?: string;
        mark_deleted?: boolean;
        message_guid?: string;
        seen_match?: boolean;
        /**
         *
         * - Format: int64
         */
        unseen_messages_count?: number;
      };
    }[]
  >
> => {
  return Http.getRequest(
    getCollaborateGet_matches.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCollaborateGet_matches.key = "/collaborate/get_matches";

/**
 *
 * Get collaborate useres to swipe
 */
/**
* @package
*/
export const getCollaborateGet_new_users = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    influencers?: CollaborateInfluencerProfile[];
    swipe_limits?: SwipeLimits;
  }>
> => {
  return Http.getRequest(
    getCollaborateGet_new_users.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCollaborateGet_new_users.key = "/collaborate/get_new_users";

/**
 * 
 * Get more messages from this chat

 */
/**
* @package
*/
export const getCollaborateInboxMatch_id = (
  /**
   *
   * The match id you want to chat with
   */
  match_id: number,
  queryParams?: GetCollaborateInboxMatch_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CollaborateMessagesReceived>> => {
  return Http.getRequest(
    template(getCollaborateInboxMatch_id.key, { match_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCollaborateInboxMatch_id.key = "/collaborate/inbox/{match_id}";

/**
 * 
 * Get user ids of chats which have new messages

 */
/**
* @package
*/
export const getCollaborateInboxNew_chats = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ match_ids?: number[] }>> => {
  return Http.getRequest(
    getCollaborateInboxNew_chats.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCollaborateInboxNew_chats.key = "/collaborate/inbox/new_chats";

/**
 * 
 * Get match id with messages

 */
/**
* @package
*/
export const getCollaborateMatchMatch_id = (
  /**
   *
   * The match id you want to chat with
   */
  match_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    influencer_details?: CollaborateInfluencerProfile;
    last_messages?: CollaborateMessagesReceived;
    /**
     *
     * - Format: date-time
     */
    matched_at?: string;
  }>
> => {
  return Http.getRequest(
    template(getCollaborateMatchMatch_id.key, { match_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCollaborateMatchMatch_id.key = "/collaborate/match/{match_id}";

/**
 * 
 * Get number of matches unseen

 */
/**
* @package
*/
export const getCollaborateMatchUnseen_count = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    unseen?: number;
  }>
> => {
  return Http.getRequest(
    getCollaborateMatchUnseen_count.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getCollaborateMatchUnseen_count.key = "/collaborate/match/unseen_count";

/**
 * 
 * get invite details

 */
/**
* @package
*/
export const getEnterprise_inviteInvite_id = (
  /**
   *
   * the invite id
   */
  invite_id: string,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    email_invited?: string;
    /**
     *
     * - Format: int64
     */
    enterprise_name?: string;
    image?: string;
    /**
     *
     * - Format: date-time
     */
    invite_created_at?: string;
    invite_status?: "accepted" | "rejected" | "removed" | "waiting_response";
  }>
> => {
  return Http.getRequest(
    template(getEnterprise_inviteInvite_id.key, { invite_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getEnterprise_inviteInvite_id.key = "/enterprise_invite/{invite_id}";

/**
 * 
 * accept invite

 */
/**
* @package
*/
export const getEnterprise_inviteInvite_idAccept = (
  /**
   *
   * the invite id
   */
  invite_id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getEnterprise_inviteInvite_idAccept.key, { invite_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getEnterprise_inviteInvite_idAccept.key =
  "/enterprise_invite/{invite_id}/accept";

/**
 * 
 * decline invite

 */
/**
* @package
*/
export const getEnterprise_inviteInvite_idDecline = (
  /**
   *
   * the invite id
   */
  invite_id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getEnterprise_inviteInvite_idDecline.key, { invite_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getEnterprise_inviteInvite_idDecline.key =
  "/enterprise_invite/{invite_id}/decline";

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const getExternal_apiProfileInfluencer_username = (
  /**
   *
   * the influencer username
   */
  influencer_username: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerProfilePage>> => {
  return Http.getRequest(
    template(getExternal_apiProfileInfluencer_username.key, {
      influencer_username,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getExternal_apiProfileInfluencer_username.key =
  "/external_api/profile/{influencer_username}";

/**
 *
 * Create a password recovery process
 */
/**
* @package
*/
export const getForgot_password = (
  queryParams: GetForgot_passwordQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getForgot_password.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getForgot_password.key = "/forgot_password/";

/**
 *
 * Validate reset password request with token and email
 */
/**
* @package
*/
export const getForgot_passwordValidate = (
  queryParams: GetForgot_passwordValidateQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getForgot_passwordValidate.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getForgot_passwordValidate.key = "/forgot_password/validate";

/**
 *
 * Validate reset password request with token and email of influencer
 */
/**
* @package
*/
export const getForgot_passwordValidate_influencer = (
  queryParams: GetForgot_passwordValidate_influencerQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ValidPasswordResetToken>> => {
  return Http.getRequest(
    getForgot_passwordValidate_influencer.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT2, configOverride),
  );
};

/** Key is end point string without base url */
getForgot_passwordValidate_influencer.key =
  "/forgot_password/validate_influencer";

/**
 * 
 * check if healthy for load balancer

 */
/**
* @package
*/
export const getHealthy = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getHealthy.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getHealthy.key = "/healthy";

/**
 *
 * get external invoices by shared guid
 */
/**
* @package
*/
export const getInvoices = (
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
    /**
     *
     * - minLength: 1
     */
    "auth-key-password": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PayoutInvoiceData[]>> => {
  return Http.getRequest(
    getInvoices.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getInvoices.key = "/invoices";

/**
 *
 * Logout from current session
 */
/**
* @package
*/
export const getLogout = (
  queryParams?: GetLogoutQueryParams,
  headerParams?: { "X-MoiBackendRefreshToken": string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getLogout.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getLogout.key = "/logout";

/**
 *
 * get advertiser queries
 */
/**
* @package
*/
export const getMoiAdvertiser_saved_queries = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ data?: string; id?: number }[]>> => {
  return Http.getRequest(
    getMoiAdvertiser_saved_queries.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAdvertiser_saved_queries.key = "/moi/advertiser_saved_queries";

/**
 *
 * Get affiliate details
 */
/**
* @package
*/
export const getMoiAffiliate_details = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    affiliate_with?: string;
    my_code?: string;
    /**
     *
     * - Format: int64
     */
    number_affiliate_with_me?: number;
  }>
> => {
  return Http.getRequest(
    getMoiAffiliate_details.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAffiliate_details.key = "/moi/affiliate_details";

/**
 *
 * Get Agents Info
 */
/**
* @package
*/
export const getMoiAgent = (
  queryParams?: GetMoiAgentQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerAgentInfo[]>> => {
  return Http.getRequest(
    getMoiAgent.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgent.key = "/moi/agent";

/**
 *
 * Get Agent Info by ID
 */
/**
* @package
*/
export const getMoiAgentAgent_id = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerAgentInfo>> => {
  return Http.getRequest(
    template(getMoiAgentAgent_id.key, { agent_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgentAgent_id.key = "/moi/agent/{agent_id}";

/**
 *
 * list all registered bank accounts
 */
/**
* @package
*/
export const getMoiAgentAgent_idBank = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<RegisteredBankAccount[]>> => {
  return Http.getRequest(
    template(getMoiAgentAgent_idBank.key, { agent_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgentAgent_idBank.key = "/moi/agent/{agent_id}/bank";

/**
 *
 * get agent registered bank account details
 */
/**
* @package
*/
export const getMoiAgentAgent_idBankId = (
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankIdQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<RegisteredBankAccount>> => {
  return Http.getRequest(
    template(getMoiAgentAgent_idBankId.key, { id, agent_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgentAgent_idBankId.key = "/moi/agent/{agent_id}/bank/{id}";

/**
 *
 * list all UK registered bank accounts for agent
 */
/**
* @package
*/
export const getMoiAgentAgent_idBankUk = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<RegisteredUKAgentBankAccount[]>> => {
  return Http.getRequest(
    template(getMoiAgentAgent_idBankUk.key, { agent_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgentAgent_idBankUk.key = "/moi/agent/{agent_id}/bank/uk";

/**
 *
 * get uk agent registered bank account details
 */
/**
* @package
*/
export const getMoiAgentAgent_idBankUkId = (
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<RegisteredUKAgentBankAccount>> => {
  return Http.getRequest(
    template(getMoiAgentAgent_idBankUkId.key, { id, agent_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgentAgent_idBankUkId.key = "/moi/agent/{agent_id}/bank/uk/{id}";

/**
 *
 * list all US registered bank accounts for agent
 */
/**
* @package
*/
export const getMoiAgentAgent_idBankUs = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  queryParams?: GetMoiAgentAgent_idBankUsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExistingUSAgentBankAccount[]>> => {
  return Http.getRequest(
    template(getMoiAgentAgent_idBankUs.key, { agent_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgentAgent_idBankUs.key = "/moi/agent/{agent_id}/bank/us";

/**
 *
 * get us agent registered bank account details
 */
/**
* @package
*/
export const getMoiAgentAgent_idBankUsId = (
  id: number,
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<RegisteredUSAgentBankAccount>> => {
  return Http.getRequest(
    template(getMoiAgentAgent_idBankUsId.key, { id, agent_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgentAgent_idBankUsId.key = "/moi/agent/{agent_id}/bank/us/{id}";

/**
 *
 * get data for agents dashboard
 */
/**
* @package
*/
export const getMoiAgents_dashboard = (
  queryParams: GetMoiAgents_dashboardQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AgentsDashboard>> => {
  return Http.getRequest(
    getMoiAgents_dashboard.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgents_dashboard.key = "/moi/agents_dashboard";

/**
 *
 * get monthly sales graph
 */
/**
* @package
*/
export const getMoiAgents_dashboardSales_graph = (
  queryParams: GetMoiAgents_dashboardSales_graphQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<DashboardDailySales[]>> => {
  return Http.getRequest(
    getMoiAgents_dashboardSales_graph.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgents_dashboardSales_graph.key = "/moi/agents_dashboard/sales_graph";

/**
 *
 * get top_influencers and top_campaigns engagement and sales for agents dashboard
 */
/**
* @package
*/
export const getMoiAgents_dashboardTop_influencers_campagins = (
  queryParams: GetMoiAgents_dashboardTop_influencers_campaginsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AgentsDashboardTopCampaignsInfluencers>> => {
  return Http.getRequest(
    getMoiAgents_dashboardTop_influencers_campagins.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAgents_dashboardTop_influencers_campagins.key =
  "/moi/agents_dashboard/top_influencers_campagins";

/**
 *
 * get all targets
 */
/**
* @package
*/
export const getMoiAuctionAuction_idTargets = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionAuction_idTargetsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Target[]>> => {
  return Http.getRequest(
    template(getMoiAuctionAuction_idTargets.key, { auction_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionAuction_idTargets.key = "/moi/auction/{auction_id}/targets";

/**
 *
 * get all months with targets
 */
/**
* @package
*/
export const getMoiAuctionAuction_idTargetsMonths = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionAuction_idTargetsMonthsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    template(getMoiAuctionAuction_idTargetsMonths.key, { auction_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionAuction_idTargetsMonths.key =
  "/moi/auction/{auction_id}/targets/months";

/**
 *
 * get all user manage boards summaries
 */
/**
* @package
*/
export const getMoiAuctionManage = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CampaignSummary[]>> => {
  return Http.getRequest(
    getMoiAuctionManage.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionManage.key = "/moi/auction/manage";

/**
 *
 * get all board details
 */
/**
* @package
*/
export const getMoiAuctionManageAuction_id = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionManageAuction_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManageAuctionData>> => {
  return Http.getRequest(
    template(getMoiAuctionManageAuction_id.key, { auction_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionManageAuction_id.key = "/moi/auction/manage/{auction_id}";

/**
 *
 * Initiate a new file upload process
 */
/**
* @package
*/
export const getMoiAuctionManageAuction_idFilesInfluencer_id = (
  /**
   *
   * ID of the influencer to whom the file is to be attached
   */
  influencer_id: number,
  /**
   *
   * Campaign ID to which the file is associated with the user
   */
  auction_id: number,
  queryParams: GetMoiAuctionManageAuction_idFilesInfluencer_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CampaignFileIdentifier>> => {
  return Http.getRequest(
    template(getMoiAuctionManageAuction_idFilesInfluencer_id.key, {
      influencer_id,
      auction_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionManageAuction_idFilesInfluencer_id.key =
  "/moi/auction/manage/{auction_id}/files/{influencer_id}";

/**
 *
 * show the file by ID
 */
/**
* @package
*/
export const getMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid = (
  /**
   *
   * ID of the influencer to whom the file is to be attached
   */
  influencer_id: number,
  /**
   *
   * Campaign ID to which the file is associated with the user
   */
  auction_id: number,
  /**
   *
   * UUID of the upload request (received in the GET request response)
   */
  file_uuid: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid.key, {
      influencer_id,
      auction_id,
      file_uuid,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid.key =
  "/moi/auction/manage/{auction_id}/files/{influencer_id}/{file_uuid}";

/**
 *
 * get all payments related to the board for influencer
 */
/**
* @package
*/
export const getMoiAuctionManageAuction_idInfluencer_payments = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  queryParams?: GetMoiAuctionManageAuction_idInfluencer_paymentsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionPaymentsData>> => {
  return Http.getRequest(
    template(getMoiAuctionManageAuction_idInfluencer_payments.key, {
      auction_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionManageAuction_idInfluencer_payments.key =
  "/moi/auction/manage/{auction_id}/influencer_payments";

/**
 *
 * get campaign influencers
 */
/**
* @package
*/
export const getMoiAuctionManageAuction_idInfluencers = (
  /**
   *
   * The id of the campaign
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<number[]>> => {
  return Http.getRequest(
    template(getMoiAuctionManageAuction_idInfluencers.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionManageAuction_idInfluencers.key =
  "/moi/auction/manage/{auction_id}/influencers";

/**
 *
 * get all board details
 */
/**
* @package
*/
export const getMoiAuctionManageAuction_idNext_month = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManageAuctionState>> => {
  return Http.getRequest(
    template(getMoiAuctionManageAuction_idNext_month.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionManageAuction_idNext_month.key =
  "/moi/auction/manage/{auction_id}/next_month";

/**
 *
 * get all payments related to the board
 */
/**
* @package
*/
export const getMoiAuctionManageAuction_idPayments = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionPaymentsData>> => {
  return Http.getRequest(
    template(getMoiAuctionManageAuction_idPayments.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionManageAuction_idPayments.key =
  "/moi/auction/manage/{auction_id}/payments";

/**
 *
 * get payments tasks data
 */
/**
* @package
*/
export const getMoiAuctionManageAuction_idPaymentsPayment_idTasks_details = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the cycle payment
   */
  payment_id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<TaskManageData[]>> => {
  return Http.getRequest(
    template(getMoiAuctionManageAuction_idPaymentsPayment_idTasks_details.key, {
      auction_id,
      payment_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionManageAuction_idPaymentsPayment_idTasks_details.key =
  "/moi/auction/manage/{auction_id}/payments/{payment_id}/tasks_details";

/**
 *
 * get board short links
 */
/**
* @package
*/
export const getMoiAuctionManageAuction_idShort_link = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    links?: {
      created_by_influencer?: boolean;
      description?: string;
      extra_links?: {
        created_by_influencer?: boolean;
        description?: string;
        /**
         *
         * - Format: int64
         */
        extra_id?: number;
        long_link?: string;
      }[];
      /**
       *
       * - Format: int64
       */
      influencer_id?: number;
      long_link?: string;
    }[];
  }>
> => {
  return Http.getRequest(
    template(getMoiAuctionManageAuction_idShort_link.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionManageAuction_idShort_link.key =
  "/moi/auction/manage/{auction_id}/short_link";

/**
 *
 * Get the most viewed products of an auction by auction id
 */
/**
* @package
*/
export const getMoiAuctionTop_productsAuction_id = (
  /**
   *
   * The auction id to retrieve top products for
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ProductDetails[]>> => {
  return Http.getRequest(
    template(getMoiAuctionTop_productsAuction_id.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionTop_productsAuction_id.key =
  "/moi/auction/top_products/{auction_id}";

/**
 *
 * Get Auction copuns only
 */
/**
* @package
*/
export const getMoiAuctionsAuction_idCoupons = (
  /**
   *
   * the auction id to get sales coupons
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CouponInSales[]>> => {
  return Http.getRequest(
    template(getMoiAuctionsAuction_idCoupons.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsAuction_idCoupons.key = "/moi/auctions/{auction_id}/coupons";

/**
 *
 * Get auction by id for influencer
 */
/**
* @package
*/
export const getMoiAuctionsAuction_idInfluencer = (
  /**
   *
   * the auction id to get
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionDataForInfluencerExtended>> => {
  return Http.getRequest(
    template(getMoiAuctionsAuction_idInfluencer.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsAuction_idInfluencer.key =
  "/moi/auctions/{auction_id}/influencer";

/**
 * 
 * Get all links for influencer

 */
/**
* @package
*/
export const getMoiAuctionsAuction_idInfluencerLinks = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionDataForInfluencerLink[]>> => {
  return Http.getRequest(
    template(getMoiAuctionsAuction_idInfluencerLinks.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsAuction_idInfluencerLinks.key =
  "/moi/auctions/{auction_id}/influencer/links";

/**
 * 
 * Get all products of auctions for influencer

 */
/**
* @package
*/
export const getMoiAuctionsAuction_idInfluencerProducts = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ products?: EditAuctionProduct[] }>> => {
  return Http.getRequest(
    template(getMoiAuctionsAuction_idInfluencerProducts.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsAuction_idInfluencerProducts.key =
  "/moi/auctions/{auction_id}/influencer/products";

/**
 * 
 * Get all auctions for influencer

 */
/**
* @package
*/
export const getMoiAuctionsAuction_idInfluencer_pixel_analytics = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  queryParams?: GetMoiAuctionsAuction_idInfluencer_pixel_analyticsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    clicks?: AffiliateTimeline;
    comission?: number;
    /**
     *
     * - Format: double
     */
    commission_value?: number;
    /**
     *
     * - Format: int64
     */
    coupons?: number;
    currency?: Currency;
    earnings?: AffiliateTimeline;
    /**
     *
     * - Format: date-time
     */
    last_updated?: string;
    purchases?: AffiliateTimeline;
  }>
> => {
  return Http.getRequest(
    template(getMoiAuctionsAuction_idInfluencer_pixel_analytics.key, {
      auction_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsAuction_idInfluencer_pixel_analytics.key =
  "/moi/auctions/{auction_id}/influencer_pixel_analytics";

/**
 * 
 * Get affiliation breakdown by phases

 */
/**
* @package
*/
export const getMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  queryParams?: GetMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdownQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      brands_included?: string[];
      color?: string;
      /**
       *
       * - Format: double
       */
      commission?: number;
      /**
       *
       * - Format: double
       */
      commission_value?: number;
      /**
       *
       * - Format: date-time
       */
      end_time?: string;
      is_base?: boolean;
      /**
       *
       * - Format: date-time
       */
      last_updated?: string;
      /**
       *
       * - Format: double
       */
      net_sales?: number;
      /**
       *
       * - Format: date-time
       */
      start_time?: string;
      title?: string;
    }[]
  >
> => {
  return Http.getRequest(
    template(getMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown.key, {
      auction_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsAuction_idInfluencer_pixel_analytics_breakdown.key =
  "/moi/auctions/{auction_id}/influencer_pixel_analytics_breakdown";

/**
 *
 * get roi for last month
 */
/**
* @package
*/
export const getMoiAuctionsAuction_idLast_month_for_roi = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: date
     */
    last_month?: string;
    /**
     *
     * - Format: date
     */
    last_month_with_purchases?: string;
    one_month_campaign?: boolean;
    pixel_enabled?: boolean;
  }>
> => {
  return Http.getRequest(
    template(getMoiAuctionsAuction_idLast_month_for_roi.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsAuction_idLast_month_for_roi.key =
  "/moi/auctions/{auction_id}/last_month_for_roi";

/**
 *
 * Get Auction pixel phases
 */
/**
* @package
*/
export const getMoiAuctionsAuction_idPixel_phases = (
  /**
   *
   * the auction id to get the phases for
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    influencers: {
      full_name?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image?: string;
    }[];
    phases: AuctionPhase[];
    app_id?: string;
  }>
> => {
  return Http.getRequest(
    template(getMoiAuctionsAuction_idPixel_phases.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsAuction_idPixel_phases.key =
  "/moi/auctions/{auction_id}/pixel_phases";

/**
 *
 * Get specific auction by id
 */
/**
* @package
*/
export const getMoiAuctionsId = (
  /**
   *
   * The id of the auction to get
   */
  id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExtendedAuctionData>> => {
  return Http.getRequest(
    template(getMoiAuctionsId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsId.key = "/moi/auctions/{id}";

/**
 *
 * Returns bids and market segmentation
 */
/**
* @package
*/
export const getMoiAuctionsIdBids = (
  /**
   *
   * The auction id to return bids to
   */
  id: number,
  queryParams?: GetMoiAuctionsIdBidsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      /**
       *
       * - Format: int64
       */
      auction_id?: number;
      /**
       *
       * BidderData
       *
       */
      bid_details?: {
        /**
         *
         * - Format: double
         */
        bid?: number;
        /**
         *
         * - Format: double
         */
        bid_commission?: number;
        /**
         *
         * - Format: int64
         */
        bid_id?: number;
        /**
         *
         * - Format: double
         */
        bid_taxes?: number;
        bidder_about_me?: string;
        bidder_address?: string;
        /**
         *
         * - Format: int64
         */
        bidder_age?: number;
        /**
         *
         * - Format: int64
         */
        bidder_id?: number;
        bidder_name?: string;
        bidder_username?: string;
        /**
         *
         * - Format: double
         */
        discounted_bid?: number;
        /**
         *
         * - Format: int64
         */
        ongoing_active_campaign_id?: number;
        picture_url?: string;
        reviews_statistics?: ReviewsStatistics;
        vip_user?: boolean;
      };
      influencer_activity_data?: InfluencerActivityData;
      /**
       *
       * InfluencerSegmentation
       *
       */
      influencer_segmentation?: {
        /**
         *
         * AgeByGenderSegmentationData
         *
         */
        age_by_gender_segmentation?: {
          females?: AgeSegmentationData;
          males?: AgeSegmentationData;
        };
        age_segmentation?: AgeSegmentationData;
        /**
         *
         * - Format: double
         */
        average_likes?: number;
        /**
         *
         * - Format: int64
         */
        followers?: number;
        /**
         *
         * GenderSegmentationData
         *
         */
        gender_segmentation?: {
          /**
           *
           * - Format: double
           */
          female?: number;
          /**
           *
           * - Format: double
           */
          male?: number;
        };
        /**
         *
         * - Format: int32
         */
        moi_grade?: number;
        states_data?: string;
      };
    }[]
  >
> => {
  return Http.getRequest(
    template(getMoiAuctionsIdBids.key, { id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsIdBids.key = "/moi/auctions/{id}/bids";

/**
 *
 * Returns ROI data for specific auction
 */
/**
* @package
*/
export const getMoiAuctionsIdRoi = (
  /**
   *
   * The auction id to return ROI to
   */
  id: number,
  queryParams?: GetMoiAuctionsIdRoiQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManagedCampaignROI>> => {
  return Http.getRequest(
    template(getMoiAuctionsIdRoi.key, { id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsIdRoi.key = "/moi/auctions/{id}/roi";

/**
 * 
 * Get all auctions for influencer

 */
/**
* @package
*/
export const getMoiAuctionsInfluencer = (
  queryParams: GetMoiAuctionsInfluencerQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * AuctionsArray
     *
     */
    auctions?: AuctionDataForInfluencer[];
  }>
> => {
  return Http.getRequest(
    getMoiAuctionsInfluencer.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsInfluencer.key = "/moi/auctions/influencer";

/**
 * 
 * Get all auctions for influencer

 */
/**
* @package
*/
export const getMoiAuctionsLiteInfluencer = (
  queryParams: GetMoiAuctionsLiteInfluencerQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * AuctionsArray
     *
     */
    auctions?: MinimalAuctionDataForInfluencer[];
  }>
> => {
  return Http.getRequest(
    getMoiAuctionsLiteInfluencer.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsLiteInfluencer.key = "/moi/auctions/lite/influencer";

/**
 *
 * List ROI Slides
 */
/**
* @package
*/
export const getMoiAuctionsRoiSlides = (
  queryParams?: GetMoiAuctionsRoiSlidesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ROISlidesReportStatus>> => {
  return Http.getRequest(
    getMoiAuctionsRoiSlides.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctionsRoiSlides.key = "/moi/auctions/roi/slides";

/**
 *
 * Returns suggested price buckets for asked filter
 */
/**
* @package
*/
export const getMoiAuctions_price_suggest = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    buckets?: {
      /**
       *
       * - Format: int64
       */
      max?: number;
      /**
       *
       * - Format: int64
       */
      min?: number;
      /**
       *
       * - Format: double
       */
      price?: number;
    }[];
  }>
> => {
  return Http.getRequest(
    getMoiAuctions_price_suggest.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiAuctions_price_suggest.key = "/moi/auctions_price_suggest";

/**
 * 
 * Get all my campaigns

 */
/**
* @package
*/
export const getMoiCampaigns = (
  queryParams?: GetMoiCampaignsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      all_sub_users_allowed?: boolean;
      /**
       *
       * - Format: int64
       */
      campaign_list_id?: number;
      campaign_state?: "brief" | "manage" | "report";
      campaign_type?: AuctionTypes;
      categories?: string[];
      /**
       *
       * - Format: int64
       */
      content_amount?: number;
      /**
       *
       * - Format: date-time
       */
      creation_time?: string;
      /**
       *
       * - Format: date-time
       */
      end_time?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image?: string;
      /**
       *
       * - Format: int64
       */
      influencers_amount?: number;
      is_pixel_enabled?: boolean;
      name?: string;
      one_month_campaign?: boolean;
      /**
       *
       * - Format: int64
       */
      original_creator_id?: number;
      owner?: Owner;
      shared_campaign?: boolean;
      sub_users_allowed?: number[];
      waiting_approval?: boolean;
    }[]
  >
> => {
  return Http.getRequest(
    getMoiCampaigns.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiCampaigns.key = "/moi/campaigns";

/**
 *
 * get community feed for influencer
 */
/**
* @package
*/
export const getMoiCommunity_feed = (
  queryParams: GetMoiCommunity_feedQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CommunityFeedItem[]>> => {
  return Http.getRequest(
    getMoiCommunity_feed.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiCommunity_feed.key = "/moi/community_feed";

/**
 *
 * get community feed for influencer
 */
/**
* @package
*/
export const getMoiCommunity_feedApproval = (
  queryParams?: GetMoiCommunity_feedApprovalQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CommunityFeedItem[]>> => {
  return Http.getRequest(
    getMoiCommunity_feedApproval.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiCommunity_feedApproval.key = "/moi/community_feed/approval";

/**
 *
 * get community feed item for influencer
 */
/**
* @package
*/
export const getMoiCommunity_feedPost_id = (
  /**
   *
   * post id
   */
  post_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    comments?: CommunityFeedComment[];
    post?: CommunityFeedItem;
  }>
> => {
  return Http.getRequest(
    template(getMoiCommunity_feedPost_id.key, { post_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiCommunity_feedPost_id.key = "/moi/community_feed/{post_id}";

/**
 *
 * get community feed comments
 */
/**
* @package
*/
export const getMoiCommunity_feedPost_idComments = (
  /**
   *
   * post id
   */
  post_id: number,
  queryParams?: GetMoiCommunity_feedPost_idCommentsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CommunityFeedComment[]>> => {
  return Http.getRequest(
    template(getMoiCommunity_feedPost_idComments.key, { post_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiCommunity_feedPost_idComments.key =
  "/moi/community_feed/{post_id}/comments";

/**
 *
 * get community feed likes of post
 */
/**
* @package
*/
export const getMoiCommunity_feedPost_idLike = (
  /**
   *
   * post id
   */
  post_id: number,
  queryParams?: GetMoiCommunity_feedPost_idLikeQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UserOnFeedAndActionTime[]>> => {
  return Http.getRequest(
    template(getMoiCommunity_feedPost_idLike.key, { post_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiCommunity_feedPost_idLike.key = "/moi/community_feed/{post_id}/like";

/**
 *
 * Query for community suggestions
 */
/**
* @package
*/
export const getMoiCommunity_feedSuggestionAll = (
  queryParams?: GetMoiCommunity_feedSuggestionAllQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CommunitySuggestion[]>> => {
  return Http.getRequest(
    getMoiCommunity_feedSuggestionAll.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiCommunity_feedSuggestionAll.key = "/moi/community_feed/suggestion/all";

/**
 *
 * list applied coupons for user
 */
/**
* @package
*/
export const getMoiCoupons = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      /**
       *
       * - Format: int32
       */
      amount_of_usages_left?: number;
      /**
       *
       * - Format: double
       */
      campaign_discount?: number;
      /**
       *
       * - pattern: ^[a-zA-Z0-9]{3,12}$
       */
      coupon?: string;
      /**
       *
       * - Format: date-time
       */
      expiration_time?: string;
      /**
       *
       * - Format: int32
       */
      max_amount_of_usages?: number;
      /**
       *
       * - Format: date-time
       */
      usage_time?: string;
      /**
       *
       * - Format: int64
       */
      used_in_auction_id?: number;
    }[]
  >
> => {
  return Http.getRequest(
    getMoiCoupons.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiCoupons.key = "/moi/coupons";

/**
 *
 * Get advertiser details
 */
/**
* @package
*/
export const getMoiDetailsAdvertiser = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AllAdvertiserDetails>> => {
  return Http.getRequest(
    getMoiDetailsAdvertiser.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiDetailsAdvertiser.key = "/moi/details/advertiser";

/**
 *
 * Get brand list by user ID
 */
/**
* @package
*/
export const getMoiDetailsBrand = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<EditBrandInfo[]>> => {
  return Http.getRequest(
    getMoiDetailsBrand.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiDetailsBrand.key = "/moi/details/brand";

/**
 *
 * Get advertiser details
 */
/**
* @package
*/
export const getMoiDetailsCurrent_user = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AllAdvertiserDetails>> => {
  return Http.getRequest(
    getMoiDetailsCurrent_user.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiDetailsCurrent_user.key = "/moi/details/current_user";

/**
 *
 * Get influencer details
 */
/**
* @package
*/
export const getMoiDetailsInfluencer = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerDetails>> => {
  return Http.getRequest(
    getMoiDetailsInfluencer.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiDetailsInfluencer.key = "/moi/details/influencer";

/**
 *
 * get influencer statistics
 */
/**
* @package
*/
export const getMoiDetailsInfluencerDashboard = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
    /**
     *
     * InfluencerDashboardSocial
     *
     */
    social_data?: {
      instagram?: InfluencerDashboardSocialData;
      tiktok?: InfluencerDashboardSocialData;
      twitter?: InfluencerDashboardSocialData;
      youtube?: InfluencerDashboardSocialData;
    };
  }>
> => {
  return Http.getRequest(
    getMoiDetailsInfluencerDashboard.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiDetailsInfluencerDashboard.key = "/moi/details/influencer/dashboard";

/**
 *
 * Get influencer rate card
 */
/**
* @package
*/
export const getMoiDetailsInfluencerRate_card = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerRateCard>> => {
  return Http.getRequest(
    getMoiDetailsInfluencerRate_card.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiDetailsInfluencerRate_card.key = "/moi/details/influencer/rate_card";

/**
 *
 * get current billing address
 */
/**
* @package
*/
export const getMoiEdit_detailsAdvertiserBilling = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingAddress>> => {
  return Http.getRequest(
    getMoiEdit_detailsAdvertiserBilling.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiEdit_detailsAdvertiserBilling.key =
  "/moi/edit_details/advertiser/billing";

/**
 *
 * get current billing address
 */
/**
* @package
*/
export const getMoiEdit_detailsCurrent_userBilling = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingAddress>> => {
  return Http.getRequest(
    getMoiEdit_detailsCurrent_userBilling.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiEdit_detailsCurrent_userBilling.key =
  "/moi/edit_details/current_user/billing";

/**
 *
 * list all registered bank accounts
 */
/**
* @package
*/
export const getMoiEdit_detailsInfluencerBank = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<RegisteredBankAccount[]>> => {
  return Http.getRequest(
    getMoiEdit_detailsInfluencerBank.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiEdit_detailsInfluencerBank.key = "/moi/edit_details/influencer/bank";

/**
 *
 * get registered bank account details
 */
/**
* @package
*/
export const getMoiEdit_detailsInfluencerBankId = (
  id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<RegisteredBankAccount>> => {
  return Http.getRequest(
    template(getMoiEdit_detailsInfluencerBankId.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiEdit_detailsInfluencerBankId.key =
  "/moi/edit_details/influencer/bank/{id}";

/**
 *
 * Check if allowed to add a US bank
 */
/**
* @package
*/
export const getMoiEdit_detailsInfluencerBankUs = (
  queryParams?: GetMoiEdit_detailsInfluencerBankUsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AllowedForUSBankAccount>> => {
  return Http.getRequest(
    getMoiEdit_detailsInfluencerBankUs.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiEdit_detailsInfluencerBankUs.key = "/moi/edit_details/influencer/bank/us";

/**
 * 
 * get users

 */
/**
* @package
*/
export const getMoiEnterpriseUsers = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      email?: string;
      image?: string;
      /**
       *
       * - Format: date-time
       */
      invite_created_at?: string;
      /**
       *
       * - Format: date-time
       */
      invite_response_at?: string;
      invite_status?: "accepted" | "rejected" | "removed" | "waiting_response";
      /**
       *
       * - Format: int64
       */
      name?: string;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    }[]
  >
> => {
  return Http.getRequest(
    getMoiEnterpriseUsers.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiEnterpriseUsers.key = "/moi/enterprise/users";

/**
 * 
 * get users logs

 */
/**
* @package
*/
export const getMoiEnterpriseUsers_logs = (
  queryParams?: GetMoiEnterpriseUsers_logsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      /**
       *
       * - Format: date-time
       */
      action_created_at?: string;
      action_description?: string;
      /**
       *
       * - Format: int64
       */
      action_name?: string;
      /**
       *
       * - Format: int64
       */
      item_id?: number;
      item_image?: string;
      item_title?: string;
      item_type?: "auction" | "list";
      sub_user_email?: string;
      /**
       *
       * - Format: int64
       */
      sub_user_id?: number;
      sub_user_name?: string;
    }[]
  >
> => {
  return Http.getRequest(
    getMoiEnterpriseUsers_logs.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiEnterpriseUsers_logs.key = "/moi/enterprise/users_logs";

/**
 *
 * Get influencer external page settings
 */
/**
* @package
*/
export const getMoiExternal_page_settings = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExternalPageSettings>> => {
  return Http.getRequest(
    getMoiExternal_page_settings.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiExternal_page_settings.key = "/moi/external_page_settings";

/**
 *
 * Returns bids and followers segmentation for influencer
 */
/**
* @package
*/
export const getMoiFollowers_segmentationHumanz_fk = (
  /**
   *
   * The humanz fk to return followers segmentation to
   */
  humanz_fk: number,
  queryParams?: GetMoiFollowers_segmentationHumanz_fkQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerFollowersSegmentation>> => {
  return Http.getRequest(
    template(getMoiFollowers_segmentationHumanz_fk.key, { humanz_fk }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiFollowers_segmentationHumanz_fk.key =
  "/moi/followers_segmentation/{humanz_fk}";

/**
 * 
 * Get the last instagram challenge of a user

 */
/**
* @package
*/
export const getMoiGet_last_user_instagram_challenge = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    challenge?: string;
    challenge_id?: string;
    checked_async?: boolean;
    /**
     *
     * - Format: int64
     */
    followers?: number;
    instagram_id?: string;
    instagram_username?: string;
    post_id?: string;
    profile_pic_url?: string;
    /**
     *
     * - Format: date-time
     */
    validated_at?: string;
  }>
> => {
  return Http.getRequest(
    getMoiGet_last_user_instagram_challenge.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiGet_last_user_instagram_challenge.key =
  "/moi/get_last_user_instagram_challenge";

/**
 *
 * get data for homepage dashboard
 */
/**
* @package
*/
export const getMoiHomepage_dashboard = (
  queryParams: GetMoiHomepage_dashboardQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<HomepageDashboard>> => {
  return Http.getRequest(
    getMoiHomepage_dashboard.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiHomepage_dashboard.key = "/moi/homepage_dashboard";

/**
 *
 * get top_influencers by sales or engagement for homepage dashboard
 */
/**
* @package
*/
export const getMoiHomepage_dashboardTop_influencers = (
  queryParams: GetMoiHomepage_dashboardTop_influencersQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerCampaignPictureMetric[]>> => {
  return Http.getRequest(
    getMoiHomepage_dashboardTop_influencers.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiHomepage_dashboardTop_influencers.key =
  "/moi/homepage_dashboard/top_influencers";

/**
 *
 * Get all available users you can chat with - for influencer or agent
 */
/**
* @package
*/
export const getMoiInbox = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - pattern: ^[1-9][0-9]+$
     */
    agents?: { [x: string]: AgentInChatInfo };
    campaigns_chats?: {
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: date-time
       */
      created_at?: string;
      image?: string;
      title?: string;
      users_chats?: Chats;
    }[];
    users_chats?: Chats;
  }>
> => {
  return Http.getRequest(
    getMoiInbox.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInbox.key = "/moi/inbox";

/**
 *
 * Get all available chat influencers you can chat with inside campaigns
 */
/**
* @package
*/
export const getMoiInboxCampaigns = (
  queryParams?: GetMoiInboxCampaignsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      agent?: AgentInChatInfo;
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: date-time
       */
      created_at?: string;
      image?: string;
      title?: string;
      /**
       *
       * - Format: int32
       */
      unread_count?: number;
    }[]
  >
> => {
  return Http.getRequest(
    getMoiInboxCampaigns.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInboxCampaigns.key = "/moi/inbox/campaigns";

/**
 *
 * Get all available chats with influencers you can chat with
 */
/**
* @package
*/
export const getMoiInboxInfluencers = (
  queryParams?: GetMoiInboxInfluencersQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    influencers?: Chats;
    /**
     *
     * - Format: int64
     */
    starred_count?: number;
  }>
> => {
  return Http.getRequest(
    getMoiInboxInfluencers.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInboxInfluencers.key = "/moi/inbox/influencers";

/**
 *
 * Get available chat with influencer
 */
/**
* @package
*/
export const getMoiInboxInfluencersInfluencer_id = (
  /**
   *
   * Influencer Id
   */
  influencer_id: number,
  queryParams?: GetMoiInboxInfluencersInfluencer_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    influencers?: Chats;
    /**
     *
     * - Format: int64
     */
    starred_count?: number;
  }>
> => {
  return Http.getRequest(
    template(getMoiInboxInfluencersInfluencer_id.key, { influencer_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInboxInfluencersInfluencer_id.key =
  "/moi/inbox/influencers/{influencer_id}";

/**
 * 
 * Get user ids of chats which have new messages

 */
/**
* @package
*/
export const getMoiInboxNew_chats = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    new_chats?: {
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    }[];
  }>
> => {
  return Http.getRequest(
    getMoiInboxNew_chats.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInboxNew_chats.key = "/moi/inbox/new_chats";

/**
 * 
 * Get user ids of chats which have new messages

 */
/**
* @package
*/
export const getMoiInboxStarred_chats = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    new_chats?: {
      /**
       *
       * - Format: int64
       */
      campaign_id?: number;
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    }[];
  }>
> => {
  return Http.getRequest(
    getMoiInboxStarred_chats.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInboxStarred_chats.key = "/moi/inbox/starred_chats";

/**
 *
 * get campaigns for autocomplete
 */
/**
* @package
*/
export const getMoiInfluencerCampaigns_autocomplete = (
  queryParams?: GetMoiInfluencerCampaigns_autocompleteQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CampaignIdNamePicture[]>> => {
  return Http.getRequest(
    getMoiInfluencerCampaigns_autocomplete.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInfluencerCampaigns_autocomplete.key =
  "/moi/influencer/campaigns_autocomplete";

/**
 *
 * get months for autocomplete
 */
/**
* @package
*/
export const getMoiInfluencerMonths_autocomplete = (
  queryParams?: GetMoiInfluencerMonths_autocompleteQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<string[]>> => {
  return Http.getRequest(
    getMoiInfluencerMonths_autocomplete.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInfluencerMonths_autocomplete.key = "/moi/influencer/months_autocomplete";

/**
 *
 * get influencer dashboard data
 */
/**
* @package
*/
export const getMoiInfluencer_dashboard = (
  queryParams?: GetMoiInfluencer_dashboardQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerProfileDashboard>> => {
  return Http.getRequest(
    getMoiInfluencer_dashboard.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInfluencer_dashboard.key = "/moi/influencer_dashboard";

/**
 *
 * get todo's for influencer dashboard
 */
/**
* @package
*/
export const getMoiInfluencer_dashboardTodolist = (
  queryParams?: GetMoiInfluencer_dashboardTodolistQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerProfileTodo[]>> => {
  return Http.getRequest(
    getMoiInfluencer_dashboardTodolist.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInfluencer_dashboardTodolist.key = "/moi/influencer_dashboard/todolist";

/**
 *
 * get top campaigns for influencer dashboard
 */
/**
* @package
*/
export const getMoiInfluencer_dashboardTop_campaigns = (
  queryParams: GetMoiInfluencer_dashboardTop_campaignsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerDashboardTopCampaign[]>> => {
  return Http.getRequest(
    getMoiInfluencer_dashboardTop_campaigns.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInfluencer_dashboardTop_campaigns.key =
  "/moi/influencer_dashboard/top_campaigns";

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const getMoiInfluencer_profile = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerProfilePage>> => {
  return Http.getRequest(
    getMoiInfluencer_profile.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInfluencer_profile.key = "/moi/influencer_profile";

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const getMoiInfluencer_profileInfluencer_username = (
  /**
   *
   * the influencer username
   */
  influencer_username: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerProfilePage>> => {
  return Http.getRequest(
    template(getMoiInfluencer_profileInfluencer_username.key, {
      influencer_username,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInfluencer_profileInfluencer_username.key =
  "/moi/influencer_profile/{influencer_username}";

/**
 *
 * get users who viewed my profile on the last 24hours
 */
/**
* @package
*/
export const getMoiInfluencer_profileProfile_statistics = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    daily_views?: DateAndCount[];
    endorsments?: DateAndCount[];
    social_network_jumps?: {
      daily_jumps?: DateAndCount[];
      social_network?: string;
    }[];
  }>
> => {
  return Http.getRequest(
    getMoiInfluencer_profileProfile_statistics.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInfluencer_profileProfile_statistics.key =
  "/moi/influencer_profile/profile_statistics";

/**
 *
 * get users who viewed my profile on the last 24hours
 */
/**
* @package
*/
export const getMoiInfluencer_profileViewed_my_profile = (
  queryParams?: GetMoiInfluencer_profileViewed_my_profileQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UserOnFeed[]>> => {
  return Http.getRequest(
    getMoiInfluencer_profileViewed_my_profile.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInfluencer_profileViewed_my_profile.key =
  "/moi/influencer_profile/viewed_my_profile";

/**
 *
 * returns a list of all insight reports
 */
/**
* @package
*/
export const getMoiInsights = (
  queryParams?: GetMoiInsightsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InsightsReportShallowInfo[]>> => {
  return Http.getRequest(
    getMoiInsights.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInsights.key = "/moi/insights";

/**
 *
 * list all of the invites generated by the user
 */
/**
* @package
*/
export const getMoiInvitesExternal = (
  queryParams?: GetMoiInvitesExternalQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getMoiInvitesExternal.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiInvitesExternal.key = "/moi/invites/external";

/**
 * 
 * Get All tags for user

 */
/**
* @package
*/
export const getMoiMeta_tags = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AllUserTags>> => {
  return Http.getRequest(
    getMoiMeta_tags.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiMeta_tags.key = "/moi/meta_tags";

/**
 *
 * get multi report
 */
/**
* @package
*/
export const getMoiMulti_reportReport_id = (
  /**
   *
   * The report id
   */
  report_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MultiReport>> => {
  return Http.getRequest(
    template(getMoiMulti_reportReport_id.key, { report_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiMulti_reportReport_id.key = "/moi/multi_report/{report_id}";

/**
 *
 * get current multi reports roi share parameters
 */
/**
* @package
*/
export const getMoiMulti_reportsReport_idShare = (
  /**
   *
   * The id of the multi report to get share details
   */
  report_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<MultiReportShareParameters[]>> => {
  return Http.getRequest(
    template(getMoiMulti_reportsReport_idShare.key, { report_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiMulti_reportsReport_idShare.key = "/moi/multi_reports/{report_id}/share";

/**
 * 
 * Get number of notifications that the server did not send already to the user

 */
/**
* @package
*/
export const getMoiNotificationsNew_notifications = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: date-time
     */
    last_date_user_marked_notifications_as_seen?: string;
    /**
     *
     * - Format: int64
     */
    not_sent_notifications_count?: number;
  }>
> => {
  return Http.getRequest(
    getMoiNotificationsNew_notifications.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiNotificationsNew_notifications.key =
  "/moi/notifications/new_notifications";

/**
 *
 * list all payments for influencer
 */
/**
* @package
*/
export const getMoiPaymentInfluencerPayments = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    currencies_summary?: {
      currency?: Currency;
      /**
       *
       * - Format: double
       */
      total_paid?: number;
      /**
       *
       * - Format: double
       */
      total_waiting_for_payment?: number;
    }[];
    payments?: {
      /**
       *
       * - Format: float
       */
      amount?: number;
      /**
       *
       * InfluencerPaymentsCamapignPaymentDetails
       *
       */
      camapign_payment_details?: {
        currency?: Currency;
        /**
         *
         * - Format: int64
         */
        id?: number;
        image?: string;
        /**
         *
         * - Format: date
         */
        month?: string;
        name?: string;
      };
      currency?: Currency;
      /**
       *
       * - Format: date-time
       */
      due_date?: string;
      invoice_url?: string;
      invoices_urls?: string[];
      is_early_payout?: boolean;
      is_managed_by_agent?: boolean;
      is_upfront_payment?: boolean;
      paid?: boolean;
      /**
       *
       * - Format: date-time
       */
      paid_at?: string;
    }[];
  }>
> => {
  return Http.getRequest(
    getMoiPaymentInfluencerPayments.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPaymentInfluencerPayments.key = "/moi/payment/influencer/payments";

/**
 *
 * get dispute details
 */
/**
* @package
*/
export const getMoiPaymentPayment_from_advertiserDispute = (
  queryParams: GetMoiPaymentPayment_from_advertiserDisputeQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AdvertiserDisputeDetailsExtended>> => {
  return Http.getRequest(
    getMoiPaymentPayment_from_advertiserDispute.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPaymentPayment_from_advertiserDispute.key =
  "/moi/payment/payment_from_advertiser/dispute";

/**
 *
 * get dispute details
 */
/**
* @package
*/
export const getMoiPaymentPayment_from_advertiserDisputes = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      /**
       *
       * - Format: int64
       */
      auction_id?: number;
      /**
       *
       * - Format: date-time
       */
      auction_start_time?: string;
      auction_title?: string;
      disputed_influencers?: AdvertiserDisputeDetailsExtended[];
    }[]
  >
> => {
  return Http.getRequest(
    getMoiPaymentPayment_from_advertiserDisputes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPaymentPayment_from_advertiserDisputes.key =
  "/moi/payment/payment_from_advertiser/disputes";

/**
 *
 * list all invoices for advertiser
 */
/**
* @package
*/
export const getMoiPaymentPayment_from_advertiserInvoices = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      /**
       *
       * - Format: float
       */
      amount?: number;
      /**
       *
       * - Format: date-time
       */
      charge_time?: string;
      charge_type?:
        | "ambassador_report_additional"
        | "ambassador_report_init"
        | "ambassador_report_upgrade"
        | "brand_report"
        | "insights_package"
        | "marketplace_bid"
        | "media_fee"
        | "subscription";
      credit_card_issuer?: CreditCardIssuer;
      currency?: Currency;
      invoice_url?: string;
      /**
       *
       * - pattern: ^[\d]{4}$
       */
      last_digits?: string;
      transaction_id?: string;
    }[]
  >
> => {
  return Http.getRequest(
    getMoiPaymentPayment_from_advertiserInvoices.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPaymentPayment_from_advertiserInvoices.key =
  "/moi/payment/payment_from_advertiser/invoices";

/**
 *
 * list credit cards in system for advertiser
 */
/**
* @package
*/
export const getMoiPaymentPayment_from_advertiserMethods = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    cards?: {
      credit_card_issuer?: CreditCardIssuer;
      currency?: Currency;
      /**
       *
       * - Format: date-time
       */
      expiry_date?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      /**
       *
       * - pattern: ^[\d]{4}$
       */
      last_digits?: string;
      name_on_card?: string;
      primary?: boolean;
    }[];
  }>
> => {
  return Http.getRequest(
    getMoiPaymentPayment_from_advertiserMethods.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPaymentPayment_from_advertiserMethods.key =
  "/moi/payment/payment_from_advertiser/methods";

/**
 *
 * get expected charge
 */
/**
* @package
*/
export const getMoiPaymentPayment_from_advertiserNext_billing_period = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    currency?: Currency;
    /**
     *
     * ExpectedChargeServices
     *
     */
    services?: {
      /**
       *
       * - Format: date
       */
      due_date?: string;
      /**
       *
       * - Format: date
       */
      from_date?: string;
      items?: {
        charge_type?: "subscription" | "media_fee";
        /**
         *
         * - Format: date
         */
        due?: string;
        /**
         *
         * - Format: int64
         */
        id?: number;
        influencers?: {
          /**
           *
           * - Format: int64
           */
          auction_id?: number;
          /**
           *
           * - Format: int64
           */
          dispute_id?: number;
          /**
           *
           * - Format: date-time
           */
          dispute_time?: string;
          /**
           *
           * - Format: int64
           */
          influencer_id?: number;
          /**
           *
           * - Format: double
           */
          minimum_usage_fee?: number;
          /**
           *
           * - Format: date
           */
          month_relevance?: string;
          name?: string;
          /**
           *
           * - Format: int64
           */
          pbi_fk?: number;
          price_details?: AdditionalPrice;
        }[];
        name?: string;
        price_details?: AdditionalPrice;
      }[];
      /**
       *
       * - Format: date
       */
      payment_date?: string;
      total?: AdditionalPrice[];
    };
  }>
> => {
  return Http.getRequest(
    getMoiPaymentPayment_from_advertiserNext_billing_period.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPaymentPayment_from_advertiserNext_billing_period.key =
  "/moi/payment/payment_from_advertiser/next_billing_period";

/**
 *
 * get share parameters
 */
/**
* @package
*/
export const getMoiPremiumAuctionAuction_idShare = (
  /**
   *
   * The id of the roi auction to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CampaignShareParameters>> => {
  return Http.getRequest(
    template(getMoiPremiumAuctionAuction_idShare.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumAuctionAuction_idShare.key =
  "/moi/premium/auction/{auction_id}/share";

/**
 *
 * list auction todo items
 */
/**
* @package
*/
export const getMoiPremiumAuctionAuction_idTodo = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ items?: AuctionToDoItem[] }>> => {
  return Http.getRequest(
    template(getMoiPremiumAuctionAuction_idTodo.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumAuctionAuction_idTodo.key =
  "/moi/premium/auction/{auction_id}/todo";

/**
 *
 * list auction todo items
 */
/**
* @package
*/
export const getMoiPremiumAuctionAuction_idTodo_status = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ completed?: number; items?: number }>> => {
  return Http.getRequest(
    template(getMoiPremiumAuctionAuction_idTodo_status.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumAuctionAuction_idTodo_status.key =
  "/moi/premium/auction/{auction_id}/todo_status";

/**
 *
 * get list data with users
 */
/**
* @package
*/
export const getMoiPremiumCampaign_listsAuction_id = (
  /**
   *
   * The id of the list to add to
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListData>> => {
  return Http.getRequest(
    template(getMoiPremiumCampaign_listsAuction_id.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumCampaign_listsAuction_id.key =
  "/moi/premium/campaign_lists/{auction_id}";

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const getMoiPremiumGet_influencer_dataInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerExtendedData>> => {
  return Http.getRequest(
    template(getMoiPremiumGet_influencer_dataInfluencer_id.key, {
      influencer_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumGet_influencer_dataInfluencer_id.key =
  "/moi/premium/get_influencer_data/{influencer_id}";

/**
 *
 * get specific influencer posts from instagram api
 */
/**
* @package
*/
export const getMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  /**
   *
   * auction_id
   */
  auction_id: number,
  queryParams?: GetMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerInstagramPost[]>> => {
  return Http.getRequest(
    template(getMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id.key, {
      influencer_id,
      auction_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumGet_influencer_ig_postsInfluencer_idAuction_id.key =
  "/moi/premium/get_influencer_ig_posts/{influencer_id}/{auction_id}";

/**
 *
 * get specific influencer stories from instagram from the db
 */
/**
* @package
*/
export const getMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  /**
   *
   * auction_id
   */
  auction_id: number,
  queryParams?: GetMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerInstagramStories[]>> => {
  return Http.getRequest(
    template(
      getMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id.key,
      { influencer_id, auction_id },
    ),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumGet_influencer_ig_storiesInfluencer_idAuction_id.key =
  "/moi/premium/get_influencer_ig_stories/{influencer_id}/{auction_id}";

/**
 *
 * Route for influencers to fetch their own stories
 */
/**
* @package
*/
export const getMoiPremiumInfluencer_stories = (
  queryParams?: GetMoiPremiumInfluencer_storiesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerInstagramStories[]>> => {
  return Http.getRequest(
    getMoiPremiumInfluencer_stories.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumInfluencer_stories.key = "/moi/premium/influencer_stories";

/**
 *
 * get all lists for logined user
 */
/**
* @package
*/
export const getMoiPremiumLists = (
  queryParams?: GetMoiPremiumListsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListSummary[]>> => {
  return Http.getRequest(
    getMoiPremiumLists.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumLists.key = "/moi/premium/lists";

/**
 *
 * Get requests data status on all influencers
 */
/**
* @package
*/
export const getMoiPremiumListsInfluencers_request_data_list = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<RequestDataInfluencersList>> => {
  return Http.getRequest(
    getMoiPremiumListsInfluencers_request_data_list.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumListsInfluencers_request_data_list.key =
  "/moi/premium/lists/influencers_request_data_list";

/**
 *
 * get list data with users
 */
/**
* @package
*/
export const getMoiPremiumListsList_id = (
  /**
   *
   * The id of the list to add to
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListData>> => {
  return Http.getRequest(
    template(getMoiPremiumListsList_id.key, { list_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumListsList_id.key = "/moi/premium/lists/{list_id}";

/**
 *
 * get the list form data
 */
/**
* @package
*/
export const getMoiPremiumListsList_idForm_data = (
  /**
   *
   * The id of the list to get the form data
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    answers?: ListFormAnswer[];
    form_uid?: string;
    questions?: AuctionQuestions;
    /**
     *
     * - Format: int64
     */
    total_answers?: number;
  }>
> => {
  return Http.getRequest(
    template(getMoiPremiumListsList_idForm_data.key, { list_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumListsList_idForm_data.key =
  "/moi/premium/lists/{list_id}/form_data";

/**
 *
 * get list users and data
 */
/**
* @package
*/
export const getMoiPremiumListsList_idFrequenices = (
  /**
   *
   * The id of the list
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ instagram?: Frequencies; twitter?: Frequencies }>
> => {
  return Http.getRequest(
    template(getMoiPremiumListsList_idFrequenices.key, { list_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumListsList_idFrequenices.key =
  "/moi/premium/lists/{list_id}/frequenices";

/**
 *
 * get slist segemntation data
 */
/**
* @package
*/
export const getMoiPremiumListsList_idGet_list_segmentation_data = (
  /**
   *
   * the list id
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListExtendedData>> => {
  return Http.getRequest(
    template(getMoiPremiumListsList_idGet_list_segmentation_data.key, {
      list_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumListsList_idGet_list_segmentation_data.key =
  "/moi/premium/lists/{list_id}/get_list_segmentation_data";

/**
 *
 * get current list share status
 */
/**
* @package
*/
export const getMoiPremiumListsList_idShare = (
  /**
   *
   * The id of the list to share
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ShareListParameters>> => {
  return Http.getRequest(
    template(getMoiPremiumListsList_idShare.key, { list_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumListsList_idShare.key = "/moi/premium/lists/{list_id}/share";

/**
 *
 * get all lists for logined user
 */
/**
* @package
*/
export const getMoiPremiumListsUsers_mapping = (
  queryParams?: GetMoiPremiumListsUsers_mappingQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      description?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image_url?: string;
      influencer_ids?: number[];
      is_favorite?: boolean;
      /**
       *
       * - Format: int64
       */
      linked_auction_id?: number;
      list_type?: "normal" | "campaign" | "list_fee";
      title?: string;
    }[]
  >
> => {
  return Http.getRequest(
    getMoiPremiumListsUsers_mapping.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumListsUsers_mapping.key = "/moi/premium/lists/users_mapping";

/**
 *
 * get all lists for logined user
 */
/**
* @package
*/
export const getMoiPremiumLists_with_scrolling = (
  queryParams?: GetMoiPremiumLists_with_scrollingQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * UserLists
     *
     */
    normal?: MinimalListSummary[];
    request_data?: RequestDataSummary;
  }>
> => {
  return Http.getRequest(
    getMoiPremiumLists_with_scrolling.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumLists_with_scrolling.key = "/moi/premium/lists_with_scrolling";

/**
 *
 * open chat channel with influencer
 */
/**
* @package
*/
export const getMoiPremiumOpen_chatInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getMoiPremiumOpen_chatInfluencer_id.key, { influencer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumOpen_chatInfluencer_id.key =
  "/moi/premium/open_chat/{influencer_id}";

/**
 *
 * get request data summary
 */
/**
* @package
*/
export const getMoiPremiumRequest_data_summary = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<RequestDataSummary>> => {
  return Http.getRequest(
    getMoiPremiumRequest_data_summary.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumRequest_data_summary.key = "/moi/premium/request_data_summary";

/**
 *
 * get share parameters
 */
/**
* @package
*/
export const getMoiPremiumRoiAuction_idShare = (
  /**
   *
   * The id of the roi auction to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ShareReportParameters>> => {
  return Http.getRequest(
    template(getMoiPremiumRoiAuction_idShare.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumRoiAuction_idShare.key = "/moi/premium/roi/{auction_id}/share";

/**
 *
 * list auction todo items
 */
/**
* @package
*/
export const getMoiPremiumTodo_templates = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ items?: TodoTemplate[] }>> => {
  return Http.getRequest(
    getMoiPremiumTodo_templates.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiPremiumTodo_templates.key = "/moi/premium/todo_templates";

/**
 *
 * Get all reviews for an influencer
 */
/**
* @package
*/
export const getMoiReviewsInfluencerId = (
  /**
   *
   * The influencer id to fetch
   */
  influencerId: number,
  queryParams?: GetMoiReviewsInfluencerIdQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * ReviewsArray
     *
     */
    reviews?: ReadReviewData[];
  }>
> => {
  return Http.getRequest(
    template(getMoiReviewsInfluencerId.key, { influencerId }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiReviewsInfluencerId.key = "/moi/reviews/{influencerId}";

/**
 *
 * List all shadow influencers of the current user (and enterprise if such exists)
 */
/**
* @package
*/
export const getMoiShadow_influencers = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ShadowInfluencerWithId[]>> => {
  return Http.getRequest(
    getMoiShadow_influencers.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiShadow_influencers.key = "/moi/shadow_influencers";

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const getMoiSharesShare_guidNotes = (
  /**
   *
   * The id of the share
   */
  share_guid: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ShareNotesSummary>> => {
  return Http.getRequest(
    template(getMoiSharesShare_guidNotes.key, { share_guid }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiSharesShare_guidNotes.key = "/moi/shares/{share_guid}/notes";

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const getMoiSharesShare_guidNotesItem_typeNotesItem_id = (
  /**
   *
   * The id of the share
   */
  share_guid: string,
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ notes?: NoteMessage[] }>> => {
  return Http.getRequest(
    template(getMoiSharesShare_guidNotesItem_typeNotesItem_id.key, {
      share_guid,
      item_type,
      item_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiSharesShare_guidNotesItem_typeNotesItem_id.key =
  "/moi/shares/{share_guid}/notes/{item_type}/notes/{item_id}";

/**
 *
 * Get sidebar of connected user
 */
/**
* @package
*/
export const getMoiSidebar = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SideBarData>> => {
  return Http.getRequest(
    getMoiSidebar.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiSidebar.key = "/moi/sidebar";

/**
 *
 * list requester tickets according to status (and organization tickets)
 */
/**
* @package
*/
export const getMoiSupport_center = (
  queryParams: GetMoiSupport_centerQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ tickets?: SupportTicket[] }>> => {
  return Http.getRequest(
    getMoiSupport_center.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiSupport_center.key = "/moi/support_center";

/**
 *
 * set last seen time of requester
 */
/**
* @package
*/
export const getMoiSupport_centerLast_seen_timeTicket_id = (
  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getMoiSupport_centerLast_seen_timeTicket_id.key, { ticket_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiSupport_centerLast_seen_timeTicket_id.key =
  "/moi/support_center/last_seen_time/{ticket_id}";

/**
 *
 * list specific ticket articles according to time filter (if provided)
 */
/**
* @package
*/
export const getMoiSupport_centerTicket_id = (
  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  queryParams?: GetMoiSupport_centerTicket_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ ticket_articles?: SupportTicketArticle[] }>> => {
  return Http.getRequest(
    template(getMoiSupport_centerTicket_id.key, { ticket_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiSupport_centerTicket_id.key = "/moi/support_center/{ticket_id}";

/**
 *
 * get task offers data
 */
/**
* @package
*/
export const getMoiTask_offersTask_offer_id = (
  /**
   *
   * The id of the offer
   */
  task_offer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ tasks_modification?: TaskModification }>> => {
  return Http.getRequest(
    template(getMoiTask_offersTask_offer_id.key, { task_offer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiTask_offersTask_offer_id.key = "/moi/task_offers/{task_offer_id}";

/**
 *
 * get specific unfinished campaign
 */
/**
* @package
*/
export const getMoiUnfinished_campaignsUnfinished_campaign_id = (
  /**
   *
   * The unfinished campaign id
   */
  unfinished_campaign_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UnfinishedCampaign>> => {
  return Http.getRequest(
    template(getMoiUnfinished_campaignsUnfinished_campaign_id.key, {
      unfinished_campaign_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiUnfinished_campaignsUnfinished_campaign_id.key =
  "/moi/unfinished_campaigns/{unfinished_campaign_id}";

/**
 *
 * get last updates
 */
/**
* @package
*/
export const getMoiUpdate_center = (
  queryParams?: GetMoiUpdate_centerQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<VersionUpdate[]>> => {
  return Http.getRequest(
    getMoiUpdate_center.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiUpdate_center.key = "/moi/update_center";

/**
 *
 * check if any version updates available
 */
/**
* @package
*/
export const getMoiUpdate_centerCheck_updates = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UpdatesAvailable>> => {
  return Http.getRequest(
    getMoiUpdate_centerCheck_updates.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiUpdate_centerCheck_updates.key = "/moi/update_center/check_updates";

/**
 *
 * check if user feedback exists
 */
/**
* @package
*/
export const getMoiUser_feature_feedback = (
  queryParams: GetMoiUser_feature_feedbackQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<boolean>> => {
  return Http.getRequest(
    getMoiUser_feature_feedback.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getMoiUser_feature_feedback.key = "/moi/user_feature_feedback";

/**
 *
 * get pixel wizard details by uuid
 */
/**
* @package
*/
export const getPixel_wizard = (
  headerParams?: {
    "x-pixel-wizard-pass": string;
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "x-pixel-wizard-uuid": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PixelWizardDetails>> => {
  return Http.getRequest(
    getPixel_wizard.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getPixel_wizard.key = "/pixel_wizard/";

/**
 *
 * get top words and posts
 */
/**
* @package
*/
export const getSharesBrand_reportTop_words = (
  queryParams?: GetSharesBrand_reportTop_wordsQueryParams,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      posts?: {
        instagram_profile_pic_url?: string;
        instagram_username?: string;
        media_id?: string;
        text?: string;
      }[];
      posts_amount?: number;
      word?: string;
      /**
       *
       * - Format: int64
       */
      word_id?: number;
    }[]
  >
> => {
  return Http.getRequest(
    getSharesBrand_reportTop_words.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesBrand_reportTop_words.key = "/shares/brand_report/top_words";

/**
 *
 * Get campaign share parameteres
 */
/**
* @package
*/
export const getSharesCampaign = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CampaignShareParametersWithOwnerDetails>> => {
  return Http.getRequest(
    getSharesCampaign.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesCampaign.key = "/shares/campaign";

/**
 *
 * Get specific auction by id
 */
/**
* @package
*/
export const getSharesCampaignBrief = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExtendedAuctionData>> => {
  return Http.getRequest(
    getSharesCampaignBrief.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesCampaignBrief.key = "/shares/campaign/brief";

/**
 *
 * get shared list - to view influencers before campaign live
 */
/**
* @package
*/
export const getSharesCampaignList = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    list?: ListData;
    owner_details?: ShareOwnerDetails;
    shared_parameters?: ShareListParameters;
  }>
> => {
  return Http.getRequest(
    getSharesCampaignList.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesCampaignList.key = "/shares/campaign/list";

/**
 *
 * get all board details
 */
/**
* @package
*/
export const getSharesCampaignManage = (
  queryParams?: GetSharesCampaignManageQueryParams,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManageAuctionData>> => {
  return Http.getRequest(
    getSharesCampaignManage.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesCampaignManage.key = "/shares/campaign/manage";

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const getSharesCampaignNotes = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ShareNotesSummary>> => {
  return Http.getRequest(
    getSharesCampaignNotes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesCampaignNotes.key = "/shares/campaign/notes";

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const getSharesCampaignNotesItem_typeNotesItem_id = (
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ notes?: NoteMessage[] }>> => {
  return Http.getRequest(
    template(getSharesCampaignNotesItem_typeNotesItem_id.key, {
      item_type,
      item_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesCampaignNotesItem_typeNotesItem_id.key =
  "/shares/campaign/notes/{item_type}/notes/{item_id}";

/**
 *
 * get shared campaign raw details
 */
/**
* @package
*/
export const getSharesCampaignShare_details = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    campaign_title?: string;
    company_name?: string;
    country?: string;
    is_password_protected?: boolean;
  }>
> => {
  return Http.getRequest(
    getSharesCampaignShare_details.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesCampaignShare_details.key = "/shares/campaign/share_details";

/**
 *
 * get tracker data
 */
/**
* @package
*/
export const getSharesCampaignTracker = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    events?: TrackerEvent[];
    /**
     *
     * CampaignTrackerDataKpi
     *
     */
    kpi?: { conversions?: KPI; engagements?: KPI; impressions?: KPI };
    /**
     *
     * CampaignTrackerSummary
     *
     */
    summary?: {
      /**
       *
       * - Format: int64
       */
      auction_id?: number;
      chosen?: KPI;
      content_approved?: KPI;
      content_published?: KPI;
      image?: string;
      title?: string;
    };
    top_content?: LinkedMediaForInfluencer[];
  }>
> => {
  return Http.getRequest(
    getSharesCampaignTracker.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesCampaignTracker.key = "/shares/campaign/tracker";

/**
 *
 * Get influencer details card
 */
/**
* @package
*/
export const getSharesInfluencer_card = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerCardData>> => {
  return Http.getRequest(
    getSharesInfluencer_card.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesInfluencer_card.key = "/shares/influencer_card";

/**
 *
 * Get shared campaign details for influencers
 */
/**
* @package
*/
export const getSharesInvite = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionDataForInfluencerExtended>> => {
  return Http.getRequest(
    getSharesInvite.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesInvite.key = "/shares/invite";

/**
 *
 * get shared list - to view influencers before campaign live
 */
/**
* @package
*/
export const getSharesLists = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    list?: ListData;
    owner_details?: ShareOwnerDetails;
    shared_parameters?: ShareListParameters;
  }>
> => {
  return Http.getRequest(
    getSharesLists.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesLists.key = "/shares/lists";

/**
 *
 * get list users and data
 */
/**
* @package
*/
export const getSharesListsFrequenices = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ instagram?: Frequencies; twitter?: Frequencies }>
> => {
  return Http.getRequest(
    getSharesListsFrequenices.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesListsFrequenices.key = "/shares/lists/frequenices";

/**
 *
 * get shared list segementation
 */
/**
* @package
*/
export const getSharesListsGet_list_segmentation_data = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListExtendedData>> => {
  return Http.getRequest(
    getSharesListsGet_list_segmentation_data.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesListsGet_list_segmentation_data.key =
  "/shares/lists/get_list_segmentation_data";

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const getSharesListsNotes = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ShareNotesSummary>> => {
  return Http.getRequest(
    getSharesListsNotes.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesListsNotes.key = "/shares/lists/notes";

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const getSharesListsNotesItem_typeNotesItem_id = (
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ notes?: NoteMessage[] }>> => {
  return Http.getRequest(
    template(getSharesListsNotesItem_typeNotesItem_id.key, {
      item_type,
      item_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesListsNotesItem_typeNotesItem_id.key =
  "/shares/lists/notes/{item_type}/notes/{item_id}";

/**
 *
 * get shared multi report
 */
/**
* @package
*/
export const getSharesMulti_reports = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    roi?: ManagedCampaignROI;
    shared_parameters?: MultiReportShareParameters;
  }>
> => {
  return Http.getRequest(
    getSharesMulti_reports.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesMulti_reports.key = "/shares/multi_reports";

/**
 *
 * get shared multi report raw details
 */
/**
* @package
*/
export const getSharesMulti_reportsShare_details = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    company_name?: string;
    is_password_protected?: boolean;
    report_title?: string;
  }>
> => {
  return Http.getRequest(
    getSharesMulti_reportsShare_details.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesMulti_reportsShare_details.key = "/shares/multi_reports/share_details";

/**
 *
 * get shared roi - to view roi of a campaign (board)
 */
/**
* @package
*/
export const getSharesRoiLast_month_for_roi = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: date
     */
    last_month?: string;
    /**
     *
     * - Format: date
     */
    last_month_with_purchases?: string;
    one_month_campaign?: boolean;
    pixel_enabled?: boolean;
  }>
> => {
  return Http.getRequest(
    getSharesRoiLast_month_for_roi.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSharesRoiLast_month_for_roi.key = "/shares/roi/last_month_for_roi";

/**
 *
 * shopify health endpoint
 */
/**
* @package
*/
export const getShopify = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    current_time?: number;
    message?: string;
  }>
> => {
  return Http.getRequest(
    getShopify.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getShopify.key = "/shopify";

/**
 *
 * get influencer data
 */
/**
* @package
*/
export const getShopifyInfluencerInfluencer_id = (
  /**
   *
   * the influencer id to get data for
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<WixInfluencerData>> => {
  return Http.getRequest(
    template(getShopifyInfluencerInfluencer_id.key, { influencer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getShopifyInfluencerInfluencer_id.key = "/shopify/influencer/{influencer_id}";

/**
 *
 * manage influencers
 */
/**
* @package
*/
export const getShopifyManageInfluencers = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<WixInfluencerManage[]>> => {
  return Http.getRequest(
    getShopifyManageInfluencers.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getShopifyManageInfluencers.key = "/shopify/manage/influencers";

/**
 *
 * Returns ROI data for specific filter requested
 */
/**
* @package
*/
export const getShopifyRoiFilter = (
  queryParams: GetShopifyRoiFilterQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManagedCampaignROI>> => {
  return Http.getRequest(
    getShopifyRoiFilter.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getShopifyRoiFilter.key = "/shopify/roi/filter";

/**
 *
 * List saved ROI filters
 */
/**
* @package
*/
export const getShopifyRoiFilterSaved = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<WixSavedRoiFilterData[]>> => {
  return Http.getRequest(
    getShopifyRoiFilterSaved.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getShopifyRoiFilterSaved.key = "/shopify/roi/filter/saved";

/**
 *
 * current user settings
 */
/**
* @package
*/
export const getShopifySettings = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<WixUserSettings>> => {
  return Http.getRequest(
    getShopifySettings.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getShopifySettings.key = "/shopify/settings";

/**
 *
 * get global invite link for agent
 */
/**
* @package
*/
export const getSocialLoginAgentInvite_influencerAgent_uuid = (
  /**
   *
   * agent uuid
   */
  agent_uuid: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AgentExternalInfluencerInviteDetails>> => {
  return Http.getRequest(
    template(getSocialLoginAgentInvite_influencerAgent_uuid.key, {
      agent_uuid,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getSocialLoginAgentInvite_influencerAgent_uuid.key =
  "/socialLogin/agent/invite_influencer/{agent_uuid}";

/**
 *
 * get login parameters for tiktok
 */
/**
* @package
*/
export const getSocialLoginAttach_socialTiktokInit = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    scopes:
      | "user.info.profile"
      | "video.list"
      | "user.info.stats"
      | "user.info.basic"[];
    state: string;
  }>
> => {
  return Http.getRequest(
    getSocialLoginAttach_socialTiktokInit.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getSocialLoginAttach_socialTiktokInit.key =
  "/socialLogin/attach_social/tiktok/init";

/**
 *
 * get link api invite details
 */
/**
* @package
*/
export const getSocialLoginExternal_registerLink_apiInvite = (
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExternalInfluencerInviteDetails>> => {
  return Http.getRequest(
    getSocialLoginExternal_registerLink_apiInvite.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
getSocialLoginExternal_registerLink_apiInvite.key =
  "/socialLogin/external_register/link_api/invite";

/**
 *
 * get the required permissions for facebook_instagram link
 */
/**
* @package
*/
export const getSocialLoginFacebook_instagramPermissions = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ scopes?: string[] }>> => {
  return Http.getRequest(
    getSocialLoginFacebook_instagramPermissions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getSocialLoginFacebook_instagramPermissions.key =
  "/socialLogin/facebook_instagram/permissions";

/**
 *
 * get request details
 */
/**
* @package
*/
export const getTipaltiGenerate_external_linkRequest_id = (
  /**
   *
   * the request id
   */
  request_id: string,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: date-time
     */
    request_end_time?: string;
    /**
     *
     * - Format: date-time
     */
    request_filled_at?: string;
    request_valid?: boolean;
    url?: string;
  }>
> => {
  return Http.getRequest(
    template(getTipaltiGenerate_external_linkRequest_id.key, { request_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getTipaltiGenerate_external_linkRequest_id.key =
  "/tipalti/generate_external_link/{request_id}";

/**
 * 
 * get app minimum versions

 */
/**
* @package
*/
export const getUtilsApp_min_versions = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ app_version?: string; tc_version?: number }>> => {
  return Http.getRequest(
    getUtilsApp_min_versions.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getUtilsApp_min_versions.key = "/utils/app_min_versions";

/**
 * 
 * get minimum app version

 */
/**
* @package
*/
export const getUtilsMin_app_version = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<string>> => {
  return Http.getRequest(
    getUtilsMin_app_version.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getUtilsMin_app_version.key = "/utils/min_app_version";

/**
 *
 * send notification about support ticket update
 */
/**
* @package
*/
export const getUtilsSend_support_notificationUser_id = (
  /**
   *
   * user id to notify
   */
  user_id: number,
  queryParams: GetUtilsSend_support_notificationUser_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getUtilsSend_support_notificationUser_id.key, { user_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getUtilsSend_support_notificationUser_id.key =
  "/utils/send_support_notification/{user_id}";

/**
 *
 * success redirect for tipalti
 */
/**
* @package
*/
export const getUtilsTipaltiRequest_id = (
  /**
   *
   * the request id
   */
  request_id: string,
  queryParams?: GetUtilsTipaltiRequest_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    template(getUtilsTipaltiRequest_id.key, { request_id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getUtilsTipaltiRequest_id.key = "/utils/tipalti/{request_id}";

/**
 *
 * Verify user mail address
 */
/**
* @package
*/
export const getVerify_email = (
  queryParams: GetVerify_emailQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.getRequest(
    getVerify_email.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getVerify_email.key = "/verify_email";

/**
 *
 * wix health endpoint
 */
/**
* @package
*/
export const getWix = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    current_time?: number;
    message?: string;
  }>
> => {
  return Http.getRequest(
    getWix.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getWix.key = "/wix";

/**
 *
 * get influencer data
 */
/**
* @package
*/
export const getWixInfluencerInfluencer_id = (
  /**
   *
   * the influencer id to get data for
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<WixInfluencerData>> => {
  return Http.getRequest(
    template(getWixInfluencerInfluencer_id.key, { influencer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getWixInfluencerInfluencer_id.key = "/wix/influencer/{influencer_id}";

/**
 *
 * manage influencers
 */
/**
* @package
*/
export const getWixManageInfluencers = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<WixInfluencerManage[]>> => {
  return Http.getRequest(
    getWixManageInfluencers.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getWixManageInfluencers.key = "/wix/manage/influencers";

/**
 *
 * Returns ROI data for specific filter requested
 */
/**
* @package
*/
export const getWixRoiFilter = (
  queryParams: GetWixRoiFilterQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManagedCampaignROI>> => {
  return Http.getRequest(
    getWixRoiFilter.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getWixRoiFilter.key = "/wix/roi/filter";

/**
 *
 * List saved ROI filters
 */
/**
* @package
*/
export const getWixRoiFilterSaved = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<WixSavedRoiFilterData[]>> => {
  return Http.getRequest(
    getWixRoiFilterSaved.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getWixRoiFilterSaved.key = "/wix/roi/filter/saved";

/**
 *
 * current user settings
 */
/**
* @package
*/
export const getWixSettings = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<WixUserSettings>> => {
  return Http.getRequest(
    getWixSettings.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
getWixSettings.key = "/wix/settings";

/**
 * 
 * save lesson

 */
/**
* @package
*/
export const postAcademyChaptersChapter_idLessonLesson_ordinal_num = (
  /**
   *
   * the current chapter id
   */
  chapter_id: number,
  /**
   *
   * the lesson ordinal number
   */
  lesson_ordinal_num: number,
  requestBody: { markdown_text?: string; title?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postAcademyChaptersChapter_idLessonLesson_ordinal_num.key, {
      chapter_id,
      lesson_ordinal_num,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAcademyChaptersChapter_idLessonLesson_ordinal_num.key =
  "/academy/chapters/{chapter_id}/lesson/{lesson_ordinal_num}";

/**
 * 
 * subscribe/unsubscribe to course

 */
/**
* @package
*/
export const postAcademyCoursesCourse_translation_id = (
  /**
   *
   * the course translation id
   */
  course_translation_id: number,
  requestBody: { subscribe?: boolean },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postAcademyCoursesCourse_translation_id.key, {
      course_translation_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAcademyCoursesCourse_translation_id.key =
  "/academy/courses/{course_translation_id}";

/**
 * 
 * Answer on test

 */
/**
* @package
*/
export const postAcademyQuizQuiz_id = (
  /**
   *
   * the chapter quiz id
   */
  quiz_id: string,
  requestBody: {
    answers?: {
      /**
       *
       * - Format: int32
       */
      chosen_answer?: number;
      /**
       *
       * - Format: int64
       */
      id?: number;
    }[];
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<QuizResult>> => {
  return Http.postRequest(
    template(postAcademyQuizQuiz_id.key, { quiz_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAcademyQuizQuiz_id.key = "/academy/quiz/{quiz_id}";

/**
 *
 * archive or unarchive a campaign for agent
 */
/**
* @package
*/
export const postAgentAuctionsAuction_idArchive = (
  /**
   *
   * The id of the auction to archive
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postAgentAuctionsAuction_idArchive.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAgentAuctionsAuction_idArchive.key = "/agent/auctions/{auction_id}/archive";

/**
 *
 * Generate path to upload direct to storage
 */
/**
* @package
*/
export const postAgentGenerate_upload_path = (
  requestBody: RequestBodyFileToUploadDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postAgentGenerate_upload_path.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAgentGenerate_upload_path.key = "/agent/generate_upload_path";

/**
 *
 * move temp influencer to archive
 */
/**
* @package
*/
export const postAgentInfluencerArchiveInfluencer_id = (
  /**
   *
   * influencer id to add to archive
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postAgentInfluencerArchiveInfluencer_id.key, { influencer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAgentInfluencerArchiveInfluencer_id.key =
  "/agent/influencer/archive/{influencer_id}";

/**
 *
 * create external influencer page uid
 */
/**
* @package
*/
export const postAgentInfluencerExternal_pageInfluencer_id = (
  /**
   *
   * The influencer id of the page
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postAgentInfluencerExternal_pageInfluencer_id.key, {
      influencer_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAgentInfluencerExternal_pageInfluencer_id.key =
  "/agent/influencer/external_page/{influencer_id}";

/**
 *
 * add influencer to agent management list
 */
/**
* @package
*/
export const postAgentInfluencerInfluencer_id = (
  /**
   *
   * influencer id to add
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postAgentInfluencerInfluencer_id.key, { influencer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAgentInfluencerInfluencer_id.key = "/agent/influencer/{influencer_id}";

/**
 *
 * influencer response for agent invite
 */
/**
* @package
*/
export const postAgentInfluencerStatus = (
  requestBody: InfluencerAgentInviteResponse,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postAgentInfluencerStatus.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAgentInfluencerStatus.key = "/agent/influencer/status";

/**
 *
 * add invoice
 */
/**
* @package
*/
export const postAgentInvoices = (
  requestBody: RequestBodyExternalInvoiceData,
  queryParams?: PostAgentInvoicesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postAgentInvoices.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAgentInvoices.key = "/agent/invoices";

/**
 *
 * Search influencer by social network
 */
/**
* @package
*/
export const postAgentSearch_influencer = (
  requestBody: {
    social_network: SocialNetworkOptions;
    /**
     *
     * - minLength: 2
     */
    username: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      /**
       *
       * - Format: int64
       */
      humanz_fk?: number;
      image?: string;
      /**
       *
       * - Format: int64
       */
      influencer_id?: number;
      social_handle?: string;
      social_network?: SocialNetworkOptions;
    }[]
  >
> => {
  return Http.postRequest(
    postAgentSearch_influencer.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postAgentSearch_influencer.key = "/agent/search_influencer";

/**
 *
 * create a challenge for phone
 */
/**
* @package
*/
export const postChallengeCreate_phone_challenge = (
  requestBody: { captcha?: string; phone?: string; phone_id?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postChallengeCreate_phone_challenge.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postChallengeCreate_phone_challenge.key = "/challenge/create_phone_challenge";

/**
 *
 * create a challenge for phone
 */
/**
* @package
*/
export const postChallengeCreate_social_login_challenge = (
  requestBody: {
    /**
     *
     * OwnDataExtracted
     *
     */
    own_data_extracted?: {
      /**
       *
       * - Format: int64
       */
      followers?: number;
      full_name?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image?: string;
      username?: string;
    };
    social_id?: string;
    social_network?: "youtube" | "twitter" | "instagram" | "tiktok";
    social_username?: string;
    /**
     *
     * - Format: int64
     */
    user_id?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postChallengeCreate_social_login_challenge.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postChallengeCreate_social_login_challenge.key =
  "/challenge/create_social_login_challenge";

/**
 *
 * call the influencer
 */
/**
* @package
*/
export const postChallengeRequest_call = (
  requestBody: { captcha?: string; challenge_id?: string; phone_id?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postChallengeRequest_call.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postChallengeRequest_call.key = "/challenge/request_call";

/**
 *
 * check email challenge validation
 */
/**
* @package
*/
export const postChallengeTest_email_challenge = (
  requestBody: RequestBodyChallengeDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postChallengeTest_email_challenge.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postChallengeTest_email_challenge.key = "/challenge/test_email_challenge";

/**
 *
 * check phone challenge validation
 */
/**
* @package
*/
export const postChallengeTest_phone_challenge = (
  requestBody: RequestBodyChallengeDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postChallengeTest_phone_challenge.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postChallengeTest_phone_challenge.key = "/challenge/test_phone_challenge";

/**
 * 
 * mark seen match

 */
/**
* @package
*/
export const postCollaborateMatchMatch_idSeen = (
  /**
   *
   * The match id you want to chat with
   */
  match_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postCollaborateMatchMatch_idSeen.key, { match_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postCollaborateMatchMatch_idSeen.key = "/collaborate/match/{match_id}/seen";

/**
 *
 * Unmatch
 */
/**
* @package
*/
export const postCollaborateMatchMatch_idUnmatch = (
  /**
   *
   * The math id
   */
  match_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postCollaborateMatchMatch_idUnmatch.key, { match_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postCollaborateMatchMatch_idUnmatch.key =
  "/collaborate/match/{match_id}/unmatch";

/**
 * 
 * new contact us request on site, notify us with mail.

 */
/**
* @package
*/
export const postContact_email = (
  requestBody: {
    /**
     *
     * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
     */
    contact_email?: string;
    message?: string;
    name?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postContact_email.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postContact_email.key = "/contact_email";

/**
 * 
 * response to invite

 */
/**
* @package
*/
export const postEnterprise_inviteInvite_id = (
  /**
   *
   * the invite id
   */
  invite_id: string,
  requestBody: { accept?: boolean },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postEnterprise_inviteInvite_id.key, { invite_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postEnterprise_inviteInvite_id.key = "/enterprise_invite/{invite_id}";

/**
 *
 * create email verification
 */
/**
* @package
*/
export const postForgot_passwordCreate_email_verification = (
  queryParams: PostForgot_passwordCreate_email_verificationQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postForgot_passwordCreate_email_verification.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postForgot_passwordCreate_email_verification.key =
  "/forgot_password/create_email_verification";

/**
 *
 * create a new password
 */
/**
* @package
*/
export const postForgot_passwordReset = (
  requestBody: {
    /**
     *
     * - Format: uuid
     */
    challenge?: string;
    /**
     *
     * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
     */
    email?: string;
    /**
     *
     * - Format: password
     */
    password?: string;
    user_type?: UserType;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postForgot_passwordReset.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postForgot_passwordReset.key = "/forgot_password/reset";

/**
 *
 * add invoice
 */
/**
* @package
*/
export const postInvoices = (
  requestBody: RequestBodyExternalInvoiceData,
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
    /**
     *
     * - minLength: 1
     */
    "auth-key-password": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postInvoices.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postInvoices.key = "/invoices";

/**
 *
 * Generate path to upload direct to storage
 */
/**
* @package
*/
export const postInvoicesGenerate_upload_path = (
  requestBody: RequestBodyFileToUploadDetails,
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
    /**
     *
     * - minLength: 1
     */
    "auth-key-password": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postInvoicesGenerate_upload_path.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postInvoicesGenerate_upload_path.key = "/invoices/generate_upload_path";

/**
 * 
 * Login using MOI's internal details

 */
/**
* @package
*/
export const postLogin = (
  requestBody: RequestBodyLoginData,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    advertiser?: AllAdvertiserDetails;
    agent?: AllAgentDetails;
  }>
> => {
  return Http.postRequest(
    postLogin.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postLogin.key = "/login";

/**
 * 
 * Login using MOI's internal details for agent

 */
/**
* @package
*/
export const postLoginAgent_mimic = (
  requestBody: {
    email?: string;
    /**
     *
     * - Format: int64
     */
    user_id?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    advertiser?: AllAdvertiserDetails;
    agent?: AllAgentDetails;
  }>
> => {
  return Http.postRequest(
    postLoginAgent_mimic.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postLoginAgent_mimic.key = "/login/agent_mimic";

/**
 * 
 * Login using MOI's internal details

 */
/**
* @package
*/
export const postLoginApp = (
  requestBody: RequestBodyLoginData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UserSocialLoginSuccess>> => {
  return Http.postRequest(
    postLoginApp.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postLoginApp.key = "/login/app";

/**
 * 
 * First time log-in using token from email password signup

 */
/**
* @package
*/
export const postLoginFirst_time_login = (
  requestBody: { signupToken?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<UserSocialLoginSuccess>> => {
  return Http.postRequest(
    postLoginFirst_time_login.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postLoginFirst_time_login.key = "/login/first_time_login";

/**
 * 
 * Login using MOI's internal details

 */
/**
* @package
*/
export const postLoginMimic = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    auction_id?: number;
    email?: string;
    /**
     *
     * - Format: int64
     */
    user_id?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AllAdvertiserDetails>> => {
  return Http.postRequest(
    postLoginMimic.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postLoginMimic.key = "/login/mimic";

/**
 * 
 * Login using MOI's internal details

 */
/**
* @package
*/
export const postLoginMimic_app = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    user_id?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerDetails>> => {
  return Http.postRequest(
    postLoginMimic_app.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postLoginMimic_app.key = "/login/mimic_app";

/**
 * 
 * Mimic a user by a special role, like "sales", "tagger", etc.

 */
/**
* @package
*/
export const postLoginMimic_role = (
  requestBody: { role?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AllAdvertiserDetails>> => {
  return Http.postRequest(
    postLoginMimic_role.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postLoginMimic_role.key = "/login/mimic_role";

/**
 *
 * Refresh token
 */
/**
* @package
*/
export const postLoginRefreshToken = (
  requestBody: { refresh_token?: string },
  headerParams?: { "X-MoiBackendAuthorization": string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postLoginRefreshToken.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postLoginRefreshToken.key = "/login/refreshToken";

/**
 *
 * add feature request
 */
/**
* @package
*/
export const postMoiAdd_feature_request = (
  requestBody: { country?: string; feature?: string; message?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<number>> => {
  return Http.postRequest(
    postMoiAdd_feature_request.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT2, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAdd_feature_request.key = "/moi/add_feature_request";

/**
 * 
 * Adding password to a registered verified user (via google for example)

 */
/**
* @package
*/
export const postMoiAdd_password = (
  requestBody: {
    /**
     *
     * - Format: password
     */
    password?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiAdd_password.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAdd_password.key = "/moi/add_password";

/**
 * 
 * Allow or Deny all sub users access to all lists, reports, and campaigns

 */
/**
* @package
*/
export const postMoiAdvertiserMini_users = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<boolean>> => {
  return Http.postRequest(
    postMoiAdvertiserMini_users.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAdvertiserMini_users.key = "/moi/advertiser/mini_users";

/**
 *
 * advertiser support request
 */
/**
* @package
*/
export const postMoiAdvertiser_support = (
  requestBody: RequestBodySupportRequestDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiAdvertiser_support.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAdvertiser_support.key = "/moi/advertiser_support";

/**
 *
 * advertiser support request
 */
/**
* @package
*/
export const postMoiAdvertiser_supportExtended = (
  requestBody: SupportRequestDetailsExtended,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiAdvertiser_supportExtended.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAdvertiser_supportExtended.key = "/moi/advertiser_support/extended";

/**
 *
 * add influencer agent
 */
/**
* @package
*/
export const postMoiAgent = (
  requestBody: RequestBodyNewAgentInfo,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiAgent.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAgent.key = "/moi/agent";

/**
 * 
 * block app user

 */
/**
* @package
*/
export const postMoiApp_block_userUser_id = (
  /**
   *
   * the user id to block
   */
  user_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiApp_block_userUser_id.key, { user_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiApp_block_userUser_id.key = "/moi/app_block_user/{user_id}";

/**
 * 
 * add app user report

 */
/**
* @package
*/
export const postMoiApp_user_report = (
  requestBody: AppUserReport,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiApp_user_report.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiApp_user_report.key = "/moi/app_user_report";

/**
 *
 * create a new target
 */
/**
* @package
*/
export const postMoiAuctionAuction_idTargetsCreate = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  requestBody: TargetEdit,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionAuction_idTargetsCreate.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionAuction_idTargetsCreate.key =
  "/moi/auction/{auction_id}/targets/create";

/**
 *
 * update existing target
 */
/**
* @package
*/
export const postMoiAuctionAuction_idTargetsUpdate = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  requestBody: TargetEdit,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionAuction_idTargetsUpdate.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionAuction_idTargetsUpdate.key =
  "/moi/auction/{auction_id}/targets/update";

/**
 *
 * Influencer's reminder for task
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id =
  (
    /**
     *
     * The auction id for proposal
     */
    auction_id: number,
    /**
     *
     * The influencer id for proposal
     */
    influencer_id: number,
    /**
     *
     * The auction id for proposal
     */
    task_id: number,
    requestBody: TaskReminderData,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
      template(
        postMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id.key,
        { auction_id, influencer_id, task_id },
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
postMoiAuctionManageAuction_idAdd_reminder_influencerInfluencer_idTaskTask_id.key =
  "/moi/auction/manage/{auction_id}/add_reminder_influencer/{influencer_id}/task/{task_id}";

/**
 *
 * post a link to a task influencer
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idAttach_storyTask_id = (
  /**
   *
   * The task
   */
  task_id: number,
  /**
   *
   * The auction id for task
   */
  auction_id: number,
  requestBody: {
    fb_id?: string;
    /**
     *
     * - Format: uuid
     */
    id?: string;
    image?: UploadedImage;
    is_subtask?: boolean;
  },
  queryParams?: PostMoiAuctionManageAuction_idAttach_storyTask_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idAttach_storyTask_id.key, {
      task_id,
      auction_id,
    }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idAttach_storyTask_id.key =
  "/moi/auction/manage/{auction_id}/attach_story/{task_id}";

/**
 *
 * create custom link
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idCustom_link = (
  /**
   *
   * The auction id
   */
  auction_id: number,
  requestBody: RequestBodyInfluencerCustomLink,
  queryParams?: PostMoiAuctionManageAuction_idCustom_linkQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idCustom_link.key, { auction_id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idCustom_link.key =
  "/moi/auction/manage/{auction_id}/custom_link/";

/**
 *
 * Complete the file upload process
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid = (
  /**
   *
   * ID of the influencer to whom the file is to be attached
   */
  influencer_id: number,
  /**
   *
   * Campaign ID to which the file is associated with the user
   */
  auction_id: number,
  /**
   *
   * UUID of the upload request (received in the GET request response)
   */
  file_uuid: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid.key, {
      influencer_id,
      auction_id,
      file_uuid,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idFilesInfluencer_idFile_uuid.key =
  "/moi/auction/manage/{auction_id}/files/{influencer_id}/{file_uuid}";

/**
 *
 * add extra payout to influencer
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idInfluencer_idExtra_payout = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencers
   */
  influencer_id: number,
  requestBody: ExtraPayoutDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idInfluencer_idExtra_payout.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idInfluencer_idExtra_payout.key =
  "/moi/auction/manage/{auction_id}/{influencer_id}/extra_payout";

/**
 *
 * pay for marketplace auction bid
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idPayInfluencer_id = (
  /**
   *
   * The auction id of the bid you want to pay for
   */
  auction_id: number,
  /**
   *
   * The influencer id of the bid you want to pay for
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SuccessfulCharge>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idPayInfluencer_id.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idPayInfluencer_id.key =
  "/moi/auction/manage/{auction_id}/pay/{influencer_id}";

/**
 *
 * change recurring payment
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idPaymentsInfluencer_idChange_recurring_payment =
  (
    /**
     *
     * The id of the auction
     */
    auction_id: number,
    /**
     *
     * The id of the influencer
     */
    influencer_id: number,
    requestBody: {
      /**
       *
       * - Format: double
       */
      change_to?: number;
      change_type?: "only_this_month" | "all_months" | "next_months";
      id?: string;
    },
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
      template(
        postMoiAuctionManageAuction_idPaymentsInfluencer_idChange_recurring_payment.key,
        { auction_id, influencer_id },
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
postMoiAuctionManageAuction_idPaymentsInfluencer_idChange_recurring_payment.key =
  "/moi/auction/manage/{auction_id}/payments/{influencer_id}/change_recurring_payment";

/**
 *
 * add dispute to payment
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencer
   */
  influencer_id: number,
  requestBody: { dispute_reason?: string; ids?: string[] },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    dispute_id?: number;
  }>
> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idPaymentsInfluencer_idDisputes.key =
  "/moi/auction/manage/{auction_id}/payments/{influencer_id}/disputes";

/**
 *
 * stop affiliation for next month
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idPaymentsInfluencer_idStop_affiliation =
  (
    /**
     *
     * The id of the auction
     */
    auction_id: number,
    /**
     *
     * The id of the influencer
     */
    influencer_id: number,
    requestBody: { from_now?: boolean; stop?: boolean },
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
      template(
        postMoiAuctionManageAuction_idPaymentsInfluencer_idStop_affiliation.key,
        { auction_id, influencer_id },
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
postMoiAuctionManageAuction_idPaymentsInfluencer_idStop_affiliation.key =
  "/moi/auction/manage/{auction_id}/payments/{influencer_id}/stop_affiliation";

/**
 *
 * add upfront payment to influencer
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencer
   */
  influencer_id: number,
  requestBody: {
    id?: string;
    /**
     *
     * - Format: double
     */
    upfront_price?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idPaymentsInfluencer_idUpfront.key =
  "/moi/auction/manage/{auction_id}/payments/{influencer_id}/upfront";

/**
 *
 * post a link to a task influencer
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idPost_linkTask_id = (
  /**
   *
   * The task
   */
  task_id: number,
  /**
   *
   * The auction id for task
   */
  auction_id: number,
  requestBody: {
    internal_media_id?: string;
    is_subtask?: boolean;
    post_type?: PostTypes;
    post_uri?: string;
  },
  queryParams?: PostMoiAuctionManageAuction_idPost_linkTask_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idPost_linkTask_id.key, {
      task_id,
      auction_id,
    }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idPost_linkTask_id.key =
  "/moi/auction/manage/{auction_id}/post_link/{task_id}";

/**
 *
 * Enable share all uploads for user
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idProposal_feedbackInfluencer_idShare_all_for_user =
  (
    /**
     *
     * The auction id
     */
    auction_id: number,
    /**
     *
     * The influencer id
     */
    influencer_id: number,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
      template(
        postMoiAuctionManageAuction_idProposal_feedbackInfluencer_idShare_all_for_user.key,
        { auction_id, influencer_id },
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
postMoiAuctionManageAuction_idProposal_feedbackInfluencer_idShare_all_for_user.key =
  "/moi/auction/manage/{auction_id}/proposal_feedback/{influencer_id}/share_all_for_user";

/**
 *
 * Advertiser's response for influencer's proposal for campaign
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idProposal_feedbackProposal_id = (
  /**
   *
   * The auction id for proposal
   */
  auction_id: number,
  /**
   *
   * The influencer's proposal id
   */
  proposal_id: number,
  requestBody: RequestBodyTaskProposalFeedbackData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idProposal_feedbackProposal_id.key, {
      auction_id,
      proposal_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idProposal_feedbackProposal_id.key =
  "/moi/auction/manage/{auction_id}/proposal_feedback/{proposal_id}";

/**
 *
 * Advertisers sharing or disable share of specific proposal on the system
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idProposal_feedbackProposal_idShare = (
  /**
   *
   * The campaign id for proposal
   */
  auction_id: number,
  /**
   *
   * The influencer's proposal id
   */
  proposal_id: number,
  requestBody: { note?: string; share?: boolean },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(
      postMoiAuctionManageAuction_idProposal_feedbackProposal_idShare.key,
      { auction_id, proposal_id },
    ),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idProposal_feedbackProposal_idShare.key =
  "/moi/auction/manage/{auction_id}/proposal_feedback/{proposal_id}/share";

/**
 *
 * Disable or Enable share uploads
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idProposal_feedbackProposal_idToggle_share_uploads =
  (
    /**
     *
     * The auction id
     */
    auction_id: number,
    /**
     *
     * The proposal id
     */
    proposal_id: number,
    requestBody: { uploads?: string[] },
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
      template(
        postMoiAuctionManageAuction_idProposal_feedbackProposal_idToggle_share_uploads.key,
        { auction_id, proposal_id },
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
postMoiAuctionManageAuction_idProposal_feedbackProposal_idToggle_share_uploads.key =
  "/moi/auction/manage/{auction_id}/proposal_feedback/{proposal_id}/toggle_share_uploads";

/**
 *
 * Influencer's proposal for campaign
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idProposal_uploadTask_id = (
  /**
   *
   * The auction id for proposal
   */
  auction_id: number,
  /**
   *
   * The auction id for proposal
   */
  task_id: number,
  requestBody: RequestBodyInfluencerTaskProposalData,
  queryParams?: PostMoiAuctionManageAuction_idProposal_uploadTask_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    proposal_id?: number;
  }>
> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idProposal_uploadTask_id.key, {
      auction_id,
      task_id,
    }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idProposal_uploadTask_id.key =
  "/moi/auction/manage/{auction_id}/proposal_upload/{task_id}";

/**
 *
 * Influencer's proposal for campaign
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idProposal_upload_as_advertiserInfluencer_idTaskTask_id =
  (
    /**
     *
     * The auction id for proposal
     */
    auction_id: number,
    /**
     *
     * The influencer id for proposal
     */
    influencer_id: number,
    /**
     *
     * The auction id for proposal
     */
    task_id: number,
    requestBody: RequestBodyInfluencerTaskProposalData,
    configOverride?: AxiosRequestConfig,
  ): Promise<
    SwaggerResponse<{
      /**
       *
       * - Format: int64
       */
      proposal_id?: number;
    }>
  > => {
    return Http.postRequest(
      template(
        postMoiAuctionManageAuction_idProposal_upload_as_advertiserInfluencer_idTaskTask_id.key,
        { auction_id, influencer_id, task_id },
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
postMoiAuctionManageAuction_idProposal_upload_as_advertiserInfluencer_idTaskTask_id.key =
  "/moi/auction/manage/{auction_id}/proposal_upload_as_advertiser/{influencer_id}/task/{task_id}";

/**
 *
 * proposal history - route for influencer
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idProposals_history = (
  /**
   *
   * The auction id
   */
  auction_id: number,
  queryParams?: PostMoiAuctionManageAuction_idProposals_historyQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      current_proposal?: ProposalData;
      /**
       *
       * - Format: date
       */
      month_relevance?: string;
      old_proposals?: ProposalData[];
      post_type?: PostTypes;
      /**
       *
       * - Format: int64
       */
      subtask_of?: number;
      /**
       *
       * - Format: int64
       */
      task_id?: number;
    }[]
  >
> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idProposals_history.key, {
      auction_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idProposals_history.key =
  "/moi/auction/manage/{auction_id}/proposals_history";

/**
 *
 * proposal history - route for advertiser
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idProposals_historyInfluencer_id = (
  /**
   *
   * The auction id
   */
  auction_id: number,
  /**
   *
   * The influencer id you want the history for
   */
  influencer_id: number,
  queryParams?: PostMoiAuctionManageAuction_idProposals_historyInfluencer_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      current_proposal?: ProposalData;
      /**
       *
       * - Format: date
       */
      month_relevance?: string;
      old_proposals?: ProposalData[];
      post_type?: PostTypes;
      /**
       *
       * - Format: int64
       */
      subtask_of?: number;
      /**
       *
       * - Format: int64
       */
      task_id?: number;
    }[]
  >
> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idProposals_historyInfluencer_id.key, {
      auction_id,
      influencer_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idProposals_historyInfluencer_id.key =
  "/moi/auction/manage/{auction_id}/proposals_history/{influencer_id}";

/**
 *
 * query influencers
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idSearch_bids = (
  /**
   *
   * the auction id to search in
   */
  auction_id: number,
  requestBody: InfluencerSearchParametersWithQuestions,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<number[]>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idSearch_bids.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idSearch_bids.key =
  "/moi/auction/manage/{auction_id}/search_bids";

/**
 *
 * Advertiser's response for influencer's proposal for campaign
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idSeen_column_notification = (
  /**
   *
   * The auction id for proposal
   */
  auction_id: number,
  requestBody: { column_name?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idSeen_column_notification.key, {
      auction_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idSeen_column_notification.key =
  "/moi/auction/manage/{auction_id}/seen_column_notification";

/**
 *
 * create new tag
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idTag = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  requestBody: RequestBodyCampaignTag2,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idTag.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idTag.key = "/moi/auction/manage/{auction_id}/tag";

/**
 *
 * stop affiliation for next month
 */
/**
* @package
*/
export const postMoiAuctionManageAuction_idToggle_influencers_next_month = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  requestBody: { influencers_ids?: number[]; stop?: boolean },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageAuction_idToggle_influencers_next_month.key, {
      auction_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageAuction_idToggle_influencers_next_month.key =
  "/moi/auction/manage/{auction_id}/toggle_influencers_next_month";

/**
 *
 * post in behalf of influencer
 */
/**
* @package
*/
export const postMoiAuctionManageBoard_idApprove_bidInfluencer_id = (
  /**
   *
   * The influencer id that post in behalf
   */
  influencer_id: number,
  /**
   *
   * The board
   */
  board_id: number,
  requestBody: {
    bid_id?: number;
    express?: boolean;
    from_next_month?: boolean;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageBoard_idApprove_bidInfluencer_id.key, {
      influencer_id,
      board_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageBoard_idApprove_bidInfluencer_id.key =
  "/moi/auction/manage/{board_id}/approve_bid/{influencer_id}";

/**
 *
 * post in behalf of influencer
 */
/**
* @package
*/
export const postMoiAuctionManageBoard_idInfluencer_idPost_link = (
  /**
   *
   * The influencer id that post in behalf
   */
  influencer_id: number,
  /**
   *
   * The board
   */
  board_id: number,
  requestBody: LinkedTaskPost[],
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageBoard_idInfluencer_idPost_link.key, {
      influencer_id,
      board_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageBoard_idInfluencer_idPost_link.key =
  "/moi/auction/manage/{board_id}/{influencer_id}/post_link";

/**
 *
 * create task and link to post in the same function
 */
/**
* @package
*/
export const postMoiAuctionManageBoard_idInfluencer_idTask_create_link = (
  /**
   *
   * The influencer id that post in behalf
   */
  influencer_id: number,
  /**
   *
   * The board
   */
  board_id: number,
  requestBody: TaskWithPost,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageBoard_idInfluencer_idTask_create_link.key, {
      influencer_id,
      board_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageBoard_idInfluencer_idTask_create_link.key =
  "/moi/auction/manage/{board_id}/{influencer_id}/task_create_link";

/**
 *
 * negotiate a bid , this mean suggest influencer another price for his job
 */
/**
* @package
*/
export const postMoiAuctionManageBoard_idNegotiate_bidInfluencer_id = (
  /**
   *
   * The influencer id we are goin to negotiate with
   */
  influencer_id: number,
  /**
   *
   * The board
   */
  board_id: number,
  requestBody: {
    bid_id?: number;
    from_next_month?: boolean;
    price?: number;
    task_modifications?: TaskModification;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionManageBoard_idNegotiate_bidInfluencer_id.key, {
      influencer_id,
      board_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageBoard_idNegotiate_bidInfluencer_id.key =
  "/moi/auction/manage/{board_id}/negotiate_bid/{influencer_id}";

/**
 *
 * update story count
 */
/**
* @package
*/
export const postMoiAuctionManageBoard_idStory_view_countInfluencer_id = (
  /**
   *
   * The id of the board to add to
   */
  board_id: number,
  /**
   *
   * The id of the influencer to change story count
   */
  influencer_id: number,
  requestBody: StoryViewsUpload,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<TaskManageData[]>> => {
  return Http.postRequest(
    template(postMoiAuctionManageBoard_idStory_view_countInfluencer_id.key, {
      board_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionManageBoard_idStory_view_countInfluencer_id.key =
  "/moi/auction/manage/{board_id}/story_view_count/{influencer_id}";

/**
 *
 * Insert new auction
 */
/**
* @package
*/
export const postMoiAuctions = (
  requestBody: NewAuctionData,
  queryParams: PostMoiAuctionsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiAuctions.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctions.key = "/moi/auctions";

/**
 *
 * attach invoice to influencer and auction id
 */
/**
* @package
*/
export const postMoiAuctionsAttach_invoice = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    auction_id?: number;
    file_url?: string;
    influencer_id?: string;
    /**
     *
     * - Format: date
     */
    month?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiAuctionsAttach_invoice.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAttach_invoice.key = "/moi/auctions/attach_invoice";

/**
 *
 * Edit Auction copuns only
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idCoupons = (
  /**
   *
   * the auction id to edit sales section
   */
  auction_id: number,
  requestBody: CouponInSales[],
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idCoupons.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idCoupons.key = "/moi/auctions/{auction_id}/coupons";

/**
 *
 * Inject events to merger queue
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idCouponsInfluencer_idPixel_inject = (
  /**
   *
   * the auction id that requested the injection
   */
  auction_id: number,
  /**
   *
   * the influencer id to inject his coupons
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idCouponsInfluencer_idPixel_inject.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idCouponsInfluencer_idPixel_inject.key =
  "/moi/auctions/{auction_id}/coupons/{influencer_id}/pixel_inject";

/**
 *
 * Edit Auction tasks
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idEdit_tasks = (
  /**
   *
   * the auction id to edit tasks to
   */
  auction_id: number,
  requestBody: {
    /**
     *
     * AuctionCampaignDates
     *
     */
    campaign_dates?: {
      /**
       *
       * - Format: date-time
       */
      end?: string;
      /**
       *
       * - Format: date-time
       */
      start?: string;
    };
    changed_tasks?: TaskWithId[];
    deleted_tasks?: number[];
    new_tasks?: Task[];
    tasks_payments_instructions?: TasksPaymentsInstructions;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idEdit_tasks.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idEdit_tasks.key =
  "/moi/auctions/{auction_id}/edit_tasks";

/**
 *
 * Edit Auction instructions
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idInstructions = (
  /**
   *
   * the auction id to edit products to
   */
  auction_id: number,
  requestBody: DosAndDonts,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idInstructions.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idInstructions.key =
  "/moi/auctions/{auction_id}/instructions";

/**
 *
 * Edit Auction kpis
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idKpis = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  requestBody: EditedKPIS,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idKpis.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idKpis.key = "/moi/auctions/{auction_id}/kpis";

/**
 *
 * add tasks for influencers in campaign
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idMultiple_tasks = (
  /**
   *
   * the auction id
   */
  auction_id: number,
  requestBody: {
    influencers?: number[];
    /**
     *
     * - Format: date
     */
    month_relevance?: string;
    /**
     *
     * - Format: int64
     */
    price?: number;
    tasks?: TaskWithAdditonalPrice[];
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    auction_data?: ManageAuctionData;
    failed_influencers_ids?: number[];
  }>
> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idMultiple_tasks.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idMultiple_tasks.key =
  "/moi/auctions/{auction_id}/multiple_tasks";

/**
 *
 * Edit Auction Overview
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idOverview = (
  /**
   *
   * the auction id to edit overview to
   */
  auction_id: number,
  requestBody: EditAuctionOverview,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idOverview.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idOverview.key = "/moi/auctions/{auction_id}/overview";

/**
 *
 * Edit Auction payments
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idPayment = (
  /**
   *
   * the auction id to edit payment to
   */
  auction_id: number,
  requestBody: EditAuctionPayments,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    auction_id?: number;
    /**
     *
     * - Format: date-time
     */
    expiration_time?: string;
    /**
     *
     * - Format: int64
     */
    list_id?: number;
  }>
> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idPayment.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idPayment.key = "/moi/auctions/{auction_id}/payment";

/**
 *
 * add auction phase
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idPixel_phases = (
  /**
   *
   * the auction id to get the phases for
   */
  auction_id: number,
  requestBody: RequestBodyAuctionPhase,
  queryParams?: PostMoiAuctionsAuction_idPixel_phasesQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionPhase>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idPixel_phases.key, { auction_id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idPixel_phases.key =
  "/moi/auctions/{auction_id}/pixel_phases";

/**
 *
 * Edit Auction products
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idProduct = (
  /**
   *
   * the auction id to edit products to
   */
  auction_id: number,
  requestBody: EditAuctionProducts,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idProduct.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idProduct.key = "/moi/auctions/{auction_id}/product";

/**
 *
 * Edit Auction questions
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idQuestions = (
  /**
   *
   * the auction id to edit questions to
   */
  auction_id: number,
  requestBody: AuctionQuestions,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idQuestions.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idQuestions.key = "/moi/auctions/{auction_id}/questions";

/**
 *
 * Edit Auction sales
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idSales = (
  /**
   *
   * the auction id to edit sales section
   */
  auction_id: number,
  requestBody: AuctionSales,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idSales.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idSales.key = "/moi/auctions/{auction_id}/sales";

/**
 *
 * upload file to server multipart
 */
/**
* @package
*/
export const postMoiAuctionsAuction_idUpload_invoice = (
  /**
   *
   * The auction id
   */
  auction_id: number,
  requestBody: {
    /**
     *
     * The invoice to upload.
     * - Format: binary
     */
    file?: string;
    /**
     *
     * The invoice id
     */
    id?: string;
    /**
     *
     * The invoice month
     * - Format: date
     */
    month?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiAuctionsAuction_idUpload_invoice.key, { auction_id }),
    undefined,
    objToForm(requestBody),
    undefined,
    overrideConfig(_CONSTANT3, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsAuction_idUpload_invoice.key =
  "/moi/auctions/{auction_id}/upload_invoice";

/**
 *
 * check link availability , in unfinished it will reserve this short link until created or deleted
 */
/**
* @package
*/
export const postMoiAuctionsCheck_link_availability = (
  requestBody: LinkAvailableCheck,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ available?: boolean }>> => {
  return Http.postRequest(
    postMoiAuctionsCheck_link_availability.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsCheck_link_availability.key =
  "/moi/auctions/check_link_availability";

/**
 *
 * Returns ROI data for specific auction
 */
/**
* @package
*/
export const postMoiAuctionsIdProducts_sales_roi = (
  /**
   *
   * The auction id to return ROI to
   */
  id: number,
  requestBody: RequestBodyFiltersAndScrollProducts,
  queryParams?: PostMoiAuctionsIdProducts_sales_roiQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ have_more?: boolean; items?: PurchaseClicksType[] }>
> => {
  return Http.postRequest(
    template(postMoiAuctionsIdProducts_sales_roi.key, { id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsIdProducts_sales_roi.key =
  "/moi/auctions/{id}/products_sales_roi";

/**
 *
 * Restart an auction that expired and didn't complete
 */
/**
* @package
*/
export const postMoiAuctionsIdRevert = (
  /**
   *
   * The auction id to revert
   */
  id: number,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    id?: number;
    /**
     *
     * - Format: date-time
     */
    new_expiration_time?: string;
  }>
> => {
  return Http.postRequest(
    template(postMoiAuctionsIdRevert.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsIdRevert.key = "/moi/auctions/{id}/revert";

/**
 *
 * Returns ROI data for specific auction
 */
/**
* @package
*/
export const postMoiAuctionsIdRoi_filtered = (
  /**
   *
   * The auction id to return ROI to
   */
  id: number,
  requestBody: CampaignReportFilterParameters,
  queryParams?: PostMoiAuctionsIdRoi_filteredQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManagedCampaignROI>> => {
  return Http.postRequest(
    template(postMoiAuctionsIdRoi_filtered.key, { id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsIdRoi_filtered.key = "/moi/auctions/{id}/roi_filtered";

/**
 *
 * Returns ROI data for specific auction
 */
/**
* @package
*/
export const postMoiAuctionsIdSales_roi_breakdownBreakdown = (
  /**
   *
   * The auction id to return ROI to
   */
  id: number,
  /**
   *
   * The breakdown for the sale
   */
  breakdown: string,
  requestBody: RequestBodyFiltersAndScrollProducts,
  queryParams?: PostMoiAuctionsIdSales_roi_breakdownBreakdownQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ have_more?: boolean; items?: PurchaseClicksType[] }>
> => {
  return Http.postRequest(
    template(postMoiAuctionsIdSales_roi_breakdownBreakdown.key, {
      id,
      breakdown,
    }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsIdSales_roi_breakdownBreakdown.key =
  "/moi/auctions/{id}/sales_roi_breakdown/{breakdown}";

/**
 *
 * get media image by media info
 */
/**
* @package
*/
export const postMoiAuctionsRoiMedia = (
  requestBody: InfluencerWithMedias[],
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerWithMedias[]>> => {
  return Http.postRequest(
    postMoiAuctionsRoiMedia.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsRoiMedia.key = "/moi/auctions/roi/media";

/**
 *
 * generate a slides report from the campaign roi
 */
/**
* @package
*/
export const postMoiAuctionsRoiSlides = (
  requestBody: ROISlidesInfo,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiAuctionsRoiSlides.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsRoiSlides.key = "/moi/auctions/roi/slides";

/**
 *
 * Calculate Reach  & Frequency for posts from ROI
 */
/**
* @package
*/
export const postMoiAuctionsRoi_reach_frequencies = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
    post_id?: string;
    /**
     *
     * - Format: date-time
     */
    post_time?: string;
    post_type?: PostTypes;
    /**
     *
     * - Format: int64
     */
    reach?: number;
    social_network?: SocialNetworkOptions;
  }[],
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    campaign?: ReachAndFrequency;
    per_influencer?: {
      /**
       *
       * - Format: int64
       */
      influencer_id?: number;
      reach_frequency?: ReachAndFrequency;
    }[];
  }>
> => {
  return Http.postRequest(
    postMoiAuctionsRoi_reach_frequencies.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAuctionsRoi_reach_frequencies.key =
  "/moi/auctions/roi_reach_frequencies";

/**
 *
 * Search brand/sku for autocomplete
 */
/**
* @package
*/
export const postMoiAutocomplete_sales = (
  requestBody: {
    app_id?: string;
    query?: string;
    search_type?: "brand" | "sku";
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      /**
       *
       * - Format: int64
       */
      amount?: number;
      result?: string;
    }[]
  >
> => {
  return Http.postRequest(
    postMoiAutocomplete_sales.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiAutocomplete_sales.key = "/moi/autocomplete_sales";

/**
 *
 * add or edit influencer age group
 */
/**
* @package
*/
export const postMoiChange_age_groupInfluencer_id = (
  /**
   *
   * The id of the list to add to
   */
  influencer_id: string,
  requestBody: { age_group?: "teen" | "child" | "adult" | "elderly" },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiChange_age_groupInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiChange_age_groupInfluencer_id.key =
  "/moi/change_age_group/{influencer_id}";

/**
 *
 * add or edit influencer category
 */
/**
* @package
*/
export const postMoiChange_categoriesInfluencer_id = (
  /**
   *
   * The id of the list to add to
   */
  influencer_id: string,
  requestBody: {
    categories?: string[];
    social_network?: "twitter" | "instagram" | "youtube" | "tiktok";
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiChange_categoriesInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiChange_categoriesInfluencer_id.key =
  "/moi/change_categories/{influencer_id}";

/**
 *
 * add or edit influencer gender
 */
/**
* @package
*/
export const postMoiChange_connectionInfluencer_id = (
  /**
   *
   * The id of the influencer
   */
  influencer_id: string,
  requestBody: {
    change_to?: string;
    social_network_from?: "youtube" | "twitter" | "instagram" | "tiktok";
    social_network_to?: "youtube" | "twitter" | "instagram" | "tiktok";
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiChange_connectionInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiChange_connectionInfluencer_id.key =
  "/moi/change_connection/{influencer_id}";

/**
 *
 * add or edit influencer country
 */
/**
* @package
*/
export const postMoiChange_countryInfluencer_id = (
  /**
   *
   * The id of the list to add to
   */
  influencer_id: string,
  requestBody: { country?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiChange_countryInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiChange_countryInfluencer_id.key = "/moi/change_country/{influencer_id}";

/**
 *
 * add or edit influencer gender
 */
/**
* @package
*/
export const postMoiChange_genderInfluencer_id = (
  /**
   *
   * The id of the list to add to
   */
  influencer_id: string,
  requestBody: { gender?: "female" | "male"; registered?: boolean },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiChange_genderInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiChange_genderInfluencer_id.key = "/moi/change_gender/{influencer_id}";

/**
 *
 * Check last eligible agent worked with the influencer
 */
/**
* @package
*/
export const postMoiCheck_influencers_agents = (
  requestBody: { influencers?: number[] },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerAgentEntity[]>> => {
  return Http.postRequest(
    postMoiCheck_influencers_agents.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCheck_influencers_agents.key = "/moi/check_influencers_agents";

/**
 *
 * Advertiser's response for influencer's proposal for campaign
 */
/**
* @package
*/
export const postMoiCity_search = (
  requestBody: { name?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<string[]>> => {
  return Http.postRequest(
    postMoiCity_search.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCity_search.key = "/moi/city_search";

/**
 *
 * upload post to feed
 */
/**
* @package
*/
export const postMoiCommunity_feed = (
  requestBody: RequestBodyCreateCommunityFeedItem,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<CommunityFeedItem>> => {
  return Http.postRequest(
    postMoiCommunity_feed.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCommunity_feed.key = "/moi/community_feed";

/**
 *
 * only for approvers , give your response
 */
/**
* @package
*/
export const postMoiCommunity_feedApprovalPost_id = (
  /**
   *
   * post id
   */
  post_id: number,
  requestBody: {
    comment?: string;
    is_approved?: boolean;
    send_to_wp?: boolean;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiCommunity_feedApprovalPost_id.key, { post_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCommunity_feedApprovalPost_id.key =
  "/moi/community_feed/approval/{post_id}";

/**
 *
 * add comment to feed item
 */
/**
* @package
*/
export const postMoiCommunity_feedPost_idComments = (
  /**
   *
   * post id
   */
  post_id: number,
  requestBody: { comment?: string; mentions?: string[]; raw_comment?: string },
  queryParams?: PostMoiCommunity_feedPost_idCommentsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiCommunity_feedPost_idComments.key, { post_id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCommunity_feedPost_idComments.key =
  "/moi/community_feed/{post_id}/comments";

/**
 *
 * like comment in feed
 */
/**
* @package
*/
export const postMoiCommunity_feedPost_idCommentsComment_idLike = (
  /**
   *
   * post id
   */
  post_id: number,
  /**
   *
   * comment id
   */
  comment_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiCommunity_feedPost_idCommentsComment_idLike.key, {
      post_id,
      comment_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCommunity_feedPost_idCommentsComment_idLike.key =
  "/moi/community_feed/{post_id}/comments/{comment_id}/like";

/**
 *
 * like post in feed
 */
/**
* @package
*/
export const postMoiCommunity_feedPost_idLike = (
  /**
   *
   * post id
   */
  post_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiCommunity_feedPost_idLike.key, { post_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCommunity_feedPost_idLike.key = "/moi/community_feed/{post_id}/like";

/**
 *
 * add link click
 */
/**
* @package
*/
export const postMoiCommunity_feedPost_idLink_clicked = (
  /**
   *
   * post id
   */
  post_id: number,
  requestBody: { url?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiCommunity_feedPost_idLink_clicked.key, { post_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCommunity_feedPost_idLink_clicked.key =
  "/moi/community_feed/{post_id}/link_clicked";

/**
 *
 * add vote on poll
 */
/**
* @package
*/
export const postMoiCommunity_feedPost_idVote_on_poll = (
  /**
   *
   * post id
   */
  post_id: number,
  requestBody: { option?: number },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiCommunity_feedPost_idVote_on_poll.key, { post_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCommunity_feedPost_idVote_on_poll.key =
  "/moi/community_feed/{post_id}/vote_on_poll";

/**
 *
 * Add a new suggestion
 */
/**
* @package
*/
export const postMoiCommunity_feedSuggestionSuggestion_id = (
  requestBody: { content: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiCommunity_feedSuggestionSuggestion_id.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCommunity_feedSuggestionSuggestion_id.key =
  "/moi/community_feed/suggestion/{suggestion_id}";

/**
 *
 * Apply coupons for user
 */
/**
* @package
*/
export const postMoiCoupons = (
  requestBody: {
    /**
     *
     * - pattern: ^[a-zA-Z0-9]{3,12}$
     */
    coupon?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int32
     */
    amount_of_usages_left?: number;
    /**
     *
     * - Format: double
     */
    campaign_discount?: number;
    /**
     *
     * - pattern: ^[a-zA-Z0-9]{3,12}$
     */
    coupon?: string;
    /**
     *
     * - Format: date-time
     */
    expiration_time?: string;
    /**
     *
     * - Format: int32
     */
    max_amount_of_usages?: number;
    /**
     *
     * - Format: date-time
     */
    usage_time?: string;
    /**
     *
     * - Format: int64
     */
    used_in_auction_id?: number;
  }>
> => {
  return Http.postRequest(
    postMoiCoupons.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiCoupons.key = "/moi/coupons";

/**
 * 
 * delete user

 */
/**
* @package
*/
export const postMoiDelete_userCancel = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiDelete_userCancel.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiDelete_userCancel.key = "/moi/delete_user/cancel";

/**
 *
 * creating new brand for advertiser
 */
/**
* @package
*/
export const postMoiDetailsBrand = (
  requestBody: EditBrandInfo,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiDetailsBrand.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiDetailsBrand.key = "/moi/details/brand";

/**
 *
 * set influencer rate card
 */
/**
* @package
*/
export const postMoiDetailsInfluencerRate_card = (
  requestBody: InfluencerRateCard,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiDetailsInfluencerRate_card.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiDetailsInfluencerRate_card.key = "/moi/details/influencer/rate_card";

/**
 *
 * edit current billing address
 */
/**
* @package
*/
export const postMoiEdit_detailsAdvertiserBilling = (
  requestBody: RequestBodyBillingAddress,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingAddress>> => {
  return Http.postRequest(
    postMoiEdit_detailsAdvertiserBilling.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiEdit_detailsAdvertiserBilling.key =
  "/moi/edit_details/advertiser/billing";

/**
 *
 * edit current billing address
 */
/**
* @package
*/
export const postMoiEdit_detailsCurrent_userBilling = (
  requestBody: RequestBodyBillingAddress,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<BillingAddress>> => {
  return Http.postRequest(
    postMoiEdit_detailsCurrent_userBilling.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiEdit_detailsCurrent_userBilling.key =
  "/moi/edit_details/current_user/billing";

/**
 *
 * Add tax number to payout details
 */
/**
* @package
*/
export const postMoiEdit_detailsInfluencerBankEdit_tax_numberBank_details_id = (
  /**
   *
   * The id of the bank details
   */
  bank_details_id: number,
  requestBody: { tax_number?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(
      postMoiEdit_detailsInfluencerBankEdit_tax_numberBank_details_id.key,
      { bank_details_id },
    ),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiEdit_detailsInfluencerBankEdit_tax_numberBank_details_id.key =
  "/moi/edit_details/influencer/bank/edit_tax_number/{bank_details_id}";

/**
 *
 * change user name for influencer
 */
/**
* @package
*/
export const postMoiEdit_detailsInfluencerUser_name = (
  requestBody: { user_name?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiEdit_detailsInfluencerUser_name.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiEdit_detailsInfluencerUser_name.key =
  "/moi/edit_details/influencer/user_name";

/**
 *
 * add user as sub user
 */
/**
* @package
*/
export const postMoiEnterpriseUsersAdd_user = (
  requestBody: { email?: string; name?: string },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    already_had_user?: boolean;
    invite_url?: string;
    /**
     *
     * - Format: int64
     */
    user_id?: number;
  }>
> => {
  return Http.postRequest(
    postMoiEnterpriseUsersAdd_user.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiEnterpriseUsersAdd_user.key = "/moi/enterprise/users/add_user";

/**
 *
 * add user as sub user
 */
/**
* @package
*/
export const postMoiEnterpriseUsersChange_enterprise = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    id?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AllAdvertiserDetails>> => {
  return Http.postRequest(
    postMoiEnterpriseUsersChange_enterprise.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiEnterpriseUsersChange_enterprise.key =
  "/moi/enterprise/users/change_enterprise";

/**
 *
 * Get information about the user from social network
 */
/**
* @package
*/
export const postMoiExact_username_search = (
  requestBody: { social_network?: string; username?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerUserDetails>> => {
  return Http.postRequest(
    postMoiExact_username_search.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiExact_username_search.key = "/moi/exact_username_search";

/**
 *
 * Change influencer external page settings
 */
/**
* @package
*/
export const postMoiExternal_page_settings = (
  requestBody: ExternalPageSettings,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiExternal_page_settings.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiExternal_page_settings.key = "/moi/external_page_settings";

/**
 *
 * get frequencies of influencers
 */
/**
* @package
*/
export const postMoiFrequenices = (
  requestBody: RequestBodyInfluencerIds,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ instagram?: Frequencies; twitter?: Frequencies }>
> => {
  return Http.postRequest(
    postMoiFrequenices.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiFrequenices.key = "/moi/frequenices";

/**
 *
 * Generate path to upload direct to storage
 */
/**
* @package
*/
export const postMoiGenerate_upload_path = (
  requestBody: RequestBodyFileToUploadDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiGenerate_upload_path.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiGenerate_upload_path.key = "/moi/generate_upload_path";

/**
 *
 * check media id for post url
 */
/**
* @package
*/
export const postMoiGet_media_id = (
  requestBody: { original_url?: string; post_type?: PostTypes },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiGet_media_id.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiGet_media_id.key = "/moi/get_media_id";

/**
 *
 * Search username for autocomplete
 */
/**
* @package
*/
export const postMoiHumanz_username_search = (
  requestBody: { username?: string },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      /**
       *
       * - Format: int64
       */
      id?: number;
      image?: string;
      name?: string;
      username?: string;
    }[]
  >
> => {
  return Http.postRequest(
    postMoiHumanz_username_search.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiHumanz_username_search.key = "/moi/humanz_username_search";

/**
 *
 * send multi message on board
 */
/**
* @package
*/
export const postMoiInboxBoard_idMulti_message = (
  /**
   *
   * The board
   */
  board_id: number,
  requestBody: {
    /**
     *
     * ChatAttachment
     *
     */
    attachment?: {
      attachment_type?: "image" | "video" | "pdf" | "doc" | "audio";
      url?: string;
    };
    influencers?: {
      /**
       *
       * - Format: int64
       */
      agent_user_id?: number;
      /**
       *
       * - Format: int64
       */
      influencer_id?: number;
      /**
       *
       * - Format: int64
       */
      influencer_user_id?: number;
    }[];
    message?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiInboxBoard_idMulti_message.key, { board_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInboxBoard_idMulti_message.key = "/moi/inbox/{board_id}/multi_message";

/**
 * 
 * Get messages from this chat.
Caller is advertiser then :id is influencer_id
Caller is influencer then :id is advertiser_id
Caller is agent then :id is influencer_id and :campaign_id must be set

 */
/**
* @package
*/
export const postMoiInboxId = (
  /**
   *
   * The user id you want to chat with
   */
  id: number,
  requestBody: RequestBodyOrderedTimeFilter,
  queryParams?: PostMoiInboxIdQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<Message[]>> => {
  return Http.postRequest(
    template(postMoiInboxId.key, { id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInboxId.key = "/moi/inbox/{id}";

/**
 *
 * Add attachment to chat
 */
/**
* @package
*/
export const postMoiInboxIdAttachment = (
  /**
 * 
 * Get messages from this chat.
Caller is advertiser then :id is influencer_id, if :agent_private_chat and :campaign_id is not null then :id is agent_id
Caller is influencer then :id is advertiser_id
Caller is agent then :id is influencer_id and :campaign_id must be set

 */
  id: number,
  requestBody: {
    /**
     *
     * The attachment type
     */
    attachment_type?: "image" | "video" | "pdf" | "doc" | "audio";
    /**
     *
     * The attachment to upload.
     * - Format: binary
     */
    file?: string;
    /**
     *
     * The inbox type
     */
    inbox_type?: "normal" | "collaborate";
    /**
     *
     * The message id
     */
    message_id?: string;
    /**
     *
     * The message id
     */
    reply_object_type?: string;
    /**
     *
     * Reply to id If the message in reply to
     */
    reply_to_id?: string;
  },
  queryParams?: PostMoiInboxIdAttachmentQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiInboxIdAttachment.key, { id }),
    queryParams,
    objToForm(requestBody),
    undefined,
    overrideConfig(_CONSTANT3, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInboxIdAttachment.key = "/moi/inbox/{id}/attachment";

/**
 *
 * star chat
 */
/**
* @package
*/
export const postMoiInboxIdStar = (
  /**
   *
   * The user id you want to chat with
   */
  id: number,
  queryParams?: PostMoiInboxIdStarQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiInboxIdStar.key, { id }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInboxIdStar.key = "/moi/inbox/{id}/star";

/**
 * 
 * Get messages from this chat.
Caller is advertiser then :id is influencer_id
Caller is influencer then :id is advertiser_id
Caller is agent then :id is influencer_id and :campaign_id must be set

 */
/**
* @package
*/
export const postMoiInbox_with_first_timeId = (
  /**
   *
   * The user id you want to chat with
   */
  id: number,
  requestBody: RequestBodyOrderedTimeFilter,
  queryParams?: PostMoiInbox_with_first_timeIdQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: date-time
     */
    first_message_time?: string;
    /**
     *
     * MessagesArray
     *
     */
    messages?: Message[];
    /**
     *
     * - Format: int64
     */
    unread_count?: number;
  }>
> => {
  return Http.postRequest(
    template(postMoiInbox_with_first_timeId.key, { id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInbox_with_first_timeId.key = "/moi/inbox_with_first_time/{id}";

/**
 *
 * update details
 */
/**
* @package
*/
export const postMoiInfluencerUpdate_details_on_login = (
  requestBody: InfluencerDetailsRegsitration,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiInfluencerUpdate_details_on_login.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInfluencerUpdate_details_on_login.key =
  "/moi/influencer/update_details_on_login";

/**
 *
 * endorse user
 */
/**
* @package
*/
export const postMoiInfluencer_profileEndorseInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  requestBody: RequestBodyEndorseBody,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiInfluencer_profileEndorseInfluencer_id.key, {
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInfluencer_profileEndorseInfluencer_id.key =
  "/moi/influencer_profile/endorse/{influencer_id}";

/**
 *
 * add links to profile
 */
/**
* @package
*/
export const postMoiInfluencer_profilePosts_link = (
  requestBody: RequestBodyPorfileMediaAttachedInsertArray,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiInfluencer_profilePosts_link.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInfluencer_profilePosts_link.key = "/moi/influencer_profile/posts_link";

/**
 *
 * un-endorse a user
 */
/**
* @package
*/
export const postMoiInfluencer_profileUnendorseInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  requestBody: RequestBodyEndorseBody,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiInfluencer_profileUnendorseInfluencer_id.key, {
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInfluencer_profileUnendorseInfluencer_id.key =
  "/moi/influencer_profile/unendorse/{influencer_id}";

/**
 *
 * mark the influencer as app rated
 */
/**
* @package
*/
export const postMoiInfluencer_rated_app = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiInfluencer_rated_app.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInfluencer_rated_app.key = "/moi/influencer_rated_app";

/**
 *
 * call influencer update
 */
/**
* @package
*/
export const postMoiInfluencer_request_update = (
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: date-time
     */
    next_date?: string;
  }>
> => {
  return Http.postRequest(
    postMoiInfluencer_request_update.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInfluencer_request_update.key = "/moi/influencer_request_update";

/**
 *
 * generates invite link to connect to social network
 */
/**
* @package
*/
export const postMoiInvitesExternal = (
  requestBody: ExternalInfluencerInviteDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiInvitesExternal.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiInvitesExternal.key = "/moi/invites/external";

/**
 * 
 * change tags

 */
/**
* @package
*/
export const postMoiMeta_tags = (
  requestBody: MetaTag,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<number>> => {
  return Http.postRequest(
    postMoiMeta_tags.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiMeta_tags.key = "/moi/meta_tags";

/**
 *
 * create a multi report
 */
/**
* @package
*/
export const postMoiMulti_report = (
  requestBody: RequestBodyMultiReport,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiMulti_report.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiMulti_report.key = "/moi/multi_report";

/**
 *
 * get multi report with filters
 */
/**
* @package
*/
export const postMoiMulti_reportReport_idReport = (
  /**
   *
   * The report id
   */
  report_id: number,
  requestBody: MultiReportFilterParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManagedCampaignROI>> => {
  return Http.postRequest(
    template(postMoiMulti_reportReport_idReport.key, { report_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiMulti_reportReport_idReport.key = "/moi/multi_report/{report_id}/report";

/**
 *
 * create a multi report
 */
/**
* @package
*/
export const postMoiMulti_reportSuggest_kpis_target = (
  requestBody: { auction_ids?: number[] },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ kpi?: EditedKPIS; target_audience?: AuctionTargetAudience }>
> => {
  return Http.postRequest(
    postMoiMulti_reportSuggest_kpis_target.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiMulti_reportSuggest_kpis_target.key =
  "/moi/multi_report/suggest_kpis_target";

/**
 * 
 * Get user notifications, filter optional

 */
/**
* @package
*/
export const postMoiNotifications = (
  requestBody: TimeFilter,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      amount?: number;
      /**
       *
       * - Format: int64
       */
      auctionId?: number;
      auctionTitle?: string;
      auction_type?: string;
      /**
       *
       * - Format: date-time
       */
      creation_time?: string;
      /**
       *
       * - Format: int64
       */
      id?: number;
      image_uri?: string;
      notification_data?: NotificationData;
      /**
       *
       * - Format: int64
       */
      objectId?: number;
      objectTitle?: string;
      objectType?: string;
      seen?: boolean;
      /**
       *
       * - Format: int64
       */
      userId?: number;
      was_sent_before?: boolean;
    }[]
  >
> => {
  return Http.postRequest(
    postMoiNotifications.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiNotifications.key = "/moi/notifications";

/**
 *
 * Request early payout
 */
/**
* @package
*/
export const postMoiPaymentEarly_payoutRequest = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    auction_id: number;
    /**
     *
     * - Format: uuid
     */
    deal_uuid: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiPaymentEarly_payoutRequest.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPaymentEarly_payoutRequest.key = "/moi/payment/early_payout/request";

/**
 *
 * Payout from moi to influencer
 */
/**
* @package
*/
export const postMoiPaymentPay_influencer = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    campaign_id?: number;
    /**
     *
     * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
     */
    paypal_email?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiPaymentPay_influencer.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPaymentPay_influencer.key = "/moi/payment/pay_influencer";

/**
 *
 * Pays for chosing an influencer
 */
/**
* @package
*/
export const postMoiPaymentPayment_from_advertiser = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    auction_id?: number;
    /**
     *
     * - Format: int64
     */
    bid_id?: number;
    /**
     *
     * - Format: int64
     */
    bidder_id?: number;
    from_mobile?: boolean;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ paypalPaymentUrl?: string }>> => {
  return Http.postRequest(
    postMoiPaymentPayment_from_advertiser.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPaymentPayment_from_advertiser.key =
  "/moi/payment/payment_from_advertiser";

/**
 *
 * Paypal response - got back from client after redirect. on success complete payment
 */
/**
* @package
*/
export const postMoiPaymentPayment_from_advertiserPaypal_response = (
  requestBody: {
    item_id?: string;
    operation_success?: boolean;
    payer_id?: string;
    /**
     *
     * - Format: int64
     */
    related_bid_id?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ message?: string; transaction_success?: boolean }>
> => {
  return Http.postRequest(
    postMoiPaymentPayment_from_advertiserPaypal_response.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPaymentPayment_from_advertiserPaypal_response.key =
  "/moi/payment/payment_from_advertiser/paypal_response";

/**
 *
 * archive or unarchive a campaign
 */
/**
* @package
*/
export const postMoiPremiumAuctionAuction_idArchive = (
  /**
   *
   * The id of the auction to archive
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumAuctionAuction_idArchive.key, { auction_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumAuctionAuction_idArchive.key =
  "/moi/premium/auction/{auction_id}/archive";

/**
 *
 * create auction todo item
 */
/**
* @package
*/
export const postMoiPremiumAuctionAuction_idTodo = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  requestBody: RequestBodyAuctionToDoItem,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionToDoItem>> => {
  return Http.postRequest(
    template(postMoiPremiumAuctionAuction_idTodo.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumAuctionAuction_idTodo.key =
  "/moi/premium/auction/{auction_id}/todo";

/**
 *
 * edit auction todo item
 */
/**
* @package
*/
export const postMoiPremiumAuctionAuction_idTodoTo_do_idComplete = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  /**
   *
   * The to do item you want to edit
   */
  to_do_id: number,
  queryParams: PostMoiPremiumAuctionAuction_idTodoTo_do_idCompleteQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumAuctionAuction_idTodoTo_do_idComplete.key, {
      auction_id,
      to_do_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumAuctionAuction_idTodoTo_do_idComplete.key =
  "/moi/premium/auction/{auction_id}/todo/{to_do_id}/complete";

/**
 *
 * create auction todo item
 */
/**
* @package
*/
export const postMoiPremiumAuctionAuction_idTodo_from_template = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  queryParams?: PostMoiPremiumAuctionAuction_idTodo_from_templateQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ items?: AuctionToDoItem[] }>> => {
  return Http.postRequest(
    template(postMoiPremiumAuctionAuction_idTodo_from_template.key, {
      auction_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumAuctionAuction_idTodo_from_template.key =
  "/moi/premium/auction/{auction_id}/todo_from_template";

/**
 *
 * reorder todo items in campaign
 */
/**
* @package
*/
export const postMoiPremiumAuctionAuction_idTodo_reorder = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  requestBody: RequestBodyReorderToDoItemsTodoandorder,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionToDoItem>> => {
  return Http.postRequest(
    template(postMoiPremiumAuctionAuction_idTodo_reorder.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumAuctionAuction_idTodo_reorder.key =
  "/moi/premium/auction/{auction_id}/todo_reorder";

/**
 *
 * search Auction content
 */
/**
* @package
*/
export const postMoiPremiumAuctionsAuction_idSearch_content = (
  /**
   *
   * the auction id
   */
  auction_id: number,
  requestBody: RequestBodyContentSearchParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerWithMedias[]>> => {
  return Http.postRequest(
    template(postMoiPremiumAuctionsAuction_idSearch_content.key, {
      auction_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumAuctionsAuction_idSearch_content.key =
  "/moi/premium/auctions/{auction_id}/search_content";

/**
 *
 * post in behalf of influencer
 */
/**
* @package
*/
export const postMoiPremiumBoard_idInfluencer_idPost_link = (
  /**
   *
   * The influencer id that post in behalf
   */
  influencer_id: number,
  /**
   *
   * The board
   */
  board_id: number,
  requestBody: { post_uri?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumBoard_idInfluencer_idPost_link.key, {
      influencer_id,
      board_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumBoard_idInfluencer_idPost_link.key =
  "/moi/premium/{board_id}/{influencer_id}/post_link";

/**
 *
 * modify task for specific influencer
 */
/**
* @package
*/
export const postMoiPremiumBoardsBoard_idModify_taskInfluencer_id = (
  /**
   *
   * The id of the board
   */
  board_id: number,
  /**
   *
   * The influencer id
   */
  influencer_id: number,
  requestBody: RequestBodyTaskModification,
  queryParams?: PostMoiPremiumBoardsBoard_idModify_taskInfluencer_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    couldnt_delete_tasks?: number[];
    influencer?: InfluencerInManageAuctionData;
    /**
     *
     * - Format: int64
     */
    offer_id?: number;
    tasks?: TaskWithId[];
  }>
> => {
  return Http.postRequest(
    template(postMoiPremiumBoardsBoard_idModify_taskInfluencer_id.key, {
      board_id,
      influencer_id,
    }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumBoardsBoard_idModify_taskInfluencer_id.key =
  "/moi/premium/boards/{board_id}/modify_task/{influencer_id}";

/**
 *
 * mark approval tasks as seen
 */
/**
* @package
*/
export const postMoiPremiumBoardsBoard_idSeen_approval_tasksInfluencer_id = (
  /**
   *
   * The id of the board
   */
  board_id: number,
  /**
   *
   * The influencer id
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumBoardsBoard_idSeen_approval_tasksInfluencer_id.key, {
      board_id,
      influencer_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumBoardsBoard_idSeen_approval_tasksInfluencer_id.key =
  "/moi/premium/boards/{board_id}/seen_approval_tasks/{influencer_id}";

/**
 *
 * get the list form data
 */
/**
* @package
*/
export const postMoiPremiumForm_dataConnect_user = (
  requestBody: { code?: string },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    already_in_community?: boolean;
    already_used?: boolean;
    connected?: boolean;
  }>
> => {
  return Http.postRequest(
    postMoiPremiumForm_dataConnect_user.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumForm_dataConnect_user.key = "/moi/premium/form_data/connect_user";

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const postMoiPremiumGet_influencer_dataInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: string,
  requestBody: RequestBodyExtraDataFilter,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    influencer_data?: InfluencerExtendedData;
    /**
     *
     * PostIdsBySocialMedia
     *
     */
    posts?: {
      instagram?: string[];
      tiktok?: string[];
      twitter?: string[];
      youtube?: string[];
    };
  }>
> => {
  return Http.postRequest(
    template(postMoiPremiumGet_influencer_dataInfluencer_id.key, {
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumGet_influencer_dataInfluencer_id.key =
  "/moi/premium/get_influencer_data/{influencer_id}";

/**
 *
 * Advertiser general influnecer note
 */
/**
* @package
*/
export const postMoiPremiumInfluencer_noteInfluencer_id = (
  /**
   *
   * The id of the influencer
   */
  influencer_id: number,
  requestBody: { note?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumInfluencer_noteInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumInfluencer_noteInfluencer_id.key =
  "/moi/premium/influencer_note/{influencer_id}";

/**
 *
 * archive or unarchive a list
 */
/**
* @package
*/
export const postMoiPremiumListsList_idArchive = (
  /**
   *
   * The id of the list to archive
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListSummary>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idArchive.key, { list_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idArchive.key = "/moi/premium/lists/{list_id}/archive";

/**
 *
 * edit lists tasks amounts
 */
/**
* @package
*/
export const postMoiPremiumListsList_idEdit_lists_tasks = (
  /**
   *
   * the list id to search in
   */
  list_id: number,
  requestBody: ListTasksAmountAndPercentages,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListSummary>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idEdit_lists_tasks.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idEdit_lists_tasks.key =
  "/moi/premium/lists/{list_id}/edit_lists_tasks";

/**
 *
 * toggle favorite
 */
/**
* @package
*/
export const postMoiPremiumListsList_idFavorite = (
  /**
   *
   * The id of the list
   */
  list_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idFavorite.key, { list_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idFavorite.key =
  "/moi/premium/lists/{list_id}/favorite";

/**
 *
 * get the list form data
 */
/**
* @package
*/
export const postMoiPremiumListsList_idForm_dataMore_answers = (
  /**
   *
   * The id of the list to get the form answers
   */
  list_id: number,
  requestBody: {
    amount?: number;
    form_uuid?: string;
    /**
     *
     * - Format: date-time
     */
    last_seen_time?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ answers?: ListFormAnswer[] }>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idForm_dataMore_answers.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idForm_dataMore_answers.key =
  "/moi/premium/lists/{list_id}/form_data/more_answers";

/**
 *
 * add or edit note
 */
/**
* @package
*/
export const postMoiPremiumListsList_idInfluencer_idNote = (
  /**
   *
   * The id of the list to add to
   */
  list_id: number,
  /**
   *
   * The id of the list to add to
   */
  influencer_id: number,
  requestBody: { note?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idInfluencer_idNote.key, {
      list_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idInfluencer_idNote.key =
  "/moi/premium/lists/{list_id}/{influencer_id}/note";

/**
 *
 * add or edit influencer price
 */
/**
* @package
*/
export const postMoiPremiumListsList_idInfluencer_idPrice = (
  /**
   *
   * The id of the list to add to
   */
  list_id: number,
  /**
   *
   * The id of the list to add to
   */
  influencer_id: number,
  requestBody: {
    amounts?: TasksAmounts;
    /**
     *
     * InfluencerPriceEdit
     *
     */
    prices?: {
      /**
       *
       * - Format: int64
       */
      ig_post?: number;
      /**
       *
       * - Format: int64
       */
      ig_reel?: number;
      /**
       *
       * - Format: int64
       */
      ig_story?: number;
      /**
       *
       * - Format: int64
       */
      tk_video?: number;
      /**
       *
       * - Format: int64
       */
      tw_tweet?: number;
      /**
       *
       * - Format: int64
       */
      yt_video?: number;
    };
  },
  queryParams?: PostMoiPremiumListsList_idInfluencer_idPriceQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListSummary>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idInfluencer_idPrice.key, {
      list_id,
      influencer_id,
    }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idInfluencer_idPrice.key =
  "/moi/premium/lists/{list_id}/{influencer_id}/price";

/**
 * 
 * change list order

 */
/**
* @package
*/
export const postMoiPremiumListsList_idInfluencers_order = (
  /**
   *
   * The list id
   */
  list_id: number,
  requestBody: {
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
    /**
     *
     * - Format: int32
     */
    position?: number;
  }[],
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idInfluencers_order.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idInfluencers_order.key =
  "/moi/premium/lists/{list_id}/influencers_order";

/**
 *
 * modify task for specific influencer
 */
/**
* @package
*/
export const postMoiPremiumListsList_idModify_taskInfluencer_id = (
  /**
   *
   * The id of the list
   */
  list_id: number,
  /**
   *
   * The influencer id
   */
  influencer_id: number,
  requestBody: RequestBodyTaskModification,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    couldnt_delete_tasks?: number[];
    influencer?: InfluencerInManageAuctionData;
    /**
     *
     * - Format: int64
     */
    offer_id?: number;
    tasks?: TaskWithId[];
  }>
> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idModify_taskInfluencer_id.key, {
      list_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idModify_taskInfluencer_id.key =
  "/moi/premium/lists/{list_id}/modify_task/{influencer_id}";

/**
 *
 * add links to profile
 */
/**
* @package
*/
export const postMoiPremiumListsList_idPosts_linkInfluencer_id = (
  /**
   *
   * the list id
   */
  list_id: number,
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  requestBody: RequestBodyPorfileMediaAttachedInsertArray,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idPosts_linkInfluencer_id.key, {
      list_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idPosts_linkInfluencer_id.key =
  "/moi/premium/lists/{list_id}/posts_link/{influencer_id}";

/**
 *
 * query influencers
 */
/**
* @package
*/
export const postMoiPremiumListsList_idSearch = (
  /**
   *
   * the list id to search in
   */
  list_id: number,
  requestBody: RequestBodyInfluencerSearchParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<number[]>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idSearch.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idSearch.key = "/moi/premium/lists/{list_id}/search";

/**
 *
 * search List content
 */
/**
* @package
*/
export const postMoiPremiumListsList_idSearch_content = (
  /**
   *
   * the list id
   */
  list_id: number,
  requestBody: RequestBodyContentSearchParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerWithMedias[]>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idSearch_content.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idSearch_content.key =
  "/moi/premium/lists/{list_id}/search_content";

/**
 *
 * create new tag
 */
/**
* @package
*/
export const postMoiPremiumListsList_idTag = (
  /**
   *
   * The id of the list
   */
  list_id: number,
  requestBody: RequestBodyCampaignTag2,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumListsList_idTag.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumListsList_idTag.key = "/moi/premium/lists/{list_id}/tag";

/**
 *
 * add influencer to request data list
 */
/**
* @package
*/
export const postMoiPremiumRequest_data = (
  requestBody: {
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
    social_network?: SocialNetworkOptions;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiPremiumRequest_data.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumRequest_data.key = "/moi/premium/request_data";

/**
 *
 * query influencers
 */
/**
* @package
*/
export const postMoiPremiumSearch_influencers = (
  requestBody: RequestBodyInfluencerSearchParameters,
  queryParams?: PostMoiPremiumSearch_influencersQueryParams,
  headerParams?: { "x-no-search-depth": boolean },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerShallowData[]>> => {
  return Http.postRequest(
    postMoiPremiumSearch_influencers.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postMoiPremiumSearch_influencers.key = "/moi/premium/search_influencers";

/**
 *
 * query influencers
 */
/**
* @package
*/
export const postMoiPremiumSearch_similar_influencersInfluencer_id = (
  /**
   *
   * The id you want to search similar to
   */
  influencer_id: number,
  requestBody: {
    same_country?: boolean;
    search_type?: EmbeddingSearchType;
    with_followers?: boolean;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerShallowData[]>> => {
  return Http.postRequest(
    template(postMoiPremiumSearch_similar_influencersInfluencer_id.key, {
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumSearch_similar_influencersInfluencer_id.key =
  "/moi/premium/search_similar_influencers/{influencer_id}";

/**
 *
 * query influencers
 */
/**
* @package
*/
export const postMoiPremiumSearch_similar_list_influencersList_id = (
  /**
   *
   * The list id you want to search similar to
   */
  list_id: number,
  requestBody: {
    high_engagement?: boolean;
    high_impressions?: boolean;
    search_type?: EmbeddingSearchType;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    by_ai?: SimilarListInfluencers;
    by_goes_with?: SimilarListInfluencers;
  }>
> => {
  return Http.postRequest(
    template(postMoiPremiumSearch_similar_list_influencersList_id.key, {
      list_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumSearch_similar_list_influencersList_id.key =
  "/moi/premium/search_similar_list_influencers/{list_id}";

/**
 *
 * create auction todo template
 */
/**
* @package
*/
export const postMoiPremiumTodo_templates = (
  requestBody: TodoTemplate,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<TodoTemplate>> => {
  return Http.postRequest(
    postMoiPremiumTodo_templates.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumTodo_templates.key = "/moi/premium/todo_templates";

/**
 *
 * create auction todo item in template
 */
/**
* @package
*/
export const postMoiPremiumTodo_templatesTemplate_id = (
  /**
   *
   * The id of the template to create item in
   */
  template_id: number,
  requestBody: RequestBodyAuctionToDoItem,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionToDoItem>> => {
  return Http.postRequest(
    template(postMoiPremiumTodo_templatesTemplate_id.key, { template_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumTodo_templatesTemplate_id.key =
  "/moi/premium/todo_templates/{template_id}";

/**
 *
 * create auction todo item in template
 */
/**
* @package
*/
export const postMoiPremiumTodo_templatesTemplate_idTodo_reorder = (
  /**
   *
   * The id of the template to reorder
   */
  template_id: number,
  requestBody: RequestBodyReorderToDoItemsTodoandorder,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiPremiumTodo_templatesTemplate_idTodo_reorder.key, {
      template_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiPremiumTodo_templatesTemplate_idTodo_reorder.key =
  "/moi/premium/todo_templates/{template_id}/todo_reorder";

/**
 *
 * remove social connection - influencer
 */
/**
* @package
*/
export const postMoiRemove_social = (
  requestBody: RequestBodyRemoveSocialConnection,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiRemove_social.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiRemove_social.key = "/moi/remove_social";

/**
 *
 * remove social connection - tagger
 */
/**
* @package
*/
export const postMoiRemove_social_connectionInfluencer_id = (
  /**
   *
   * The id of the influencer
   */
  influencer_id: string,
  requestBody: RequestBodyRemoveSocialConnection,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiRemove_social_connectionInfluencer_id.key, {
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiRemove_social_connectionInfluencer_id.key =
  "/moi/remove_social_connection/{influencer_id}";

/**
 *
 * add new review
 */
/**
* @package
*/
export const postMoiReviewsInfluencerId = (
  /**
   *
   * The campaign id to fetch
   */
  influencerId: number,
  requestBody: NewReviewData,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiReviewsInfluencerId.key, { influencerId }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiReviewsInfluencerId.key = "/moi/reviews/{influencerId}";

/**
 *
 * mark influencer as page
 */
/**
* @package
*/
export const postMoiSet_as_pageInfluencer_id = (
  /**
   *
   * The id of the list to add to
   */
  influencer_id: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiSet_as_pageInfluencer_id.key, { influencer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiSet_as_pageInfluencer_id.key = "/moi/set_as_page/{influencer_id}";

/**
 *
 * Create new shadow influencer
 */
/**
* @package
*/
export const postMoiShadow_influencers = (
  requestBody: ShadowInfluencer,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiShadow_influencers.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiShadow_influencers.key = "/moi/shadow_influencers";

/**
 *
 * Edit shadow influencer by id
 */
/**
* @package
*/
export const postMoiShadow_influencersShadow_influencer_id = (
  /**
   *
   * Shadow influencer id to edit
   */
  shadow_influencer_id: number,
  requestBody: ShadowInfluencer,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ShadowInfluencer>> => {
  return Http.postRequest(
    template(postMoiShadow_influencersShadow_influencer_id.key, {
      shadow_influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiShadow_influencersShadow_influencer_id.key =
  "/moi/shadow_influencers/{shadow_influencer_id}";

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const postMoiSharesShare_guidNotesItem_typeNotesItem_id = (
  /**
   *
   * The id of the share
   */
  share_guid: string,
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  requestBody: RequestBodyNoteToAdd,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    note_id?: number;
  }>
> => {
  return Http.postRequest(
    template(postMoiSharesShare_guidNotesItem_typeNotesItem_id.key, {
      share_guid,
      item_type,
      item_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiSharesShare_guidNotesItem_typeNotesItem_id.key =
  "/moi/shares/{share_guid}/notes/{item_type}/notes/{item_id}";

/**
 *
 * subscribe your affiliate
 */
/**
* @package
*/
export const postMoiSubscribe_affiliateAffiliateCode = (
  /**
   *
   * The affiliate code to subscribe to
   */
  affiliateCode: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiSubscribe_affiliateAffiliateCode.key, { affiliateCode }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiSubscribe_affiliateAffiliateCode.key =
  "/moi/subscribe_affiliate/{affiliateCode}";

/**
 *
 * add ticket article
 */
/**
* @package
*/
export const postMoiSupport_centerTicket_id = (
  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  requestBody: NewSupportTicketArticle,
  queryParams?: PostMoiSupport_centerTicket_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiSupport_centerTicket_id.key, { ticket_id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiSupport_centerTicket_id.key = "/moi/support_center/{ticket_id}";

/**
 *
 * response to task offer
 */
/**
* @package
*/
export const postMoiTask_offersTask_offer_id = (
  /**
   *
   * The id of the offer
   */
  task_offer_id: number,
  requestBody: RequestBodyResponseToTaskOfferResponse,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiTask_offersTask_offer_id.key, { task_offer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiTask_offersTask_offer_id.key = "/moi/task_offers/{task_offer_id}";

/**
 *
 * Create new unfinished campaign
 */
/**
* @package
*/
export const postMoiUnfinished_campaigns = (
  requestBody: { json_data?: string; title?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiUnfinished_campaigns.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiUnfinished_campaigns.key = "/moi/unfinished_campaigns";

/**
 *
 * update unfinished campaign
 */
/**
* @package
*/
export const postMoiUnfinished_campaignsUnfinished_campaign_id = (
  /**
   *
   * The unfinished campaign id
   */
  unfinished_campaign_id: number,
  requestBody: { image_url?: string; json_data?: string; title?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiUnfinished_campaignsUnfinished_campaign_id.key, {
      unfinished_campaign_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiUnfinished_campaignsUnfinished_campaign_id.key =
  "/moi/unfinished_campaigns/{unfinished_campaign_id}";

/**
 *
 * update unfinished campaign image uploaded successfully
 */
/**
* @package
*/
export const postMoiUnfinished_campaignsUnfinished_campaign_idImage_uploaded = (
  /**
   *
   * The unfinished campaign id
   */
  unfinished_campaign_id: number,
  requestBody: {
    /**
     *
     * - Format: uuid
     */
    image_id?: string;
    image_url?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(
      postMoiUnfinished_campaignsUnfinished_campaign_idImage_uploaded.key,
      { unfinished_campaign_id },
    ),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiUnfinished_campaignsUnfinished_campaign_idImage_uploaded.key =
  "/moi/unfinished_campaigns/{unfinished_campaign_id}/image_uploaded";

/**
 *
 * upload auction image to server
 */
/**
* @package
*/
export const postMoiUpload_imageUpload_type = (
  upload_type: AllowedImageUploadTypes,
  requestBody: {
    /**
     *
     * - Format: uuid
     */
    id?: string;
    image?: UploadedImage;
  },
  queryParams?: PostMoiUpload_imageUpload_typeQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postMoiUpload_imageUpload_type.key, { upload_type }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiUpload_imageUpload_type.key = "/moi/upload_image/{upload_type}";

/**
 *
 * upload video to server multipart
 */
/**
* @package
*/
export const postMoiUpload_video = (
  requestBody: {
    /**
     *
     * The video to upload.
     * - Format: binary
     */
    file?: string;
    /**
     *
     * The video id
     */
    id?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiUpload_video.key,
    undefined,
    objToForm(requestBody),
    undefined,
    overrideConfig(_CONSTANT3, configOverride),
  );
};

/** Key is end point string without base url */
postMoiUpload_video.key = "/moi/upload_video";

/**
 *
 * user add feature feedback
 */
/**
* @package
*/
export const postMoiUser_feature_feedback = (
  requestBody: UserFeatureFeedback,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiUser_feature_feedback.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiUser_feature_feedback.key = "/moi/user_feature_feedback";

/**
 * 
 * new log from fe

 */
/**
* @package
*/
export const postMoiWrite_log = (
  requestBody: string,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postMoiWrite_log.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postMoiWrite_log.key = "/moi/write_log";

/**
 *
 * trigger pixel validations
 */
/**
* @package
*/
export const postPixel_wizard = (
  requestBody: PixelEventData,
  headerParams?: {
    "x-pixel-wizard-pass": string;
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "x-pixel-wizard-uuid": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PixelEventsCheckResponse>> => {
  return Http.postRequest(
    postPixel_wizard.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postPixel_wizard.key = "/pixel_wizard/";

/**
 *
 * update plugin's pixel app id into pixel app id
 */
/**
* @package
*/
export const postPixel_wizardUpdate_platform = (
  requestBody: {
    /**
     *
     * - Format: uuid
     */
    search_uuid?: string;
  },
  headerParams?: {
    "x-pixel-wizard-pass": string;
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "x-pixel-wizard-uuid": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postPixel_wizardUpdate_platform.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postPixel_wizardUpdate_platform.key = "/pixel_wizard/update_platform";

/**
 *
 * get frequencies of influencers
 */
/**
* @package
*/
export const postSharesAmbassador_reportFrequencies = (
  requestBody: RequestBodyInfluencerIds,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ instagram?: Frequencies; twitter?: Frequencies }>
> => {
  return Http.postRequest(
    postSharesAmbassador_reportFrequencies.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSharesAmbassador_reportFrequencies.key =
  "/shares/ambassador_report/frequencies";

/**
 *
 * Advertiser's response for influencer's proposal for campaign
 */
/**
* @package
*/
export const postSharesCampaignManageProposal_feedbackProposal_id = (
  /**
   *
   * The influencer's proposal id
   */
  proposal_id: number,
  requestBody: RequestBodyTaskProposalFeedbackData,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postSharesCampaignManageProposal_feedbackProposal_id.key, {
      proposal_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSharesCampaignManageProposal_feedbackProposal_id.key =
  "/shares/campaign/manage/proposal_feedback/{proposal_id}";

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const postSharesCampaignNotesItem_typeNotesItem_id = (
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  requestBody: RequestBodyNoteToAdd,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    note_id?: number;
  }>
> => {
  return Http.postRequest(
    template(postSharesCampaignNotesItem_typeNotesItem_id.key, {
      item_type,
      item_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSharesCampaignNotesItem_typeNotesItem_id.key =
  "/shares/campaign/notes/{item_type}/notes/{item_id}";

/**
 *
 * proposal history - route for advertiser
 */
/**
* @package
*/
export const postSharesCampaignProposals_historyInfluencer_id = (
  /**
   *
   * The influencer id you want the history for
   */
  influencer_id: number,
  queryParams?: PostSharesCampaignProposals_historyInfluencer_idQueryParams,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<
    {
      current_proposal?: ProposalData;
      /**
       *
       * - Format: date
       */
      month_relevance?: string;
      old_proposals?: ProposalData[];
      post_type?: PostTypes;
      /**
       *
       * - Format: int64
       */
      subtask_of?: number;
      /**
       *
       * - Format: int64
       */
      task_id?: number;
    }[]
  >
> => {
  return Http.postRequest(
    template(postSharesCampaignProposals_historyInfluencer_id.key, {
      influencer_id,
    }),
    queryParams,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSharesCampaignProposals_historyInfluencer_id.key =
  "/shares/campaign/proposals_history/{influencer_id}";

/**
 *
 * get shared roi - to view roi of a campaign (board)
 */
/**
* @package
*/
export const postSharesCampaignRoi = (
  requestBody: RequestBodyCampaignReportFilterParameters,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManageCampaignRoiWithParameters>> => {
  return Http.postRequest(
    postSharesCampaignRoi.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSharesCampaignRoi.key = "/shares/campaign/roi";

/**
 *
 * finish working on list - notify the agency/user who shared this link
 */
/**
* @package
*/
export const postSharesLists = (
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postSharesLists.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSharesLists.key = "/shares/lists";

/**
 *
 * get specific influencer data
 */
/**
* @package
*/
export const postSharesListsGet_influencer_dataInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: string,
  requestBody: RequestBodyExtraDataFilter,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    influencer_data?: InfluencerExtendedData;
    /**
     *
     * PostIdsBySocialMedia
     *
     */
    posts?: {
      instagram?: string[];
      tiktok?: string[];
      twitter?: string[];
      youtube?: string[];
    };
  }>
> => {
  return Http.postRequest(
    template(postSharesListsGet_influencer_dataInfluencer_id.key, {
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSharesListsGet_influencer_dataInfluencer_id.key =
  "/shares/lists/get_influencer_data/{influencer_id}";

/**
 *
 * get shared notes for share
 */
/**
* @package
*/
export const postSharesListsNotesItem_typeNotesItem_id = (
  /**
   *
   * The item type
   */
  item_type: string,
  /**
   *
   * The item id
   */
  item_id: number,
  requestBody: RequestBodyNoteToAdd,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: int64
     */
    note_id?: number;
  }>
> => {
  return Http.postRequest(
    template(postSharesListsNotesItem_typeNotesItem_id.key, {
      item_type,
      item_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSharesListsNotesItem_typeNotesItem_id.key =
  "/shares/lists/notes/{item_type}/notes/{item_id}";

/**
 *
 * query influencers
 */
/**
* @package
*/
export const postSharesListsSearch = (
  requestBody: RequestBodyInfluencerSearchParameters,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<number[]>> => {
  return Http.postRequest(
    postSharesListsSearch.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSharesListsSearch.key = "/shares/lists/search";

/**
 *
 * get shared roi - to view roi of a campaign (board)
 */
/**
* @package
*/
export const postSharesRoi = (
  requestBody: RequestBodyCampaignReportFilterParameters,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManageCampaignRoiWithParameters>> => {
  return Http.postRequest(
    postSharesRoi.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSharesRoi.key = "/shares/roi";

/**
 *
 * Returns ROI data for specific auction
 */
/**
* @package
*/
export const postSharesRoiProducts_sales_roi = (
  requestBody: RequestBodyFiltersAndScrollProducts,
  queryParams?: PostSharesRoiProducts_sales_roiQueryParams,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{ have_more?: boolean; items?: PurchaseClicksType[] }>
> => {
  return Http.postRequest(
    postSharesRoiProducts_sales_roi.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSharesRoiProducts_sales_roi.key = "/shares/roi/products_sales_roi";

/**
 *
 * Generate path to upload direct to storage
 */
/**
* @package
*/
export const postShopifyGenerate_upload_path = (
  requestBody: RequestBodyFileToUploadDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postShopifyGenerate_upload_path.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postShopifyGenerate_upload_path.key = "/shopify/generate_upload_path";

/**
 *
 * Returns ROI data for specific wix user influencers
 */
/**
* @package
*/
export const postShopifyRoi = (
  requestBody: RequestBodyCampaignReportFilterParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManagedCampaignROI>> => {
  return Http.postRequest(
    postShopifyRoi.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postShopifyRoi.key = "/shopify/roi";

/**
 *
 * query influencers
 */
/**
* @package
*/
export const postShopifySearch_influencers = (
  requestBody: RequestBodyInfluencerSearchParameters,
  queryParams?: PostShopifySearch_influencersQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerShallowData[]>> => {
  return Http.postRequest(
    postShopifySearch_influencers.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postShopifySearch_influencers.key = "/shopify/search_influencers";

/**
 *
 * check link availability, will not reserve this short link until created or deleted
 */
/**
* @package
*/
export const postShopifySettingsLink = (
  requestBody: RequestBodyLinkAvailableCheck,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ available?: boolean }>> => {
  return Http.postRequest(
    postShopifySettingsLink.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postShopifySettingsLink.key = "/shopify/settings/link";

/**
 *
 * update influencer short link
 */
/**
* @package
*/
export const postShopifySettingsLinkInfluencer_id = (
  /**
   *
   * The influencer_id
   */
  influencer_id: number,
  requestBody: RequestBodyInfluencerShortLinkUpdateData2,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postShopifySettingsLinkInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postShopifySettingsLinkInfluencer_id.key =
  "/shopify/settings/link/{influencer_id}";

/**
 *
 * wix user support request
 */
/**
* @package
*/
export const postShopifyUser_support = (
  requestBody: RequestBodySupportRequestDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postShopifyUser_support.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postShopifyUser_support.key = "/shopify/user_support";

/**
 *
 * Register using Humanz internal details
 */
/**
* @package
*/
export const postSignup = (
  requestBody: {
    /**
     *
     * - minLength: 1
     * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
     */
    email: string;
    /**
     *
     * - Format: password
     * - minLength: 1
     */
    password: string;
    agent_details?: EditAgentDetails;
    captcha?: string;
    /**
     *
     * - Format: uuid
     */
    invite_uuid?: string;
    is_influencer?: boolean;
    user_type?: UserType;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * - Format: uuid
     */
    challenge_id?: string;
    disposable_token?: string;
    picture_url?: string;
    provider?:
      | "local"
      | "email"
      | "instagram"
      | "google"
      | "facebook"
      | "twitter"
      | "youtube"
      | "apple";
    /**
     *
     * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
     */
    user_email?: string;
    /**
     *
     * - Format: int64
     */
    user_id?: number;
    user_kind?: UserType;
    user_name?: string;
  }>
> => {
  return Http.postRequest(
    postSignup.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSignup.key = "/signup";

/**
 * 
 * Register using MOI's internal details

 */
/**
* @package
*/
export const postSignupFrom_influencer_challenge = (
  requestBody: InfluencerDetailsRegsitration,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerDetails>> => {
  return Http.postRequest(
    postSignupFrom_influencer_challenge.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSignupFrom_influencer_challenge.key = "/signup/from_influencer_challenge";

/**
 * 
 * Login using third-party oauth provider

 */
/**
* @package
*/
export const postSocialLoginAdvertiser_login_with_social = (
  requestBody: {
    /**
     *
     * - Format: password
     */
    access_token?: string;
    challenge_token?: string;
    profile_id?: string;
    provider?: SocialLoginProvider;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    first_time?: boolean;
    returning_user?: AllAdvertiserDetails;
    social_login_data?: SocialLoginDataExtracted;
    waiting_enterprise_invite?: string;
  }>
> => {
  return Http.postRequest(
    postSocialLoginAdvertiser_login_with_social.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSocialLoginAdvertiser_login_with_social.key =
  "/socialLogin/advertiser_login_with_social";

/**
 *
 * Social api linkage on global agent invite
 */
/**
* @package
*/
export const postSocialLoginAgentInvite_influencerAgent_uuid = (
  /**
   *
   * agent uuid
   */
  agent_uuid: string,
  requestBody: RequestBodySocialRegisteredLinkData,
  queryParams: PostSocialLoginAgentInvite_influencerAgent_uuidQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ username?: string }>> => {
  return Http.postRequest(
    template(postSocialLoginAgentInvite_influencerAgent_uuid.key, {
      agent_uuid,
    }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSocialLoginAgentInvite_influencerAgent_uuid.key =
  "/socialLogin/agent/invite_influencer/{agent_uuid}";

/**
 *
 * Login using third-party oauth provider
 */
/**
* @package
*/
export const postSocialLoginAgent_login_with_social = (
  requestBody: {
    /**
     *
     * - Format: password
     */
    access_token?: string;
    challenge_token?: string;
    /**
     *
     * - Format: uuid
     */
    invite_uuid?: string;
    profile_id?: string;
    provider?: SocialLoginProvider;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    returning_user?: AllAgentDetails;
    social_login_data?: SocialLoginDataExtracted;
    waiting_enterprise_invite?: string;
  }>
> => {
  return Http.postRequest(
    postSocialLoginAgent_login_with_social.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSocialLoginAgent_login_with_social.key =
  "/socialLogin/agent_login_with_social";

/**
 * 
 * Login using third-party oauth provider

 */
/**
* @package
*/
export const postSocialLoginAttach_social = (
  requestBody: RequestBodySocialRegisteredLinkData,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * AlreadyBelongToUserDetails
     *
     */
    belong_to_user?: {
      provider?: "google" | "apple" | "facebook" | "phone" | "local";
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    };
    link_suggestions?: {
      /**
       *
       * - Format: int64
       */
      followers?: number;
      image_url?: string;
      name?: string;
      profile_id?: string;
      social_network?: string;
      username?: string;
    }[];
    returning_user?: InfluencerDetails;
    social_link_attached?: MinimalSocialData;
  }>
> => {
  return Http.postRequest(
    postSocialLoginAttach_social.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSocialLoginAttach_social.key = "/socialLogin/attach_social";

/**
 * 
 * Login using third-party oauth provider

 */
/**
* @package
*/
export const postSocialLoginAttach_social_unregistered = (
  requestBody: {
    /**
     *
     * - Format: password
     */
    access_token?: string;
    /**
     *
     * - Format: password
     */
    access_token_secret?: string;
    challenge_token?: string;
    profile_id?: string;
    provider?: "youtube" | "instagram" | "twitter" | "tiktok";
    social_challenge_id?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    /**
     *
     * AlreadyBelongToUserDetails
     *
     */
    belong_to_user?: {
      provider?: "google" | "apple" | "facebook" | "phone" | "local";
      /**
       *
       * - Format: int64
       */
      user_id?: number;
    };
    link_suggestions?: {
      /**
       *
       * - Format: int64
       */
      followers?: number;
      image_url?: string;
      name?: string;
      profile_id?: string;
      social_network?: string;
      username?: string;
    }[];
    returning_user?: InfluencerDetails;
    social_link_attached?: MinimalSocialData;
  }>
> => {
  return Http.postRequest(
    postSocialLoginAttach_social_unregistered.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSocialLoginAttach_social_unregistered.key =
  "/socialLogin/attach_social_unregistered";

/**
 * 
 * Login using third-party oauth provider

 */
/**
* @package
*/
export const postSocialLoginExternal_attach_social = (
  requestBody: {
    api_key?: string;
    /**
     *
     * - Format: int64
     */
    influencer_id?: number;
    is_registered?: boolean;
    profile_id?: string;
    provider?: "youtube" | "instagram" | "twitter" | "tiktok";
    social_challenge_id?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postSocialLoginExternal_attach_social.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSocialLoginExternal_attach_social.key =
  "/socialLogin/external_attach_social";

/**
 *
 * External Register business discovert
 */
/**
* @package
*/
export const postSocialLoginExternal_registerBusiness_discovery = (
  requestBody: RequestBodyRegisterExternalInfluencerBusinessDiscoveryUsername,
  queryParams?: PostSocialLoginExternal_registerBusiness_discoveryQueryParams,
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ id?: string }>> => {
  return Http.postRequest(
    postSocialLoginExternal_registerBusiness_discovery.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSocialLoginExternal_registerBusiness_discovery.key =
  "/socialLogin/external_register/business_discovery";

/**
 *
 * Connect external influencer
 */
/**
* @package
*/
export const postSocialLoginExternal_registerLink_api = (
  requestBody: RequestBodySocialRegisteredLinkData,
  queryParams: PostSocialLoginExternal_registerLink_apiQueryParams,
  headerParams?: {
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ username?: string }>> => {
  return Http.postRequest(
    postSocialLoginExternal_registerLink_api.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postSocialLoginExternal_registerLink_api.key =
  "/socialLogin/external_register/link_api";

/**
 *
 * get the required permissions for facebook_instagram link
 */
/**
* @package
*/
export const postSocialLoginFacebook_instagramBusiness_discovery = (
  requestBody: RequestBodyRegisterExternalInfluencerBusinessDiscoveryUsername,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ id?: string }>> => {
  return Http.postRequest(
    postSocialLoginFacebook_instagramBusiness_discovery.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSocialLoginFacebook_instagramBusiness_discovery.key =
  "/socialLogin/facebook_instagram/business_discovery";

/**
 * 
 * Login using third-party oauth provider

 */
/**
* @package
*/
export const postSocialLoginUnregistered_login_with_social = (
  requestBody: {
    /**
     *
     * - Format: password
     */
    access_token?: string;
    challenge_token?: string;
    phone_id?: string;
    profile_id?: string;
    provider?: SocialLoginProvider;
  },
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    challenge_id?: string;
    returning_user?: InfluencerDetails;
    social_login_data?: SocialLoginDataExtracted;
  }>
> => {
  return Http.postRequest(
    postSocialLoginUnregistered_login_with_social.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postSocialLoginUnregistered_login_with_social.key =
  "/socialLogin/unregistered_login_with_social";

/**
 * 
 * save device info

 */
/**
* @package
*/
export const postUtilsDevice_info = (
  requestBody: DeviceInfo,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postUtilsDevice_info.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postUtilsDevice_info.key = "/utils/device_info";

/**
 *
 * send notification about targets achievment
 */
/**
* @package
*/
export const postUtilsSend_targets_notification = (
  requestBody: TargetNotification[],
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postUtilsSend_targets_notification.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postUtilsSend_targets_notification.key = "/utils/send_targets_notification";

/**
 *
 * response to task offer external (BO / Tools)
 */
/**
* @package
*/
export const postUtilsTask_offersTask_offer_id = (
  /**
   *
   * The id of the offer
   */
  task_offer_id: number,
  requestBody: RequestBodyResponseToTaskOfferResponse,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "x-api-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postUtilsTask_offersTask_offer_id.key, { task_offer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
postUtilsTask_offersTask_offer_id.key = "/utils/task_offers/{task_offer_id}";

/**
 * 
 * new log from fe

 */
/**
* @package
*/
export const postUtilsWrite_log = (
  requestBody: {
    app_window?: string;
    event_name?: string;
    /**
     *
     * - Format: double
     */
    event_value_number?: number;
    event_value_str?: string;
    /**
     *
     * - Format: int64
     */
    feed_post_id?: number;
    log_level?: "info" | "error";
    message?: string;
    method_name?: string;
    phone_id?: string;
    phone_type?: "ios" | "android";
    user_country?: string;
    /**
     *
     * - Format: int64
     */
    user_id?: number;
    version?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postUtilsWrite_log.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postUtilsWrite_log.key = "/utils/write_log";

/**
 *
 * Generate path to upload direct to storage
 */
/**
* @package
*/
export const postWixGenerate_upload_path = (
  requestBody: RequestBodyFileToUploadDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postWixGenerate_upload_path.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postWixGenerate_upload_path.key = "/wix/generate_upload_path";

/**
 *
 * Returns ROI data for specific wix user influencers
 */
/**
* @package
*/
export const postWixRoi = (
  requestBody: RequestBodyCampaignReportFilterParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ManagedCampaignROI>> => {
  return Http.postRequest(
    postWixRoi.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postWixRoi.key = "/wix/roi";

/**
 *
 * query influencers
 */
/**
* @package
*/
export const postWixSearch_influencers = (
  requestBody: RequestBodyInfluencerSearchParameters,
  queryParams?: PostWixSearch_influencersQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InfluencerShallowData[]>> => {
  return Http.postRequest(
    postWixSearch_influencers.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postWixSearch_influencers.key = "/wix/search_influencers";

/**
 *
 * check link availability, will not reserve this short link until created or deleted
 */
/**
* @package
*/
export const postWixSettingsLink = (
  requestBody: RequestBodyLinkAvailableCheck,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ available?: boolean }>> => {
  return Http.postRequest(
    postWixSettingsLink.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postWixSettingsLink.key = "/wix/settings/link";

/**
 *
 * update influencer short link
 */
/**
* @package
*/
export const postWixSettingsLinkInfluencer_id = (
  /**
   *
   * The influencer_id
   */
  influencer_id: number,
  requestBody: RequestBodyInfluencerShortLinkUpdateData2,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    template(postWixSettingsLinkInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postWixSettingsLinkInfluencer_id.key = "/wix/settings/link/{influencer_id}";

/**
 *
 * wix user support request
 */
/**
* @package
*/
export const postWixUser_support = (
  requestBody: RequestBodySupportRequestDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.postRequest(
    postWixUser_support.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
postWixUser_support.key = "/wix/user_support";

/**
 * 
 * Mark lesson as completed

 */
/**
* @package
*/
export const putAcademyChaptersChapter_idLessonLesson_ordinal_num = (
  /**
   *
   * the current chapter id
   */
  chapter_id: number,
  /**
   *
   * the lesson ordinal number
   */
  lesson_ordinal_num: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putAcademyChaptersChapter_idLessonLesson_ordinal_num.key, {
      chapter_id,
      lesson_ordinal_num,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putAcademyChaptersChapter_idLessonLesson_ordinal_num.key =
  "/academy/chapters/{chapter_id}/lesson/{lesson_ordinal_num}";

/**
 *
 * Toggle the active state of an invoice
 */
/**
* @package
*/
export const putAgentPaymentsExternal_page = (
  headerParams?: {
    /**
     *
     * - Format: uuid
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putAgentPaymentsExternal_page.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
putAgentPaymentsExternal_page.key = "/agent/payments/external_page";

/**
 * 
 * edit influencer filter paarmeters for collaborate

 */
/**
* @package
*/
export const putCollaborateFilter_parameters = (
  requestBody: InfluencerCollaborateFilterParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putCollaborateFilter_parameters.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putCollaborateFilter_parameters.key = "/collaborate/filter_parameters";

/**
 * 
 * messages to resend

 */
/**
* @package
*/
export const putCollaborateInboxMatch_idResend_messages = (
  /**
   *
   * The match id you want to chat with
   */
  match_id: number,
  requestBody: {
    message?: string;
    message_content_type?: string;
    /**
     *
     * - Format: uuid
     */
    message_guid?: string;
    /**
     *
     * - Format: int64
     */
    send_to?: number;
  }[],
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putCollaborateInboxMatch_idResend_messages.key, { match_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putCollaborateInboxMatch_idResend_messages.key =
  "/collaborate/inbox/{match_id}/resend_messages";

/**
 *
 * remove yourself from collaborate - will remove all your matches and you won't appear on swipes
 */
/**
* @package
*/
export const putCollaborateOptout = (
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putCollaborateOptout.key,
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putCollaborateOptout.key = "/collaborate/optout";

/**
 * 
 * swipe influencer

 */
/**
* @package
*/
export const putCollaborateSwipe_influencerInfluencer_id = (
  /**
   *
   * The influencer id to swipe
   */
  influencer_id: number,
  requestBody: { accept?: boolean },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ limits?: SwipeLimits; match?: boolean }>> => {
  return Http.putRequest(
    template(putCollaborateSwipe_influencerInfluencer_id.key, {
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT2, configOverride),
  );
};

/** Key is end point string without base url */
putCollaborateSwipe_influencerInfluencer_id.key =
  "/collaborate/swipe_influencer/{influencer_id}";

/**
 *
 * add new advertiser query
 */
/**
* @package
*/
export const putMoiAdvertiser_saved_queries = (
  requestBody: { data?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<number>> => {
  return Http.putRequest(
    putMoiAdvertiser_saved_queries.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT2, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAdvertiser_saved_queries.key = "/moi/advertiser_saved_queries";

/**
 *
 * update influencer agent
 */
/**
* @package
*/
export const putMoiAgentAgent_id = (
  /**
   *
   * The agent id to update
   */
  agent_id: number,
  requestBody: RequestBodyNewAgentInfo,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAgentAgent_id.key, { agent_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAgentAgent_id.key = "/moi/agent/{agent_id}";

/**
 *
 * add a bank account for agent
 */
/**
* @package
*/
export const putMoiAgentAgent_idBank = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  requestBody: RequestBodyBankAccountDetails,
  queryParams?: PutMoiAgentAgent_idBankQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAgentAgent_idBank.key, { agent_id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAgentAgent_idBank.key = "/moi/agent/{agent_id}/bank";

/**
 *
 * add a bank turkish account for agent
 */
/**
* @package
*/
export const putMoiAgentAgent_idBankTurkey = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  requestBody: RequestBodyTurkishBankAccount,
  queryParams?: PutMoiAgentAgent_idBankTurkeyQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAgentAgent_idBankTurkey.key, { agent_id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAgentAgent_idBankTurkey.key = "/moi/agent/{agent_id}/bank/turkey";

/**
 *
 * add a uk bank account for agent
 */
/**
* @package
*/
export const putMoiAgentAgent_idBankUk = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  requestBody: RequestBodyUKBankAccount,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAgentAgent_idBankUk.key, { agent_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAgentAgent_idBankUk.key = "/moi/agent/{agent_id}/bank/uk";

/**
 *
 * add a us bank account for agent
 */
/**
* @package
*/
export const putMoiAgentAgent_idBankUs = (
  /**
   *
   * The agent id to query
   */
  agent_id: number,
  requestBody: { address?: BillingAddress; ein?: string },
  queryParams?: PutMoiAgentAgent_idBankUsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ExistingUSAgentBankAccount>> => {
  return Http.putRequest(
    template(putMoiAgentAgent_idBankUs.key, { agent_id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAgentAgent_idBankUs.key = "/moi/agent/{agent_id}/bank/us";

/**
 *
 * edit custom link
 */
/**
* @package
*/
export const putMoiAuctionManageAuction_idCustom_link = (
  /**
   *
   * The auction id
   */
  auction_id: number,
  requestBody: RequestBodyInfluencerCustomLink,
  queryParams?: PutMoiAuctionManageAuction_idCustom_linkQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionManageAuction_idCustom_link.key, { auction_id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionManageAuction_idCustom_link.key =
  "/moi/auction/manage/{auction_id}/custom_link/";

/**
 *
 * change influencer tags on managed campaign
 */
/**
* @package
*/
export const putMoiAuctionManageAuction_idInfluencer_idTags = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencers
   */
  influencer_id: number,
  requestBody: { tags?: string[] },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionManageAuction_idInfluencer_idTags.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionManageAuction_idInfluencer_idTags.key =
  "/moi/auction/manage/{auction_id}/{influencer_id}/tags";

/**
 *
 * put influencer into trash
 */
/**
* @package
*/
export const putMoiAuctionManageAuction_idInfluencer_idTrash = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencers
   */
  influencer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionManageAuction_idInfluencer_idTrash.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionManageAuction_idInfluencer_idTrash.key =
  "/moi/auction/manage/{auction_id}/{influencer_id}/trash";

/**
 *
 * remove influencer from trash
 */
/**
* @package
*/
export const putMoiAuctionManageAuction_idInfluencer_idUntrash = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencers
   */
  influencer_id: number,
  requestBody: InfluencerInvite,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionManageAuction_idInfluencer_idUntrash.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionManageAuction_idInfluencer_idUntrash.key =
  "/moi/auction/manage/{auction_id}/{influencer_id}/untrash";

/**
 *
 * invite influencers to your campaign
 */
/**
* @package
*/
export const putMoiAuctionManageAuction_idInvite = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  requestBody: InfluencerInvite[],
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionManageAuction_idInvite.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionManageAuction_idInvite.key =
  "/moi/auction/manage/{auction_id}/invite";

/**
 *
 * add agent to influencer on campaign
 */
/**
* @package
*/
export const putMoiAuctionManageAuction_idManage_influencerInfluencer_idAdd_agentAgent_id =
  (
    /**
     *
     * The id of the board
     */
    auction_id: number,
    /**
     *
     * The id of the influencer
     */
    influencer_id: number,
    /**
     *
     * The id of the agent
     */
    agent_id: number,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<InfluencerInManageAuctionData>> => {
    return Http.putRequest(
      template(
        putMoiAuctionManageAuction_idManage_influencerInfluencer_idAdd_agentAgent_id.key,
        { auction_id, influencer_id, agent_id },
      ),
      undefined,
      undefined,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
putMoiAuctionManageAuction_idManage_influencerInfluencer_idAdd_agentAgent_id.key =
  "/moi/auction/manage/{auction_id}/manage_influencer/{influencer_id}/add_agent/{agent_id}";

/**
 *
 * create or edit influencer short link
 */
/**
* @package
*/
export const putMoiAuctionManageAuction_idShort_linkInfluencer_id = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencer
   */
  influencer_id: number,
  requestBody: {
    description?: string;
    /**
     *
     * - Format: int64
     */
    extra_id?: number;
    link_type?: "campaign";
    long_link?: string;
    short_link?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionManageAuction_idShort_linkInfluencer_id.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionManageAuction_idShort_linkInfluencer_id.key =
  "/moi/auction/manage/{auction_id}/short_link/{influencer_id}";

/**
 *
 * change board tag
 */
/**
* @package
*/
export const putMoiAuctionManageAuction_idTagTag_id = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the tag
   */
  tag_id: number,
  requestBody: RequestBodyCampaignTag,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionManageAuction_idTagTag_id.key, {
      auction_id,
      tag_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionManageAuction_idTagTag_id.key =
  "/moi/auction/manage/{auction_id}/tag/{tag_id}";

/**
* @package
*/
export const putMoiAuctionManageBoard_idInfluencer_idChange_note = (
  /**
   *
   * The id of the board
   */
  board_id: number,
  /**
   *
   * The id of the influencer
   */
  influencer_id: number,
  requestBody: { new_note?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionManageBoard_idInfluencer_idChange_note.key, {
      board_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionManageBoard_idInfluencer_idChange_note.key =
  "/moi/auction/manage/{board_id}/{influencer_id}/change_note";

/**
 *
 * advertiser response for influencer negotiation
 */
/**
* @package
*/
export const putMoiAuctionManageBoard_idInvite_influencersInfluencer_idAdvertiser_response =
  (
    /**
     *
     * The id of the board
     */
    board_id: number,
    /**
     *
     * The id of the influencer
     */
    influencer_id: number,
    requestBody: RequestBodyInviteResponse2,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putMoiAuctionManageBoard_idInvite_influencersInfluencer_idAdvertiser_response.key,
        { board_id, influencer_id },
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
putMoiAuctionManageBoard_idInvite_influencersInfluencer_idAdvertiser_response.key =
  "/moi/auction/manage/{board_id}/invite_influencers/{influencer_id}/advertiser_response";

/**
 *
 * influencer response for invite
 */
/**
* @package
*/
export const putMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response =
  (
    /**
     *
     * The id of the board
     */
    board_id: number,
    /**
     *
     * The id of the influencer
     */
    influencer_id: number,
    requestBody: RequestBodyInviteResponse,
    queryParams?: PutMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_responseQueryParams,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response.key,
        { board_id, influencer_id },
      ),
      queryParams,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
putMoiAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response.key =
  "/moi/auction/manage/{board_id}/invite_influencers/{influencer_id}/influencer_response";

/**
 *
 * advertiser response for influencer negotiation
 */
/**
* @package
*/
export const putMoiAuctionManageInfluencer_idInvite_taskTask_idAdvertiser_response =
  (
    /**
     *
     * The id of the board
     */
    influencer_id: number,
    /**
     *
     * The id of the influencer
     */
    task_id: number,
    requestBody: RequestBodyInviteResponse2,
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<InfluencerInManageAuctionData>> => {
    return Http.putRequest(
      template(
        putMoiAuctionManageInfluencer_idInvite_taskTask_idAdvertiser_response.key,
        { influencer_id, task_id },
      ),
      undefined,
      requestBody,
      undefined,
      overrideConfig(_CONSTANT0, configOverride),
    );
  };

/** Key is end point string without base url */
putMoiAuctionManageInfluencer_idInvite_taskTask_idAdvertiser_response.key =
  "/moi/auction/manage/{influencer_id}/invite_task/{task_id}/advertiser_response";

/**
 *
 * influencer response for invite
 */
/**
* @package
*/
export const putMoiAuctionManageInvite_taskTask_idInfluencer_response = (
  /**
   *
   * The id of the task
   */
  task_id: number,
  requestBody: RequestBodyInviteResponse,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionManageInvite_taskTask_idInfluencer_response.key, {
      task_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionManageInvite_taskTask_idInfluencer_response.key =
  "/moi/auction/manage/invite_task/{task_id}/influencer_response";

/**
 *
 * Create new bid for auction
 */
/**
* @package
*/
export const putMoiAuctionsAuction_idBidsEdit_bid = (
  /**
   *
   * The auction id to create bid for
   */
  auction_id: number,
  requestBody: RequestBodyNewBidForAuctionData,
  queryParams?: PutMoiAuctionsAuction_idBidsEdit_bidQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionsAuction_idBidsEdit_bid.key, { auction_id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionsAuction_idBidsEdit_bid.key =
  "/moi/auctions/{auction_id}/bids/edit_bid";

/**
 *
 * Add auction instruction to existing auction
 */
/**
* @package
*/
export const putMoiAuctionsAuction_idInstructions = (
  /**
   *
   * the auction id to add instructions to
   */
  auction_id: number,
  requestBody: DosAndDonts,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionsAuction_idInstructions.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionsAuction_idInstructions.key =
  "/moi/auctions/{auction_id}/instructions";

/**
 * 
 * update mini users

 */
/**
* @package
*/
export const putMoiAuctionsAuction_idMini_users = (
  auction_id: number,
  requestBody: RequestBodyMiniUsersIds,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionsAuction_idMini_users.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionsAuction_idMini_users.key =
  "/moi/auctions/{auction_id}/mini_users";

/**
 *
 * edit auction phase
 */
/**
* @package
*/
export const putMoiAuctionsAuction_idPixel_phasesPhase_id = (
  /**
   *
   * the auction id to get the phases for
   */
  auction_id: number,
  /**
   *
   * the phase id get edit
   */
  phase_id: number,
  requestBody: RequestBodyAuctionPhase,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionsAuction_idPixel_phasesPhase_id.key, {
      auction_id,
      phase_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionsAuction_idPixel_phasesPhase_id.key =
  "/moi/auctions/{auction_id}/pixel_phases/{phase_id}";

/**
 *
 * Edit Auction question answers for influencer
 */
/**
* @package
*/
export const putMoiAuctionsAuction_idQuestions_answers = (
  /**
   *
   * the auction id to edit questions to
   */
  auction_id: number,
  requestBody: QuestionAnswer[],
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionsAuction_idQuestions_answers.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionsAuction_idQuestions_answers.key =
  "/moi/auctions/{auction_id}/questions_answers";

/**
 *
 * Edit an existing auction
 */
/**
* @package
*/
export const putMoiAuctionsId = (
  /**
   *
   * The auction id to revert
   */
  id: number,
  requestBody: {
    acquiring_product_instructions?: string;
    /**
     *
     * editAuctionAttachedBrief
     *
     */
    attached_brief?: {
      /**
       *
       * - Format: uuid
       */
      replace_with?: string;
    };
    auction_link?: AuctionLink;
    auction_type?: AuctionTypes;
    /**
     *
     * - Format: date-time
     */
    creation_time?: string;
    description?: string;
    /**
     *
     * - Format: date-time
     */
    expiration_time?: string;
    followers?: FollowersData;
    images?: {
      /**
       *
       * - Format: uuid
       */
      id?: string;
      /**
       *
       * - Format: int32
       */
      index?: number;
    }[];
    order_type?: string;
    /**
     *
     * PriceData
     *
     */
    price?: {
      /**
       *
       * - Format: double
       */
      max?: number;
      /**
       *
       * - Format: double
       */
      min?: number;
    };
    product_description?: string;
    product_included?: boolean;
    /**
     *
     * - Format: double
     */
    product_value?: number;
    should_arrive_to_business?: boolean;
    tags?: string[];
    title?: string;
    /**
     *
     * - Format: int64
     */
    user_id?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionsId.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionsId.key = "/moi/auctions/{id}";

/**
 *
 * Influencer want to add task in campaign
 */
/**
* @package
*/
export const putMoiAuctionsIdAdd_new_task_as_an_influencer = (
  /**
   *
   * The auction id to create for
   */
  id: number,
  requestBody: { post_type?: PostTypes },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionsIdAdd_new_task_as_an_influencer.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionsIdAdd_new_task_as_an_influencer.key =
  "/moi/auctions/{id}/add_new_task_as_an_influencer";

/**
 *
 * Create new bid for auction as Agent for unregistered influencers
 */
/**
* @package
*/
export const putMoiAuctionsIdBid_invite_as_agent = (
  /**
   *
   * The auction id to create bid for
   */
  id: number,
  requestBody: RequestBodyNewBidForAuctionData,
  queryParams?: PutMoiAuctionsIdBid_invite_as_agentQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionsIdBid_invite_as_agent.key, { id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionsIdBid_invite_as_agent.key =
  "/moi/auctions/{id}/bid_invite_as_agent";

/**
 *
 * Create new bid for auction
 */
/**
* @package
*/
export const putMoiAuctionsIdBids = (
  /**
   *
   * The auction id to create bid for
   */
  id: number,
  requestBody: RequestBodyNewBidForAuctionData,
  queryParams?: PutMoiAuctionsIdBidsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionsIdBids.key, { id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionsIdBids.key = "/moi/auctions/{id}/bids";

/**
 *
 * Influencer want to participate in campaign
 */
/**
* @package
*/
export const putMoiAuctionsIdWant_to_participate = (
  /**
   *
   * The auction id to create bid for
   */
  id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiAuctionsIdWant_to_participate.key, { id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiAuctionsIdWant_to_participate.key =
  "/moi/auctions/{id}/want_to_participate";

/**
 * 
 * hide bid

 */
/**
* @package
*/
export const putMoiBidsBid_idHide = (
  /**
   *
   * The bid id you want to hide
   */
  bid_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiBidsBid_idHide.key, { bid_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiBidsBid_idHide.key = "/moi/bids/{bid_id}/hide";

/**
 *
 * feed approver pin community post
 */
/**
* @package
*/
export const putMoiCommunity_feedPin_postPost_id = (
  /**
   *
   * post id
   */
  post_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiCommunity_feedPin_postPost_id.key, { post_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiCommunity_feedPin_postPost_id.key =
  "/moi/community_feed/pin_post/{post_id}";

/**
 *
 * edit feed Post
 */
/**
* @package
*/
export const putMoiCommunity_feedPost_id = (
  /**
   *
   * post id
   */
  post_id: number,
  requestBody: RequestBodyCreateCommunityFeedItem,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiCommunity_feedPost_id.key, { post_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiCommunity_feedPost_id.key = "/moi/community_feed/{post_id}";

/**
 *
 * add view to view in feed
 */
/**
* @package
*/
export const putMoiCommunity_feedPost_idVideo = (
  /**
   *
   * post id
   */
  post_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiCommunity_feedPost_idVideo.key, { post_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiCommunity_feedPost_idVideo.key = "/moi/community_feed/{post_id}/video";

/**
 *
 * toggle read status of a suggestion
 */
/**
* @package
*/
export const putMoiCommunity_feedSuggestionSuggestion_id = (
  /**
   *
   * toggles is_read flag (true<->false)
   */
  suggestion_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<{ is_read_updated_state?: boolean }>> => {
  return Http.putRequest(
    template(putMoiCommunity_feedSuggestionSuggestion_id.key, {
      suggestion_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiCommunity_feedSuggestionSuggestion_id.key =
  "/moi/community_feed/suggestion/{suggestion_id}";

/**
 *
 * Edit brand by brand ID
 */
/**
* @package
*/
export const putMoiDetailsBrandBrand_id = (
  /**
   *
   * The ID of the brand to edit
   */
  brand_id: number,
  requestBody: EditBrandInfo,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiDetailsBrandBrand_id.key, { brand_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiDetailsBrandBrand_id.key = "/moi/details/brand/{brand_id}";

/**
 *
 * edit influencer details for current logged in advertiser
 */
/**
* @package
*/
export const putMoiEdit_detailsAdvertiser = (
  requestBody: {
    about_us?: string;
    advertiser_type?: "brand" | "agency";
    categories?: string[];
    company_address?: string;
    company_country?: string;
    company_name?: string;
    contact_details?: string;
    currency?: Currency;
    /**
     *
     * - pattern: ^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$
     */
    email?: string;
    /**
     *
     * - pattern: ^(?:https?:\/\/)?(?:www\.|m\.|touch\.)?(?:facebook\.com|fb(?:\.me|\.com))\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*?(\/)?([^/?\s]*)(?:/|&|\?)?.*$
     */
    facebook_page?: string;
    full_name?: string;
    image?: UploadedImage;
    image_changed?: boolean;
    /**
     *
     * - pattern: ^(?:(?:http|https)://)?(?:www.)?(?:instagram.com|instagr.am)/([A-Za-z0-9-_]+)(/?)?(.*)
     */
    instagram_page?: string;
    invoice_email?: string;
    new_password?: string;
    old_password?: string;
    original_image_url?: string;
    phone?: string;
    show_bids_notifications?: boolean;
    /**
     *
     * - pattern: ^(?:(?:http|https)://)?(?:www.)?(?:twitter.com)/([A-Za-z0-9-_]+)(/?)?(.*)
     */
    twitter_page?: string;
    youtube_page?: string;
  },
  queryParams?: PutMoiEdit_detailsAdvertiserQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    can_open_auctions?: boolean;
    finished_basic_details?: boolean;
    new_image_url?: string;
  }>
> => {
  return Http.putRequest(
    putMoiEdit_detailsAdvertiser.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsAdvertiser.key = "/moi/edit_details/advertiser";

/**
 *
 * Edit agent details for current logged in agent
 */
/**
* @package
*/
export const putMoiEdit_detailsAgent = (
  requestBody: EditAgentDetails,
  queryParams?: PutMoiEdit_detailsAgentQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    finished_basic_details?: boolean;
    humanz_verified_agent?: boolean;
  }>
> => {
  return Http.putRequest(
    putMoiEdit_detailsAgent.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsAgent.key = "/moi/edit_details/agent";

/**
 *
 * edit user country
 */
/**
* @package
*/
export const putMoiEdit_detailsCountry = (
  requestBody: { country?: string },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiEdit_detailsCountry.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsCountry.key = "/moi/edit_details/country";

/**
 *
 * Update terms and condition flag
 */
/**
* @package
*/
export const putMoiEdit_detailsCurrent_userTocUpdate = (
  requestBody: { flag_value?: boolean },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiEdit_detailsCurrent_userTocUpdate.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsCurrent_userTocUpdate.key =
  "/moi/edit_details/current_user/toc/update";

/**
 *
 * edit influencer details for current logged in influencer
 */
/**
* @package
*/
export const putMoiEdit_detailsInfluencer = (
  requestBody: InfluencerDetailsEdit,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiEdit_detailsInfluencer.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsInfluencer.key = "/moi/edit_details/influencer";

/**
 *
 * add a bank account
 */
/**
* @package
*/
export const putMoiEdit_detailsInfluencerBank = (
  requestBody: RequestBodyBankAccountDetails,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiEdit_detailsInfluencerBank.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsInfluencerBank.key = "/moi/edit_details/influencer/bank";

/**
 *
 * Add a IL bank account
 */
/**
* @package
*/
export const putMoiEdit_detailsInfluencerBankIl = (
  requestBody: ILBankAccount,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiEdit_detailsInfluencerBankIl.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsInfluencerBankIl.key = "/moi/edit_details/influencer/bank/il";

/**
 *
 * Add an Indian bank account
 */
/**
* @package
*/
export const putMoiEdit_detailsInfluencerBankIndia = (
  requestBody: IndianBankAccount,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiEdit_detailsInfluencerBankIndia.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsInfluencerBankIndia.key =
  "/moi/edit_details/influencer/bank/india";

/**
 *
 * Add an Turkish bank account
 */
/**
* @package
*/
export const putMoiEdit_detailsInfluencerBankTurkey = (
  requestBody: RequestBodyTurkishBankAccount,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiEdit_detailsInfluencerBankTurkey.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsInfluencerBankTurkey.key =
  "/moi/edit_details/influencer/bank/turkey";

/**
 *
 * Add a UK bank account
 */
/**
* @package
*/
export const putMoiEdit_detailsInfluencerBankUk = (
  requestBody: RequestBodyUKBankAccount,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiEdit_detailsInfluencerBankUk.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsInfluencerBankUk.key = "/moi/edit_details/influencer/bank/uk";

/**
 *
 * Add a US bank account
 */
/**
* @package
*/
export const putMoiEdit_detailsInfluencerBankUs = (
  requestBody: InitUSBankAccount,
  queryParams?: PutMoiEdit_detailsInfluencerBankUsQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<InitUSBankAccountSuccess>> => {
  return Http.putRequest(
    putMoiEdit_detailsInfluencerBankUs.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsInfluencerBankUs.key = "/moi/edit_details/influencer/bank/us";

/**
 *
 * edit influencer password for current logged in influencer
 */
/**
* @package
*/
export const putMoiEdit_detailsInfluencerPassword = (
  requestBody: InfluencerChangePassword,
  queryParams?: PutMoiEdit_detailsInfluencerPasswordQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiEdit_detailsInfluencerPassword.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiEdit_detailsInfluencerPassword.key =
  "/moi/edit_details/influencer/password";

/**
 * 
 * Add message from this chat.
Caller is advertiser then :id is influencer_id
Caller is influencer then :id is advertiser_id
Caller is agent then :id is influencer_id and :campaign_id must be set

 */
/**
* @package
*/
export const putMoiInboxId = (
  /**
   *
   * The user id you want to chat with
   */
  id: number,
  requestBody: { message?: string },
  queryParams?: PutMoiInboxIdQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiInboxId.key, { id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiInboxId.key = "/moi/inbox/{id}";

/**
 *
 * Messages to resend
 */
/**
* @package
*/
export const putMoiInboxIdResend_messages = (
  /**
   *
   * The user id you want to chat with
   */
  id: number,
  requestBody: {
    /**
     *
     * - Format: int64
     */
    campaign_id?: number;
    is_private?: boolean;
    message?: string;
    message_content_type?: string;
    /**
     *
     * - Format: uuid
     */
    message_guid?: string;
    reply_object_type?: string;
    reply_to_id?: string;
  }[],
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiInboxIdResend_messages.key, { id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiInboxIdResend_messages.key = "/moi/inbox/{id}/resend_messages";

/**
 *
 * update influencer intro video
 */
/**
* @package
*/
export const putMoiInfluencer_intro_video = (
  requestBody: {
    /**
     *
     * - Format: float
     */
    ratio?: number;
    thumbnail_url?: string;
    video_url?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiInfluencer_intro_video.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiInfluencer_intro_video.key = "/moi/influencer_intro_video";

/**
 * 
 * update mini users allowed for reports

 */
/**
* @package
*/
export const putMoiInsightsReport_idMini_users = (
  report_id: number,
  requestBody: RequestBodyMiniUsersIds,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiInsightsReport_idMini_users.key, { report_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiInsightsReport_idMini_users.key = "/moi/insights/{report_id}/mini_users";

/**
 *
 * update multi report
 */
/**
* @package
*/
export const putMoiMulti_reportReport_id = (
  /**
   *
   * The report id
   */
  report_id: number,
  requestBody: RequestBodyMultiReport,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiMulti_reportReport_id.key, { report_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiMulti_reportReport_id.key = "/moi/multi_report/{report_id}";

/**
 *
 * share roi for ambasador report
 */
/**
* @package
*/
export const putMoiMulti_reportsReport_idShare = (
  /**
   *
   * The id of the auction to share
   */
  report_id: number,
  requestBody: RequestBodyMultiReportShareParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PremiumListSharedLink>> => {
  return Http.putRequest(
    template(putMoiMulti_reportsReport_idShare.key, { report_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiMulti_reportsReport_idShare.key = "/moi/multi_reports/{report_id}/share";

/**
 *
 * share roi for ambasador report
 */
/**
* @package
*/
export const putMoiMulti_reportsReport_idShareShared_guid = (
  /**
   *
   * The id of the auction to share
   */
  report_id: number,
  /**
   *
   * the shared guid id
   */
  shared_guid: string,
  requestBody: RequestBodyMultiReportShareParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PremiumListSharedLink>> => {
  return Http.putRequest(
    template(putMoiMulti_reportsReport_idShareShared_guid.key, {
      report_id,
      shared_guid,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiMulti_reportsReport_idShareShared_guid.key =
  "/moi/multi_reports/{report_id}/share/{shared_guid}";

/**
 *
 * Mark notification as seen
 */
/**
* @package
*/
export const putMoiNotificationsNotificationId = (
  /**
   *
   * Notification id to mark as seen
   */
  notificationId: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiNotificationsNotificationId.key, { notificationId }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiNotificationsNotificationId.key = "/moi/notifications/{notificationId}";

/**
 *
 * Register Firebase Cloud Messaging token for push notification for mobile
 */
/**
* @package
*/
export const putMoiNotificationsRegister_push_token = (
  requestBody: {
    device_language?: "hebrew" | "english" | "turkish" | "portuguese";
    device_os?: "android" | "ios";
    phone_id?: string;
    token?: string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiNotificationsRegister_push_token.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiNotificationsRegister_push_token.key =
  "/moi/notifications/register_push_token";

/**
 *
 * create a dispute for specific usage billing
 */
/**
* @package
*/
export const putMoiPaymentPayment_from_advertiserDispute = (
  requestBody: {
    custom_message?: string;
    /**
     *
     * - Format: int64
     */
    pbi_fk?: number;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiPaymentPayment_from_advertiserDispute.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPaymentPayment_from_advertiserDispute.key =
  "/moi/payment/payment_from_advertiser/dispute";

/**
 *
 * delete payment method
 */
/**
* @package
*/
export const putMoiPaymentPayment_from_advertiserPrimaryPayment_method_id = (
  /**
   *
   * The payment method to delete
   */
  payment_method_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiPaymentPayment_from_advertiserPrimaryPayment_method_id.key, {
      payment_method_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPaymentPayment_from_advertiserPrimaryPayment_method_id.key =
  "/moi/payment/payment_from_advertiser/primary/{payment_method_id}";

/**
 *
 * Save tokenized credit card details
 */
/**
* @package
*/
export const putMoiPaymentPayment_from_advertiserTokenize_credit_card = (
  requestBody: {
    /**
     *
     * YaadPaySoftInitResponse
     *
     */
    init?: {
      /**
       *
       * YaadResponseData
       *
       */
      yaad_soft_response_data?: string;
      yaad_soft_response_headers?: {
        header_key?: string;
        header_value?: string;
      }[];
      /**
       *
       * YaadResponseStatusCode
       *
       * - Format: int32
       * - max: 1000
       */
      yaad_soft_status?: number;
    };
    provider?: "yaad" | "peach";
    /**
     *
     * YaadPayTokenResponse
     *
     */
    token?: {
      /**
       *
       * YaadTokenResponseData
       *
       */
      yaad_token_response_data?: string;
      yaad_token_response_headers?: {
        header_key?: string;
        header_value?: string;
      }[];
      /**
       *
       * YaadTokenResponseStatusCode
       *
       * - Format: int32
       * - max: 1000
       */
      yaad_token_status?: number;
    };
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<SuccessfulCreditCardTokenization>> => {
  return Http.putRequest(
    putMoiPaymentPayment_from_advertiserTokenize_credit_card.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPaymentPayment_from_advertiserTokenize_credit_card.key =
  "/moi/payment/payment_from_advertiser/tokenize_credit_card";

/**
 *
 * pay for premium subscription
 */
/**
* @package
*/
export const putMoiPaymentPayment_from_advertiserUpgrade_to_premium = (
  queryParams?: PutMoiPaymentPayment_from_advertiserUpgrade_to_premiumQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiPaymentPayment_from_advertiserUpgrade_to_premium.key,
    queryParams,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPaymentPayment_from_advertiserUpgrade_to_premium.key =
  "/moi/payment/payment_from_advertiser/upgrade_to_premium";

/**
 *
 * share roi for auction (or reanable)
 */
/**
* @package
*/
export const putMoiPremiumAuctionAuction_idShare = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  requestBody: CampaignShareParameters,
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    campaign?: PremiumListSharedLink;
    list?: PremiumListSharedLink;
    roi?: PremiumListSharedLink;
    tracker?: PremiumListSharedLink;
  }>
> => {
  return Http.putRequest(
    template(putMoiPremiumAuctionAuction_idShare.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumAuctionAuction_idShare.key =
  "/moi/premium/auction/{auction_id}/share";

/**
 *
 * Generate open link, inviting influencers to join a campaign
 */
/**
* @package
*/
export const putMoiPremiumAuctionAuction_idShareInvite_influencers = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<string>> => {
  return Http.putRequest(
    template(putMoiPremiumAuctionAuction_idShareInvite_influencers.key, {
      auction_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumAuctionAuction_idShareInvite_influencers.key =
  "/moi/premium/auction/{auction_id}/share/invite_influencers";

/**
 *
 * edit auction todo item
 */
/**
* @package
*/
export const putMoiPremiumAuctionAuction_idTodoTo_do_id = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  /**
   *
   * The to do item you want to edit
   */
  to_do_id: number,
  requestBody: RequestBodyAuctionToDoItem,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionToDoItem>> => {
  return Http.putRequest(
    template(putMoiPremiumAuctionAuction_idTodoTo_do_id.key, {
      auction_id,
      to_do_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumAuctionAuction_idTodoTo_do_id.key =
  "/moi/premium/auction/{auction_id}/todo/{to_do_id}";

/**
 * 
 * update mini users

 */
/**
* @package
*/
export const putMoiPremiumListList_idMini_users = (
  list_id: number,
  requestBody: RequestBodyMiniUsersIds,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiPremiumListList_idMini_users.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumListList_idMini_users.key =
  "/moi/premium/list/{list_id}/mini_users";

/**
 *
 * Create new list for this user
 */
/**
* @package
*/
export const putMoiPremiumLists = (
  requestBody: {
    description?: string;
    name?: string;
    upload_image?: UploadedImage;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putMoiPremiumLists.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumLists.key = "/moi/premium/lists";

/**
 *
 * add to list
 */
/**
* @package
*/
export const putMoiPremiumListsAdd_to_lists = (
  requestBody: {
    influencers?: string[];
    /**
     *
     * - Format: int64
     */
    list_id?: number;
  }[],
  configOverride?: AxiosRequestConfig,
): Promise<
  SwaggerResponse<{
    changed_ids?: { new_id?: string; old_id?: string }[];
    lists_summary?: ListSummary[];
  }>
> => {
  return Http.putRequest(
    putMoiPremiumListsAdd_to_lists.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumListsAdd_to_lists.key = "/moi/premium/lists/add_to_lists";

/**
 *
 * add to list
 */
/**
* @package
*/
export const putMoiPremiumListsList_id = (
  /**
   *
   * The id of the list to add to
   */
  list_id: number,
  requestBody: RequestBodyUsersToOperate,
  queryParams?: PutMoiPremiumListsList_idQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListSummary>> => {
  return Http.putRequest(
    template(putMoiPremiumListsList_id.key, { list_id }),
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumListsList_id.key = "/moi/premium/lists/{list_id}";

/**
 *
 * edit list
 */
/**
* @package
*/
export const putMoiPremiumListsList_idEdit = (
  /**
   *
   * The id of the list to edit
   */
  list_id: number,
  requestBody: {
    description?: string;
    name?: string;
    upload_image?: UploadedImage;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiPremiumListsList_idEdit.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumListsList_idEdit.key = "/moi/premium/lists/{list_id}/edit";

/**
 *
 * remove users from list
 */
/**
* @package
*/
export const putMoiPremiumListsList_idRemove_influencers = (
  /**
   *
   * The id of the list to operate on
   */
  list_id: number,
  requestBody: RequestBodyUsersToOperate,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListSummary>> => {
  return Http.putRequest(
    template(putMoiPremiumListsList_idRemove_influencers.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumListsList_idRemove_influencers.key =
  "/moi/premium/lists/{list_id}/remove_influencers";

/**
 *
 * share list (or reanable)
 */
/**
* @package
*/
export const putMoiPremiumListsList_idShare = (
  /**
   *
   * The id of the list to share
   */
  list_id: number,
  requestBody: ShareListParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PremiumListSharedLink>> => {
  return Http.putRequest(
    template(putMoiPremiumListsList_idShare.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumListsList_idShare.key = "/moi/premium/lists/{list_id}/share";

/**
 *
 * hide/unhide users on list
 */
/**
* @package
*/
export const putMoiPremiumListsList_idSwitch_hide_influencers = (
  /**
   *
   * The id of the list to operate on
   */
  list_id: number,
  requestBody: RequestBodyUsersToOperate,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListSummary>> => {
  return Http.putRequest(
    template(putMoiPremiumListsList_idSwitch_hide_influencers.key, { list_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumListsList_idSwitch_hide_influencers.key =
  "/moi/premium/lists/{list_id}/switch_hide_influencers";

/**
 *
 * change board tag
 */
/**
* @package
*/
export const putMoiPremiumListsList_idTagTag_id = (
  /**
   *
   * The id of the list
   */
  list_id: number,
  /**
   *
   * The id of the tag
   */
  tag_id: number,
  requestBody: RequestBodyCampaignTag,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiPremiumListsList_idTagTag_id.key, { list_id, tag_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumListsList_idTagTag_id.key =
  "/moi/premium/lists/{list_id}/tag/{tag_id}";

/**
 *
 * share roi for auction (or reanable)
 */
/**
* @package
*/
export const putMoiPremiumRoiAuction_idShare = (
  /**
   *
   * The id of the auction to share
   */
  auction_id: number,
  requestBody: ShareReportParameters,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<PremiumListSharedLink>> => {
  return Http.putRequest(
    template(putMoiPremiumRoiAuction_idShare.key, { auction_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumRoiAuction_idShare.key = "/moi/premium/roi/{auction_id}/share";

/**
 *
 * edit auction todo item
 */
/**
* @package
*/
export const putMoiPremiumTodo_templatesTemplate_idTodoTo_do_id = (
  /**
   *
   * The id of the template to edit
   */
  template_id: number,
  /**
   *
   * The to do item you want to edit
   */
  to_do_id: number,
  requestBody: RequestBodyAuctionToDoItem,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<AuctionToDoItem>> => {
  return Http.putRequest(
    template(putMoiPremiumTodo_templatesTemplate_idTodoTo_do_id.key, {
      template_id,
      to_do_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiPremiumTodo_templatesTemplate_idTodoTo_do_id.key =
  "/moi/premium/todo_templates/{template_id}/todo/{to_do_id}";

/**
 *
 * pin or unpin specific ticket
 */
/**
* @package
*/
export const putMoiSupport_centerTicket_id = (
  /**
   *
   * ticket id to query
   */
  ticket_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiSupport_centerTicket_id.key, { ticket_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiSupport_centerTicket_id.key = "/moi/support_center/{ticket_id}";

/**
 *
 * update task offer
 */
/**
* @package
*/
export const putMoiTask_offersTask_offer_id = (
  /**
   *
   * The id of the offer
   */
  task_offer_id: number,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putMoiTask_offersTask_offer_id.key, { task_offer_id }),
    undefined,
    undefined,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putMoiTask_offersTask_offer_id.key = "/moi/task_offers/{task_offer_id}";

/**
 *
 * update user wizard status to complete \ uncomplete
 */
/**
* @package
*/
export const putPixel_wizard = (
  requestBody: { status: boolean },
  headerParams?: {
    "x-pixel-wizard-pass": string;
    /**
     *
     * - Format: uuid
     * - minLength: 1
     */
    "x-pixel-wizard-uuid": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putPixel_wizard.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
putPixel_wizard.key = "/pixel_wizard/";

/**
 *
 * add or edit note
 */
/**
* @package
*/
export const putSharesListsInfluencer_idNote = (
  /**
   *
   * the influencer to operate on
   */
  influencer_id: number,
  requestBody: { note?: string },
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putSharesListsInfluencer_idNote.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
putSharesListsInfluencer_idNote.key = "/shares/lists/{influencer_id}/note";

/**
 *
 * approve or disaprove influencer
 */
/**
* @package
*/
export const putSharesListsInfluencer_idResponse = (
  /**
   *
   * the influencer to operate on
   */
  influencer_id: number,
  requestBody: { approve?: boolean },
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "auth-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<ListSummary>> => {
  return Http.putRequest(
    template(putSharesListsInfluencer_idResponse.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
putSharesListsInfluencer_idResponse.key =
  "/shares/lists/{influencer_id}/response";

/**
 *
 * add influencers to user
 */
/**
* @package
*/
export const putShopifyManageInfluencers = (
  requestBody: { influencers?: number[] },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putShopifyManageInfluencers.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putShopifyManageInfluencers.key = "/shopify/manage/influencers";

/**
 *
 * add media influencer
 */
/**
* @package
*/
export const putShopifyManageInfluencersMediaInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  requestBody: {
    deleted_media?: WixInfluencerMedia[];
    deleted_stories?: WixStoryView[];
    new_media?: WixInfluencerMedia[];
    new_stories?: WixStoryView[];
    updated_media?: WixInfluencerMedia[];
    updated_stories?: WixStoryView[];
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<WixInfluencerManage>> => {
  return Http.putRequest(
    template(putShopifyManageInfluencersMediaInfluencer_id.key, {
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putShopifyManageInfluencersMediaInfluencer_id.key =
  "/shopify/manage/influencers/media/{influencer_id}";

/**
 *
 * Save ROI filter
 */
/**
* @package
*/
export const putShopifyRoiFilter = (
  requestBody: RequestBodyCampaignReportFilterParameters,
  queryParams: PutShopifyRoiFilterQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putShopifyRoiFilter.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putShopifyRoiFilter.key = "/shopify/roi/filter";

/**
 *
 * update user settings
 */
/**
* @package
*/
export const putShopifySettings = (
  requestBody: RequestBodyWixUserSettings,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putShopifySettings.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putShopifySettings.key = "/shopify/settings";

/**
 *
 * influencer response for invite
 */
/**
* @package
*/
export const putUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response =
  (
    /**
     *
     * The id of the board
     */
    board_id: number,
    /**
     *
     * The id of the influencer
     */
    influencer_id: number,
    requestBody: RequestBodyInviteResponse,
    queryParams?: PutUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_responseQueryParams,
    headerParams?: {
      /**
       *
       * - minLength: 1
       */
      "x-api-key": string;
    },
    configOverride?: AxiosRequestConfig,
  ): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
      template(
        putUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response.key,
        { board_id, influencer_id },
      ),
      queryParams,
      requestBody,
      undefined,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT1,
            ...headerParams,
          },
        },
        configOverride,
      ),
    );
  };

/** Key is end point string without base url */
putUtilsAuctionManageBoard_idInvite_influencersInfluencer_idInfluencer_response.key =
  "/utils/auction/manage/{board_id}/invite_influencers/{influencer_id}/influencer_response";

/**
 *
 * Response on behalf of a influencer for task invite
 */
/**
* @package
*/
export const putUtilsTask_invite_responseInfluencer_idTask_id = (
  /**
   *
   * The id of the task
   */
  task_id: number,
  /**
   *
   * The id of the influencer
   */
  influencer_id: number,
  requestBody: RequestBodyInviteResponse,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "x-api-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putUtilsTask_invite_responseInfluencer_idTask_id.key, {
      task_id,
      influencer_id,
    }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
putUtilsTask_invite_responseInfluencer_idTask_id.key =
  "/utils/task_invite_response/{influencer_id}/{task_id}";

/**
 *
 * Response on behalf of a influencer for task invite
 */
/**
* @package
*/
export const putUtilsTrash_influencerAuction_idInfluencer_id = (
  /**
   *
   * The id of the auction
   */
  auction_id: number,
  /**
   *
   * The id of the influencer
   */
  influencer_id: number,
  headerParams?: {
    /**
     *
     * - minLength: 1
     */
    "x-api-key": string;
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    template(putUtilsTrash_influencerAuction_idInfluencer_id.key, {
      auction_id,
      influencer_id,
    }),
    undefined,
    undefined,
    undefined,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT1,
          ...headerParams,
        },
      },
      configOverride,
    ),
  );
};

/** Key is end point string without base url */
putUtilsTrash_influencerAuction_idInfluencer_id.key =
  "/utils/trash_influencer/{auction_id}/{influencer_id}";

/**
 *
 * add influencers to user
 */
/**
* @package
*/
export const putWixManageInfluencers = (
  requestBody: { influencers?: number[] },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putWixManageInfluencers.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWixManageInfluencers.key = "/wix/manage/influencers";

/**
 *
 * add media influencer
 */
/**
* @package
*/
export const putWixManageInfluencersMediaInfluencer_id = (
  /**
   *
   * the influencer id
   */
  influencer_id: number,
  requestBody: {
    deleted_media?: WixInfluencerMedia[];
    deleted_stories?: WixStoryView[];
    new_media?: WixInfluencerMedia[];
    new_stories?: WixStoryView[];
    updated_media?: WixInfluencerMedia[];
    updated_stories?: WixStoryView[];
  },
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<WixInfluencerManage>> => {
  return Http.putRequest(
    template(putWixManageInfluencersMediaInfluencer_id.key, { influencer_id }),
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWixManageInfluencersMediaInfluencer_id.key =
  "/wix/manage/influencers/media/{influencer_id}";

/**
 *
 * Save ROI filter
 */
/**
* @package
*/
export const putWixRoiFilter = (
  requestBody: RequestBodyCampaignReportFilterParameters,
  queryParams: PutWixRoiFilterQueryParams,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putWixRoiFilter.key,
    queryParams,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWixRoiFilter.key = "/wix/roi/filter";

/**
 *
 * update user settings
 */
/**
* @package
*/
export const putWixSettings = (
  requestBody: RequestBodyWixUserSettings,
  configOverride?: AxiosRequestConfig,
): Promise<SwaggerResponse<any>> => {
  return Http.putRequest(
    putWixSettings.key,
    undefined,
    requestBody,
    undefined,
    overrideConfig(_CONSTANT0, configOverride),
  );
};

/** Key is end point string without base url */
putWixSettings.key = "/wix/settings";
/**
* @package
*/
export const _CONSTANT0 = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
/**
* @package
*/
export const _CONSTANT1 = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
/**
* @package
*/
export const _CONSTANT2 = {
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*",
  },
};
/**
* @package
*/
export const _CONSTANT3 = {
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
};
