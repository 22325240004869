// TODO - lint
/* eslint-disable react/no-deprecated, no-unused-vars, camelcase, camelcase, no-unused-vars, no-unused-vars, camelcase, camelcase, class-methods-use-this, consistent-return, consistent-return, camelcase, consistent-return, camelcase, camelcase, no-unused-vars, no-unused-vars, no-unused-vars, no-unused-vars, camelcase, camelcase, import/no-default-export */
import { Box, Button, Spinner } from "@chakra-ui/react";
import Tabs, { TabPane } from "rc-tabs";
import InkTabBar from "rc-tabs/lib/InkTabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tooltip from "rc-tooltip";
import React from "react";
import Infinite from "react-infinite";
import { connect } from "react-redux";
import { Rnd } from "react-rnd";
import { useNavigate } from "react-router-dom";
import HumanzButtonGroup from "src/ui/humanz/HumanzButtonGroup";
import {
  MAX_MESSAGES_AMOUNT,
  getAvailableChats,
  getAvailableInfluencersChats,
  getChatMessages,
  openNewChat,
} from "../../../actions/inboxActions";
import NOIMAGE from "../../../images/svgs/placeholder.svg";
import ChatSection from "./ChatSection";
import StartNewChat from "./StartNewChat";

let campaignSearchTimeout = null;
let influencerSearchTimeout = null;
class ChatsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      selectedChatCampaignId: null,
      selectedChatInfluencerId: null,
      chatLoading: false,
      selectedChatTitle: null,
      innerChat: {},
      propsData: {},
      textAreaHeight: 32,
      height: window.innerHeight >= 800 ? 720 : window.innerHeight - 60,
      width: 900,
      selectedColumn: "",
      isPagingLoading: false,
      searchInfluencer: "",
      is_starred: false,
      isPrivate: false,
      influencer: {},
    };
    this.system_message_to_label = {
      1: "system_message_bid_on_auction_",
      2: "system_message_selected_for_campaign_",
      3: "system_message_selected_for_express_campaign_",
      4: "system_message_proposal_created_",
      5: "system_message_proposal_approved_",
      6: "system_message_proposal_declined_",
      7: "system_message_post_linked_",
    };

    this.columns = [
      { label: "BIDS", name: "bids" },
      { label: "INVITES", name: "wait_influencer" },
      { label: "APPROVAL", name: "wait_approval" },
      { label: "PUBLISH", name: "done" },
      { label: "trash", name: "trash" },
    ];
  }

  componentWillMount() {
    this.props.getAvailableChats(true, null, null, null, (res) => {});
    this.openChatFromProps();
  }

  componentDidUpdate() {
    if (
      this.props.data.campaign_id !== this.state.propsData.campaign_id ||
      this.props.data.influencer_id !== this.state.propsData.influencer_id
    ) {
      this.openChatFromProps();
    }
  }

  moveToInboxScreen = () => {
    this.props.handleClose(
      true,
      this.state.selectedChatCampaignId,
      this.state.selectedChatInfluencerId,
    );
  };

  moveToCampaign = () => {
    this.props.navigate(
      `/campaign/${this.state.selectedChatCampaignId}/manage`,
    );
  };

  selectCampaign(campaign_id) {
    this.setState({
      selectedChatCampaignId: campaign_id,
    });
  }

  openChatFromProps() {
    const { data } = this.props;
    this.setState({ propsData: data });
    if (data.influencer_id) {
      const chat = { influencer_id: data.influencer_id };
      if (data.new_chat) {
        this.setState({
          chatLoading: true,
          selectedChatInfluencerId: chat.influencer_id,
        });
        openNewChat(chat.influencer_id, (success) => {
          if (success) {
            this.openChat(chat);
          } else {
            this.setState({
              chatLoading: false,
              selectedChatInfluencerId: null,
              searchInfluencer: null,
            });
          }
        });
      } else if (data.campaign_id) {
        this.setState({ selectedChatCampaignId: data.campaign_id }, () =>
          this.openChat(chat),
        );
      } else {
        this.openChat(chat);
      }
    }
  }

  openChatSegmentations = (id) => {
    if (id) this.props.openInfluencerSegmentations(id);
  };

  openChat(chatToOpen, openedNew) {
    const campaignId =
      this.state.selectedChatCampaignId === -1
        ? null
        : this.state.selectedChatCampaignId;

    let chat;
    if (openedNew && chatToOpen.user_id) {
      const influencerChats = this.props.inbox.users_chats.influencers
        .filter(
          (x) =>
            x.influencer_id === chatToOpen.influencer_id &&
            x.auction_id === chatToOpen.auction_id,
        )
        .sort((a, b) => b.unseen_messages_count - a.unseen_messages_count);

      if (!this.props.inbox.users_chats.influencers.length) {
        return;
      }

      chat =
        influencerChats?.length > 1
          ? influencerChats.find(
              (x) => x.unseen_messages_count > 0 || !x.agent?.is_private,
            )
          : influencerChats[0];

      if (!chat) {
        chat = influencerChats[0];
      }
    } else {
      chat = chatToOpen;
    }

    this.setState({
      selectedChatInfluencerId: chat.influencer_id,
      chatLoading: true,
      innerChat: {
        name: chat?.name || chat?.influencer_social_network_data?.name,
        picture_url:
          chat?.picture_url || chat?.influencer_social_network_data?.image,
        column: chat.campaign_column,
        starred: chat.is_starred,
        agent: chat?.agent,
        user_id: chat.user_id,
        auction_id: chat.auction_id,
        influencer_id: chat.influencer_id,
        user_registered: !!chat.user_id,
      },

      openSegmentatios: () => this.openChatSegmentations(chat.influencer_id),
    });

    this.setState({ isPrivate: chat.agent?.is_private });

    this.props.getChatMessages(
      chat.influencer_id,
      campaignId,
      null,
      null,
      MAX_MESSAGES_AMOUNT,
      null,
      "desc",
      chat.agent?.is_private,
      () => this.openChatCallback(),
    );
  }

  openChatCallback = () => {
    this.setState({ chatLoading: false });
    window.setTimeout(() => {
      const objDiv = document.getElementById("chat_container");
      if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
    }, 1);
  };

  showChat(chat) {
    // hide influencers
    if (chat.campaign_id === this.state.selectedChatCampaignId) {
      this.setState(
        {
          selectedChatTitle: "",
          selectedChatInfluencerId: null,
          selectedChatCampaignId: null,
          searchInfluencer: null,
          selectedChat: null,
          isStarred: false,
        },
        () => {
          this.getCampaignInfluencersChats(
            this.state.selectedChatCampaignId,
            true,
            (res) => {
              this.setState({ influencersEndReached: false });
            },
          );
        },
      );
      // show influencers
    } else {
      this.setState(
        {
          selectedChatTitle: chat.title,
          selectedChatInfluencerId: null,
          selectedChatCampaignId: chat.campaign_id,
          searchInfluencer: null,
        },
        () => {
          this.getCampaignInfluencersChats(
            this.state.selectedChatCampaignId,
            true,
            (res) => {
              this.setState({ influencersEndReached: false });
            },
          );
        },
      );
    }
  }

  renderChatRow = (chat, index) => {
    const unseen = this.props.inbox.new_chats[chat.campaign_id];
    const isOpen = chat.campaign_id === this.state.selectedChatCampaignId;
    return !this.state.campaignsUnreadFilter ||
      (this.state.campaignsUnreadFilter && unseen) ? (
      <div
        className={`chat-row ${isOpen}`}
        key={`chat${chat.campaign_id}_${index}`}
        onClick={() => (isOpen ? null : this.showChat(chat))}
      >
        <div>
          <img className="avatar" src={chat.image ? chat.image : NOIMAGE} />
          <span className="campaign-title" title={chat.title}>
            {chat.title}
          </span>
        </div>
        {unseen && (
          <div className="notification-chat-badge">{unseen.length}</div>
        )}
      </div>
    ) : null;
  };

  renderUserChatRow = (chat, i) => {
    let current = false;
    if (this.state.selectedChatInfluencerId) {
      current = this.state.selectedChatInfluencerId === chat.influencer_id;
    }
    const auction_id = chat.auction_id ? chat.auction_id : 0;
    const unseen = this.props.inbox.new_chats[auction_id]?.includes(
      chat.influencer_id,
    );
    const isRegistered = !!chat.user_id;

    return !this.state.influencersUnreadFilter ||
      (this.state.influencersUnreadFilter && unseen) ? (
      <div
        className={`chat-row ${current}`}
        key={`chat${chat.influencer_id}_${i}`}
        onClick={() => (current ? null : this.openChat(chat, true))}
      >
        <div className="relative">
          {chat?.agent && (
            <Tooltip overlay={`Agent: ${chat.agent.agent_name}`}>
              <div className="agent-icon">
                <i className="fas fa-user-tie" />
              </div>
            </Tooltip>
          )}
          <img
            className="avatar"
            src={
              chat.picture_url && chat.picture_url.replace(".jpg", "_small.jpg")
            }
            style={{
              opacity: isRegistered ? 1 : 0.5,
            }}
          />
          <span title={chat.name}>{chat.name}</span>
        </div>
        <div>
          {unseen ? (
            <div className="notification-chat-badge">{unseen}</div>
          ) : null}
          {chat.is_starred ? <i className={"fas fa-star"} /> : null}
        </div>
      </div>
    ) : null;
  };

  searchInfluencer = (e) => {
    const searchInfluencer = e ? e.target.value?.toLowerCase() : "";
    this.setState({ searchInfluencer: searchInfluencer }, () => {
      const objDiv = document.querySelector(
        ".influencers-search-infinite-categories",
      );
      if (objDiv) objDiv.scrollTop = 0;
    });
  };

  render() {
    if (this.props.floating) return this.renderFloatingContainer();
    return this.renderPageContainer();
  }

  elementInfiniteLoad = (loader) => {
    if (!loader) return;
    return (
      <div className="infinite-list-item">
        <Spinner />
      </div>
    );
  };

  handleInfiniteLoad = () => {
    if (this.state.campaignsEndReached) return;
    this.setState({ isPagingLoading: true }, () => {
      const { chats } = this.props.inbox;
      if (!chats || !chats.length) {
        this.setState({ isPagingLoading: false });
        return false;
      }
      const last_created_at = chats[chats.length - 1].created_at;
      this.props.getAvailableChats(
        false,
        null,
        last_created_at,
        this.state.search,
        (res) => {
          this.setState({
            chatsLoaded: true,
            campaignsEndReached: !res || res.length === 0,
            isPagingLoading: false,
            influencersEndReached: false,
          });
        },
      );
    });
  };

  getCampaignInfluencersChats = (campaign_id, cleanStars, callback) => {
    this.setState(
      { isInfluencerPagingLoading: true, searchInfluencer: null },
      () => {
        setTimeout(() => {
          document
            .querySelectorAll(
              ".campaigns-search-infinite-categories .chat-row.true",
            )?.[0]
            ?.scrollIntoView();
        }, 10);
        this.props.getAvailableInfluencersChats(
          true,
          cleanStars,
          null,
          null,
          campaign_id,
          this.state.is_starred,
          (res) => {
            callback(res);
            this.setState(
              {
                isInfluencerPagingLoading: false,
                InfluencersEndReached: true,
                influencersChats: res?.influencers,
              },
              () => {
                this.searchInfluencer(null);
              },
            );
          },
        );
      },
    );
  };

  searchCampaign = (e) => {
    const search = e?.target?.value?.toLowerCase();
    if (campaignSearchTimeout) clearTimeout(campaignSearchTimeout);
    if (this.state.campaignsUnreadFilter) {
      this.setState(
        {
          campaignsChats: this.state?.campaignsChats?.filter(
            (chat) => chat.unread_count > 0,
          ),
        },
        () => {
          const objDiv = document.querySelector(
            ".campaigns-search-infinite-categories",
          );
          if (objDiv) objDiv.scrollTop = 0;
        },
      );
    }
    campaignSearchTimeout = setTimeout(() => {
      if (search !== this.state.search) {
        this.setState({
          isPagingLoading: true,
          influencersChats: [],
          searchInfluencer: null,
          selectedChatCampaignId: null,
        });
        this.props.getAvailableChats(true, null, null, search, (res) => {
          this.setState(
            {
              isPagingLoading: false,
              campaignsEndReached: false,
              search,
              searchInfluencer: null,
              influencersEndReached: true,
              selectedChatInfluencerId: null,
              // influencersUnreadFilter: false,
              // campaignsUnreadFilter: false,
            },
            () => {
              if (res.length > MAX_MESSAGES_AMOUNT) {
                this.handleInfiniteLoad();
              }
            },
          );
        });
      }
    }, 250);
  };

  renderInfluencersChatContainer(key, usersChats, starredChats) {
    return (
      <Infinite
        elementHeight={66}
        containerHeight={
          key !== "all" || this.state.selectedChatCampaignId !== -1
            ? (this.state.height - 140) * 0.48 - 25
            : this.state.height - 260
        }
        className="influencers-search-infinite-categories"
        timeScrollStateLastsForAfterUserScrolls={0}
        useWindowAsScrollContainer={false}
        infiniteLoadBeginEdgeOffset={0}
        preloadBatchSize={0.5}
        onInfiniteLoad={() => {}}
        isInfiniteLoading={false}
      >
        {key === "all"
          ? usersChats.map(this.renderUserChatRow)
          : starredChats.map(this.renderUserChatRow)}
      </Infinite>
    );
  }

  onResize = (e, direction, ref, delta, position) => {
    if (direction === "top") {
      this.setState({
        height: ref.style.height.replace("px", ""),
      });
    } else {
      this.setState({
        width: ref.style.width.replace("px", ""),
        height: ref.style.height.replace("px", ""),
      });
    }
  };

  switchTabs = (key) => {
    if (key === "starred") {
      this.setState({ is_starred: true }, () => {
        this.getCampaignInfluencersChats(
          this.state.selectedChatCampaignId,
          false,
          (res) => {
            this.setState({
              influencersEndReached: true,
            });
          },
        );
      });
    } else {
      this.setState({ is_starred: false }, () => {
        this.getCampaignInfluencersChats(
          this.state.selectedChatCampaignId,
          false,
          (res) => {
            this.setState({
              influencersEndReached: true,
            });
          },
        );
      });
    }
  };

  renderInboxContainer = () => {
    const { searchInfluencer } = this.state;
    let usersChats = null;
    let starredChats = [];
    if (this.state.selectedChatCampaignId) {
      usersChats = this.props.inbox.users_chats.influencers;
      if (usersChats?.length) {
        if (searchInfluencer) {
          usersChats = usersChats.filter(
            (user) =>
              user?.name &&
              user?.name?.toLowerCase()?.includes(searchInfluencer),
          );
        }
        usersChats = usersChats.filter(
          (user, index, self) =>
            index ===
            self.findIndex((u) => u.influencer_id === user.influencer_id),
        );

        starredChats = usersChats?.filter((x) => x.is_starred);
      }
    }
    const height = this.state.height - 117;

    return (
      <div
        className="inbox-container"
        style={{ width: "100%", height: this.state.height }}
      >
        <div className="inbox-left-side" style={{ width: 320 }}>
          <div className="chats-container">
            <div
              className={`channel-category chat-row ${
                this.state.selectedChatCampaignId === -1
              }`}
              style={{ marginBottom: 5, marginTop: 0 }}
              key={"chat-all-influencers"}
              onClick={() => this.showChat({ title: "ALL", campaign_id: -1 })}
            >
              <div
                className="flex"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  flex: 1,
                  width: "100%",
                }}
              >
                <div
                  className="flex"
                  style={{
                    alignItems: "center",
                    height: 40,
                  }}
                >
                  <i className="channel-icon moi-influencer-circles" />
                  <span className="campaign-title" title={"All Influencers"}>
                    {"All Influencers"}
                  </span>
                </div>
                {this.props.inbox.new_chats &&
                  this.props.inbox.new_chats[0] && (
                    <div
                      className="notification-chat-badge all-influencers"
                      style={{ paddingTop: 0, marginTop: 0 }}
                    >
                      {this.props.inbox.new_chats[0].length}
                    </div>
                  )}
              </div>
            </div>

            <div
              className={`channel-category ${
                this.state.selectedChatCampaignId === null
              }`}
            >
              <div
                className="flex"
                style={{
                  alignItems: "center",
                  height: 48,
                  display: "flex",
                  padding: "19px 6px",
                  gap: 7,
                  width: "100%",
                }}
              >
                <i
                  className="channel-icon fas fa-megaphone"
                  style={{ fontSize: 12 }}
                />
                <div
                  className="campaign-title clickable"
                  title={"Campaigns"}
                  style={{ width: "100%" }}
                  onClick={() =>
                    this.showChat({ selectedChatCampaignId: null })
                  }
                >
                  {"Campaigns"}
                </div>
              </div>
              {(this.state.selectedChatCampaignId === null ||
                this.state.selectedChatCampaignId !== -1) && (
                <>
                  <div
                    className="flex"
                    style={{ gap: 5, paddingLeft: 5, paddingRight: 5 }}
                  >
                    <div className="searchbox-shadow">
                      <div className="moi-search-bold" />
                      <input
                        onChange={this.searchCampaign}
                        placeholder={"Campaign"}
                        className="clickable form-control"
                      />
                    </div>
                    {/* <HumanzButtonGroup>
                      {["All", "Unread"].map((v) => (
                        <Button
                          key={v}
                          rounded="full"
                          variant={"roundedWhite"}
                          isActive={
                            this.state.campaignsUnreadFilter
                              ? v === "Unread"
                              : v === "All"
                          }
                          onClick={() => {
                            this.setState(
                              {
                                campaignsUnreadFilter: v === "Unread",
                              },

                              this.searchCampaign,
                            );
                          }}
                          style={{ height: 25, fontSize: 12 }}
                        >
                          {v}
                        </Button>
                      ))}
                    </HumanzButtonGroup> */}
                  </div>
                  <Infinite
                    elementHeight={66}
                    containerHeight={
                      this.state.selectedChatCampaignId
                        ? height * 0.48 - 120
                        : height - 50
                    }
                    className="campaigns-search-infinite-categories "
                    timeScrollStateLastsForAfterUserScrolls={0}
                    useWindowAsScrollContainer={false}
                    infiniteLoadBeginEdgeOffset={0}
                    preloadBatchSize={0.5}
                    onInfiniteLoad={this.handleInfiniteLoad}
                    loadingSpinnerDelegate={this.elementInfiniteLoad(
                      this.state.isPagingLoading,
                    )}
                    isInfiniteLoading={this.state.isPagingLoading}
                  >
                    {this.props.chatsLoaded &&
                      this.props.inbox.chats.map(this.renderChatRow)}
                  </Infinite>
                </>
              )}
            </div>
          </div>

          {usersChats && (
            <div className={"influencersChats"}>
              <div className="chat-section-header">
                <div
                  className="choose-influ-campagin"
                  style={{
                    display: "inline-block",
                    verticalAlign: "bottom",
                  }}
                >
                  Influencers from{" "}
                  <span
                    className="blue-text-button ellipsis"
                    style={{
                      maxWidth: 155,
                      display: "inline-block",
                      verticalAlign: "bottom",
                    }}
                    title={this.state.selectedChatTitle}
                  >
                    {this.state.selectedChatTitle}
                  </span>
                </div>
                <div
                  className="flex"
                  style={{ gap: 5, marginTop: 5, paddingRight: 5 }}
                >
                  <div className="searchbox-shadow">
                    <div className="moi-search-bold" />
                    <input
                      placeholder={"Influencer"}
                      value={this.state.searchInfluencer}
                      onChange={(e) => this.searchInfluencer(e)}
                      className="clickable form-control"
                    />
                  </div>
                  <HumanzButtonGroup>
                    {["All", "Unread"].map((v) => (
                      <Button
                        key={v}
                        rounded="full"
                        variant={"roundedWhite"}
                        isActive={
                          this.state.influencersUnreadFilter
                            ? v === "Unread"
                            : v === "All"
                        }
                        onClick={() => {
                          this.setState(
                            {
                              influencersUnreadFilter: v === "Unread",
                            },

                            this.searchInfluencer,
                          );
                        }}
                        style={{ height: 25, fontSize: 12 }}
                      >
                        {v}
                      </Button>
                    ))}
                  </HumanzButtonGroup>
                </div>
              </div>
              <Tabs
                style={{
                  height:
                    this.state.selectedChatCampaignId !== -1
                      ? height * 0.48
                      : height - 100,
                }}
                renderTabBar={() => <InkTabBar />}
                renderTabContent={() => <TabContent />}
                onChange={(key) => this.switchTabs(key)}
              >
                <TabPane tab={`ALL`} key="all">
                  {this.renderInfluencersChatContainer(
                    "all",
                    usersChats,
                    starredChats,
                  )}
                  <div className="loader-absolute">
                    {this.elementInfiniteLoad(
                      this.state.isInfluencerPagingLoading,
                    )}
                  </div>
                </TabPane>
                {/* <TabPane
                  tab={`STARRED (${
                    this.props.inbox.users_chats.starred_count || 0
                  })`}
                  key="starred"
                >
                  {this.renderInfluencersChatContainer(
                    "starred",
                    usersChats,
                    starredChats,
                  )}
                  <div className="loader-absolute">
                    {this.elementInfiniteLoad(
                      this.state.isInfluencerPagingLoading,
                    )}
                  </div>
                </TabPane> */}
              </Tabs>
            </div>
          )}
        </div>

        {this.state.selectedChatInfluencerId ? (
          this.state.chatLoading ? (
            <div className="infinite-list-item">
              <Spinner />
            </div>
          ) : (
            <ChatSection
              {...this.state}
              setIsPrivate={(isPrivate) => {
                this.setState({ isPrivate });
                const relevantChat =
                  this.props.inbox.users_chats.influencers.filter(
                    (chat) =>
                      chat?.influencer_id ===
                        this.state.selectedChatInfluencerId &&
                      (isPrivate
                        ? chat?.agent?.is_private === isPrivate
                        : !chat?.agent || !chat?.agent?.is_private),
                  ) || [];
                this.openChat(relevantChat?.[0]);
              }}
              containerWidth={"calc(100% - 320px)"}
              chatContainerHeight={height + 40}
            />
          )
        ) : (
          <Box width={"100%"} flex={1} alignContent={"center"}>
            <StartNewChat text="Select a chat" />
          </Box>
        )}
      </div>
    );
  };

  renderPageContainer() {
    return (
      <div style={{ paddingTop: 60, minWidth: "var(----main-inner-size)" }}>
        <div
          style={{
            padding: 0,
            height: this.state.height,
            overflowY: "hidden",
            overflowX: "hidden",
          }}
        >
          {this.renderInboxContainer()}
        </div>
      </div>
    );
  }

  renderFloatingContainer() {
    const { width, selectedChatCampaignId } = this.state;
    return (
      <Rnd
        default={{
          x: window.innerWidth / 2 - width / 2,
          y: window.scrollY + 40,
        }}
        minHeight={this.state.height}
        minWidth={width}
        className="chat-draggable"
        cancel={".clickable"}
        onResize={this.onResize}
        bounds={"parent"}
        enableResizing={false}
      >
        <div
          className="far fa-times"
          onClick={() => this.props.handleClose()}
          style={{ marginLeft: width - 30 }}
        />
        <div
          className="far fa-expand-arrows clickable"
          onClick={this.moveToInboxScreen}
          style={{ marginLeft: width - 60 }}
        />
        {selectedChatCampaignId && selectedChatCampaignId > -1 && (
          <div
            className="moi-megaphone clickable"
            onClick={this.moveToCampaign}
            style={{ marginLeft: width - 90 }}
          />
        )}

        <div
          style={{
            padding: 0,
            height: "100%",
            overflowY: "hidden",
            overflowX: "hidden",
            width,
          }}
        >
          {this.renderInboxContainer()}
        </div>
      </Rnd>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inbox: state.inboxReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChatMessages: (
      id,
      campaignId,
      from,
      to,
      amount,
      offset,
      order,
      isPrivate,
      callback,
    ) => {
      dispatch(
        getChatMessages(
          id,
          campaignId,
          from,
          to,
          amount,
          offset,
          order,
          isPrivate,
          callback,
        ),
      );
    },
    getAvailableChats: (
      cleanMessages,
      type,
      max_seen_time,
      nameFilter,
      callBack,
    ) => {
      dispatch(
        getAvailableChats(
          cleanMessages,
          type,
          max_seen_time,
          nameFilter,
          callBack,
        ),
      );
    },
    getAvailableInfluencersChats: (
      cleanMessages,
      cleanStars,
      max_seen_time,
      nameFilter,
      auctionId,
      starred,
      callBack,
    ) => {
      dispatch(
        getAvailableInfluencersChats(
          cleanMessages,
          cleanStars,
          max_seen_time,
          nameFilter,
          auctionId,
          starred,
          callBack,
        ),
      );
    },
  };
};

const ChatsSectionConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatsSection);

export default (props) => {
  const navigate = useNavigate();

  return <ChatsSectionConnected {...props} navigate={navigate} />;
};
