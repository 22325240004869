import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { environmentName } from "./actions/commonActionsConf";

Sentry.init({
  dsn: "https://030c3f931131483e953f7573ec5b813f@o943509.ingest.us.sentry.io/4505108878393344",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Session Replay
  environment: environmentName,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: environmentName === "prod" ? 1 : 0.2,
  sourceMaps: environmentName === "prod",
  disableLogger: environmentName !== "prod",
  enabled: environmentName !== "localhost",
});
